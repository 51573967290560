import { Link, Box, Button, OutlinedInput, CircularProgress, IconButton } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import { storeAccessAuthToken, storeRefreshAuthToken } from "../../utils/authToken";
import alertSZ from "../../utils/alert";
import { useMediaQuery } from 'react-responsive'
import { IoMailOutline } from "react-icons/io5";
import { RiLockPasswordLine } from "react-icons/ri";
import { AiOutlineEye } from "react-icons/ai";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { Helmet } from "react-helmet";
import axios from "axios";
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next";
import { API_CR_SIGN_IN } from "../../share/api";
import { useTheme } from '@mui/material/styles';

const useStyles = makeStyles({
    input: {
        WebkitBoxShadow: '0 0 0 1000px white inset',
    },
});

const LandingPage = () => {

    const { setIsLogin } = useContext(AuthContext);
    const theme = useTheme();
    const { t } = useTranslation();
    const urlParams = new URLSearchParams(window.location.search);
    const emailVerified = urlParams.get('emailVerified');
    
    useEffect(() => {
        if (emailVerified === 'true') {
            alertSZ(t('loginpage.email_verified'));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t])

    const mBreakPoint0_1280 = useMediaQuery({ query: '(min-width: 0px) and (max-width: 1280px)' });
    const mBreakPoint1281_1366 = useMediaQuery({ query: '(min-width: 1281px) and (max-width: 1768px)' });

    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const history = useNavigate()
    const [mStateForRegButton, setStateForRegButton] = React.useState(false);

    const [mEmail, setEmail] = useState();
    const [mPwd, setPwd] = useState();
    const [mRequiredMark, setRequiredMark] = useState(true);

    const styleClass = useStyles();
    const handleKeyPress = (event) => {
        if (event && event.key === 'Enter') {
            event.preventDefault();
            handleChange(event);
        }
    };

    const handleChange = event => {

        if (!mEmail || !mPwd) {
            setRequiredMark(false)
            return false;
        }

        setStateForRegButton(true)

        const signIn = async () => {
            await axios.post(API_CR_SIGN_IN, {
                mEmail,
                mPwd
            }).then(res => {
                setStateForRegButton(false)
                if (res.status === 204) {
                    history('/verifyemail');
                    return;
                }
                if (res.status === 200) {
                    const tokenData = res.data.tokendata;
                    const { refreshToken, accessToken } = tokenData;

                    storeAccessAuthToken(accessToken);
                    storeRefreshAuthToken(refreshToken);
                    setIsLogin(true);
                    const queryParams = new URLSearchParams(window.location.search);
                    if (queryParams.has("redirecturl")) {
                        try {
                            const url = queryParams.get("redirecturl");
                            window.open(url, '_self');
                        } catch (error) {
                            history('/dashboard');
                        }
                        return
                    }
                    history('/dashboard');
                    return;
                }
            }).catch(e => {
                setStateForRegButton(false)
                const text = e.response.data
                alertSZ(t(`backend.${text}`));
                return;
            })
        }
        signIn();
    };

    return (
        <Box>
            <Helmet>
                <title>{t('loginpage.signin')} - Multifun</title>
            </Helmet>
            {!mBreakPoint0_1280 && (
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    backgroundColor: 'white',
                    overflow: 'hidden',
                    height: '100vh',
                }}>
                    <Box sx={{
                        width: '100%',
                        overflow: 'hidden',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        position: 'relative',
                        alignItems: 'center',
                        backgroundImage: 'url("/Images/Ellipse1.png")',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat'
                    }}>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                            <img src={process.env.PUBLIC_URL + '/Images/multifunlogo.svg'}
                                alt="logo"
                                style={{
                                    width: '250px',
                                    cursor: 'pointer'
                                }} onClick={() => history('/')} />
                            <Box sx={{
                                marginTop: '100px',
                                paddingLeft: '24px', paddingRight: '24px',
                                borderColor: 'rgba(0,0,0,0.1)',
                                borderWidth: '1px',
                                borderStyle: 'solid',
                                backgroundColor: 'rgba(255, 255, 255, 1)',
                                borderRadius: '8px',
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: 'center',
                                zIndex: '2'
                            }}>
                                <p style={{ margin: 0, fontWeight: 500, fontSize: '25px', lineHeight: '36px', marginTop: '24px', fontFamily: theme.typography.fontFamily, color: 'black' }}>
                                    {t('loginpage.signin')}
                                </p>
                                <p style={{ margin: 0, fontWeight: 500, fontSize: '15px', lineHeight: '20px', marginTop: '20px', fontFamily: theme.typography.fontFamily, color: 'rgba(101,101,101,1)' }}>
                                    {t('loginpage.welcomeback')}!
                                </p>
                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '400px' }}>
                                    <Box sx={{ marginTop: '24px', width: '100%' }}>
                                        <OutlinedInput
                                            type="email"
                                            placeholder={t('loginpage.email')}
                                            sx={{
                                                padding: 0, backgroundColor: "white",
                                                border: 'border: 1px solid #B4B4B4',
                                                borderRadius: mBreakPoint1281_1366 ? '5px' : '8px',
                                                width: '100%',
                                                fontSize: '14px',
                                                fontWeight: '400',
                                                lineHeight: '21px',
                                                height: '45px',
                                            }}
                                            classes={{ input: styleClass.input }}

                                            startAdornment={
                                                <IoMailOutline style={{ fontSize: '25px', color: 'grey', marginLeft: '15px', marginRight: '15px' }} />
                                            }
                                            onKeyPress={handleKeyPress}
                                            value={mEmail}
                                            onChange={(e) => setEmail(e.target.value.toLowerCase())}
                                            inputProps={{
                                                style: {
                                                    textTransform: 'lowercase',
                                                },
                                            }}
                                        />
                                        <Box sx={{ color: 'red', fontSize: '14px' }}>
                                            {!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(mEmail) && !mRequiredMark && t('loginpage.invalid_value')}
                                        </Box>
                                    </Box>
                                    <Box sx={{ marginTop: '24px', width: '100%' }}>
                                        <OutlinedInput
                                            id="outlined-adornment-password"
                                            type={showPassword ? 'text' : 'password'}
                                            placeholder={t('loginpage.password')}
                                            startAdornment={
                                                <RiLockPasswordLine style={{ fontSize: '25px', color: 'grey', marginLeft: '1px', marginRight: '15px' }} />
                                            }
                                            classes={{ input: styleClass.input }}
                                            endAdornment={
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    edge="start"
                                                >
                                                    {showPassword ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                                                </IconButton>
                                            }
                                            sx={{
                                                backgroundColor: "#FFFFFF",
                                                border: 'border: 1px solid #B4B4B4',
                                                borderRadius: mBreakPoint1281_1366 ? '5px' : '8px',
                                                fontSize: '14px',
                                                fontWeight: '400',
                                                lineHeight: '21px',
                                                height: '45px',
                                                width: '100%'
                                            }}
                                            //autoComplete="new-password"
                                            value={mPwd}
                                            onChange={(e) => setPwd(e.target.value)}
                                            onKeyPress={handleKeyPress}
                                        />
                                        <Box sx={{ color: 'red' }}>
                                            {!mPwd && !mRequiredMark && 'required'}
                                        </Box>
                                    </Box>
                                    <Box sx={{ marginTop: '10px', display: 'flex', justifyContent: 'end', alignItems: 'center', width: '100%' }}>
                                        <Link
                                            onClick={() => history('/reset-password')}
                                            variant="body2"
                                            style={{ cursor: 'pointer', textDecoration: 'none', fontFamily: theme.typography.fontFamily, color: 'rgba(101, 101, 101, 1)', fontSize: '11px' }}
                                        >
                                            {t('loginpage.forgot_password')}?
                                        </Link>
                                    </Box>
                                    <Button
                                        type="button"
                                        fullWidth
                                        variant="contained"
                                        sx={{ height: '45px', borderRadius: '8px', backgroundColor: 'rgba(58, 32, 109, 1)', textTransform: 'none', marginTop: '15px', '&:hover': { backgroundColor: 'rgba(128, 80, 215, 0.8)' }, fontSize: '14px' }}
                                        onClick={handleChange}
                                    >{(mStateForRegButton ? <CircularProgress sx={{ color: 'white', }} size={'36px'} /> : t('loginpage.signin'))}</Button>
                                    <Box sx={{ marginTop: '35px', marginBottom: '35px', display: 'flex', justifyContent: 'center' }}>
                                        <Link
                                            onClick={() => history('/signup')}
                                            variant="body2"
                                            style={{ cursor: 'pointer', textDecoration: 'none' }}
                                        >
                                            <p style={{ margin: 0, fontWeight: 500, fontSize: '12px', lineHeight: '18px', fontFamily: theme.typography.fontFamily }}>
                                                <span style={{ color: 'rgba(101, 101, 101, 1)' }}>{t('loginpage.dont_have_account')} </span> <span style={{ fontWeight: '500', color: 'black', fontSize: mBreakPoint1281_1366 ? '10px' : '12px', lineHeight: '20px', fontFamily: theme.typography.fontFamily }}>{t('homepage.signup')}</span>
                                            </p>
                                        </Link>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            )}
            {mBreakPoint0_1280 && (
                <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', backgroundColor: 'rgba(58, 32, 109, 1)', minHeight: '100vh', paddingLeft: '24px', paddingRight: '24px' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <img src={process.env.PUBLIC_URL + '/Images/multifunlogo.svg'} onClick={() => history('/')} alt="" style={{ width: '60%' }} />
                    </Box>
                    <Box sx={{ marginTop: '24px', paddingLeft: '24px', paddingRight: '24px', borderColor: 'rgba(0,0,0,0.1)', borderWidth: '1px', borderStyle: 'solid', backgroundColor: 'rgba(255, 255, 255, 1)', borderRadius: '8px', display: "flex", flexDirection: "column", alignItems: "center", justifyContent: 'center' }}>
                        <p style={{ margin: 0, fontWeight: 500, fontSize: '25px', lineHeight: '36px', marginTop: '24px', fontFamily: theme.typography.fontFamily }}>
                            <span style={{ color: 'black' }}>Sign in</span>
                        </p>
                        <p style={{ margin: 0, fontWeight: 500, fontSize: '15px', lineHeight: '20px', marginTop: '20px', fontFamily: theme.typography.fontFamily }}>
                            <span style={{ color: 'rgba(101, 101, 101, 1)' }}>Welcome back!</span>
                        </p>
                        <Box sx={{ width: '100%' }}>
                            <Box sx={{ marginTop: '24px' }}>
                                <OutlinedInput
                                    placeholder="Email"
                                    sx={{ padding: 0, paddingLeft: '5px', paddingRight: '14px', backgroundColor: "#FFFFFF", border: 'border: 1px solid #B4B4B4', borderRadius: '8px', fontSize: '14px', fontWeight: '400', lineHeight: '21px', width: '100%', height: '45px' }}
                                    startAdornment={
                                        <IoMailOutline style={{ fontSize: '25px', color: 'grey', marginLeft: '5px', marginRight: '5px' }} />
                                    }
                                    classes={{ input: styleClass.input }}
                                    //name={`email-${Math.random()}`}
                                    onKeyPress={handleKeyPress}
                                    value={mEmail}
                                    onChange={(e) => setEmail(e.target.value.toLowerCase())}
                                    inputProps={{
                                        style: {
                                            textTransform: 'lowercase',
                                        },
                                    }}
                                />
                                <Box sx={{ color: 'red' }}>
                                    {!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(mEmail) && !mRequiredMark && 'Invalid email'}
                                </Box>
                            </Box>
                            <Box sx={{ marginTop: '24px' }}>
                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    type={showPassword ? 'text' : 'password'}
                                    placeholder="Password"
                                    startAdornment={
                                        <RiLockPasswordLine style={{ fontSize: '25px', color: 'grey', marginLeft: '5px', marginRight: '5px' }} />
                                    }
                                    classes={{ input: styleClass.input }}
                                    endAdornment={
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            edge="start"
                                        >
                                            {showPassword ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                                        </IconButton>
                                    }
                                    sx={{ backgroundColor: "#FFFFFF", border: 'border: 1px solid #B4B4B4', paddingLeft: '5px', paddingRight: '5px', borderRadius: '8px', fontSize: '14px', fontWeight: '400', lineHeight: '21px', width: '100%', height: '45px' }}
                                    //autoComplete="new-password"
                                    value={mPwd}
                                    onChange={(e) => setPwd(e.target.value)}
                                    onKeyPress={handleKeyPress}
                                />
                                <Box sx={{ color: 'red' }}>
                                    {!mPwd && !mRequiredMark && 'required'}
                                </Box>
                            </Box>
                            <Box sx={{ marginTop: '10px', display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                                <Link
                                    onClick={() => history('/reset-password')}
                                    variant="body2"
                                    style={{ cursor: 'pointer', textDecoration: 'none' }}
                                >
                                    <p style={{ margin: 0, fontWeight: 500, fontSize: '12px', lineHeight: '18px' }}>
                                        <span style={{ color: 'rgba(101, 101, 101, 1)' }}>{t('loginpage.forgot_password')}? </span>
                                    </p>
                                </Link>
                            </Box>
                            <Button
                                type="button"
                                fullWidth
                                variant="contained"
                                sx={{ height: '45px', borderRadius: '8px', backgroundColor: 'rgba(58, 32, 109, 1)', textTransform: 'none', marginTop: '10px' }}
                                onClick={handleChange}
                            >{(mStateForRegButton ? <CircularProgress sx={{ color: 'white' }} /> : 'Sign in')}</Button>
                            <Box sx={{ marginTop: '35px', marginBottom: '35px', display: 'flex', justifyContent: 'center' }}>
                                <Link
                                    onClick={() => history('/signup')}
                                    variant="body2"
                                    style={{ cursor: 'pointer', textDecoration: 'none' }}
                                >
                                    <p style={{ margin: 0, fontWeight: 500, fontSize: '12px', lineHeight: '18px' }}>
                                        <span style={{ color: 'rgba(101, 101, 101, 1)' }}>Don't have an account? </span> <span style={{ fontWeight: '500', color: 'black', fontSize: '12px', lineHeight: '20px' }}>Sign Up</span>
                                    </p>
                                </Link>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            )}
        </Box>
    );
}

export default LandingPage;