import { Box } from "@mui/material";
import { useMediaQuery } from 'react-responsive'
const DownloadVideo = () => {
    const mBreakPoint0_900 = useMediaQuery({ query: '(min-width: 0px) and (max-width: 900px)' });
    return (
        <Box sx={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {!mBreakPoint0_900 && (
                < div >
                    <h1>Multifun</h1>
                    <video controls width="1280" height="720">
                        <source src={process.env.PUBLIC_URL + '/video/Multifun-Demo.mp4'} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            )}
            {mBreakPoint0_900 && (
                < div >
                    <h1>Multifun</h1>
                    <video controls width="320" height="160">
                        <source src={process.env.PUBLIC_URL + '/video/Multifun-Demo.mp4'} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            )}
        </Box >
    )
}
export default DownloadVideo;