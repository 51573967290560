import React, { useContext, useEffect, useState } from "react";
import { Select, FormControl, Box, Button, Grid, styled, MenuItem, Typography } from "@mui/material";
import Icon from "../../components/Icon";
import { S3Url } from "../../share/config";
import jwt_decode from "jwt-decode";
import { getStoredAuthToken } from "../../utils/authToken";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { useMediaQuery } from 'react-responsive'
import { Helmet } from "react-helmet";
import BackDrop from "../../utils/Backdrop";
import { useTranslation } from "react-i18next";
import { API_R_ACCOUNTDATA, API_R_ReferralData } from "../../share/api";
import { IoMailOutline } from "react-icons/io5";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MobileTooltip from "../../utils/MobileTooltilp";
import CustomPagination from "../../utils/CustomPagination";
import { useTheme } from '@mui/material/styles';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CopyToClipboard from "react-copy-to-clipboard";

const Accounts = () => {

    const { isLogin } = useContext(AuthContext);
    const { mStatusAccountMode } = useContext(AuthContext);
    const history = useNavigate();

    const theme = useTheme();
    const authtoken = getStoredAuthToken();
    const email = authtoken ? jwt_decode(authtoken).email : null;
    if (!isLogin || !email) {
        history('/signin');
    }

    const { t } = useTranslation();
    const mBreakPoint0_1280 = useMediaQuery({ query: '(min-width: 0px) and (max-width: 1280px)' });
    const mBreakPoint1281_1366 = useMediaQuery({ query: '(min-width: 1281px) and (max-width: 1768px)' });
    const [mCheckGamingButton, setCheckGamingButton] = useState(1);
    const [mLoadingDataFromServer, setLoadingDataFromServer] = React.useState(true);
    const [mGamingData, setGamingData] = React.useState(null);
    const { mSearchContextValue, setSearchContextValue } = useContext(AuthContext);
    const { setDealType } = useContext(AuthContext);
    setDealType(0);
    const [mFilteGamingData, setFilterGamingData] = useState(mGamingData);
    const [currentPage, setCurrentPage] = React.useState(0);
    const itemPerPage = 10;
    const startIndex = currentPage * itemPerPage;
    const endIndex = startIndex + itemPerPage;
    const [disPlayItems, setDisPlayItems] = useState([]);
    const [inputCurrentPage, setInputCurrentPage] = React.useState();
    const [activeButton, setActiveButton] = React.useState(t('sidebar.casinos'));
    const [isCopied, setCopied] = useState(false);


    useEffect(() => {
        if (isLogin && email && !mStatusAccountMode) {
            setCurrentPage(0);
            setFilterGamingData(mGamingData?.filter(value => (value.profileEmail.toString().toLowerCase().includes(mSearchContextValue.trim().toLowerCase())
                || value.brandName.toString().toLowerCase().includes(mSearchContextValue.trim().toLowerCase())
                || value.LastCashback.toString().toLowerCase().includes(mSearchContextValue.trim().toLowerCase())
                || value.TotalCashback.toString().toLowerCase().includes(mSearchContextValue.trim().toLowerCase()))));
        }
    }, [mSearchContextValue, mGamingData, isLogin, email, mStatusAccountMode])

    useEffect(() => {
        if (mStatusAccountMode) return
        const displayTemp = mFilteGamingData?.slice(startIndex, endIndex);
        setDisPlayItems(displayTemp);
    }, [startIndex, endIndex, mFilteGamingData, mStatusAccountMode])
    useEffect(() => {
        const getGameData = async () => {
            if (mStatusAccountMode) return;
            setSearchContextValue('');
            setLoadingDataFromServer(true);
            try {
                const response = await axios.post(API_R_ACCOUNTDATA, {
                    email, mCheckGamingButton
                });
                setLoadingDataFromServer(false);
                const body = response.data;
                if (response.status === 200) {
                    setGamingData(body)

                    return;
                }
            } catch (error) {
                history('/signin');
                return;
            }
        }
        if (isLogin && email) {
            getGameData();
        }
    }, [mCheckGamingButton, email, history, isLogin, mStatusAccountMode, setSearchContextValue])

    const handleGotoCasino = (url, additionalLink, PayloadID, status, affSys) => {
        const extralink = additionalLink ? additionalLink : ''
        if (status) {
            window.open(url + extralink, '_blank');
        }
        else {
            const b = url.includes('?');
            if (affSys === 'MyAffiliates') {
                window.open(url + `${b ? '&' : '?'}payload=${PayloadID}` + extralink, '_blank');
            }
            if (affSys === 'IncomeAccess') {
                window.open(url + `${b ? '&' : '?'}c=${PayloadID}` + extralink, '_blank');
            }
            if (affSys === 'VPartners') {
                window.open(url + `${b ? '&' : '?'}sub_id=${PayloadID}` + extralink, '_blank');
            }
            if (affSys === 'ReferOn') {
                window.open(url + `${b ? '&' : '?'}subid=${PayloadID}` + extralink, '_blank');
            }
            if (affSys === 'Smartico') {
                window.open(url + `${b ? '&' : '?'}afp=${PayloadID}` + extralink, '_blank');
            }
            if (affSys === 'Affilka') {
                window.open(url + `${b ? '&' : '?'}var=${PayloadID}` + extralink, '_blank');
            }
            if (affSys === 'EGO') {
                window.open(url + `${b ? '&' : '?'}dyn_id=${PayloadID}` + extralink, '_blank');
            }
            if (affSys === 'CellXpert') {
                window.open(url + `${b ? '&' : '?'}afp=${PayloadID}` + extralink, '_blank');
            }
        }
    };

    const SelectButton = styled(Button)({
        color: 'white',
        fontSize: mBreakPoint1281_1366 ? '9px' : '12px',
        fontWeight: 400,
        height: mBreakPoint1281_1366 ? '35px' : '80%',
        letterSpacing: '0.1px',
        padding: '15px 14px',
        textTransform: 'none',
        flex: '1',
        margin: '0 15px 0 0',
        borderRadius: mBreakPoint1281_1366 ? '5px' : '8px',
        backgroundColor: 'rgba(99,57,143,1)',
        '&.active': {
            backgroundColor: '#ffc500'
        },
        '&:hover': {
            backgroundColor: 'rgba(99,57,143,0.8)',
        },
    });

    const ToolTipForDesktop = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: 'grey',
            color: 'white',
            borderRadius: '8px',
            fontSize: mBreakPoint1281_1366 ? '8px' : '10px',
            maxWidth: '500px',
        },
    }));

    const [mSelectedAccount, setSelectedAccount] = useState(0);
    const handleSelectAccont = (idx) => {
        if (mSelectedAccount === idx) {
            setSelectedAccount(null); // Deselect if the same item is clicked again
        } else {
            setSelectedAccount(idx);
        }
    }

    const SelectBox = styled(Box)(({ index }) => ({
        position: 'relative',
        '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: mBreakPoint1281_1366 ? '3px' : '5px',
            height: '100%',
            background: 'linear-gradient(180deg, #6C3D9E 20%, #E91287 100%)',
            borderRadius: '6px 0 0 6px',
            opacity: mSelectedAccount === index ? 1 : 0,
            transition: 'opacity 0.3s ease-in-out',
        },
        '&:hover::before': {
            opacity: 1,
        },
        '&:hover': {
            cursor: 'pointer',
            borderRadius: '6px',
            backgroundColor: 'rgba(200,200,200,0.2)'
        },
        borderRadius: '6px',
    }));



    const ShiningSyncProblemIcon = styled(SyncProblemIcon)({
        animation: 'shining 1.5s infinite',
        '@keyframes shining': {
            '0%': {
                color: 'black',
            },
            '50%': {
                color: '#ffc500',
            },
            '100%': {
                color: 'black',
            },
        },
    });

    const StyledTypography = styled(Typography)({
        fontWeight: 400,
        letterSpacing: '-0.3px',
        color: 'rgba(24, 24, 25, 0.7)',
        fontSize: mBreakPoint1281_1366 ? '9px' : '11px',
    });

    const StyledBox = styled(Box)({
        display: 'flex',
        justifyContent: 'start',
        variant: "overline",
        fontWeight: '300',
        fontStyle: 'normal',
        fontSize: mBreakPoint1281_1366 ? '9px' : '11px',
        lineHeight: '140%',
        letterSpacing: '0.5px',
        textTransform: "none"
        , fontFamily: theme.typography.fontFamily
    });

    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage - 1)
    }
    const handleInputChange = (e) => {
        setInputCurrentPage('')
        const inputValue = e.target.value;

        const page = parseInt(inputValue, 10);
        if (page >= 1 && page <= Math.ceil(mGamingData?.length / itemPerPage)) {
            setInputCurrentPage(page);
        }
    }

    const handleGoButtonClick = () => {
        const page = parseInt(inputCurrentPage, 10);
        if (page >= 1 && page <= Math.ceil(mGamingData?.length / itemPerPage)) {
            setCurrentPage(page - 1);
        }
    };

    const [currentSelectedItem, setCurrentSelectedItem] = useState();
    const handleForMobileTooltip = (idx) => {
        setCurrentSelectedItem(idx)

        setTimeout(() => {
            setCurrentSelectedItem(-1);
        }, 5000);
    }


    const handleCopy = () => {
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 2000); // Reset the "Copied" message after 2 seconds
    };


    const [mReferralCode, setReferralCode] = useState('');
    const [mReferreeData, setReferreeData] = useState();
    useEffect(() => {
        const getReferralData = async () => {
            if (!mStatusAccountMode) return;
            setSearchContextValue('');
            setLoadingDataFromServer(true);
            try {
                const response = await axios.post(API_R_ReferralData, {
                    email
                });
                setLoadingDataFromServer(false)
                const body = response.data;
                if (response.status === 200) {
                    setReferralCode(body?.referralCode);
                    setReferreeData(body?.referreeData);
                    return;
                }
            } catch (error) {
                history('/signin');
                return;
            }
        }
        getReferralData();
    }, [email, history, mStatusAccountMode, setSearchContextValue]);

    useEffect(() => {
        if (isLogin && email && mStatusAccountMode) {
            setCurrentPage(0);
            setFilterGamingData(mReferreeData?.filter(value => (value.name.toString().toLowerCase().includes(mSearchContextValue.trim().toLowerCase())
                || value.regDate.toString().toLowerCase().includes(mSearchContextValue.trim().toLowerCase())
                || value.commission.toString().toLowerCase().includes(mSearchContextValue.trim().toLowerCase()))));
        }
    }, [mSearchContextValue, mReferreeData, isLogin, email, mStatusAccountMode])

    useEffect(() => {
        if (!mStatusAccountMode) return
        const displayTemp = mFilteGamingData?.slice(startIndex, endIndex);
        setDisPlayItems(displayTemp);
    }, [startIndex, endIndex, mFilteGamingData, mStatusAccountMode]);

    return (
        <Box sx={{ fontFamily: theme.typography.fontFamily }}>
            <Helmet>
                <title>{t('sidebar.your_accounts')} - Multifun</title>
            </Helmet>
            {!mBreakPoint0_1280 && !mStatusAccountMode && (
                <Box sx={{ paddingRight: '10px', paddingLeft: '10px', backgroundColor: 'rgba(250, 250, 250, 0.7)', height: '92vh', position: 'relative' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <SelectButton
                            startIcon={
                                <Icon name='casinos-button' />
                            }
                            sx={{ marginLeft: '3px' }}
                            onClick={() => { setActiveButton(t('sidebar.casinos')); setCheckGamingButton(1) }}
                            className={activeButton === t('sidebar.casinos') ? 'active' : ''}
                        >{t('sidebar.casinos')}</SelectButton>
                        <SelectButton
                            startIcon={
                                <Icon name='sportsbook-button' />
                            }
                            onClick={() => { setActiveButton(t('sidebar.sportsbook')); setCheckGamingButton(2) }}
                            className={activeButton === t('sidebar.sportsbook') ? 'active' : ''}
                        >{t('sidebar.sportsbook')}</SelectButton>
                        <SelectButton
                            startIcon={
                                <Icon name='bingo-button' />
                            }
                            onClick={() => { setActiveButton(t('sidebar.bingo')); setCheckGamingButton(3) }}
                            className={activeButton === t('sidebar.bingo') ? 'active' : ''}
                        >{t('sidebar.bingo')}</SelectButton>
                        <SelectButton
                            startIcon={
                                <Icon name='poker-button' />
                            }
                            onClick={() => { setActiveButton(t('sidebar.poker')); setCheckGamingButton(4) }}
                            className={activeButton === t('sidebar.poker') ? 'active' : ''}
                        >{t('sidebar.poker')}</SelectButton>
                        <SelectButton
                            startIcon={
                                <Icon name='fantasy-button' />
                            }
                            sx={{ marginRight: '1px' }}
                            onClick={() => { setActiveButton(t('sidebar.fantasy')); setCheckGamingButton(5) }}
                            className={activeButton === t('sidebar.fantasy') ? 'active' : ''}
                        >{t('sidebar.fantasy')}</SelectButton>
                    </Box>
                    <Box sx={{ marginTop: '17px', borderBottom: '1px solid rgba(0, 0, 0, 0.1)', paddingBottom: '8px' }}>
                        <Box sx={{ height: '40px', paddingTop: '14px', paddingLeft: '16px', paddingRight: '16px', paddingBottom: '8px', borderBottom: '2px solid #6C3D9E', display: 'inline' }}>
                            <p style={{ fontWeight: 700, display: 'inline', fontSize: mBreakPoint1281_1366 ? '9px' : '12px', letterSpacing: '-0.3px', color: '#181819' }}>{activeButton} {t('youraccount.accounts')}</p>
                        </Box>
                    </Box>
                    <Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingTop: '8px' }}>
                            <StyledTypography style={{ width: '13%', marginLeft: '10px' }}>
                                {mCheckGamingButton === 1 ? t('sidebar.casinos') : mCheckGamingButton === 2 ? t('sidebar.sportsbook') : mCheckGamingButton === 3 ? t('sidebar.bingo') : mCheckGamingButton === 4 ? t('sidebar.poker') : mCheckGamingButton === 5 ? t('sidebar.fantasy') : ''}
                            </StyledTypography>
                            <StyledTypography style={{ width: '13%' }}>{t('dashboard.brand_name')}</StyledTypography>
                            <StyledTypography style={{ width: '18%' }}>{t('dashboard.email')}</StyledTypography>
                            <StyledTypography style={{ width: '14%' }}>{t('dashboard.status')}</StyledTypography>
                            <StyledTypography style={{ width: '14%' }}>{t('dashboard.last_cashback')}</StyledTypography>
                            <StyledTypography style={{ width: '14%' }}>{t('dashboard.total_cashback')}</StyledTypography>
                            <StyledTypography style={{ width: '14%' }}>{t('dashboard.play_now')}</StyledTypography>
                        </Box>
                        {disPlayItems?.map((item, idx) => (
                            <SelectBox index={idx} onClick={() => handleSelectAccont(idx)} sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '8px', backgroundColor: 'rgba(255,255,255,1)', paddingTop: mBreakPoint1281_1366 ? '1px' : '3px', paddingBottom: mBreakPoint1281_1366 ? '1px' : '3px', alignItems: 'center' }}>
                                <Box sx={{ width: '13%', display: 'flex', flexDirection: 'row', justifyContent: "start", paddingLeft: '10px', position: 'relative', alignItems: 'center' }}>
                                    <img alt="green iguana" src={`${S3Url}/images/${item.LogoUrl}`} style={{ height: mBreakPoint1281_1366 ? '25px' : '40px', borderRadius: mBreakPoint1281_1366 ? '5px' : '8px' }} />
                                    {item.status === false && (
                                        <ToolTipForDesktop title={`${t('dashboard.tooltip_sync_time').replaceAll('{BrandName}', item.brandName)}`} arrow>
                                            <span style={{ marginLeft: '10px', zIndex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#ffc500', width: mBreakPoint1281_1366 ? '15px' : '24px', height: mBreakPoint1281_1366 ? '15px' : '24px', borderRadius: '50%', cursor: 'pointer', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)', pointerEvents: 'auto' }}>
                                                <ShiningSyncProblemIcon style={{ fontSize: mBreakPoint1281_1366 ? '10px' : '16px' }} />
                                            </span>
                                        </ToolTipForDesktop>
                                    )}
                                </Box>
                                <StyledBox width={'13%'} color={"black"}>
                                    {item.brandName}
                                </StyledBox>
                                <ToolTipForDesktop title={item.profileEmail} arrow>
                                    <StyledBox width={'18%'} color={"black"}>
                                        {item.profileEmail?.split(', ').length === 1 ? `${item.profileEmail?.substring(0, 23)}${item.profileEmail.length > 23 ? '...' : ''}` : (item.profileEmail?.split(', ')[0])?.substring(0, 23) + '... +' + (item.profileEmail?.split(', ').length - 1)}
                                    </StyledBox>
                                </ToolTipForDesktop>
                                <StyledBox width={'14%'} color={item.status ? '#35b34b' : "black"}>
                                    {item.status ? t('dashboard.synced') : t('dashboard.syncing') + '...'}
                                </StyledBox>
                                <StyledBox width={'14%'} color={"black"}>
                                    €{item.LastCashback?.toFixed(2)}
                                </StyledBox>
                                <StyledBox width={'14%'} color={"black"}>
                                    €{item.TotalCashback?.toFixed(2)}
                                </StyledBox>
                                <StyledBox width={'14%'} color={"black"}>
                                    <Button sx={{
                                        width: '90%',
                                        padding: mBreakPoint1281_1366 ? '1px 12px' : '3px 12px',
                                        color: "#229427",
                                        fontWeight: "600",
                                        fontSize: mBreakPoint1281_1366 ? '9px' : '11px',
                                        backgroundColor: "rgba(34, 148, 39, 0.2)",
                                        borderRadius: mBreakPoint1281_1366 ? '6px' : "16px",
                                        textTransform: 'none',
                                        '&:hover': {
                                            backgroundColor: "rgba(34, 148, 39, 0.4)"
                                        }
                                    }}
                                        onClick={() => handleGotoCasino(item.affiliateLink, item.additionalLink, item.PayloadID, item.status, item.affSys)}
                                    >{t('dashboard.visit_website')}</Button>
                                </StyledBox>
                            </SelectBox>
                        ))}
                    </Box>
                    {disPlayItems?.length > 0 && (
                        <Box sx={{ display: 'flex', padding: mBreakPoint1281_1366 ? "3px 10px" : "3px 20px", backgroundColor: '#F1F2F2', borderRadius: '44px', bottom: '25px', right: '24px', position: 'absolute' }}>
                            <CustomPagination
                                pageCount={Math.ceil(mFilteGamingData?.length / itemPerPage)}
                                currentPage={currentPage}
                                onChange={handleChangePage}
                                inputCurrentPage={inputCurrentPage}
                                handleInputChange={handleInputChange}
                                handleGoButtonClick={handleGoButtonClick}
                            />
                        </Box>)}
                    <BackDrop open={mLoadingDataFromServer} />
                </Box>
            )}
            {!mBreakPoint0_1280 && mStatusAccountMode && (
                <Box sx={{ paddingRight: '10px', paddingLeft: '10px', backgroundColor: 'rgba(250, 250, 250, 0.7)', height: '92vh', position: 'relative' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Box sx={{ display: 'inline-flex', alignItems: 'center', flexDirection: 'row', padding: '10px 24px', backgroundColor: 'white', borderRadius: '8px' }}>
                            <StyledTypography style={{ fontWeight: '600', fontSize: '14px' }}>{t('youraccount.your_referral_code')} : {mReferralCode}</StyledTypography>
                            <CopyToClipboard text={mReferralCode} onCopy={handleCopy}>
                                <ContentCopyIcon sx={{ margin: 0, color: 'black', marginLeft: '10px', fontSize: '20px' }} />
                            </CopyToClipboard>
                        </Box>
                        {isCopied && (
                            <StyledTypography style={{ fontWeight: '400', fontSize: '12px', marginLeft: '5px' }}>{t('youraccount.copied')}</StyledTypography>
                        )}
                    </Box>
                    <Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingTop: '8px' }}>
                            <StyledTypography style={{ width: '20%', marginLeft: '15px' }}>{t('youraccount.name')}</StyledTypography>
                            <StyledTypography style={{ width: '20%' }}>{t('youraccount.registratioin_date')}</StyledTypography>
                            <StyledTypography style={{ width: '20%' }}>{t('youraccount.commission')}</StyledTypography>
                        </Box>
                        {disPlayItems?.map((item, idx) => (
                            <SelectBox index={idx} onClick={() => handleSelectAccont(idx)} sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '8px', backgroundColor: 'rgba(255,255,255,1)', paddingTop: mBreakPoint1281_1366 ? '10px' : '15px', paddingBottom: mBreakPoint1281_1366 ? '10px' : '15px', alignItems: 'center' }}>
                                <StyledBox width={'20%'} color={"black"} marginLeft={'15px'}>
                                    {item.name}.
                                </StyledBox>
                                <StyledBox width={'20%'} color={"black"}>
                                    {item.regDate?.substring(0, 10)}
                                </StyledBox>
                                <StyledBox width={'20%'} color={"black"}>
                                    €{parseFloat(item.commission)?.toFixed(2)}
                                </StyledBox>
                            </SelectBox>
                        ))}
                    </Box>
                    {disPlayItems?.length > 0 && (
                        <Box sx={{ display: 'flex', padding: mBreakPoint1281_1366 ? "3px 10px" : "3px 20px", backgroundColor: '#F1F2F2', borderRadius: '44px', bottom: '25px', right: '24px', position: 'absolute' }}>
                            <CustomPagination
                                pageCount={Math.ceil(mFilteGamingData?.length / itemPerPage)}
                                currentPage={currentPage}
                                onChange={handleChangePage}
                                inputCurrentPage={inputCurrentPage}
                                handleInputChange={handleInputChange}
                                handleGoButtonClick={handleGoButtonClick}
                            />
                        </Box>)}
                    <BackDrop open={mLoadingDataFromServer} />
                </Box>
            )}
            {mBreakPoint0_1280 && !mStatusAccountMode && (
                <Grid container sx={{ backgroundColor: '#E5E5E5' }}>
                    <Grid item xs={12}>
                        <Box sx={{ padding: '15px', backgroundColor: '#3a206d', minHeight: '100vh' }}>
                            <Box sx={{ backgroundColor: '#ffc500', borderWidth: '1px', borderColor: '#ffc500', borderStyle: 'solid', borderRadius: '8px' }}>
                                <FormControl fullWidth backgroundColor={'white'} overflow={'auto'} >
                                    <Select
                                        value={mCheckGamingButton}
                                        onChange={(e) => { setCheckGamingButton(e.target.value) }}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 'none', color: 'rgba(0,0,0,0.8)', borderRadius: '8px' }, width: '100%', '.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': { padding: '13px 14px' }, '.css-hfutr2-MuiSvgIcon-root-MuiSelect-icon': { color: 'white' }, '.css-bpeome-MuiSvgIcon-root-MuiSelect-icon': { color: 'white' }, height: '40px', color: 'white' }}

                                    >
                                        <MenuItem value={1} display={'flex'} style={{ backgroundColor: '#ffc500' }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <Box sx={{ marginLeft: '15px', display: 'flex', alignItems: 'center' }}>
                                                    <img width="25vh" src="../../Images/casino.svg" sx={{ marginRight: '10px' }} alt="" />
                                                </Box>
                                                <Box sx={{ marginLeft: '15px', color: 'white' }}>
                                                    {t('sidebar.casinos')}
                                                </Box>
                                            </Box>
                                        </MenuItem>
                                        <MenuItem value={2} display={'flex'} style={{ backgroundColor: '#ffc500' }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <Box sx={{ marginLeft: '15px', display: 'flex', alignItems: 'center' }}>
                                                    <img width="25vh" src="../../Images/sportsbook.svg" sx={{ marginRight: '10px' }} alt="" />
                                                </Box>
                                                <Box sx={{ marginLeft: '15px', color: 'white' }}>
                                                    {t('sidebar.sportsbook')}
                                                </Box>
                                            </Box>
                                        </MenuItem>
                                        <MenuItem value={3} display={'flex'} style={{ backgroundColor: '#ffc500' }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <Box sx={{ marginLeft: '15px', display: 'flex', alignItems: 'center' }}>
                                                    <img width="25vh" src="../../Images/bingo.svg" sx={{ marginRight: '10px' }} alt="" />
                                                </Box>
                                                <Box sx={{ marginLeft: '15px', color: 'white' }}>
                                                    {t('sidebar.bingo')}
                                                </Box>
                                            </Box>
                                        </MenuItem>
                                        <MenuItem value={4} display={'flex'} style={{ backgroundColor: '#ffc500' }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <Box sx={{ marginLeft: '15px', display: 'flex', alignItems: 'center' }}>
                                                    <img width="25vh" src="../../Images/poker.svg" sx={{ marginRight: '10px' }} alt="" />
                                                </Box>
                                                <Box sx={{ marginLeft: '15px', color: 'white' }}>
                                                    {t('sidebar.poker')}
                                                </Box>
                                            </Box>
                                        </MenuItem>
                                        <MenuItem value={5} display={'flex'} style={{ backgroundColor: '#ffc500' }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <Box sx={{ marginLeft: '15px', display: 'flex', alignItems: 'center' }}>
                                                    <img width="25vh" src="../../Images/fantasy.svg" sx={{ marginRight: '10px' }} alt="" />
                                                </Box>
                                                <Box sx={{ marginLeft: '15px', color: 'white' }}>
                                                    {t('sidebar.fantasy')}
                                                </Box>
                                            </Box>
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box sx={{ marginTop: '15px' }}>
                                {disPlayItems?.map((item, idx) => (
                                    <Box
                                        key={idx}
                                        sx={{
                                            borderRadius: '16px',
                                            width: '100%',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            marginTop: '15px',
                                            backgroundColor: 'rgba(248, 248, 248, 1)'
                                        }}
                                    >
                                        <Grid item xs={12} md={4} textAlign="center" style={{ padding: '15px 15px 0 15px' }} >
                                            <img alt="green iguana" src={S3Url + '/images/' + item.LogoUrl} style={{ width: '100%', borderRadius: '16px' }} />
                                        </Grid>
                                        <Grid item xs={12} md={4} style={{ padding: '0 15px' }} display={'flex'} justifyContent={'space-between'} width={'100%'} marginTop={'10px'}>
                                            <Box style={{ overflow: 'auto', whiteSpace: 'nowrap', display: 'flex', justifyContent: 'center', alignItems: 'center', fontFamily: theme.typography.fontFamily }}
                                                sx={{ fontSize: '11px', fontWeight: '400', letterSpacing: '-0.3px', textAlign: 'center', textTransform: 'none', color: 'black' }}
                                                onClick={() => handleForMobileTooltip(idx)}
                                            >
                                                < Tooltip title={item.profileEmail} open={idx === currentSelectedItem ? true : false} />
                                                <IoMailOutline style={{ fontSize: '15px', color: 'black', marginRight: '5px' }} />
                                                {item.profileEmail?.split(', ').length === 1 ? `${item.profileEmail?.substring(0, 23)}${item.profileEmail.length > 23 ? '...' : ''}` : (item.profileEmail?.split(', ')[0])?.substring(0, 23) + '... +' + (item.profileEmail?.split(', ').length - 1)}
                                            </Box>
                                            <Box style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                backgroundColor: item.status ? 'rgba(208, 240, 210, 1)' : 'rgba(244, 246, 101, 1)',
                                                borderRadius: '16px',
                                                padding: '1px 10px', fontFamily: theme.typography.fontFamily
                                            }}
                                                sx={{ fontSize: '11px', color: 'black', fontWeight: '400', letterSpacing: '-0.3px', textAlign: 'center', textTransform: 'none' }}
                                            >
                                                {item.status ?
                                                    <CheckCircleIcon style={{ color: 'green', fontSize: '15px', marginRight: '5px' }} />
                                                    :
                                                    <Box sx={{ marginRight: '5px' }}>
                                                        <MobileTooltip title={`${t('dashboard.tooltip_sync_time').replaceAll('{BrandName}', item.brandName)}`} flag={1} arrow />
                                                    </Box>
                                                }
                                                {item.status ? t('dashboard.synced') : t('dashboard.syncing') + '...'}
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={4} style={{ padding: '0 15px' }} display={'flex'} justifyContent={'space-between'} width={'100%'} marginTop={'15px'}>
                                            <Box style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                backgroundColor: 'rgba(119, 86, 164, 1)',
                                                borderRadius: '16px',
                                                padding: '10px 24px',
                                                width: '100%',
                                                marginRight: '24px'
                                                , fontFamily: theme.typography.fontFamily
                                            }}>
                                                <p style={{ fontSize: '10px', color: 'white', fontWeight: '400', letterSpacing: '-0.3px', textTransform: 'none', margin: 0, textAlign: 'center' }}>{t('dashboard.last_cashback')}</p>
                                                <p style={{ fontSize: '16px', color: 'white', fontWeight: '500', letterSpacing: '-0.3px', textTransform: 'none', margin: 0, marginTop: '10px', textAlign: 'center' }}>€{item.LastCashback?.toFixed(2)}</p>
                                            </Box>
                                            <Box style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                backgroundColor: 'rgba(246, 140, 37, 1)',
                                                borderRadius: '16px',
                                                padding: '10px 24px',
                                                width: '100%'
                                                , fontFamily: theme.typography.fontFamily
                                            }}>
                                                <p style={{ fontSize: '10px', color: 'white', fontWeight: '400', letterSpacing: '-0.3px', textTransform: 'none', margin: 0, textAlign: 'center' }}>{t('dashboard.total_cashback')}</p>
                                                <p style={{ fontSize: '16px', color: 'white', fontWeight: '500', letterSpacing: '-0.3px', textTransform: 'none', margin: 0, marginTop: '10px', textAlign: 'center' }}>€{item.TotalCashback?.toFixed(2)}</p>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} textAlign="center" style={{ marginTop: '15px', marginBottom: '15px', width: '100%', padding: '0 15px' }}>
                                            <Button sx={{ width: '100%', padding: '6px 7px', color: "#229427", fontWeight: "600", fontSize: '14px', backgroundColor: "rgba(34, 148, 39, 0.2)", borderRadius: "100px", textTransform: 'none', '&:hover': { backgroundColor: "rgba(34, 148, 39, 0.4)" } }}
                                                onClick={() => handleGotoCasino(item.affiliateLink, item.additionalLink, item.PayloadID, item.status, item.affSys)}
                                            >{t('dashboard.visit_website')}</Button>
                                        </Grid>
                                    </Box>
                                ))}
                            </Box>
                            {disPlayItems?.length > 0 && (
                                <Box sx={{ marginTop: '15px', display: 'flex', justifyContent: 'center' }}>
                                    <CustomPagination
                                        pageCount={Math.ceil(mFilteGamingData?.length / itemPerPage)}
                                        currentPage={currentPage}
                                        onChange={handleChangePage}
                                        inputCurrentPage={inputCurrentPage}
                                        handleInputChange={handleInputChange}
                                        handleGoButtonClick={handleGoButtonClick}
                                    />
                                </Box>
                            )}
                        </Box>
                        <BackDrop open={mLoadingDataFromServer} />
                    </Grid>
                </Grid>
            )}
            {mBreakPoint0_1280 && mStatusAccountMode && (
                <Grid container sx={{ backgroundColor: '#E5E5E5' }}>
                    <Grid item xs={12}>
                        <Box sx={{ padding: '15px', backgroundColor: '#3a206d', minHeight: '100vh' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <Box sx={{ display: 'inline-flex', alignItems: 'center', flexDirection: 'row', padding: '5px 20px', backgroundColor: 'white', borderRadius: '8px' }}>
                                    <StyledTypography style={{ fontWeight: '600', fontSize: '14px' }}>{t('youraccount.your_referral_code')} : {mReferralCode}</StyledTypography>
                                    <CopyToClipboard text={mReferralCode} onCopy={handleCopy}>
                                        <ContentCopyIcon sx={{ margin: 0, color: 'black', marginLeft: '10px', fontSize: '20px' }} />
                                    </CopyToClipboard>
                                </Box>
                                {isCopied && (
                                    <StyledTypography style={{ fontWeight: '400', fontSize: '11px', marginLeft: '5px', color: 'white' }}>{t('youraccount.copied')}</StyledTypography>
                                )}
                            </Box>
                            <Box sx={{ marginTop: '15px' }}>
                                {disPlayItems?.map((item, idx) => (
                                    <Box
                                        key={idx}
                                        sx={{
                                            borderRadius: '16px',
                                            width: '100%',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            marginTop: '15px',
                                            backgroundColor: 'rgba(248, 248, 248, 1)'
                                        }}
                                    >
                                        <Grid item xs={12} md={4} style={{ padding: '0 15px' }} justifyContent={'start'} width={'100%'} marginTop={'15px'}>
                                            <p style={{ fontSize: '10px', color: 'black', fontWeight: '400', letterSpacing: '-0.3px', textTransform: 'none', margin: 0, textAlign: 'center' }}>{t('youraccount.registratioin_date')}</p>
                                            <p style={{ fontSize: '16px', color: 'black', fontWeight: '500', letterSpacing: '-0.3px', textTransform: 'none', margin: 0, textAlign: 'center' }}>{item.regDate?.substring(0, 10)}</p>
                                        </Grid>
                                        <Grid item xs={12} md={4} style={{ padding: '0 15px' }} display={'flex'} justifyContent={'space-between'} width={'100%'} marginTop={'15px'} marginBottom={'15px'}>
                                            <Box style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                backgroundColor: 'rgba(119, 86, 164, 1)',
                                                borderRadius: '16px',
                                                padding: '10px 24px',
                                                width: '100%',
                                                marginRight: '24px'
                                                , fontFamily: theme.typography.fontFamily
                                            }}>
                                                <p style={{ fontSize: '10px', color: 'white', fontWeight: '400', letterSpacing: '-0.3px', textTransform: 'none', margin: 0, textAlign: 'center' }}>{t('youraccount.name')}</p>
                                                <p style={{ fontSize: '16px', color: 'white', fontWeight: '500', letterSpacing: '-0.3px', textTransform: 'none', margin: 0, marginTop: '10px', textAlign: 'center' }}>{item.name}.</p>
                                            </Box>
                                            <Box style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                backgroundColor: 'rgba(246, 140, 37, 1)',
                                                borderRadius: '16px',
                                                padding: '10px 24px',
                                                width: '100%'
                                                , fontFamily: theme.typography.fontFamily
                                            }}>
                                                <p style={{ fontSize: '10px', color: 'white', fontWeight: '400', letterSpacing: '-0.3px', textTransform: 'none', margin: 0, textAlign: 'center' }}>{t('youraccount.commission')}</p>
                                                <p style={{ fontSize: '16px', color: 'white', fontWeight: '500', letterSpacing: '-0.3px', textTransform: 'none', margin: 0, marginTop: '10px', textAlign: 'center' }}>€{parseFloat(item.commission)?.toFixed(2)}</p>
                                            </Box>
                                        </Grid>
                                    </Box>
                                ))}
                            </Box>
                            {disPlayItems?.length > 0 && (
                                <Box sx={{ marginTop: '15px', display: 'flex', justifyContent: 'center' }}>
                                    <CustomPagination
                                        pageCount={Math.ceil(mFilteGamingData?.length / itemPerPage)}
                                        currentPage={currentPage}
                                        onChange={handleChangePage}
                                        inputCurrentPage={inputCurrentPage}
                                        handleInputChange={handleInputChange}
                                        handleGoButtonClick={handleGoButtonClick}
                                    />
                                </Box>
                            )}
                        </Box>
                        <BackDrop open={mLoadingDataFromServer} />
                    </Grid>
                </Grid>
            )}
        </Box>
    )
}

export default Accounts;