import React from 'react';
import { Box, Stack, Pagination, Input, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

const CustomPagination = ({
    pageCount,
    currentPage,
    onChange,
    inputCurrentPage,
    handleInputChange,
    handleGoButtonClick,
}) => {
    const { t } = useTranslation();
    return (
        <Box
            sx={{
                padding: '2px 10px',
                backgroundColor: '#F1F2F2',
                borderRadius: '44px',
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Stack spacing={2}>
                    <Pagination
                        count={pageCount}
                        page={currentPage + 1}
                        onChange={onChange}
                        boundaryCount={2}
                        siblingCount={0}
                        size="small"
                        sx={{
                            '& .MuiPagination-ul': {
                                '& .MuiPaginationItem-root': {
                                    fontSize: '10px',
                                    height: '20px',
                                    minWidth: '20px',
                                },
                            },
                        }}
                    />
                </Stack>
            </Box>
            <Box
                sx={{
                    color: 'white',
                    borderRadius: '44px',
                    display: 'flex',
                    height: '25px',
                }}
            >
                <Input
                    id="filled-basic"
                    sx={{
                        padding: '1px 12px',
                        height: '100%',
                        width: '35px',
                        border: 'none',
                        backgroundColor: 'rgba(58, 55, 55, 0.1)',
                        borderRadius: '8px 0px 0px 8px',
                        borderBottom: '0',
                        '&:before': {
                            borderBottom: 'none',
                        },
                        '&:after': {
                            borderBottom: 'none',
                        },
                        '&:hover:not(.Mui-disabled, .Mui-error):before': {
                            borderBottom: 'none',
                        },
                    }}
                    value={inputCurrentPage}
                    onChange={(e) => handleInputChange(e)}
                />
                <Button
                    sx={{
                        color: 'white',
                        backgroundColor: '#0A0A0A',
                        opacity: '0.5',
                        borderRadius: '0px 8px 8px 0px',
                        padding: '5px 1px',
                        minWidth: '45px',
                        textTransform: 'none',
                        height: '100%',
                        fontSize: '12px',
                        '&:hover': { backgroundColor: 'rgba(10,10,10,0.8)' },
                    }}
                    onClick={handleGoButtonClick}
                >
                    {t('youraccount.go')}
                </Button>
            </Box>
        </Box>
    );
};

export default CustomPagination;
