import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from 'react-responsive'
import { Helmet } from "react-helmet";
import { useTheme } from '@mui/material/styles';
const ResponsibleGambling = () => {

    const mBreakPoint0_900 = useMediaQuery({ query: '(min-width: 0px) and (max-width: 900px)' });
    const history = useNavigate()

    useEffect(() => {
        // scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }, [])
    const goPrevPage = () => {
        history(-1);
    }
    const theme = useTheme();
    return (
        <Box sx={{ fontFamily: theme.typography.fontFamily }}>
            <Helmet>
                <title>Responsible Gambling - Multifun</title>
                <meta name="description" content="Multifun is committed to endorsing our client's Responsible Gambling policies and efforts, including promoting the awareness of problem gambling and improving prevention, intervention, and treatment of gambling addiction or problems." />
            </Helmet>
            {!mBreakPoint0_900 && (
                <Box sx={{ display: 'flex', flexDirection: 'column', overflow: 'hidden', width: '100vw' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Box sx={{ backgroundColor: 'rgba(58, 32, 109, 1)', position: 'fixed', width: '100%', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)' }}>
                            <img src={process.env.PUBLIC_URL + '/Images/multifunlogo.svg'} alt="logo" style={{ width: '10%', marginLeft: '150px', marginTop: '10px', marginBottom: '10px', cursor: 'pointer' }} onClick={() => goPrevPage()} />
                        </Box>
                        <Box sx={{ backgroundColor: 'white', paddingLeft: '150px', paddingRight: '150px', marginTop: '150px' }}>
                            <p>Multifun is committed to endorsing our client's Responsible Gambling policies and efforts including promoting the awareness of problem gambling and improving prevention, intervention and treatment of gambling addiction or problems.</p>
                            <p>We believe it is our duty as a responsible partner to keep up to date with our client's policies and with full discretion report any potential gambling problems that players might have. </p>
                            <p>Multifun Mission Statement 2023</p>
                        </Box>
                    </Box>
                </Box>
            )}
            {mBreakPoint0_900 && (
                <Box sx={{ display: 'flex', flexDirection: 'column', overflow: 'hidden', width: '100vw' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Box sx={{ backgroundColor: 'rgba(58, 32, 109, 1)', position: 'fixed', width: '100%', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center' }}>
                            <img src={process.env.PUBLIC_URL + '/Images/multifunlogo.svg'} alt="logo" style={{ width: '40%', marginTop: '10px', marginBottom: '10px', cursor: 'pointer' }} onClick={() => goPrevPage()} />
                        </Box>
                        <Box sx={{ backgroundColor: 'white', paddingLeft: '24px', paddingRight: '24px', marginTop: '150px' }}>
                            <p>Multifun is committed to endorsing our client's Responsible Gambling policies and efforts including promoting the awareness of problem gambling and improving prevention, intervention and treatment of gambling addiction or problems.</p>
                            <p>We believe it is our duty as a responsible partner to keep up to date with our client's policies and with full discretion report any potential gambling problems that players might have. </p>
                            <p>Multifun Mission Statement 2023</p>
                        </Box>
                    </Box>
                </Box>
            )}
        </Box>
    );
}

export default ResponsibleGambling;