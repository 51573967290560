import { BACKEND_URL } from "./config";

//CRUD
export const API_R_BRAND_COUNT = `${BACKEND_URL}/api-R-brandcount`;
export const API_CR_SIGN_IN = `${BACKEND_URL}/api-CR-signin`;
export const API_CR_SIGN_UP = `${BACKEND_URL}/api-CR-signup`;
export const API_U_VERIFY = `${BACKEND_URL}/api-U-verify`;
export const API_R_VERIFY = `${BACKEND_URL}/api-R-verify`;
export const API_U_RESET_PASSWORD = `${BACKEND_URL}/api-U-resetpassword`;
export const API_U_RESET_PASSWORDCONFIRM = `${BACKEND_URL}/api-U-resetpasswordconfirm`;
export const API_R_RECENT_CASHBACK = `${BACKEND_URL}/api-R-recentcashback`;
export const API_R_DATA_DASHBOARD = `${BACKEND_URL}/api-R-dashboarddata`;
export const API_U_STATUS_REFERFRIEND = `${BACKEND_URL}/api-U-statusreferfriend`;
export const API_D_NOTIFICATION = `${BACKEND_URL}/api-D-notification`;
export const API_R_GETACCOUNT = `${BACKEND_URL}/api-R-getaccount`;
export const API_R_GETNOTIFICATION = `${BACKEND_URL}/api-R-getnotification`;
export const API_D_MARKASREADALL = `${BACKEND_URL}/api-D-markasreadall`;
export const API_R_SYNCTIME = `${BACKEND_URL}/api-R-synctime`;
export const API_R_ACCOUNTDATA = `${BACKEND_URL}/api-R-accountdata`;
export const API_R_DEALS = `${BACKEND_URL}/api-R-deals`;
export const API_R_DEALSTATUS = `${BACKEND_URL}/api-R-dealstatus`;
export const API_R_CHECKDEALS = `${BACKEND_URL}/api-R-checkdeals`;
export const API_C_REGTODEAL = `${BACKEND_URL}/api-C-regtodeal`;
export const API_R_PREVIEWDATA = `${BACKEND_URL}/api-R-previewdata`;
export const API_R_REVIEWDATA = `${BACKEND_URL}/api-R-reviewdata`;
export const API_C_REVIEW = `${BACKEND_URL}/api-C-review`;
export const API_R_PAYMENT = `${BACKEND_URL}/api-R-payment`;
export const API_R_PAYMENTDETAIL = `${BACKEND_URL}/api-R-paymentdetail`;
export const API_U_PAYMENTDETAIL = `${BACKEND_URL}/api-U-paymentdetail`;
export const API_U_PLAYERCASHBACK = `${BACKEND_URL}/api-U-playercashback`;
export const API_U_PROFILE = `${BACKEND_URL}/api-U-profile`;
export const API_U_PASSWORD = `${BACKEND_URL}/api-U-password`;
export const API_U_NOTIFICATION = `${BACKEND_URL}/api-U-notification`;
export const API_R_ReferralData = `${BACKEND_URL}/api-R-ReferralData`;