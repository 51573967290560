/* eslint-disable react/jsx-pascal-case */
import React, { useContext, useState } from "react";
import { Box, Collapse, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useMediaQuery } from 'react-responsive'
import { Helmet } from "react-helmet";
import { AuthContext } from "../../contexts/AuthContext";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { useTheme } from '@mui/material/styles';

const HelpSection = () => {

    const { t } = useTranslation();
    const mBreakPoint0_1280 = useMediaQuery({ query: '(min-width: 0px) and (max-width: 1280px)' });
    const mBreakPoint1281_1366 = useMediaQuery({ query: '(min-width: 1281px) and (max-width: 1768px)' });

    const [mSelectedIndexFAQsBox, setSelectedIndexFAQsBox] = useState(0);

    const handleStatusFAQs = (index) => {
        setSelectedIndexFAQsBox((prevIndex) => prevIndex === index ? -1 : index);
    }
    const FAQs_List = [
        {
            Question: 'how_do_I_sync_my_gambling_account_with_my_multifun_account',
            Answer: `after_creating_a_cashback_request_from_Multifun`,
        },
        {
            Question: 'types_of_websites',
            Answer: 'supports_all_gambling_websites',
        },
        {
            Question: 'play_on_different_websites',
            Answer: 'with_different_gambling_website',
        },
        {
            Question: 'how_is_cashback_calculated',
            Answer: `cashback_is_calculated`,
            Answer1: `in_both_case`,
        },
        {
            Question: 'when_can_withdraw_earnings',
            Answer: 'your_monthly_cashback',
        },
        {
            Question: 'payment_methods_supported',
            Answer: 'you_can_withdraw_your_cashback',
        },
        {
            Question: 'earn_cashback_from_existing_account',
            Answer: 'for_multifun_to_sync',
        },
        {
            Question: 'can_i_change_the_email_listed_on_a_cashback_account',
            Answer: 'the_emails_on_your_cashback_accounts_are_only_used',
        },
        {
            Question: 'when_are_statistics_updated_in_my_account',
            Answer: `statistics_are_updated_daily_at_1200_PM`,
        },
        {
            Question: 'i_requested_a_withdrawal_and_it_is_still_showing_as_pending_why',
            Answer: `withdrawals_can_take_up_to_5_working_days_to_be_processed`,
        },
        {
            Question: 'cashback_amount_is_different_than_i_expected',
            Answer: `due_to_fees_imposed`,
        },
    ]

    const Typography_ = styled(Typography)({
        fontSize: mBreakPoint1281_1366 ? '9px'
            : '15px',
        lineHeight: '140%',
        color: 'black',
        margin: 0
    });

    const { setDealType } = useContext(AuthContext);
    setDealType(0);
    const contactUsEmail = 'support@multifun.io';

    const handleContactClick = () => {
        const windowFeatures = 'width=800,height=600,scrollbars=yes,resizable=yes';
        window.open(`mailto:${contactUsEmail}`, '_blank', windowFeatures);
    };

    const theme = useTheme();
    return (
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', maxHeight: '90vh', overflow: 'auto', fontFamily: theme.typography.fontFamily }}>
            <Helmet>
                <title>{t('sidebar.help')} - Multifun</title>
            </Helmet>
            {!mBreakPoint0_1280 && (
                <Box sx={{ width: '80%' }}>
                    <Box id='FAQs' sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', width: '100%', borderRadius: '16px' }}>
                        <p style={{ margin: 0, fontWeight: 700, fontSize: mBreakPoint1281_1366 ? '30px' : '50px', lineHeight: '60px', marginTop: mBreakPoint1281_1366 ? '70px' : '250px', textAlign:'center' }}>
                            <span style={{ color: 'rgba(58, 32, 109, 1)' }}>{t('homepage.faqs')}</span>
                        </p>
                        <p style={{ margin: 0, fontWeight: 400, fontSize: mBreakPoint1281_1366 ? '14px' : '25px', lineHeight: '30px', marginTop: mBreakPoint1281_1366 ? '5px' : '20px' }}>
                            <span style={{ color: 'black' }}>{t('homepage.frequentlyaskedquestions')}</span>
                        </p>
                        <Box sx={{ width: '100%', marginBottom: '30px' }}>
                            <Box sx={{ marginTop: mBreakPoint1281_1366 ? '24px' : '60px' }}>
                            </Box>
                            {FAQs_List.map((item, index) => (
                                <Box>
                                    <Box key={index} sx={{ backgroundColor: mSelectedIndexFAQsBox === index ? 'rgba(58, 32, 109, 1)' : 'white', height: mBreakPoint1281_1366 ? '40px' : '60px', borderStyle: 'solid', borderWidth: mSelectedIndexFAQsBox === index ? '0' : '1px', borderColor: 'rgba(0,0,0,0.1)', borderRadius: index === 0 ? '8px 8px 0px 0px' : '0', display: 'flex', alignItems: 'center', justifyContent: 'space-between', cursor: 'pointer' }} onClick={() => handleStatusFAQs(index)}>
                                        <p style={{ margin: 0, fontWeight: 400, fontSize: mBreakPoint1281_1366 ? '10px' : '20px', lineHeight: '30px', marginLeft: '24px' }}>
                                            <span style={{ color: mSelectedIndexFAQsBox === index ? 'white' : 'rgba(58, 46, 82, 0.9)' }}>{t(`faqlist_homepage_question.${item.Question}`)}</span>
                                        </p>
                                        <Box sx={{ marginRight: '24px' }}>
                                            {mSelectedIndexFAQsBox === index ? <img src={process.env.PUBLIC_URL + '/Images/iconArrowUp.png'} alt="" style={{ opacity: '0.5' }} /> : <img src={process.env.PUBLIC_URL + '/Images/iconArrowDown.png'} alt="" style={{ opacity: '0.5' }} />}
                                        </Box>
                                    </Box>

                                    <Collapse in={mSelectedIndexFAQsBox === index} timeout={300}>
                                        <Box sx={{ borderColor: 'rgba(0,0,0,0.1)', borderWidth: '1px', borderStyle: 'solid', borderRadius: index === FAQs_List.length - 1 ? '0px 0px 8px 8px' : '0', maxHeight: mSelectedIndexFAQsBox === index ? '1000px' : '0', opacity: mSelectedIndexFAQsBox === index ? '1' : '0', overflow: 'hidden', transition: 'max-height 1.5s ease-out, opacity 1.5s ease-out' }}>
                                            <p style={{ fontWeight: 400, fontSize: mBreakPoint1281_1366 ? '8px' : '16px', lineHeight: mBreakPoint1281_1366 ? '15px' : '30px', margin: '24px' }}>
                                                <span style={{ color: 'rgba(58, 46, 82, 0.8)' }}>{t(`faqlist_homepage_answer.${item.Answer}`)}</span>
                                                {item.Answer1 && (
                                                    <>
                                                        <br /><br />
                                                        <span style={{ color: 'rgba(58, 46, 82, 0.8)' }}>{t(`faqlist_homepage_answer.${item.Answer1}`)}</span>
                                                    </>
                                                )}
                                            </p>
                                        </Box>
                                    </Collapse>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', borderColor: 'rgba(58, 32, 109, 1)' }}>
                        <Typography_ >{t('help.bottom_line_text')} <Link style={{ cursor: 'pointer', color: 'rgba(58, 32, 109, 1)' }} onClick={() => handleContactClick()}>support@multifun.io</Link></Typography_>
                    </Box>
                </Box>
            )}
            {mBreakPoint0_1280 && (
                <Grid container sx={{ backgroundColor: 'white' }}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        backgroundColor: 'white',
                        marginTop:'450px',
                        padding:'15px'
                    }}>
                        <p style={{ margin: 0, fontWeight: 700, fontSize: '40px', lineHeight: '60px', textAlign:'center'}}>
                            <span style={{ color: 'rgba(58, 32, 109, 1)' }}>{t('homepage.faqs')}</span>
                        </p>
                        <p style={{ margin: 0, fontWeight: 400, fontSize: '16px', lineHeight: '20px'}}>
                            <span style={{ color: 'black' }}>{t('homepage.frequentlyaskedquestions')}</span>
                        </p>
                        <Box sx={{ width: '100%', marginBottom: '15px', marginTop:'15px' }}>
                            {FAQs_List.map((item, index) => (
                                <Box>
                                    <Box key={index} sx={{ paddingTop: '3px', paddingBottom: '3px', backgroundColor: mSelectedIndexFAQsBox === index ? 'rgba(58, 32, 109, 1)' : 'white', borderStyle: 'solid', borderWidth: mSelectedIndexFAQsBox === index ? '0' : '1px', borderColor: 'rgba(0,0,0,0.1)', borderRadius: index === 0 ? '8px 8px 0px 0px' : '0', display: 'flex', alignItems: 'center', justifyContent: 'space-between', cursor: 'pointer' }} onClick={() => handleStatusFAQs(index)}>
                                        <p style={{ margin: 0, fontWeight: 400, fontSize: '14px', lineHeight: '20px', marginLeft: '10px' }}>
                                            <span style={{ color: mSelectedIndexFAQsBox === index ? 'white' : 'rgba(58, 46, 82, 0.9)' }}>{t(`faqlist_homepage_question.${item.Question}`)}</span>
                                        </p>
                                        <Box sx={{ marginRight: '10px', marginLeft:'10px' }}>
                                            {mSelectedIndexFAQsBox === index ? <img src={process.env.PUBLIC_URL + '/Images/iconArrowUp.png'} alt=""  style={{ opacity: '0.5' }}/> : <img src={process.env.PUBLIC_URL + '/Images/iconArrowDown.png'} alt="" style={{ opacity: '0.5' }}/>}
                                        </Box>
                                    </Box>

                                    <Collapse in={mSelectedIndexFAQsBox === index} timeout={300}>
                                        <Box sx={{ borderColor: 'rgba(0,0,0,0.1)', borderWidth: '1px', borderStyle: 'solid', borderRadius: index === FAQs_List.length - 1 ? '0px 0px 8px 8px' : '0', maxHeight: mSelectedIndexFAQsBox === index ? '1000px' : '0', opacity: mSelectedIndexFAQsBox === index ? '1' : '0', overflow: 'hidden', transition: 'max-height 1.5s ease-out, opacity 1.5s ease-out' }}>
                                            <p style={{ fontWeight: 400, fontSize: '12px', lineHeight: '20px', margin: '10px' }}>
                                                <span style={{ color: 'rgba(58, 46, 82, 0.8)' }}>{t(`faqlist_homepage_answer.${item.Answer}`)}</span>
                                                {item.Answer1 && (
                                                    <>
                                                        <br /><br />
                                                        <span style={{ color: 'rgba(58, 46, 82, 0.8)' }}>{t(`faqlist_homepage_answer.${item.Answer1}`)}</span>
                                                    </>
                                                )}
                                            </p>
                                        </Box>
                                    </Collapse>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderColor: 'rgba(58, 46, 82, 0.8)', padding:'0px 15px 15px 15px' }}>
                        <p style={{ fontWeight: 400, fontSize: '13px', lineHeight: '20px', margin: 0 }}>
                            {t('help.bottom_line_text')} <Link style={{ cursor: 'pointer', color: 'rgba(58, 46, 82, 0.8)' }} onClick={() => handleContactClick()}>support@multifun.io</Link>
                        </p>
                    </Box>
                </Grid>
            )}
        </Box>
    )
}

export default HelpSection;