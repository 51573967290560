/* eslint-disable react/jsx-pascal-case */
import React, { useContext, useEffect, useState } from "react";
import { Box, Button, OutlinedInput } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getStoredAuthToken } from "../../utils/authToken";
import jwt_decode from 'jwt-decode';
import axios from "axios";
import { S3Url } from "../../share/config";
import Swal from "sweetalert2";
import alertSZ from "../../utils/alert";
import { useMediaQuery } from 'react-responsive'
import { Helmet } from "react-helmet";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { makeStyles } from '@material-ui/core/styles';
import { IoMailOutline } from "react-icons/io5";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { API_C_REGTODEAL, API_R_CHECKDEALS, API_R_DEALSTATUS } from "../../share/api";
import { AuthContext } from "../../contexts/AuthContext";
import { useTheme } from '@mui/material/styles';

const useStyles = makeStyles({
    input: {
        WebkitBoxShadow: '0 0 0 1000px white inset',
    },
});

const ManualRegAccount = () => {

    const { isLogin } = useContext(AuthContext);
    const history = useNavigate();
    const authtoken = getStoredAuthToken();
    const theme = useTheme();
    const email = authtoken ? jwt_decode(authtoken).email : null;
    if (!isLogin || !email) {
        history('/signin');
    }
    const { t } = useTranslation();
    const mBreakPoint0_1280 = useMediaQuery({ query: '(min-width: 0px) and (max-width: 1280px)' });
    const mBreakPoint1281_1366 = useMediaQuery({ query: '(min-width: 1281px) and (max-width: 1768px)' });

    const [profileEmail, setProfileEmail] = useState();
    const [mRequiredMark, setRequiredMark] = useState(true);
    const [mBrandName, setBrandName] = useState();

    const queryParams = new URLSearchParams(window.location.search)
    const encodedBrandData = queryParams.get("brand");
    const brandData = JSON.parse(decodeURIComponent(encodedBrandData));

    const styleClass = useStyles();
    useEffect(() => {
        setBrandName(brandData?.brandName)
    }, [brandData])

    const Button_ = styled(Button)({
        padding: mBreakPoint1281_1366 ? '1px 5px'
            : '15px 29px',
        color: "#FFFFFF",
        fontWeight: "400",
        fontSize: mBreakPoint1281_1366 ? '9px'
            : '14px',
        backgroundColor: "#6C3D9E",
        borderRadius: "8px",
        textTransform: 'none',
        height: mBreakPoint1281_1366 ? '25px'
            : '40px',
        width: '100%',
        '&:hover': { backgroundColor: 'rgba(99,57,143,0.8)' }
    });
    const [mRegistrationStatus, setRegistrationStatus] = useState(null);
    const [mLoadingDataFromServer, setLoadingDataFromServer] = useState(false);

    useEffect(() => {
        const getRegistrationStatus = async () => {
            setLoadingDataFromServer(false);
            const url = brandData?.affiliateLink;
            await axios.post(API_R_DEALSTATUS, {
                email, url
            }).then(res => {
                const body = res.data;
                setLoadingDataFromServer(true);
                if (res.status === 200) {
                    setRegistrationStatus(body);
                    return;
                }
            }).catch(e => {
                setLoadingDataFromServer(true);
                const text = e.response.data;
                alertSZ(t(`backend.${text}`))
                return
            })
        }
        if (isLogin && email) {
            getRegistrationStatus();
        }
    }, [brandData?.affiliateLink, email, isLogin, t])

    const handleKeyPress = (event) => {
        if (event && event.key === 'Enter') {
            event.preventDefault();
            handleGotoMrGreen(event);
        }
    };
    const handleGotoMrGreen = async (event) => {

        if (mRegistrationStatus) {
            history('/accounts')
            return
        }

        if (!mLoadingDataFromServer) return;

        const url = brandData?.affiliateLink;
        if (!profileEmail || (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(profileEmail)) || !url) {
            setRequiredMark(false);
            return
        }

        await axios.post(API_R_CHECKDEALS, {
            email, url,
        }).then(async (res) => {
            if (res.status === 204) {
                Swal.fire({
                    title: t('registertocasino.you_have_a_pending_connection'),
                    text: t('registertocasino.you_cannot_create_another_account').replace('{BrandName}', mBrandName),
                    icon: "question",
                    showCancelButton: true,
                    cancelButtonText: t('registertocasino.close'),
                    showConfirmButton: false,
                })
                return
            }
            if (res.status === 205) {
                Swal.fire({
                    title: t('registertocasino.you_already_have_an_account_with').replace('{BrandName}', mBrandName),
                    text: t('registertocasino.are_you_sure_you_want_to_create_a_new_account'),
                    icon: "question",
                    showCancelButton: true,
                    confirmButtonText: t('registertocasino.yes_register_a_new_account'),
                    cancelButtonText: t('registertocasino.no_cancel_this_registration')
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        await axios.post(API_C_REGTODEAL, {
                            email, url, profileEmail
                        }).then(res => {
                            const body = res.data;
                            if (res.status === 204) {
                                Swal.fire({
                                    title: t('registertocasino.register_a_new_account_for_this_month'),
                                    text: t('registertocasino.you_cannot_register_an_additional_account').replace('{BrandName}', mBrandName),
                                    icon: "question",
                                    showCancelButton: true,
                                    cancelButtonText: t('registertocasino.close'),
                                    showConfirmButton: false,
                                })
                                return;
                            }
                            if (res.status === 208) {
                                alertSZ(t(`backend.${body}`))
                                return;
                            }
                            if (res.status === 200) {
                                console.log(brandData)
                                const extralink = brandData?.additionalLink ? brandData?.additionalLink : ''
                                const b = url.includes('?');
                                if (body?.affSys === 'MyAffiliates') {
                                    const affiliateUrl = url + `${b ? '&' : '?'}payload=` + body?.payloadID + extralink;
                                    window.open(affiliateUrl, '_blank');
                                }
                                if (body?.affSys === 'IncomeAccess') {
                                    const affiliateUrl = url + `${b ? '&' : '?'}c=` + body?.payloadID + extralink;
                                    window.open(affiliateUrl, '_blank');
                                }
                                if (body?.affSys === 'VPartners') {
                                    const affiliateUrl = url + `${b ? '&' : '?'}sub_id=` + body?.payloadID + extralink;
                                    window.open(affiliateUrl, '_blank');
                                }
                                if (body?.affSys === 'ReferOn') {
                                    const affiliateUrl = url + `${b ? '&' : '?'}subid=` + body?.payloadID + extralink;
                                    window.open(affiliateUrl, '_blank');
                                }
                                if (body?.affSys === 'Smartico') {
                                    const affiliateUrl = url + `${b ? '&' : '?'}afp=` + body?.payloadID + extralink;
                                    window.open(affiliateUrl, '_blank');
                                }
                                if (body?.affSys === 'Affilka') {
                                    const affiliateUrl = url + `${b ? '&' : '?'}var=` + body?.payloadID + extralink;
                                    window.open(affiliateUrl, '_blank');
                                }
                                if (body?.affSys === 'EGO') {
                                    const affiliateUrl = url + `${b ? '&' : '?'}dyn_id=` + body?.payloadID + extralink;
                                    window.open(affiliateUrl, '_blank');
                                }
                                if (body?.affSys === 'CellXpert') {
                                    const affiliateUrl = url + `${b ? '&' : '?'}afp=` + body?.payloadID + extralink;
                                    window.open(affiliateUrl, '_blank');
                                }
                                return;
                            }
                        }).catch(e => {
                            return
                        })
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                        return
                    }
                });
                return;
            }
            if (res.status === 200) {
                await axios.post(API_C_REGTODEAL, {
                    email, url, profileEmail
                }).then(res => {
                    const body = res.data;
                    if (res.status === 204) {
                        Swal.fire({
                            title: t('registertocasino.register_a_new_account_for_this_month'),
                            text: t('registertocasino.you_cannot_register_an_additional_account').replace('{BrandName}', mBrandName),
                            icon: "question",
                            showCancelButton: true,
                            cancelButtonText: t('registertocasino.close'),
                            showConfirmButton: false,
                        })
                        return;
                    }
                    if (res.status === 208) {
                        alertSZ(t(`backend.${body}`))
                        return;
                    }
                    if (res.status === 200) {
                        console.log(brandData)
                        const extralink = brandData?.additionalLink ? brandData?.additionalLink : ''
                        const b = url.includes('?');
                        if (body?.affSys === 'MyAffiliates') {
                            const affiliateUrl = url + `${b ? '&' : '?'}payload=` + body?.payloadID + extralink;
                            window.open(affiliateUrl, '_blank');
                        }
                        if (body?.affSys === 'IncomeAccess') {
                            const affiliateUrl = url + `${b ? '&' : '?'}c=` + body?.payloadID + extralink;
                            window.open(affiliateUrl, '_blank');
                        }
                        if (body?.affSys === 'VPartners') {
                            const affiliateUrl = url + `${b ? '&' : '?'}sub_id=` + body?.payloadID + extralink;
                            window.open(affiliateUrl, '_blank');
                        }
                        if (body?.affSys === 'ReferOn') {
                            const affiliateUrl = url + `${b ? '&' : '?'}subid=` + body?.payloadID + extralink;
                            window.open(affiliateUrl, '_blank');
                        }
                        if (body?.affSys === 'Smartico') {
                            const affiliateUrl = url + `${b ? '&' : '?'}afp=` + body?.payloadID + extralink;
                            window.open(affiliateUrl, '_blank');
                        }
                        if (body?.affSys === 'Affilka') {
                            const affiliateUrl = url + `${b ? '&' : '?'}var=` + body?.payloadID + extralink;
                            window.open(affiliateUrl, '_blank');
                        }
                        if (body?.affSys === 'EGO') {
                            const affiliateUrl = url + `${b ? '&' : '?'}dyn_id=` + body?.payloadID + extralink;
                            window.open(affiliateUrl, '_blank');
                        }
                        if (body?.affSys === 'CellXpert') {
                            const affiliateUrl = url + `${b ? '&' : '?'}afp=` + body?.payloadID + extralink;
                            window.open(affiliateUrl, '_blank');
                        }
                        setRegistrationStatus(true);
                        return;
                    }
                }).catch(e => {
                    const text = e.response.data;
                    alertSZ(t(`backend.${text}`))
                    return
                })
                return;
            }
        }).catch(e => {
            return
        })
    }

    return (
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', fontFamily: theme.typography.fontFamily }}>
            <Helmet>
                <title>{`${mBrandName} Cashback - Multifun`}</title>
            </Helmet>
            {!mBreakPoint0_1280 && (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: mBreakPoint1281_1366 ? '35%' : '40%', height: '92vh' }}>
                    {mLoadingDataFromServer && (
                        <Box sx={{ paddingBottom: '20px', backgroundColor: 'white', borderRadius: '30px', boxShadow: '6px 6px 50px rgba(0, 0, 0, 0.05)' }}>
                            <Box sx={{ paddingLeft: '48px', paddingRight: '48px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <p style={{ margin: 0, marginTop: '32px', fontSize: mBreakPoint1281_1366 ? '12px' : '20px', fontWeight: 600, lineHeight: '36px', color: '#131517' }}>{t('registertocasino.register_for_cashback')}</p>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '9px' }}>
                                <img src="/Images/Rectangle.svg" alt="" />
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'center', paddingLeft: '48px', paddingRight: '48px', marginTop: '20px' }}>
                                <img src={S3Url + '/images/' + brandData?.logoUrl} alt="" style={{ width: '100%', borderRadius: '16px', }} />
                            </Box>
                            {mRegistrationStatus && (
                                <Box sx={{ display: 'flex', justifyContent: 'center', paddingLeft: '48px', paddingRight: '48px', marginTop: '20px' }}>
                                    <CheckCircleIcon style={{ color: 'green', fontSize: '60px' }} />
                                </Box>
                            )}
                            <Box sx={{ display: 'flex', justifyContent: 'center', paddingLeft: '48px', paddingRight: '48px' }}>
                                <p style={{ margin: 0, marginTop: '20px', fontSize: mBreakPoint1281_1366 ? '10px' : '14px', letterSpacing: '-0.3px', fontWeight: 400, lineHeight: '140%', color: '#131517', textAlign: 'center' }}>
                                    {mRegistrationStatus ? t('registertocasino.your_cashback_request_was_created_Register_your_account').replaceAll('{BrandName}', mBrandName) : t('registertocasino.enter_the_email_you_will_register_with').replaceAll('{BrandName}', mBrandName)}
                                </p>
                            </Box>
                            <Box sx={{ paddingLeft: '48px', paddingRight: '48px' }}>
                                {!mRegistrationStatus && (
                                    <Box sx={{ marginTop: '20px' }}>
                                        <OutlinedInput
                                            placeholder={t('setting.email_address')}
                                            disabled={!mRegistrationStatus ? false : true}
                                            classes={{ input: styleClass.input }}
                                            sx={{
                                                input: {
                                                    "&::placeholder": {    // <----- Add this.
                                                        opacity: 1,
                                                    },
                                                },
                                                paddingLeft: mBreakPoint1281_1366 ? '5px' : '15px',
                                                backgroundColor: "#FFFFFF",
                                                border: '0px solid grey',
                                                borderRadius: '8px',
                                                fontSize: mBreakPoint1281_1366 ? '9px'
                                                    : '12px',
                                                fontWeight: '400',
                                                lineHeight: '21px',
                                                width: '100%',
                                                height: mBreakPoint1281_1366 ? '25px'
                                                    : '40px'
                                            }}
                                            startAdornment={
                                                <IoMailOutline style={{ fontSize: mBreakPoint1281_1366 ? '20px' : '30px', color: 'grey', marginLeft: '5px', marginRight: '5px' }} />
                                            }
                                            value={profileEmail}
                                            onKeyPress={handleKeyPress}
                                            onChange={(e) => setProfileEmail(e.target.value.toLowerCase())}
                                            inputProps={{
                                                style: {
                                                    textTransform: 'lowercase',
                                                }
                                            }}
                                        />
                                        <Box sx={{ color: 'red' }}>
                                            {(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(profileEmail)) && !mRequiredMark && 'Invalid email address'}
                                        </Box>
                                    </Box>
                                )}
                                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: mBreakPoint1281_1366 ? '10px' : '20px', marginBottom: '16px' }}>
                                    <Button_
                                        onClick={handleGotoMrGreen}
                                    >{mRegistrationStatus ? t('registertocasino.go_to_your_accounts') : t('registertocasino.go_to_casino_Registration').replaceAll('{BrandName}', mBrandName)}</Button_>
                                </Box>
                            </Box>
                        </Box>
                    )}
                </Box>
            )}
            {mBreakPoint0_1280 && (
                <Box sx={{ padding: '12px 16px' }}>
                    {mLoadingDataFromServer && (
                        <Box sx={{ paddingBottom: '20px', backgroundColor: 'white', borderRadius: '30px', boxShadow: '6px 6px 50px rgba(0, 0, 0, 0.05)' }}>
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <p style={{ margin: 0, marginTop: '32px', fontSize: '16px', fontWeight: 600, lineHeight: '36px', color: '#131517' }}>{t('registertocasino.register_for_cashback')}</p>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '9px' }}>
                                <img src="/Images/Rectangle.svg" alt="" />
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'center', paddingLeft: '24px', paddingRight: '24px', marginTop: '20px' }}>
                                <img src={S3Url + '/images/' + brandData?.logoUrl} alt="" style={{ width: '100%', borderRadius: '16px' }} />
                            </Box>
                            {mRegistrationStatus && (
                                <Box sx={{ display: 'flex', justifyContent: 'center', paddingLeft: '48px', paddingRight: '48px', marginTop: '20px' }}>
                                    <CheckCircleIcon style={{ color: 'green', fontSize: '60px' }} />
                                </Box>
                            )}
                            <Box sx={{ display: 'flex', justifyContent: 'center', paddingLeft: '24px', paddingRight: '24px' }}>
                                <p style={{ margin: 0, marginTop: '20px', fontSize: '12px', letterSpacing: '-0.3px', fontWeight: 400, lineHeight: '140%', color: '#131517', textAlign: 'center' }}>
                                    {mRegistrationStatus ? t('registertocasino.your_cashback_request_was_created_Register_your_account').replaceAll('{BrandName}', mBrandName) : t('registertocasino.enter_the_email_you_will_register_with').replaceAll('{BrandName}', mBrandName)}
                                </p>
                            </Box>
                            <Box sx={{ paddingLeft: '24px', paddingRight: '24px' }}>
                                {!mRegistrationStatus && (
                                    <Box sx={{ marginTop: '20px' }}>
                                        <OutlinedInput
                                            placeholder={t('setting.email_address')}
                                            disabled={!mRegistrationStatus ? false : true}
                                            sx={{ padding: 0, paddingLeft: '15px', backgroundColor: "#FFFFFF", border: 'border: 1px solid #B4B4B4', borderRadius: '8px', fontSize: '12px', fontWeight: '400', lineHeight: '21px', width: '100%', height: '40px' }}
                                            startAdornment={
                                                <IoMailOutline style={{ fontSize: '30px', color: 'grey', marginRight: '5px' }} />
                                            }
                                            value={profileEmail}
                                            onKeyPress={handleKeyPress}
                                            onChange={(e) => setProfileEmail(e.target.value.toLowerCase())}
                                            inputProps={{
                                                style: {
                                                    textTransform: 'lowercase',
                                                }
                                            }}
                                        />
                                        <Box sx={{ color: 'red' }}>
                                            {(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(profileEmail)) && !mRequiredMark && 'Invalid email address'}
                                        </Box>
                                    </Box>
                                )}
                                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px', marginBottom: '16px' }}>
                                    <Button sx={{ width: '100%', padding: '16px 5px', color: "#FFFFFF", fontWeight: "400", fontSize: '12px', backgroundColor: "#6C3D9E", borderRadius: "8px", textTransform: 'none', maxHeight: '50px', '&:hover': { backgroundColor: 'rgba(99,57,143,0.8)' } }}
                                        onClick={handleGotoMrGreen}
                                    >{mRegistrationStatus ? t('registertocasino.go_to_your_accounts') : t('registertocasino.go_to_casino_Registration').replaceAll('{BrandName}', mBrandName)}</Button>
                                </Box>
                            </Box>
                        </Box>
                    )}
                </Box>
            )}
        </Box>
    )
}

export default ManualRegAccount;