import { Link, Box, Button, Grid, styled, Backdrop, CircularProgress, Divider, Rating, OutlinedInput } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Icon from "../../components/Icon";
import { useNavigate } from "react-router-dom";
import { S3Url } from "../../share/config";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { getStoredAuthToken } from "../../utils/authToken";
import alertSZ from "../../utils/alert";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { useMediaQuery } from 'react-responsive'
import { Helmet } from "react-helmet";
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { useTranslation } from "react-i18next";
import { API_C_REVIEW, API_R_PREVIEWDATA, API_R_REVIEWDATA } from "../../share/api";
import { AuthContext } from "../../contexts/AuthContext";
import { useTheme } from '@mui/material/styles';

const ReviewDashboard = () => {

    const { isLogin } = useContext(AuthContext);
    const history = useNavigate();
    const authtoken = getStoredAuthToken();
    const theme = useTheme();
    const email = authtoken ? jwt_decode(authtoken).email : null;
    if (!isLogin || !email) {
        history('/signin');
    }

    const { t } = useTranslation();
    const mBreakPoint0_1280 = useMediaQuery({ query: '(min-width: 0px) and (max-width: 1280px)' });
    const mBreakPoint1281_1366 = useMediaQuery({ query: '(min-width: 1281px) and (max-width: 1768px)' });

    const ToolTipForDesktop = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: 'grey',
            color: 'white',
            borderRadius: '8px',
            fontSize: '10px',
            maxWidth: '500px',
        },
    }));

    let AffiliateLinkObj = null;
    let mCheckGamingButton = 0;
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.has("gameItem")) {
        try {
            AffiliateLinkObj = JSON.parse(queryParams.get("gameItem"));
        } catch (error) {
            console.error('Error parsing JSON:', error);
        }
    }
    if (queryParams.has("mCheckGamingButton")) {
        mCheckGamingButton = queryParams.get('mCheckGamingButton');
    }

    const [mLoadingDataFromServer, setLoadingDataFromServer] = React.useState(false);
    const [mBrandANDgamingData, setBrandANDgamingData] = useState(null)
    const [mShowLeaveReview, setShowLeaveReview] = useState(false);
    const [mSaveButtonState, setSaveButtonState] = useState(false);
    const [mStatusRefreshReviewData, setStatusRefreshReviewData] = useState(1)

    const ResigterButton = styled(Button)({
        backgroundColor: '#FFFFFF',
        color: '#6861AB',
        borderRadius: mBreakPoint1281_1366 ? '5px' : '8px',
        width: '100%',
        fontWeight: 600,
        alignItems: 'center',
        fontSize: mBreakPoint1281_1366 ? '9px' : '11px',
        lineHeight: '100%',
        padding: '13px',
        '&:hover': {
            backgroundColor: 'rgba(255,255,255,0.8)',
        },
    });

    const [mUserAccount, setUserAccount] = useState([]);
    const [mCheckCashbackGenerateForEachAffiliateLink, setCheckCashbackGenerateForEachAffiliateLink] = useState([]);

    useEffect(() => {
        const getData = async () => {
            await axios.post(API_R_PREVIEWDATA, { //get lastcashback so user generated cashback or not
                email, mCheckGamingButton
            }).then(res => {
                const body = res.data;
                if (res.status === 200) {
                    setUserAccount(body?.user)
                    setCheckCashbackGenerateForEachAffiliateLink(body?.deals);
                    return;
                }
            }).catch(e => {
                const text = e.response.data;
                alertSZ(t(`backend.${text}`))
                return
            })
        }
        if (isLogin && email) {
            getData();
        }
    }, [email, isLogin, mCheckGamingButton, t])

    const [currentDate, setCurrentDate] = useState('');
    useEffect(() => {
        const getReviewData = async () => {
            const today = new Date();
            const options = { day: 'numeric', month: 'short', year: 'numeric' };
            const formattedDate = new Intl.DateTimeFormat('en-US', options).format(today);
            setCurrentDate(formattedDate);
            const itemID = AffiliateLinkObj.Data._id;
            setLoadingDataFromServer(true);
            await axios.post(API_R_REVIEWDATA, {
                itemID
            }).then(res => {
                const body = res.data;
                setLoadingDataFromServer(false);
                if (res.status === 200) {
                    setBrandANDgamingData(body)
                    setStatusRefreshReviewData(1)
                    return;
                }
            }).catch(e => {
                setLoadingDataFromServer(false);
                const text = e.response.data;
                alertSZ(t(`backend.${text}`));
                history('/signin');
                return
            })
        }
        if (isLogin && email) {
            getReviewData();
        }
    }, [AffiliateLinkObj.Data._id, email, history, isLogin, mStatusRefreshReviewData, t])

    const SelectSaveButton = styled(Button)({
        color: '#6C3D9E',
        width: '83px',
        fontSize: mBreakPoint1281_1366 ? '11px' : '12px',
        fontWeight: 600,
        letterSpacing: '0.5px',
        textTransform: 'none',
        flex: '1',
        borderRadius: '8px',
        backgroundColor: 'rgba(50.2, 50.2, 50.2, 0.2)',
        '&.inactive': {
            backgroundColor: 'rgba(50.2, 50.2, 50.2, 0.05)'
        },

    });

    const [mContextReview, setContextReview] = useState(null);
    const [mValueStar, setValueStar] = useState(0);
    const [mRequiredMark, setRequiredMark] = useState(true)
    const handleSaveReview = () => {

        const mAffiliateLink = AffiliateLinkObj.Data.affiliateLink;

        if (mCheckCashbackGenerateForEachAffiliateLink?.find(({ affiliateLink }) => affiliateLink === mAffiliateLink) === undefined
            || mCheckCashbackGenerateForEachAffiliateLink?.find(({ affiliateLink }) => affiliateLink === mAffiliateLink)?.TotalCashback <= 0) {

            const brandName = mBrandANDgamingData?.brandData?.BrandName;
            alertSZ(t(`backend.you_need_to_have_generated_cashback_from_to_leave_a_review`).replaceAll('{BrandName}', brandName));
            return
        }

        if (!mContextReview || mContextReview.length < 100) {
            setRequiredMark(false)
            return
        }
        if (mValueStar === 0) {
            alertSZ(t('backend.please_leave_star_review'));
            return;
        }

        const setReviewData = async () => {
            const affiliateLink = AffiliateLinkObj.Data.affiliateLink;
            const firstName = mUserAccount?.firstName;
            const lastName = mUserAccount?.lastName;
            const reportDate = currentDate;
            const contextReview = mContextReview;
            const ratingForReview = mValueStar;
            setSaveButtonState(true);
            await axios.post(API_C_REVIEW, {
                email, affiliateLink, firstName, lastName, reportDate, contextReview, ratingForReview
            }).then(res => {
                const body = res.data;
                setSaveButtonState(false);
                if (res.status === 208) {
                    alertSZ(body)
                    return;
                }
                if (res.status === 200) {
                    setShowLeaveReview(prev => !prev)
                    setStatusRefreshReviewData(2)
                    return;
                }
            }).catch(e => {
                const text = e.response.data;
                alertSZ(t(`backend.${text}`));
                history('/signin')
                return
            })
        }
        setReviewData();
    }

    const mShowperPage = 5;
    const [mShowIndexReviewData, setShowIndexReviewData] = useState(1);
    const handleLoadMore = () => {
        setShowIndexReviewData(prev => prev + 1);
    }
    const handleRegisterAccount = () => {
        if (mBrandANDgamingData?.signUp) {
            const affiliateLinke = mBrandANDgamingData?.gamingData?.affiliateLink;
            const additioinalLink = mBrandANDgamingData?.gamingData?.additioinalLink ? mBrandANDgamingData?.gamingData?.additioinalLink : '';
            window.open(affiliateLinke + additioinalLink, '_blank');
        } else {
            const data = {
                brandName: AffiliateLinkObj?.brandName,
                logoUrl: AffiliateLinkObj?.logoUrl,
                affiliateLink: AffiliateLinkObj?.Data?.affiliateLink,
                additionalLink: AffiliateLinkObj?.Data?.additionalLink,
            }
            history(`/review/register-to?brand=${encodeURIComponent(JSON.stringify(data))}`)
        }
    }
    return (
        <Box sx={{ height: '92vh', fontFamily: theme.typography.fontFamily }}>
            <Helmet>
                <title>{`${AffiliateLinkObj?.brandName} ${t('reviewpage.review')} - Multifun`}</title>
            </Helmet>
            {!mBreakPoint0_1280 && (
                <Box sx={{ paddingRight: '24px', paddingLeft: '24px', backgroundColor: 'rgba(250, 250, 250, 0.7)', height: '100%', overflow: 'scroll' }}>
                    <Box sx={{ backgroundColor: '#5B2F8A', position: "relative", zIndex: 1, borderRadius: '16px', height: '28%', padding: "24px", display: 'flex', justifyContent: 'space-between' }}>
                        <img src='/Images/Mask group.png' style={{ position: 'absolute', right: 0, top: 0, height: '100%', width: '100%', borderRadius: mBreakPoint1281_1366 ? '8px' : '16px' }} alt="" />
                        <Box sx={{ width: '69%', zIndex: 10 }}>
                            <Box sx={{ height: '70%' }}>
                                <Box sx={{ display: 'flex', justifyContent: 'start' }}>
                                    <p style={{ fontWeight: 600, color: 'white', fontSize: mBreakPoint1281_1366 ? '28px' : '33px', lineHeight: mBreakPoint1281_1366 ? '20px' : '46px', letterSpacing: '0.5px', margin: '0px', textTransform: 'none' }}>{mBrandANDgamingData?.brandData?.BrandName}</p>
                                </Box>
                                <Box sx={{ marginTop: '2px' }}>
                                    <Box>
                                        <p style={{ margin: 0, fontWeight: 600, color: 'white', fontSize: mBreakPoint1281_1366 ? '15px' : '18px', letterSpacing: '0.5px' }}>{`${mBrandANDgamingData?.gamingData?.promotions}`}</p>
                                        <p style={{ margin: 0, fontWeight: 600, color: 'white', fontSize: mBreakPoint1281_1366 ? '15px' : '18px', letterSpacing: '0.5px' }}>{`💰 Multifun ${t('dashboard.cashback')}: ${mBrandANDgamingData?.gamingData?.visibleCashback}`}</p>
                                    </Box>
                                    <ToolTipForDesktop title={mBrandANDgamingData ? `${mBrandANDgamingData?.brandData?.BrandDescription}` : `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.`} arrow>
                                        <Box sx={{ marginTop: '10px' }}>
                                            <p style={{ cursor: 'pointer', margin: 0, fontWeight: 400, fontSize: mBreakPoint1281_1366 ? '9px' : '11px', letterSpacing: '0.5px', color: '#FFFFFF', paddingRight: '10px' }}>{`${mBrandANDgamingData?.brandData?.BrandDescription.substring(0, mBreakPoint1281_1366 ? 400 : 500)}`}</p>
                                        </Box>
                                    </ToolTipForDesktop>
                                </Box>
                            </Box>
                            {(mBrandANDgamingData?.CommissionUserCount >= 25) && (
                                <Box sx={{ height: '30%', display: 'flex', flexDirection: 'column', justifyContent: 'end' }}>
                                    <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                                        <img src={"/Images/user icon.png"} alt="" style={{ height: '10px' }} />
                                        <p style={{ cursor: 'pointer', margin: 0, fontWeight: 400, fontSize: mBreakPoint1281_1366 ? '9px' : '11px', letterSpacing: '0.5px', color: '#FFFFFF', marginLeft: '15px' }}>{`${mBrandANDgamingData ? mBrandANDgamingData?.CommissionUserCount : 0} ${t('reviewpage.users_have_tried_this_casino')}`}</p>
                                    </Box>
                                    <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                                        <img src={"/Images/review icon.png"} alt="" style={{ height: '11px' }} />
                                        <p style={{ cursor: 'pointer', margin: 0, fontWeight: 400, fontSize: mBreakPoint1281_1366 ? '9px' : '11px', letterSpacing: '0.5px', color: '#FFFFFF', marginLeft: '16px' }}>{`${mBrandANDgamingData ? mBrandANDgamingData?.NumberofLeaveofReview : 0} ${t('reviewpage.users_left_a_review')}`}</p>
                                    </Box>
                                    <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                                        <img src={"/Images/Money icon.png"} alt="" style={{ height: '14px' }} />
                                        <p style={{ cursor: 'pointer', margin: 0, fontWeight: 400, fontSize: mBreakPoint1281_1366 ? '9px' : '11px', letterSpacing: '0.5px', color: '#FFFFFF', marginLeft: '17px' }}>{`€${mBrandANDgamingData ? mBrandANDgamingData?.TotalCashbackPaidOut : 0} ${t('reviewpage.in_cashbacks_paid_out')}`}</p>
                                    </Box>
                                </Box>
                            )}
                        </Box>
                        <Box sx={{ width: '31%', display: 'flex', justifyContent: 'space-between', flexDirection: 'column', zIndex: 10 }}>
                            <Box sx={{ height: '70%' }}>
                                <img src={`${S3Url}/images/${mBrandANDgamingData?.brandData?.LogoUrl}`} alt="" style={{ width: '100%', height: '100%', borderRadius: mBreakPoint1281_1366 ? '8px' : '16px' }} />
                            </Box>
                            <Box sx={{ height: mBreakPoint1281_1366 ? '25%' : '30%', display: 'flex', flexDirection: 'column', justifyContent: 'end' }}>
                                <ResigterButton
                                    startIcon={
                                        mBrandANDgamingData?.signUp ? <PlayCircleOutlineIcon /> : <Icon name='register-account' />
                                    }
                                    sx={{ margin: 0, height: '60%', textTransform: 'none' }}
                                    onClick={() => handleRegisterAccount()}
                                >{mBrandANDgamingData?.signUp ? t('dashboard.play_now') : t('dashboard.register_account')}</ResigterButton>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{ backgroundColor: 'white', padding: '24px', marginTop: '30px', borderRadius: '8px', boxShadow: '6px 6px 50px rgba(0, 0, 0, 0.05)' }}>
                        <Box sx={{ marginBottom: '24px' }}>
                            <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                                <img src={"/Images/Game Variety icon.png"} alt="" style={{ height: '70%' }} />
                                <p style={{ cursor: 'pointer', margin: 0, fontWeight: 600, fontSize: mBreakPoint1281_1366 ? '11px' : '12px', letterSpacing: '0.5px', color: 'Black', marginLeft: '15px' }}>{t('reviewpage.game_variety')}</p>
                            </Box>
                            <p style={{ cursor: 'pointer', margin: 0, marginTop: '10px', fontWeight: 400, fontSize: mBreakPoint1281_1366 ? '9px' : '11px', letterSpacing: '0.5px', color: 'Black' }}>{mBrandANDgamingData?.brandData?.GameProvider?.split(',')?.join(', ')}</p>
                        </Box>
                        <Divider />
                        <Box sx={{ marginTop: '24px', marginBottom: '24px' }}>
                            <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                                <img src={"/Images/Payment options icon.png"} alt="" style={{ height: '70%' }} />
                                <p style={{ cursor: 'pointer', margin: 0, fontWeight: 600, fontSize: mBreakPoint1281_1366 ? '11px' : '12px', letterSpacing: '0.5px', color: 'Black', marginLeft: '15px' }}>{t('reviewpage.payment_options')}</p>
                            </Box>
                            <p style={{ cursor: 'pointer', margin: 0, marginTop: '10px', fontWeight: 400, fontSize: mBreakPoint1281_1366 ? '9px' : '11px', letterSpacing: '0.5px', color: 'Black' }}>{mBrandANDgamingData?.brandData?.PaymentMethod?.split(',')?.join(', ')}</p>
                        </Box>
                        <Divider />
                        <Box sx={{ marginTop: '24px' }}>
                            <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                                <img src={"/Images/Licenses icon.png"} alt="" style={{ height: '70%' }} />
                                <p style={{ cursor: 'pointer', margin: 0, fontWeight: 600, fontSize: mBreakPoint1281_1366 ? '11px' : '12px', letterSpacing: '0.5px', color: 'Black', marginLeft: '15px' }}>{t('reviewpage.licenses')}</p>
                            </Box>

                            {mBrandANDgamingData?.brandData?.Licenses?.split(',')?.map((item, index) => (
                                <p style={{ cursor: 'pointer', margin: 0, marginTop: '10px', fontWeight: 400, fontSize: mBreakPoint1281_1366 ? '9px' : '11px', letterSpacing: '0.5px', color: 'Black' }}><li>{item}</li></p>
                            ))}

                        </Box>
                    </Box>
                    <div style={{ transition: 'opacity 1s', opacity: mShowLeaveReview ? 1 : 0 }}>
                        {mShowLeaveReview && (
                            <Box sx={{ backgroundColor: 'white', padding: '24px', marginTop: '30px', borderRadius: '8px', boxShadow: '6px 6px 50px rgba(0, 0, 0, 0.05)' }} >
                                <Box sx={{ marginBottom: '24px' }}>
                                    <Box sx={{ display: 'flex', justifyContent: 'start' }}>
                                        <p style={{ cursor: 'pointer', margin: 0, fontWeight: 600, fontSize: mBreakPoint1281_1366 ? '11px' : '12px', letterSpacing: '0.5px', color: 'Black' }}>{`${mUserAccount?.firstName} ${mUserAccount?.lastName[0]}`}. - {currentDate}</p>
                                    </Box>
                                    <OutlinedInput
                                        placeholder={t('reviewpage.Wed_love_to_hear_what_you_think_about').replaceAll('{BrandName}', mBrandANDgamingData?.brandData?.BrandName)}
                                        multiline
                                        rows={4}
                                        sx={{ textOverflow: "ellipsis", alignItems: 'start', paddingLeft: '16px', backgroundColor: "#FFFFFF", border: 'border: 1px solid #B4B4B4', marginTop: mBreakPoint1281_1366 ? '11px' : '24px', borderRadius: '8px', fontSize: mBreakPoint1281_1366 ? '11px' : '12px', fontWeight: '400', lineHeight: '21px', width: '100%' }}
                                        value={mContextReview}
                                        onChange={e => setContextReview(e.target.value)}
                                    />
                                    <Box sx={{ color: 'red', fontSize: mBreakPoint1281_1366 ? '11px' : '12px' }}>
                                        {((!mRequiredMark && mContextReview?.length < 100) || (!mRequiredMark && !mContextReview)) && t('reviewpage.at_least_more_than_100_characters_long')}
                                    </Box>
                                </Box>

                                <Box sx={{ marginTop: mBreakPoint1281_1366 ? '11px' : '24px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Rating defaultValue={mValueStar}
                                        value={mValueStar}
                                        onChange={e => setValueStar(e.target.value)}
                                    />
                                    <Box sx={{ width: '10%' }}>
                                        <SelectSaveButton
                                            onClick={(e) => handleSaveReview()}
                                        >{mSaveButtonState === false ? t('reviewpage.save') : <CircularProgress />}</SelectSaveButton>
                                    </Box>
                                </Box>
                            </Box>
                        )}
                    </div>
                    <Box sx={{ backgroundColor: 'white', padding: '24px', marginTop: mBreakPoint1281_1366 ? '11px' : '30px', borderRadius: '8px', boxShadow: '6px 6px 50px rgba(0, 0, 0, 0.05)' }}>
                        <Box sx={{ marginBottom: '24px', display: 'flex', justifyContent: 'space-between' }}>
                            <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                                <img src={"/Images/Star icon.png"} alt="" style={{ height: '70%' }} />
                                <p style={{ cursor: 'pointer', margin: 0, fontWeight: 600, fontSize: mBreakPoint1281_1366 ? '11px' : '12px', letterSpacing: '0.5px', color: 'Black', marginLeft: '15px' }}>{t('reviewpage.review')}</p>
                            </Box>
                            <Link onClick={e => setShowLeaveReview(prev => !prev)} variant="body1" style={{ cursor: 'pointer', color: 'black', fontSize: mBreakPoint1281_1366 ? '11px' : '12px' }}>
                                {mShowLeaveReview ? t('reviewpage.cancel_a_review') : t('reviewpage.leave_a_review')}
                            </Link>
                        </Box>
                        {mBrandANDgamingData?.reviewData.length === 0 && (
                            <Box sx={{ marginTop: '24px' }}>
                                <p style={{ cursor: 'pointer', margin: 0, fontWeight: 400, fontSize: mBreakPoint1281_1366 ? '11px' : '12px', letterSpacing: '0.5px', color: 'Black' }}>{t('reviewpage.no_data_to_show')}</p>
                            </Box>
                        )}
                        {mBrandANDgamingData?.reviewData.slice(0, mShowIndexReviewData * mShowperPage).map((item, index) => (
                            <Box sx={{ marginTop: '24px' }}>
                                <Box sx={{ display: 'flex', justifyContent: 'start' }}>
                                    <p style={{ cursor: 'pointer', margin: 0, fontWeight: 600, fontSize: mBreakPoint1281_1366 ? '11px' : '12px', letterSpacing: '0.5px', color: 'Black' }}>{`${item.firstName} ${item.lastName[0]}`}. - </p>
                                    <p style={{ cursor: 'pointer', margin: 0, fontWeight: 600, fontSize: mBreakPoint1281_1366 ? '11px' : '12px', letterSpacing: '0.5px', color: 'Black' }}>{item.reportDate}</p>
                                </Box>
                                <Box sx={{ marginTop: '10px', marginBottom: '24px' }}>
                                    <Rating defaultValue={item.ratingForReview} readOnly />
                                    <p style={{ cursor: 'pointer', margin: 0, fontWeight: 400, fontSize: mBreakPoint1281_1366 ? '11px' : '11px', letterSpacing: '0.5px', color: 'Black' }}>{item.contextReview}</p>
                                </Box>
                                <Divider />
                            </Box>
                        ))}
                        {mShowIndexReviewData * mShowperPage < mBrandANDgamingData?.reviewData.length && (
                            <Box sx={{ marginTop: '24px', display: 'flex', justifyContent: 'center' }}>
                                <Link onClick={e => handleLoadMore()} variant="body1" style={{ cursor: 'pointer', color: 'black', fontWeight:400, fontSize: mBreakPoint1281_1366 ? '9px' : '11px' }}>
                                    {t('reviewpage.load_more')}...
                                </Link>
                            </Box>
                        )}
                    </Box>
                    <Box>
                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, opacity: '0.8 !important', backgroundColor: '#000000' }}
                            open={mLoadingDataFromServer}
                        >
                            <Box>
                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <CircularProgress color={'warning'} size={'80px'} />
                                </Box>
                                <Box>
                                    <p style={{ margin: 0, marginTop: '20px', fontSize: mBreakPoint1281_1366 ? '11px' : '20px', fontWeight: 400, lineHeight: '30px', color: 'white', textAlign: 'center' }}>
                                        {t('custom.loading_data_please_wait')}...
                                    </p>
                                </Box>
                            </Box>
                        </Backdrop>
                    </Box>
                </Box>
            )}
            {mBreakPoint0_1280 && (
                <Grid container sx={{ backgroundColor: '#3a206d' }}>
                    <Grid item xs={12} paddingLeft={'15px'} paddingRight={'15px'}>
                        <Box sx={{ borderRadius: '16px', padding: '15px 15px 0 15px', backgroundColor: 'black', marginTop: '15px' }}>
                            <img src={`${S3Url}/images/${mBrandANDgamingData?.brandData?.LogoUrl}`} style={{ borderRadius: '16px', width: '100%' }} alt="" />
                            <Box display={`flex`} flexDirection={`column`} justifyContent={`center`} alignItems={`start`}>
                                <p style={{ fontWeight: 700, color: 'white', fontSize: '16px', lineHeight: '30px', letterSpacing: '0.5px', margin: 0, textAlign: 'start' }}>{`💰 Multifun ${t('dashboard.cashback')}: ${mBrandANDgamingData?.gamingData?.visibleCashback}`}</p>
                            </Box>
                            {(mBrandANDgamingData?.CommissionUserCount >= 25) && (
                                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'end'}}>
                                    <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                                        <img src={"/Images/user icon.png"} alt="" style={{ height: '13px', marginLeft:'3px' }} />
                                        <p style={{ cursor: 'pointer', margin: 0, fontWeight: 400, fontSize: '11px', letterSpacing: '0.5px', color: '#FFFFFF', marginLeft: '6px' }}>{`${mBrandANDgamingData ? mBrandANDgamingData?.CommissionUserCount : 0} ${t('reviewpage.users_have_tried_this_casino')}`}</p>
                                    </Box>
                                    <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                                        <img src={"/Images/review icon.png"} alt="" style={{ height: '14px', marginLeft:'3px' }} />
                                        <p style={{ cursor: 'pointer', margin: 0, fontWeight: 400, fontSize: '11px', letterSpacing: '0.5px', color: '#FFFFFF', marginLeft: '8px' }}>{`${mBrandANDgamingData ? mBrandANDgamingData?.NumberofLeaveofReview : 0} ${t('reviewpage.users_left_a_review')}`}</p>
                                    </Box>
                                    <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                                        <img src={"/Images/Money icon.png"} alt="" style={{ height: '17px', marginLeft:'3px' }} />
                                        <p style={{ cursor: 'pointer', margin: 0, fontWeight: 400, fontSize: '11px', letterSpacing: '0.5px', color: '#FFFFFF', marginLeft: '9px' }}>{`€${mBrandANDgamingData ? mBrandANDgamingData?.TotalCashbackPaidOut : 0} ${t('reviewpage.in_cashbacks_paid_out')}`}</p>
                                    </Box>
                                </Box>
                            )}
                            <Button sx={{
                                width: '100%',
                                padding: '6px 7px',
                                color: "rgb(0,245,11)",
                                fontWeight: "600",
                                fontSize: '14px',
                                backgroundColor: "rgba(34, 148, 39, 0.2)",
                                borderRadius: "16px",
                                textTransform: 'none',
                                marginBottom: '15px',
                                marginTop: '10px',
                                '&:hover': { backgroundColor: "rgba(34, 148, 39, 0.2)" }
                            }}
                                onClick={() => handleRegisterAccount()}
                            >{mBrandANDgamingData?.signUp ? t('dashboard.play_now') : t('dashboard.register_account')}
                            </Button>
                        </Box>
                        <Box sx={{ borderRadius: '16px', padding: '15px', backgroundColor: 'black', marginTop: '15px' }}>
                            <p style={{ fontWeight: 400, color: 'white', fontSize: '11px', lineHeight: '20px', letterSpacing: '0.5px', margin: 0, textAlign: 'center' }}>{`${mBrandANDgamingData?.brandData?.BrandDescription}`}</p>
                        </Box>
                        <Box>
                            <Box sx={{ backgroundColor: 'white', padding: '24px', marginTop: '15px', borderRadius: '8px', boxShadow: '6px 6px 50px rgba(0, 0, 0, 0.05)' }}>
                                <Box sx={{ marginBottom: '24px', overflow: 'hidden' }}>
                                    <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                                        <img src={"/Images/Game Variety icon.png"} alt="" style={{ height: '70%' }} />
                                        <p style={{ cursor: 'pointer', margin: 0, fontWeight: 600, fontSize: '14px', letterSpacing: '0.5px', color: 'Black', marginLeft: '15px' }}>{t('reviewpage.game_variety')}</p>
                                    </Box>
                                    <p style={{ cursor: 'pointer', margin: 0, marginTop: '10px', fontWeight: 400, fontSize: '14px', letterSpacing: '0.5px', color: 'Black' }}>{`${mBrandANDgamingData ? mBrandANDgamingData?.brandData?.GameProvider?.split(',')?.join(', ') : 'No data to show.'}`}</p>
                                </Box>
                                <Divider />
                                <Box sx={{ marginTop: '24px', marginBottom: '24px', overflow: 'hidden' }}>
                                    <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                                        <img src={"/Images/Payment options icon.png"} alt="" style={{ height: '70%' }} />
                                        <p style={{ cursor: 'pointer', margin: 0, fontWeight: 600, fontSize: '14px', letterSpacing: '0.5px', color: 'Black', marginLeft: '15px' }}>{t('reviewpage.payment_options')}</p>
                                    </Box>
                                    <p style={{ cursor: 'pointer', margin: 0, marginTop: '10px', fontWeight: 400, fontSize: '14px', letterSpacing: '0.5px', color: 'Black' }}>{`${mBrandANDgamingData ? mBrandANDgamingData?.brandData?.PaymentMethod?.split(',')?.join(', ') : 'No data to show.'}`}</p>
                                </Box>
                                <Divider />
                                <Box sx={{ marginTop: '24px', overflow: 'hidden' }}>
                                    <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                                        <img src={"/Images/Licenses icon.png"} alt="" style={{ height: '70%' }} />
                                        <p style={{ cursor: 'pointer', margin: 0, fontWeight: 600, fontSize: '14px', letterSpacing: '0.5px', color: 'Black', marginLeft: '15px' }}>{t('reviewpage.licenses')}</p>
                                    </Box>
                                    {mBrandANDgamingData?.brandData?.Licenses?.split(',')?.map((item, index) => (
                                        <p style={{ cursor: 'pointer', margin: 0, marginTop: '10px', fontWeight: 400, fontSize: '14px', letterSpacing: '0.5px', color: 'Black' }}><li>{item}</li></p>
                                    ))}
                                </Box>
                            </Box>
                            <div style={{ transition: 'opacity 1s', opacity: mShowLeaveReview ? 1 : 0 }}>
                                {mShowLeaveReview && (
                                    <Box sx={{ backgroundColor: 'white', padding: '24px', marginTop: '15px', borderRadius: '8px', boxShadow: '6px 6px 50px rgba(0, 0, 0, 0.05)' }} >
                                        <Box sx={{ marginBottom: '24px' }}>
                                            <Box sx={{ display: 'flex', justifyContent: 'start' }}>
                                                <p style={{ cursor: 'pointer', margin: 0, fontWeight: 600, fontSize: '14px', letterSpacing: '0.5px', color: 'Black' }}>{`${mUserAccount?.firstName} ${mUserAccount?.lastName[0]}`}. - {currentDate}</p>
                                            </Box>
                                            <OutlinedInput
                                                placeholder={t('reviewpage.Wed_love_to_hear_what_you_think_about').replaceAll('{BrandName}', mBrandANDgamingData?.brandData?.BrandName)}
                                                multiline
                                                rows={4}
                                                sx={{ textOverflow: "ellipsis", alignItems: 'start', paddingLeft: '16px', backgroundColor: "#FFFFFF", border: 'border: 1px solid #B4B4B4', marginTop: '24px', borderRadius: '8px', fontSize: '14px', fontWeight: '400', lineHeight: '21px', width: '100%' }}
                                                value={mContextReview}
                                                onChange={e => setContextReview(e.target.value)}
                                            />
                                            <Box sx={{ color: 'red' }}>
                                                {((!mRequiredMark && mContextReview?.length < 100) || (!mRequiredMark && !mContextReview)) && t('reviewpage.at_least_more_than_100_characters_long')}
                                            </Box>
                                        </Box>

                                        <Box sx={{ marginTop: '24px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Rating defaultValue={mValueStar}
                                                value={mValueStar}
                                                onChange={e => setValueStar(e.target.value)}
                                            />
                                            <Box>
                                                <SelectSaveButton
                                                    onClick={(e) => handleSaveReview()}
                                                >{mSaveButtonState === false ? t('reviewpage.save') : <CircularProgress />}</SelectSaveButton>
                                            </Box>
                                        </Box>
                                    </Box>
                                )}
                            </div>
                            <Box sx={{ backgroundColor: 'rgba(255,255,255,1)', padding: '24px', marginTop: '15px', marginBottom:'15px',borderRadius: '8px', boxShadow: '6px 6px 50px rgba(0, 0, 0, 0.05)' }}>
                                <Box sx={{ marginBottom: '24px', display: 'flex', justifyContent: 'space-between' }}>
                                    <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                                        <img src={"/Images/Star icon.png"} alt="" style={{ height: '70%' }} />
                                        <p style={{ cursor: 'pointer', margin: 0, fontWeight: 600, fontSize: '14px', letterSpacing: '0.5px', color: 'Black', marginLeft: '15px' }}>{t('reviewpage.review')}</p>
                                    </Box>
                                    <Link onClick={e => setShowLeaveReview(prev => !prev)} variant="body1" style={{ cursor: 'pointer', color: 'black' }}>
                                        {mShowLeaveReview ? t('reviewpage.cancel_a_review') : t('reviewpage.leave_a_review')}
                                    </Link>
                                </Box>
                                {mBrandANDgamingData?.reviewData.length === 0 && (
                                    <Box sx={{ marginTop: '24px' }}>
                                        <p style={{ cursor: 'pointer', margin: 0, fontWeight: 400, fontSize: '14px', letterSpacing: '0.5px', color: 'Black' }}>{t('reviewpage.no_data_to_show')}</p>
                                    </Box>
                                )}
                                {mBrandANDgamingData?.reviewData.slice(0, mShowIndexReviewData * mShowperPage).map((item, index) => (
                                    <Box sx={{ marginTop: '24px' }}>
                                        <Box sx={{ display: 'flex', justifyContent: 'start' }}>
                                            <p style={{ cursor: 'pointer', margin: 0, fontWeight: 600, fontSize: '14px', letterSpacing: '0.5px', color: 'Black' }}>{`${item.firstName} ${item.lastName[0]}`}. - </p>
                                            <p style={{ cursor: 'pointer', margin: 0, fontWeight: 600, fontSize: '14px', letterSpacing: '0.5px', color: 'Black' }}>{item.reportDate}</p>
                                        </Box>
                                        <Box sx={{ marginTop: '10px', marginBottom: '24px' }}>
                                            <Rating defaultValue={item.ratingForReview} readOnly />
                                            <p style={{ cursor: 'pointer', margin: 0, fontWeight: 400, fontSize: '14px', letterSpacing: '0.5px', color: 'Black' }}>{item.contextReview}</p>
                                        </Box>
                                        <Divider />
                                    </Box>
                                ))}
                                {mShowIndexReviewData * mShowperPage < mBrandANDgamingData?.reviewData.length && (
                                    <Box sx={{ marginTop: '24px', display: 'flex', justifyContent: 'center' }}>
                                        <Link onClick={e => handleLoadMore()} variant="body1" style={{ cursor: 'pointer', color: 'black' }}>
                                            {t('reviewpage.load_more')}...
                                        </Link>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, opacity: '0.8 !important', backgroundColor: '#000000' }}
                            open={mLoadingDataFromServer}
                        >
                            <Box>
                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <CircularProgress color={'warning'} size={'80px'} />
                                </Box>
                                <Box>
                                    <p style={{ margin: 0, marginTop: '20px', fontSize: '20px', fontWeight: 400, lineHeight: '30px', color: 'white', textAlign: 'center' }}>
                                        {t('custom.loading_data_please_wait')}...
                                    </p>
                                </Box>
                            </Box>
                        </Backdrop>
                    </Grid>
                </Grid>
            )}
        </Box>
    )
}

export default ReviewDashboard;