import { Box, Button, styled, List, ListItem, Collapse } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import Icon from "../Icon";
import { removeStoredAuthToken } from "../../utils/authToken";
import { AuthContext } from "../../contexts/AuthContext";
import { useMediaQuery } from 'react-responsive';
import { TbUserQuestion } from 'react-icons/tb';
import axios from "axios";
import { useTranslation } from "react-i18next";
import { API_R_SYNCTIME } from "../../share/api";
import { useTheme } from '@mui/material/styles';

const Sidebar = ({ handleCancelOpen }) => {
    const { t } = useTranslation();
    const { setIsLogin } = useContext(AuthContext);
    const theme = useTheme();
    const { mDealType, setDealType } = useContext(AuthContext);
    const { pathname } = useLocation();
    const [mSyncTime, setSyncTime] = useState(0);
    const [isNewRegisterCollapse, setNewRegisterCollapse] = React.useState(false);
    const history = useNavigate()
    const [activeTab, setActiveTab] = useState(pathname.split('/')[1]);

    const mBreakPoint0_1280 = useMediaQuery({ query: '(min-width: 0px) and (max-width: 1280px)' });
    const mBreakPoint1281_1360 = useMediaQuery({ query: '(min-width: 1281px) and (max-width: 1768px)' });

    const handleisNewRegisterCollapse = () => {
        setNewRegisterCollapse(prev => !prev);
    }

    const NavButton = styled(Button)({
        width: '100%',
        backgroundColor: '#ffffff00',
        opacity: '0.6',
        borderRadius: 8,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        height: mBreakPoint1281_1360 ? 30 : 30,
        fontSize: mBreakPoint1281_1360 ? '9px' : '12px',
        color: '#ffffff',
        padding: '18px 22px',
        textTransform: 'none',
        '&.active': {
            backgroundColor: 'rgba(255, 255, 255, 0.2)'
        },
        gap: 10,
        fontWeight: 600,
    });

    const ListButton = styled(Button)({
        color: 'rgba(255, 255, 255, 0.6)',
        opacity: 0.6,
        fontSize: mBreakPoint1281_1360 ? '10px' : '12px',
        textTransform: 'none',
        '&.active': {
            color: 'white',
            opacity: 1,
        },
        gap: 10,
        display:'flex',
        justifyContent:'start',
        width:'100%'
    })

    useEffect(() => {

        setActiveTab(pathname.split('/')[1])
        if (pathname.split('/')[1] === 'accounts') {
            setNewRegisterCollapse(false)
        }

        const getSynctime = async () => {
            await axios.post(API_R_SYNCTIME)
                .then(res => {
                    const body = res.data;
                    if (res.status === 200) {
                        setSyncTime(body);
                        return;
                    }
                })
        }
        getSynctime();
    }, [pathname])

    const handleSignout = () => {
        removeStoredAuthToken();
        setIsLogin(false);
        handleCancelOpen();
        setNewRegisterCollapse(false);
        history('/signin');
    }
    return (
        <Box sx={{ minHeight: '100vh' }}>
            {!mBreakPoint0_1280 && (
                <Box sx={{ backgroundColor: '#3A206D', height: '100vh', paddingLeft: '10px', paddingRight: '10px', overflow: 'auto', position: 'relative' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'start' }}>
                        <img src={process.env.PUBLIC_URL + '/Images/multifunlogo.svg'} alt="logo" style={{ width: mBreakPoint1281_1360 ? '80%' : '215px', marginTop: '10px', marginBottom: '10px' }} />
                    </Box>
                    <Box sx={{ paddingTop: mBreakPoint1281_1360 ? '5px' : '26px', paddingBottom: mBreakPoint1281_1360 ? '5px' : '21px', borderTop: 'solid 1px rgba(230, 231, 233, 0.2)' }}>
                        <NavButton
                            startIcon={
                                <Icon name='layers' />
                            }
                            style={{ paddingLeft: '10px', marginBottom: mBreakPoint1281_1360 ? '5px' : '15px' }}
                            className={(!isNewRegisterCollapse && activeTab.toLowerCase()) === `dashboard` ? `active` : ``}
                            onClick={() => { history('/dashboard'); setNewRegisterCollapse(false); }}
                        >
                            {t('sidebar.dashboard')}
                        </NavButton>

                        <NavButton
                            startIcon={
                                <Icon name='user' />
                            }
                            style={{ paddingLeft: '10px', marginBottom: mBreakPoint1281_1360 ? '5px' : '15px' }}
                            className={(!isNewRegisterCollapse && activeTab.toLowerCase()) === `accounts` ? `active` : ``}
                            onClick={() => { history('/accounts'); setNewRegisterCollapse(false); }}
                        >
                            {t('sidebar.your_accounts')}
                        </NavButton>

                        <NavButton
                            startIcon={
                                <Icon name='user-plus' />
                            }
                            style={{ paddingLeft: '10px', marginBottom: mBreakPoint1281_1360 ? '5px' : '15px' }}
                            className={isNewRegisterCollapse ? `active` : ``}
                            onClick={() => handleisNewRegisterCollapse()}
                        >
                            {t('sidebar.get_cashback')}
                        </NavButton>

                        <Collapse in={isNewRegisterCollapse}>
                            <List sx={{ marginLeft: mBreakPoint1281_1360 ? '10px' : '10px', paddingTop: mBreakPoint1281_1360 ? '1px' : '5px', paddingBottom: mBreakPoint1281_1360 ? '1px' : '5px' }}>
                                <ListItem style={{ paddingTop: mBreakPoint1281_1360 ? '1px' : '5px', paddingBottom: mBreakPoint1281_1360 ? '1px' : '5px' }}>
                                    <ListButton
                                        startIcon={
                                            mDealType === 1 ?
                                                <Icon name='list-casinos-button_white' /> : <Icon name='list-casinos-button_grey' />
                                        }
                                        className={mDealType === 1 ? `active` : ``}
                                        onClick={() => { history(`/getcashback?deal=casino`); setDealType(1) }}
                                    >
                                        {t('sidebar.casinos')}
                                    </ListButton>
                                </ListItem>
                                <ListItem style={{ paddingTop: mBreakPoint1281_1360 ? '1px' : '8px', paddingBottom: mBreakPoint1281_1360 ? '1px' : '8px' }}>
                                    <ListButton
                                        startIcon={
                                            mDealType === 2 ?
                                                <Icon name='list-sportsbook-button_white' /> : <Icon name='list-sportsbook-button_grey' />
                                        }
                                        className={mDealType === 2 ? `active` : ``}

                                        onClick={() => { history(`/getcashback?deal=sportsbook`); setDealType(2) }}
                                    >
                                        {t('sidebar.sportsbook')}
                                    </ListButton>
                                </ListItem>
                                <ListItem style={{ paddingTop: mBreakPoint1281_1360 ? '1px' : '8px', paddingBottom: mBreakPoint1281_1360 ? '1px' : '8px' }}>
                                    <ListButton

                                        startIcon={
                                            mDealType === 3 ?
                                                <Icon name='list-bingo-button-white' /> : <Icon name='list-bingo-button-grey' />
                                        }
                                        className={mDealType === 3 ? `active` : ``}
                                        onClick={() => { history(`/getcashback?deal=bingo`); setDealType(3) }}
                                    >
                                        {t('sidebar.bingo')}
                                    </ListButton>
                                </ListItem>
                                <ListItem style={{ paddingTop: mBreakPoint1281_1360 ? '1px' : '8px', paddingBottom: mBreakPoint1281_1360 ? '1px' : '8px' }}>
                                    <ListButton
                                        startIcon={
                                            mDealType === 4 ?
                                                <Icon name='list-poker-button-white' /> : <Icon name='list-poker-button-grey' />
                                        }
                                        className={mDealType === 4 ? `active` : ``}
                                        onClick={() => { history(`/getcashback?deal=poker`); setDealType(4) }}
                                    >
                                        {t('sidebar.poker')}
                                    </ListButton>
                                </ListItem>
                                <ListItem style={{ paddingTop: mBreakPoint1281_1360 ? '1px' : '8px', paddingBottom: mBreakPoint1281_1360 ? '1px' : '8px' }}>
                                    <ListButton
                                        startIcon={
                                            mDealType === 5 ?
                                                <Icon name='list-fantasy-button-white' /> : <Icon name='list-fantasy-button-grey' />
                                        }
                                        className={mDealType === 5 ? `active` : ``}
                                        onClick={() => { history(`/getcashback?deal=fantasy`); setDealType(5) }}
                                    >
                                        {t('sidebar.fantasy')}
                                    </ListButton>
                                </ListItem>
                            </List>
                        </Collapse>
                    </Box>
                    <Box sx={{ paddingTop: mBreakPoint1281_1360 ? '5px' : '23px', borderTop: 'solid 1px rgba(230, 231, 233, 0.2)' }}>
                        <NavButton
                            startIcon={
                                <Icon name='settings' />
                            }
                            style={{ paddingLeft: '10px', marginBottom: mBreakPoint1281_1360 ? '5px' : '15px' }}
                            className={(!isNewRegisterCollapse && activeTab.toLowerCase()) === `payments` ? `active` : ``}
                            onClick={() => { history('/payments'); setNewRegisterCollapse(false); }}
                        >
                            {t('sidebar.payments')}
                        </NavButton>

                        <NavButton
                            startIcon={
                                <Icon name='grid' />
                            }
                            style={{ paddingLeft: '10px', marginBottom: mBreakPoint1281_1360 ? '5px' : '15px' }}
                            className={(!isNewRegisterCollapse && activeTab.toLowerCase()) === `account-setting` ? `active` : ``}
                            onClick={() => { history('/account-setting'); setNewRegisterCollapse(false); }}
                        >
                            {t('sidebar.settings')}
                        </NavButton>

                        <NavButton
                            startIcon={
                                <TbUserQuestion style={{ fontSize: mBreakPoint1281_1360 ? '18px' : '24px' }} />
                            }
                            style={{ paddingLeft: '10px', marginBottom: mBreakPoint1281_1360 ? '5px' : '15px' }}
                            className={(!isNewRegisterCollapse && activeTab.toLowerCase()) === `help` ? `active` : ``}
                            onClick={() => { history('/help'); setNewRegisterCollapse(false); }}
                        >
                            {t('sidebar.help')}
                        </NavButton>

                        <NavButton
                            startIcon={
                                <Icon name='log-out' />
                            }
                            style={{ paddingLeft: '10px' }}
                            className={(!isNewRegisterCollapse && activeTab.toLowerCase()) === `signin` ? `active` : ``}
                            onClick={() => handleSignout()}
                        >
                            {t('sidebar.logout')}
                        </NavButton>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', position: 'absolute', bottom: '30px', left: '40px', fontFamily: theme.typography.fontFamily }}>
                        <p style={{ margin: 0, fontWeight: 300, fontSize: mBreakPoint1281_1360 ? '7px' : '9px', lineHeight: '20px', marginTop: '10px', color: 'rgba(255,255,255,0.8)' }}>
                            {mSyncTime === 0 ? t('sidebar.last_sync1') : mSyncTime === 1 ? t('sidebar.last_sync2') : t('sidebar.last_sync3').replace('{time}', mSyncTime)}
                        </p>
                    </Box>
                </Box>
            )}
            {mBreakPoint0_1280 && (
                <Box sx={{ backgroundColor: '#3A206D', minHeight: '100%', position: 'relative', paddingLeft: '30px', paddingRight: '30px' }}>
                    <Button
                        startIcon={
                            <Icon name='arrow-left_white' />
                        }
                        sx={{ padding: 0, marginTop: '24px' }}
                        onClick={() => handleCancelOpen()}
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <img src={process.env.PUBLIC_URL + '/Images/multifunlogo.svg'} alt="logo" style={{ width: '70%', marginTop: '30px' }} />
                    </Box>
                    <Box sx={{ borderTop: 'solid 1px rgba(230, 231, 233, 0.2)', marginTop: '20px' }}>
                        <Box sx={{ marginTop: '20px' }}></Box>
                        <NavButton
                            startIcon={
                                <Icon name='layers' />
                            }
                            className={(!isNewRegisterCollapse && activeTab.toLowerCase()) === `dashboard` ? `active` : ``}
                            onClick={() => { handleCancelOpen(); history('/dashboard'); setNewRegisterCollapse(false); }}
                            sx={{ marginTop: '10px' }}
                        >
                            {t('sidebar.dashboard')}
                        </NavButton>
                        <NavButton
                            startIcon={
                                <Icon name='user' />
                            }
                            className={(!isNewRegisterCollapse && activeTab.toLowerCase()) === `accounts` ? `active` : ``}
                            onClick={() => { handleCancelOpen(); history('/accounts'); setNewRegisterCollapse(false); }}
                            sx={{ marginTop: '5px' }}
                        >
                            {t('sidebar.your_accounts')}
                        </NavButton>

                        <NavButton
                            startIcon={
                                <Icon name='user-plus' />
                            }
                            endIcon={
                                <Icon name='chevron-right' />
                            }
                            className={isNewRegisterCollapse ? `active` : ``}
                            onClick={() => handleisNewRegisterCollapse()}
                            sx={{ marginTop: '5px' }}
                        >
                            {t('sidebar.get_cashback')}
                        </NavButton>

                        <Collapse in={isNewRegisterCollapse}>
                            <List sx={{ marginLeft: '40px' }}>
                                <ListItem>
                                    <ListButton
                                        startIcon={
                                            mDealType === 1 ?
                                                <Icon name='list-casinos-button_white' /> : <Icon name='list-casinos-button_grey' />
                                        }
                                        className={(!isNewRegisterCollapse && mDealType === 1) ? `active` : ``}
                                        onClick={() => { handleCancelOpen(); history('/getcashback?deal=casino'); setDealType(1); setNewRegisterCollapse(false) }}
                                    >
                                        {t('sidebar.casinos')}
                                    </ListButton>
                                </ListItem>
                                <ListItem>
                                    <ListButton
                                        startIcon={
                                            mDealType === 2 ?
                                                <Icon name='list-sportsbook-button_white' /> : <Icon name='list-sportsbook-button_grey' />
                                        }
                                        className={(!isNewRegisterCollapse && mDealType === 2) ? `active` : ``}
                                        onClick={() => { handleCancelOpen(); history('/getcashback?deal=sportsbook'); setDealType(2); setNewRegisterCollapse(false) }}
                                    >
                                        {t('sidebar.sportsbook')}
                                    </ListButton>
                                </ListItem>
                                <ListItem>
                                    <ListButton

                                        startIcon={
                                            mDealType === 3 ?
                                                <Icon name='list-bingo-button-white' /> : <Icon name='list-bingo-button-grey' />
                                        }
                                        className={(!isNewRegisterCollapse && mDealType === 3) ? `active` : ``}
                                        onClick={() => { handleCancelOpen(); history('/getcashback?deal=bingo'); setDealType(3); setNewRegisterCollapse(false) }}
                                    >
                                        {t('sidebar.bingo')}
                                    </ListButton>
                                </ListItem>
                                <ListItem>
                                    <ListButton
                                        startIcon={
                                            mDealType === 4 ?
                                                <Icon name='list-poker-button-white' /> : <Icon name='list-poker-button-grey' />
                                        }
                                        className={(!isNewRegisterCollapse && mDealType === 4) ? `active` : ``}
                                        onClick={() => { handleCancelOpen(); history('/getcashback?deal=poker'); setDealType(4); setNewRegisterCollapse(false) }}

                                    >
                                        {t('sidebar.poker')}
                                    </ListButton>
                                </ListItem>
                                <ListItem>
                                    <ListButton
                                        startIcon={
                                            mDealType === 5 ?
                                                <Icon name='list-fantasy-button-white' /> : <Icon name='list-fantasy-button-grey' />
                                        }
                                        className={(!isNewRegisterCollapse && mDealType === 5) ? `active` : ``}
                                        onClick={() => { handleCancelOpen(); history('/getcashback?deal=fantasy'); setDealType(5); setNewRegisterCollapse(false) }}
                                    >
                                        {t('sidebar.fantasy')}
                                    </ListButton>
                                </ListItem>
                            </List>
                        </Collapse>
                    </Box>
                    <Box sx={{ borderTop: 'solid 1px rgba(230, 231, 233, 0.2)', minHeight: '250px', marginTop: '20px' }}>
                        <NavButton
                            startIcon={
                                <Icon name='settings' />
                            }
                            className={(!isNewRegisterCollapse && activeTab.toLowerCase()) === `payments` ? `active` : ``}
                            onClick={() => { handleCancelOpen(); history('/payments'); setNewRegisterCollapse(false); }}
                            sx={{ marginTop: '20px' }}
                        >
                            {t('sidebar.payments')}
                        </NavButton>

                        <NavButton
                            startIcon={
                                <Icon name='grid' />
                            }
                            className={(!isNewRegisterCollapse && activeTab.toLowerCase()) === `account-setting` ? `active` : ``}
                            onClick={() => { handleCancelOpen(); history('/account-setting'); setNewRegisterCollapse(false); }}
                            sx={{ marginTop: '10px' }}
                        >
                            {t('sidebar.settings')}
                        </NavButton>

                        <NavButton
                            startIcon={
                                <TbUserQuestion style={{ fontSize: '24px' }} />
                            }
                            className={(!isNewRegisterCollapse && activeTab.toLowerCase()) === `help` ? `active` : ``}
                            onClick={() => { handleCancelOpen(); history('/help'); setNewRegisterCollapse(false); }}
                            sx={{ marginTop: '10px' }}
                        >
                            {t('sidebar.help')}
                        </NavButton>

                        <NavButton
                            startIcon={
                                <Icon name='log-out' />
                            }
                            className={(!isNewRegisterCollapse && activeTab.toLowerCase()) === `signin` ? `active` : ``}
                            onClick={() => handleSignout()}
                            sx={{ marginTop: '10px' }}
                        >
                            {t('sidebar.logout')}
                        </NavButton>
                    </Box>
                    <Box sx={{ display: 'flex', fontFamily: theme.typography.fontFamily }}>
                        <p style={{ margin: 0, fontWeight: 400, fontSize: '10px', lineHeight: '20px', marginTop: '50px', marginBottom: '30px', marginLeft: '30px', color: 'rgba(255,255,255,0.8)' }}>
                            {mSyncTime === 0 ? t('sidebar.last_sync1') : mSyncTime === 1 ? t('sidebar.last_sync2') : t('sidebar.last_sync3').replace('{time}', mSyncTime)}
                        </p>
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default Sidebar;