import { Link, Box, Button, Collapse, Drawer, Select, MenuItem } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Icon from "../../components/Icon";
import { useMediaQuery } from 'react-responsive'
import axios from "axios";
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';
import { countries } from "country-flags-svg";
import { FindLangCodeByCtyName, localizationCountry } from "../../utils/localization";
import { getLocaleInfo, getRememberPath, setClickID, setReferralCode, storeLocaleInfo } from "../../utils/authToken";
import { API_R_BRAND_COUNT } from "../../share/api";
import { getGEOinfo_ipstack } from "../../utils/getGEO";
import { AuthContext } from "../../contexts/AuthContext";
import { useTheme } from '@mui/material/styles';
import getCountryNameByCountryCode from "../../utils/getCountryNameByCountryCode";


const PublicWebsite = () => {

    const [showPage, setShowPage] = useState(false);
    const theme = useTheme();

    const { countryCode } = useParams();

    const mBreakPoint0_1280 = useMediaQuery({ query: '(min-width: 0px) and (max-width: 1280px)' });
    const mBreakPoint1281_1366 = useMediaQuery({ query: '(min-width: 1281px) and (max-width: 1768px)' });
    const mBreakPoint1769_1920 = useMediaQuery({ query: '(min-width: 1769px) and (max-width: 1920px)' });

    const history = useNavigate()
    const [mSelectedIndexFAQsBox, setSelectedIndexFAQsBox] = useState(-1);

    const { setLanguage } = useContext(AuthContext);
    setLanguage(getLocaleInfo() ? getLocaleInfo() : 'en');

    const launageIndex = getLocaleInfo() ? localizationCountry.findIndex(item => item.languageCode === getLocaleInfo()) : 0
    const [mLanguageCountryIndex, setLanguageCountryIndex] = useState(launageIndex);
    const [mBrandCount, setBrandCount] = useState(0);
    const [isScrolled, setIsScrolled] = useState(false);
    const { t, i18n } = useTranslation();
    const [openDrawer, setOpenDrawer] = React.useState(false);
    const contactUsEmail = 'support@multifun.io';

    const handleStatusFAQs = (index) => {
        setSelectedIndexFAQsBox((prevIndex) => prevIndex === index ? -1 : index);
    }
    const FAQs_List = [
        {
            Question: 'types_of_websites',
            Answer: 'supports_all_gambling_websites',
        },
        {
            Question: 'play_on_different_websites',
            Answer: 'with_different_gambling_website',
        },
        {
            Question: 'how_is_cashback_calculated',
            Answer: `cashback_is_calculated`,
            Answer1: `in_both_case`,
        },
        {
            Question: 'when_can_withdraw_earnings',
            Answer: 'your_monthly_cashback',
        },
        {
            Question: 'payment_methods_supported',
            Answer: 'you_can_withdraw_your_cashback',
        },
        {
            Question: 'earn_cashback_from_existing_account',
            Answer: 'for_multifun_to_sync',
        },
    ]

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleContactClick = () => {
        const windowFeatures = 'width=800,height=600,scrollbars=yes,resizable=yes';
        window.open(`mailto:${contactUsEmail}`, '_blank', windowFeatures);
    };

    useEffect(() => {
        const getLocale = async () => {
            let countryName = '';
            if (countryCode) {
                countryName = getCountryNameByCountryCode(countryCode);
            } else {
                const result = await getGEOinfo_ipstack();
                countryName = result?.country_name ? result?.country_name : ' ';
            }
            const langCode = await FindLangCodeByCtyName(countryName);
            storeLocaleInfo(langCode);
            setLanguage(langCode);
            i18n.changeLanguage(langCode);
            const launageIndex = getLocaleInfo() ? localizationCountry.findIndex(item => item.languageCode === getLocaleInfo()) : 0
            setLanguageCountryIndex(launageIndex);
        }
        getLocale();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const lanuageCode = localizationCountry[mLanguageCountryIndex] ? localizationCountry[mLanguageCountryIndex].languageCode : 'en';
        storeLocaleInfo(lanuageCode)
        setLanguage(lanuageCode);
        i18n.changeLanguage(lanuageCode);
    }, [i18n, mLanguageCountryIndex, setLanguage]);

    useEffect(() => {
        const url = getRememberPath();
        if (url) {
            window.open(url, '_self');
            const delay = 1000;

            const timerId = setTimeout(() => {
                setShowPage(true);
            }, delay);

            return () => clearTimeout(timerId);
        } else {
            setShowPage(true);
        }
    }, []);

    useEffect(() => {

        const queryParams = new URLSearchParams(window.location.search);
        const clickId = queryParams.get('click_id');
        if (clickId) {
            setClickID(clickId);
        }

        const referral = queryParams.get('ref');
        if (referral) {
            setReferralCode(referral)
        }
        const getBrandCount = async () => {
            await axios.post(API_R_BRAND_COUNT)
                .then(res => {
                    const body = res.data;
                    if (res.status === 200) {
                        setBrandCount(body);
                        return;
                    }
                }).catch(e => {
                    return;
                })
        }
        getBrandCount();
        const handleScroll = () => {
            const scrollTop = window.scrollY;
            const threshold = 100;
            setIsScrolled(scrollTop > threshold);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const getLogoWidth = () => {
        if (mBreakPoint1281_1366) return '30%';
        return '35%';
    };
    const getFontSizeGetUpto = () => {
        return mBreakPoint1281_1366 ? '35px' : '48px'
    }

    const getFontTextLabel = () => {
        return mBreakPoint1281_1366 ? '12px' : '16px';
    }
    const getLineHeightTextLabel = () => {
        return mBreakPoint1281_1366 ? '16px' : '20px';
    }

    const handleWatchDemo = () => {
        const youtubeVideoURL = 'https://www.youtube.com/watch?v=MLY4V2O0-nM';
        window.open(youtubeVideoURL, '_blank');
    };

    return (
        <Box>
            <Helmet>
                <title>{t('homepage.multifun_up_to_10_Gambling_Cashback')}</title>
                <meta name="description" content={t('homepage.meta_description')} />
            </Helmet>
            {!mBreakPoint0_1280 && showPage && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100vh',
                        overflowY: 'scroll',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            position: 'relative',
                        }}
                    >
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                            position: 'fixed',
                            zIndex: '100',
                            height: mBreakPoint1281_1366 ? '60px' : '80px',
                            transition: 'background-color 0.3s ease',
                            backdropFilter: !isScrolled ? 'blur(10px)' : 'blur(100px)'
                        }}>
                            <Box>
                                <img src={process.env.PUBLIC_URL + '/Images/Logo.svg'} alt="logo" style={{ width: getLogoWidth(), userSelect: 'none' }} />
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row' }}>
                                <Link
                                    onClick={() => { scrollToSection('Howitworks') }}
                                    style={{
                                        ...styles.link,
                                        ...{
                                            fontSize: mBreakPoint1281_1366 ? '14px' : '16px',
                                            marginLeft: mBreakPoint1281_1366 ? '30px' : '50px',
                                            fontFamily: theme.typography.fontFamily
                                        }
                                    }}
                                >
                                    {t('homepage.howitworks')}
                                </Link>
                                <Link
                                    onClick={() => { scrollToSection('OneAccount') }}
                                    style={{
                                        ...styles.link,
                                        ...{
                                            fontSize: mBreakPoint1281_1366 ? '14px' : '16px',
                                            marginLeft: mBreakPoint1281_1366 ? '30px' : '50px',
                                            fontFamily: theme.typography.fontFamily
                                        }
                                    }}
                                >
                                    {t('homepage.oneaccount')}
                                </Link>
                                <Link //
                                    onClick={() => { scrollToSection('FAQs') }}
                                    style={{
                                        ...styles.link,
                                        ...{
                                            fontSize: mBreakPoint1281_1366 ? '14px' : '16px',
                                            marginLeft: mBreakPoint1281_1366 ? '30px' : '50px',
                                            fontFamily: theme.typography.fontFamily
                                        }
                                    }}
                                >
                                    {t('homepage.faqs')}
                                </Link>
                                <Link
                                    onClick={() => { history('/signin') }}
                                    style={{
                                        ...styles.link,
                                        ...{
                                            fontSize: mBreakPoint1281_1366 ? '14px' : '16px',
                                            marginLeft: mBreakPoint1281_1366 ? '30px' : '50px',
                                            fontFamily: theme.typography.fontFamily
                                        }
                                    }}
                                >
                                    {t('homepage.login')}
                                </Link>
                                <Button
                                    type="button"
                                    variant="outlined"
                                    sx={{
                                        borderRadius: '5px',
                                        marginLeft: '20px',
                                        color: 'white',
                                        textTransform: 'none',
                                        fontSize: mBreakPoint1281_1366 ? '14px' : '16px',
                                        lineHeight: mBreakPoint1281_1366 ? '20px' : '24px',
                                        fontWeight: '400',
                                        textDecoration: 'none',
                                        backgroundColor: 'rgba(58, 32, 109, 1)',
                                        '&:hover': { backgroundColor: 'rgba(128, 80, 215, 0.8)' },
                                        border: 'none',
                                    }}
                                    style={{ border: 'none' }}
                                    onClick={() => history('/signup')}
                                >{t('homepage.signup')}</Button>
                                <Select
                                    value={mLanguageCountryIndex}
                                    onChange={(e) => setLanguageCountryIndex(e.target.value)}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    sx={{
                                        marginLeft: '20px',
                                        height: mBreakPoint1281_1366 ? '30px' : '35px',
                                        boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 1, color: 'rgba(0,0,0,0.2)', borderRadius: '5px' },
                                        '.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': { padding: '1px 14px' }
                                    }}

                                >
                                    {localizationCountry?.map((item, index) => (
                                        <MenuItem value={index} display={'flex'}>
                                            <Box sx={{ display: 'flex', justifyContent: 'start' }}>
                                                <Box sx={{ marginRight: '5px', display: 'flex' }}>
                                                    <img
                                                        width={mBreakPoint1281_1366 ? '25vw' : '30vw'}
                                                        src={countries?.find(ITM => ITM.name === item.countryName)?.flag} sx={{ marginRight: '10px' }}
                                                        alt=""
                                                    />
                                                </Box>
                                                <Box sx={{
                                                    marginLeft: '5px',
                                                    display: 'flex',
                                                    fontSize: mBreakPoint1281_1366 ? '14px' : '16px'
                                                }}
                                                >
                                                    {item.languageName}
                                                </Box>
                                            </Box>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', alignItems: 'center', backgroundColor: 'white', position: 'relative' }}>
                            <img src={process.env.PUBLIC_URL + '/Images/Mask Group.svg'} style={{ width: '50%', position: 'absolute', top: '0', right: '0', zIndex: '1' }} alt="" />
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', flexDirection: 'column', zIndex: '2', marginTop: mBreakPoint1281_1366 ? '150px' : '200px' }}>
                                <p style={{
                                    margin: 0,
                                    fontWeight: 500,
                                    fontSize: getFontSizeGetUpto(),
                                    lineHeight: mBreakPoint1281_1366 ? '46px' : '60px',
                                    color: 'rgba(58, 32, 109, 1)',
                                    fontFamily: theme.typography.fontFamily
                                }}
                                >
                                    <div dangerouslySetInnerHTML={{ __html: t('homepage.get_up_to') }} />
                                </p>

                                <Box sx={{ marginTop: mBreakPoint1281_1366 ? '30px' : '70px' }}>
                                    <Button
                                        type="button"
                                        variant="outlined"
                                        sx={{
                                            borderRadius: '5px',
                                            color: 'white',
                                            fontSize: mBreakPoint1281_1366 ? '11px' : '14px',
                                            lineHeight: mBreakPoint1281_1366 ? '28px' : '35px',
                                            fontWeight: '400',
                                            padding: '3px 25px',
                                            backgroundColor: 'rgba(58, 32, 109, 1)',
                                            textTransform: 'none',
                                            '&:hover': { backgroundColor: 'rgba(58, 32, 109, 0.8)' }
                                        }}
                                        style={{ border: 'none' }}
                                        onClick={() => history('/signup')}
                                    >{t('homepage.signupnow')}</Button>
                                    <Button
                                        type="button"
                                        variant="outlined"
                                        sx={{
                                            borderRadius: '5px',
                                            color: 'black',
                                            fontSize: mBreakPoint1281_1366 ? '11px' : '14px',
                                            lineHeight: mBreakPoint1281_1366 ? '28px' : '35px',
                                            fontWeight: '600',
                                            padding: '3px 25px',
                                            backgroundColor: 'rgba(240, 190, 14, 1)',
                                            textTransform: 'none',
                                            '&:hover': { backgroundColor: 'rgba(240, 190, 14, 0.8)' },
                                            marginLeft: '24px'
                                        }}
                                        style={{ border: 'none' }}
                                        onClick={() => handleWatchDemo()}
                                    >{t('homepage.watch_demo')}</Button>
                                </Box>
                                <img src={process.env.PUBLIC_URL + '/Images/Macbook.svg'} alt="" style={{ width: '975px', zIndex: 4, marginTop: '100px', marginBottom: '100px', userSelect: 'none' }} />
                                <img src={process.env.PUBLIC_URL + '/Images/Vector.svg'} style={{ position: 'absolute', left: '0', zIndex: '-1', marginTop: '100px', userSelect: 'none' }} alt="" />
                            </Box>
                        </Box>
                        <Box sx={{ backgroundColor: 'white', width: '100%', marginBottom: '30px', display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                            <p style={{
                                margin: 0,
                                fontWeight: 500,
                                lineHeight: '36px',
                                fontSize: '25px',
                                marginLeft: mBreakPoint1769_1920 ? '20px'
                                    : mBreakPoint1281_1366 ? '55px'
                                        : '0px',
                                color: 'black',
                                fontFamily: theme.typography.fontFamily
                            }}>
                                {t('homepage.choosefromover').replace('{brandCount}', mBrandCount)}
                            </p>
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '100px' }}>
                                <img src={process.env.PUBLIC_URL + '/Images/playojo.svg'} style={{ width: '50px', marginLeft: '130px', marginRight: '50px', userSelect: 'none' }} alt="PlayOJO" />
                                <img src={process.env.PUBLIC_URL + '/Images/casimba.svg'} style={{ width: '60px', marginLeft: '10px', marginRight: '50px', userSelect: 'none' }} alt="Casimba Casino" />
                                <img src={process.env.PUBLIC_URL + '/Images/partycasino.svg'} style={{ width: '100px', marginRight: '50px', userSelect: 'none' }} alt="Party Casino" />
                                <img src={process.env.PUBLIC_URL + '/Images/platincasino.svg'} style={{ width: '130px', marginRight: '50px', userSelect: 'none' }} alt="Platin Casino" />
                                <img src={process.env.PUBLIC_URL + '/Images/betnacional.svg'} style={{ width: '140px', marginRight: '50px', userSelect: 'none' }} alt="Bet Nacional" />
                                <img src={process.env.PUBLIC_URL + '/Images/kingbilly.svg'} style={{ marginBottom: '15px', width: '70px', marginRight: '10px', userSelect: 'none' }} alt="King Billy Casino" />
                                <img src={process.env.PUBLIC_URL + '/Images/iBet.svg'} style={{ width: '120px', marginRight: '50px', userSelect: 'none' }} alt="iBet" />
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: mBreakPoint1281_1366 ? '5px' : '20px' }}>
                                <img src={process.env.PUBLIC_URL + '/Images/mansion.svg'} style={{ width: '100px', marginLeft: '250px', marginRight: '50px', userSelect: 'none' }} alt="Mansion Casino" />
                                <img src={process.env.PUBLIC_URL + '/Images/Rizk.svg'} style={{ width: '60px', marginRight: '50px', userSelect: 'none' }} alt="Rizk" />
                                <img src={process.env.PUBLIC_URL + '/Images/optibet.svg'} style={{ width: '90px', marginRight: '50px', userSelect: 'none' }} alt="Optibet" />
                                <img src={process.env.PUBLIC_URL + '/Images/vulkanvegas.svg'} style={{ width: '80px', marginRight: '50px', userSelect: 'none' }} alt="Vulkan Casino" />
                                <img src={process.env.PUBLIC_URL + '/Images/wazamba.svg'} style={{ width: '110px', marginRight: '50px', userSelect: 'none' }} alt="Wazamba Casino" />
                                <img src={process.env.PUBLIC_URL + '/Images/n1casino.svg'} style={{ width: '100px', marginRight: '50px', userSelect: 'none' }} alt="N1 Casino" />
                                <img src={process.env.PUBLIC_URL + '/Images/bets10.svg'} style={{ width: '60px', marginRight: '210px', userSelect: 'none' }} alt="" />
                            </Box>

                        </Box>
                    </Box>
                    <Box id='Howitworks' sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', width: '100%', zIndex: '1', marginBottom: '100px', position: 'relative', marginTop: mBreakPoint1281_1366 ? '0' : '-100px' }}>
                        <p style={{
                            ...styles.textTitle,
                            ...{
                                fontSize: mBreakPoint1281_1366 ? '35px' : '36px', lineHeight: '44px',
                                marginTop: mBreakPoint1281_1366 ? '80px' : '210px',
                                fontFamily: theme.typography.fontFamily
                            }
                        }}>
                            {t('homepage.howitworks')}
                        </p>
                        <p style={{
                            margin: 0,
                            fontWeight: 400,
                            fontSize: mBreakPoint1281_1366 ? '16px' : '20px',
                            lineHeight: '30px',
                            marginTop: '10px',
                            marginBottom: '40px',
                            color: 'black',
                            textAlign: 'center',
                            width: '60%',
                            fontFamily: theme.typography.fontFamily
                        }}>
                            <div dangerouslySetInnerHTML={{ __html: t('homepage.veryfew_youcanfinally') }} />
                        </p>
                        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '24px' }}>
                            <Box sx={{
                                boxShadow: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                textAlign: 'center',
                                padding: '24px',
                                borderRadius: '16px',
                                zIndex: '4',
                                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                width: '20%',
                            }}>
                                <p style={{ margin: 0, fontWeight: 600, color: 'rgba(255, 197, 0, 1)', fontSize: mBreakPoint1281_1366 ? '16px' : '30px', lineHeight: '30px', fontFamily: theme.typography.fontFamily }}>1</p>
                                <img src={process.env.PUBLIC_URL + '/Images/screenshot1.svg'}
                                    style={{ marginTop: '24px', width: mBreakPoint1281_1366 ? '80%' : '100%', userSelect: 'none' }}
                                    alt=""
                                />
                                <p style={{
                                    ...styles.textLabel,
                                    ...{
                                        fontSize: getFontTextLabel(),
                                        lineHeight: getLineHeightTextLabel(),
                                        marginTop: mBreakPoint1281_1366 ? '24px' : '55px',
                                        fontFamily: theme.typography.fontFamily
                                    }
                                }}
                                >
                                    {t('homepage.choosewebsite')}
                                </p>
                            </Box>
                            <Box sx={{
                                boxShadow: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                textAlign: 'center',
                                padding: '24px',
                                borderRadius: '16px',
                                zIndex: '4',
                                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                width: '20%',
                                marginLeft: '24px',
                                fontSize: '16px',
                            }}>
                                <p style={{ margin: 0, fontWeight: 600, color: 'rgba(255, 197, 0, 1)', fontSize: mBreakPoint1281_1366 ? '16px' : '30px', lineHeight: '30px', fontFamily: theme.typography.fontFamily }}>2</p>
                                <img src={process.env.PUBLIC_URL + '/Images/screenshot2.svg'}
                                    style={{ marginTop: '24px', width: mBreakPoint1281_1366 ? '80%' : '100%', userSelect: 'none' }}
                                    alt=""
                                />
                                <p style={{
                                    ...styles.textLabel,
                                    ...{
                                        fontSize: getFontTextLabel(),
                                        lineHeight: getLineHeightTextLabel(),
                                        marginTop: mBreakPoint1281_1366 ? '24px' : '55px',
                                        fontFamily: theme.typography.fontFamily
                                    }
                                }}
                                >
                                    {t('homepage.createyouraccount')}
                                </p>
                            </Box>
                            <Box sx={{
                                boxShadow: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                textAlign: 'center',
                                padding: '24px',
                                borderRadius: '16px',
                                zIndex: '4',
                                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                width: '20%',
                                marginLeft: '24px',
                                fontSize: '16px',
                            }}>
                                <p style={{ margin: 0, fontWeight: 600, color: 'rgba(255, 197, 0, 1)', fontSize: mBreakPoint1281_1366 ? '16px' : '30px', lineHeight: '30px', fontFamily: theme.typography.fontFamily }}>3</p>
                                <img src={process.env.PUBLIC_URL + '/Images/screenshot3.svg'}
                                    style={{ marginTop: '24px', width: mBreakPoint1281_1366 ? '80%' : '100%', userSelect: 'none' }}
                                    alt=""
                                />
                                <p style={{
                                    ...styles.textLabel,
                                    ...{
                                        fontSize: getFontTextLabel(),
                                        lineHeight: getLineHeightTextLabel(),
                                        marginTop: mBreakPoint1281_1366 ? '24px' : '55px',
                                        fontFamily: theme.typography.fontFamily
                                    }
                                }}
                                >
                                    {t('homepage.startseeingyourcashback')}
                                </p>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: mBreakPoint1281_1366 ? '30px' : '70px' }}>
                            <Button
                                type="button"
                                variant="outlined"
                                sx={{
                                    borderRadius: '5px',
                                    color: 'white',
                                    fontSize: mBreakPoint1281_1366 ? '11px' : '14px',
                                    lineHeight: mBreakPoint1281_1366 ? '28px' : '35px',
                                    fontWeight: '400',
                                    backgroundColor: 'rgba(58, 32, 109, 1)',
                                    textTransform: 'none',
                                    height: mBreakPoint1281_1366 ? '80%' : '100%',
                                    '&:hover': {
                                        backgroundColor: 'rgba(58, 32, 109, 0.8)'
                                    }
                                }}
                                style={{ border: 'none' }}
                                onClick={() => history('/signup')}
                            >{t('homepage.signupnow')}</Button>
                        </Box>
                        <img src={process.env.PUBLIC_URL + '/Images/Vector1.svg'} style={{ position: 'absolute', right: '0', zIndex: '1', marginTop: '1500px', userSelect: 'none' }} alt="" />
                    </Box>
                    <Box id='OneAccount'
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: 'white',
                            position: 'relative',
                            marginTop: mBreakPoint1281_1366 ? '-100px' : '50px'
                        }}
                    >
                        <Box sx={{ display: 'flex', justifyContent: 'start', flexDirection: 'column', marginTop: '100px', zIndex: '3', width: '40%' }}>
                            <p style={{
                                ...styles.textTitle,
                                ...{
                                    fontSize: mBreakPoint1281_1366 ? '40px' : '36px',
                                    lineHeight: '44px',
                                    fontFamily: theme.typography.fontFamily
                                }
                            }}>
                                {t('homepage.oneaccount1')}
                            </p>
                            <p style={{
                                ...styles.textLabel,
                                ...{
                                    fontSize: mBreakPoint1281_1366 ? '14px' : '20px',
                                    lineHeight: mBreakPoint1281_1366 ? '16px' : '28px',
                                    marginTop: '10px',
                                    fontFamily: theme.typography.fontFamily
                                }
                            }}>
                                <div dangerouslySetInnerHTML={{ __html: t('homepage.watchallaccount') }} />
                            </p>
                        </Box>
                        <Box>
                            <img src={process.env.PUBLIC_URL + '/Images/Mock Ups.png'}
                                style={{
                                    zIndex: '4',
                                    marginTop: '100px',
                                    width: '530px', userSelect: 'none'
                                }}
                                alt=""
                            />
                            <img src={process.env.PUBLIC_URL + '/Images/Mark Group1.svg'}
                                style={{
                                    position: 'absolute',
                                    left: '0',
                                    zIndex: '1',
                                    top: '130px', userSelect: 'none'
                                }}
                                alt=""
                            />
                        </Box>
                    </Box>
                    <Box id='FAQs' sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        backgroundColor: 'white',
                        width: '100%',
                        marginTop: mBreakPoint1281_1366 ? '-100px' : '100px'
                    }}>
                        <p style={{
                            ...styles.textTitle,
                            ...{ fontSize: mBreakPoint1281_1366 ? '20px' : '36px', lineHeight: '44px', marginTop: '100px', fontFamily: theme.typography.fontFamily }
                        }}>
                            {t('homepage.frequentlyaskedquestions')}
                        </p>
                        <p style={{
                            ...styles.textLabel,
                            ...{ fontSize: mBreakPoint1281_1366 ? '16px' : '20px', lineHeight: '28px', fontFamily: theme.typography.fontFamily }
                        }}>
                            <span style={{ color: 'black' }}>{t('homepage.everythingyouneed')}</span>
                        </p>
                        <Box sx={{ width: '43%' }}>
                            <Box sx={{ marginTop: '60px' }}>
                            </Box>
                            {FAQs_List.map((item, index) => (
                                <Box>
                                    <Box key={index} sx={{ backgroundColor: 'white', height: mBreakPoint1281_1366 ? '40px' : '60px', borderStyle: 'solid', borderWidth: '0 0 1px 0', borderColor: 'rgba(0,0,0,0.1)', display: 'flex', alignItems: 'center', justifyContent: 'space-between', cursor: 'pointer' }} onClick={() => handleStatusFAQs(index)}>
                                        <p style={{ margin: 0, fontWeight: 500, fontSize: mBreakPoint1281_1366 ? '12px' : '18px', lineHeight: mBreakPoint1281_1366 ? '16px' : '28px', marginLeft: '24px', fontFamily: theme.typography.fontFamily, color: 'rgba(58, 46, 82, 1)' }}>
                                            {t(`faqlist_homepage_question.${item.Question}`)}
                                        </p>
                                        <Box sx={{ marginRight: '24px' }}>
                                            {mSelectedIndexFAQsBox === index ? <img src={process.env.PUBLIC_URL + '/Images/iconArrowUp.png'} alt="" style={{ opacity: '0.5', userSelect: 'none' }} /> : <img src={process.env.PUBLIC_URL + '/Images/iconArrowDown.png'} alt="" style={{ opacity: '0.5', userSelect: 'none' }} />}
                                        </Box>
                                    </Box>

                                    <Collapse in={mSelectedIndexFAQsBox === index} timeout={300}>
                                        <Box sx={{ borderColor: 'rgba(0,0,0,0.1)', borderWidth: '0 0 1px 0', borderStyle: 'solid', borderRadius: index === FAQs_List.length - 1 ? '0px 0px 8px 8px' : '0', maxHeight: mSelectedIndexFAQsBox === index ? '1000px' : '0', opacity: mSelectedIndexFAQsBox === index ? '1' : '0', overflow: 'hidden', transition: 'max-height 1.5s ease-out, opacity 1.5s ease-out' }}>
                                            <p style={{ fontWeight: 400, fontSize: mBreakPoint1281_1366 ? '11px' : '17px', lineHeight: mBreakPoint1281_1366 ? '16px' : '28px', margin: '24px', fontFamily: theme.typography.fontFamily, color: 'rgba(58, 46, 82, 0.8)' }}>
                                                {t(`faqlist_homepage_answer.${item.Answer}`)}
                                                {item.Answer1 && (
                                                    <>
                                                        <br /><br />
                                                        {t(`faqlist_homepage_answer.${item.Answer1}`)}
                                                    </>
                                                )}
                                            </p>
                                        </Box>
                                    </Collapse>

                                </Box>
                            ))}
                        </Box>
                    </Box>
                    <Box sx={{ marginTop: mBreakPoint1281_1366 ? '140px' : '240px', display: 'flex', justifyContent: 'center' }}>
                        <Box sx={{ backgroundColor: 'white', boxShadow: `-14.3363px 43.1971px 52.8651px 0 rgba(81, 78, 120, 0.18), 1.5458px 2.3187px 0px 0px rgba(255, 255, 255, 0.6)`, borderRadius: '16px', marginLeft: mBreakPoint1281_1366 ? '200px' : '300px', marginRight: mBreakPoint1281_1366 ? '200px' : '300px', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', padding: '80px', position: 'relative', margin: 0 }}>
                                <p style={{
                                    ...styles.textTitle,
                                    ...{
                                        fontSize: mBreakPoint1281_1366 ? '30px' : '48px', color: 'rgba(58, 32, 109, 1)', lineHeight: mBreakPoint1281_1366 ? '30px' : '60px',
                                        fontFamily: theme.typography.fontFamily
                                    }
                                }}>
                                    <div dangerouslySetInnerHTML={{ __html: t(`homepage.ready_to_earn`) }} />
                                </p>
                                <Button
                                    type="button"
                                    variant="outlined"
                                    sx={{ borderRadius: '5px', color: 'white', fontSize: mBreakPoint1281_1366 ? '13px' : '14px', lineHeight: mBreakPoint1281_1366 ? '20px' : '30px', fontWeight: '400', backgroundColor: 'rgba(58, 32, 109, 1)', textTransform: 'none', width: '20%', '&:hover': { backgroundColor: 'rgba(58, 32, 109, 0.8)' }, marginTop: '24px' }}
                                    onClick={() => history('/signup')}
                                    style={{ border: 'none' }}
                                >{t('homepage.signupnow')}</Button>
                                <img src={process.env.PUBLIC_URL + '/Images/screenAsset.svg'} style={{ position: 'absolute', right: '0', top: '-80px', width: mBreakPoint1281_1366 ? '50%' : '45%', userSelect: 'none' }} alt="" />
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{ marginTop: '180px', backgroundColor: 'rgba(24, 14, 46, 1)', width: '100%' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Box sx={{ marginTop: mBreakPoint1281_1366 ? '25px' : '60px' }}>
                                <img src={process.env.PUBLIC_URL + '/Images/multifunlogo.svg'} style={{ marginLeft: mBreakPoint1281_1366 ? '200px' : '250px', width: mBreakPoint1281_1366 ? '20%' : '30%', userSelect: 'none' }} alt="" />
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: mBreakPoint1281_1366 ? '25px' : '60px' }}>
                                <p style={{ margin: 0, fontWeight: mBreakPoint1281_1366 ? '300' : '400', fontSize: mBreakPoint1281_1366 ? '11px' : '14px', lineHeight: mBreakPoint1281_1366 ? '10px' : '24px', marginTop: '17px' }}>
                                    <Link style={{ color: 'white', textDecoration: 'none', cursor: 'pointer', fontFamily: theme.typography.fontFamily }} onClick={() => history('/signin')}>{t(`homepage.log_in`)}</Link>
                                </p>
                                <p style={{ margin: 0, fontWeight: mBreakPoint1281_1366 ? '300' : '400', fontSize: mBreakPoint1281_1366 ? '11px' : '14px', lineHeight: mBreakPoint1281_1366 ? '10px' : '24px', marginTop: '17px' }}>
                                    <Link style={{ color: 'white', textDecoration: 'none', cursor: 'pointer', fontFamily: theme.typography.fontFamily }} onClick={() => history('/signup')}>{t(`homepage.signup`)}</Link>
                                </p>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: mBreakPoint1281_1366 ? '25px' : '60px' }}>
                                <p style={{ margin: 0, fontWeight: mBreakPoint1281_1366 ? '300' : '400', fontSize: mBreakPoint1281_1366 ? '11px' : '16px', lineHeight: mBreakPoint1281_1366 ? '10px' : '24px', marginTop: '17px' }}>
                                    <Link style={{ color: 'white', textDecoration: 'none', cursor: 'pointer', fontFamily: theme.typography.fontFamily }} onClick={() => history('/privacypolicy')}>{t(`homepage.privacy_policy`)}</Link>
                                </p>
                                <p style={{ margin: 0, fontWeight: mBreakPoint1281_1366 ? '300' : '400', fontSize: mBreakPoint1281_1366 ? '11px' : '16px', lineHeight: mBreakPoint1281_1366 ? '10px' : '24px', marginTop: '17px' }}>
                                    <Link style={{ color: 'white', textDecoration: 'none', cursor: 'pointer', fontFamily: theme.typography.fontFamily }} onClick={() => history('/termscondition')}>{t(`homepage.term_conditions`)}</Link>
                                </p>
                                <p style={{ margin: 0, fontWeight: mBreakPoint1281_1366 ? '300' : '400', fontSize: mBreakPoint1281_1366 ? '11px' : '16px', lineHeight: mBreakPoint1281_1366 ? '10px' : '24px', marginTop: '17px' }}>
                                    <Link style={{ color: 'white', textDecoration: 'none', cursor: 'pointer', fontFamily: theme.typography.fontFamily }} onClick={() => history('/responsiblegambling')}>{t(`homepage.responsible_gambling`)}</Link>
                                </p>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: mBreakPoint1281_1366 ? '25px' : '60px', marginRight: '250px' }}>
                                <p style={{ margin: 0, fontWeight: mBreakPoint1281_1366 ? '300' : '400', fontSize: mBreakPoint1281_1366 ? '11px' : '16px', lineHeight: mBreakPoint1281_1366 ? '10px' : '24px', marginTop: '17px' }}>
                                    <Link style={{ color: 'white', textDecoration: 'none', cursor: 'pointer', fontFamily: theme.typography.fontFamily }} onClick={() => { scrollToSection('FAQs') }}>{t(`homepage.faqs`)}</Link>
                                </p>
                                <p style={{ margin: 0, fontWeight: mBreakPoint1281_1366 ? '300' : '400', fontSize: mBreakPoint1281_1366 ? '11px' : '16px', lineHeight: mBreakPoint1281_1366 ? '10px' : '24px', marginTop: '17px' }}>
                                    <Link style={{ color: 'white', textDecoration: 'none', cursor: 'pointer', fontFamily: theme.typography.fontFamily }} onClick={() => handleContactClick()}>{t(`homepage.contact_us`)}</Link>
                                </p>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: mBreakPoint1281_1366 ? '25px' : '60px', borderTop: 'solid 1px rgba(209, 208, 208, 1)', marginLeft: mBreakPoint1281_1366 ? '200px' : '250px', marginRight: mBreakPoint1281_1366 ? '200px' : '250px', alignItems: 'center' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <p style={{ margin: 0, fontWeight: mBreakPoint1281_1366 ? '300' : '400', fontSize: mBreakPoint1281_1366 ? '11px' : '16px', lineHeight: mBreakPoint1281_1366 ? '10px' : '24px', marginTop: '17px', color: "white", fontFamily: theme.typography.fontFamily }}>
                                    © 2023.  Multifun. {t('homepage.all_rights_reserved')}.
                                </p>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: mBreakPoint1281_1366 ? '20px' : mBreakPoint1281_1366 ? '20px' : '30px', marginBottom: mBreakPoint1281_1366 ? '20px' : '30px' }}>
                                <img src={process.env.PUBLIC_URL + '/Images/gameCare.png'} style={{ width: mBreakPoint1281_1366 ? '15%' : '100%', userSelect: 'none' }} alt="" />
                                <img src={process.env.PUBLIC_URL + '/Images/gamblingtherapy.png'} style={{ marginLeft: '50px', width: mBreakPoint1281_1366 ? '15%' : '100%', userSelect: 'none' }} alt="" />
                                <img src={process.env.PUBLIC_URL + '/Images/18Plus.png'} style={{ marginLeft: '50px', width: mBreakPoint1281_1366 ? '10%' : '100%', userSelect: 'none' }} alt="" />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            )}
            {mBreakPoint0_1280 && showPage && (
                <Box sx={{ display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
                    <Drawer
                        sx={{
                            width: '80%',
                            flexShrink: 0,
                            '& .MuiDrawer-paper': {
                                width: '80%',
                                boxSizing: 'border-box',
                                borderRight: 'none',
                            },
                            zIndex: 999
                        }}
                        variant="persistent"
                        anchor="left"
                        open={openDrawer}
                    >
                        <Box sx={{ backgroundColor: 'white', height: '100%', paddingLeft: '50px', paddingRight: '50px', boxShadow: '2' }}>
                            <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                                <Link
                                    onClick={() => { setOpenDrawer(false); scrollToSection('Howitworks') }}
                                    variant="body2"
                                    style={{ cursor: 'pointer', color: 'rgba(58, 32, 109, 1)', fontSize: '16px', lineHeight: '16px', fontWeight: '500', textDecoration: 'none', marginTop: '100px' }}
                                >
                                    {t('homepage.howitworks')}
                                </Link>
                                <Link
                                    onClick={() => { setOpenDrawer(false); scrollToSection('OneAccount') }}
                                    variant="body2"
                                    style={{ cursor: 'pointer', color: 'rgba(58, 32, 109, 1)', fontSize: '16px', lineHeight: '16px', fontWeight: '500', textDecoration: 'none', marginTop: '20px' }}
                                >
                                    {t('homepage.oneaccount')}
                                </Link>
                                <Link
                                    onClick={() => { setOpenDrawer(false); scrollToSection('FAQs') }}
                                    variant="body2"
                                    style={{ cursor: 'pointer', color: 'rgba(58, 32, 109, 1)', fontSize: '16px', lineHeight: '16px', fontWeight: '500', textDecoration: 'none', marginTop: '20px' }}
                                >
                                    {t('homepage.faqs')}
                                </Link>
                                <Link
                                    onClick={() => { setOpenDrawer(false); history('/signin') }}
                                    variant="body2"
                                    style={{ cursor: 'pointer', color: 'rgba(58, 32, 109, 1)', fontSize: '16px', lineHeight: '16px', fontWeight: '600', textDecoration: 'none', marginTop: '30px' }}
                                >
                                    {t('homepage.login')}
                                </Link>
                                <Button
                                    type="button"
                                    variant="outlined"
                                    sx={{ borderRadius: '5px', color: 'white', textTransform: 'none', fontSize: '14px', lineHeight: '24px', fontWeight: '400', textDecoration: 'none', backgroundColor: 'rgba(58, 32, 109, 1)', '&:hover': { backgroundColor: 'rgba(128, 80, 215, 0.8)' }, marginTop: '15px' }}
                                    onClick={() => history('/signup')}
                                    style={{ border: 'none' }}
                                >{t('homepage.signup')}</Button>
                                <Select
                                    value={mLanguageCountryIndex}
                                    onChange={(e) => setLanguageCountryIndex(e.target.value)}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    sx={{ height: '35px', marginTop: '20px', boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 1, color: 'rgba(0,0,0,0.2)', borderRadius: '8px' }, '.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': { padding: '1px 14px' } }}

                                >
                                    {localizationCountry?.map((item, index) => (
                                        <MenuItem value={index} display={'flex'}>
                                            <Box sx={{ display: 'flex', justifyContent: 'start' }}>
                                                <Box sx={{ marginRight: '5px', display: 'flex' }}>
                                                    <img width='30vw' src={countries?.find(ITM => ITM.name === item.countryName)?.flag} sx={{ marginRight: '10px' }} alt="" />
                                                </Box>
                                                <Box sx={{ marginLeft: '5px', display: 'flex' }}>
                                                    {item.languageName}
                                                </Box>
                                            </Box>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Box>
                        </Box>
                    </Drawer>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', zIndex: 1000, position: 'fixed', backgroundColor: 'transparent', transition: 'background-color 0.3s ease', backdropFilter: !isScrolled ? 'blur(10px)' : 'blur(100px)' }}>
                        <Box>
                            <img src={process.env.PUBLIC_URL + '/Images/Logo.svg'} alt="logo" style={{ marginTop: '15px', marginBottom: '10px', marginLeft: '10px', width: '45%' }} />
                        </Box>
                        <Button startIcon={<Icon name={!openDrawer ? 'menu' : 'close'} color='rgba(58, 32, 109, 1)' />}
                            onClick={() => setOpenDrawer(prev => !prev)}
                            sx={{ padding: 0, width: '24px' }}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', backgroundColor: 'white', overflow: 'inherit', width: '100%', position: 'relative' }}>
                            <img src={process.env.PUBLIC_URL + '/Images/Mask Group.svg'} style={{ width: '80%', position: 'absolute', top: '0', right: '0', zIndex: '1' }} alt="" />
                            <Box sx={{ marginLeft: '10px', marginRight: '10px', marginTop: '100px', textAlign: 'center', zIndex: '2' }}>
                                <p style={{ margin: 0, fontWeight: 500, fontSize: '25px', lineHeight: '35px', color: 'rgba(58, 32, 109, 1)', fontFamily: theme.typography.fontFamily }}>
                                    <div dangerouslySetInnerHTML={{ __html: t('homepage.get_up_to') }} />
                                </p>
                                <Button
                                    type="button"
                                    variant="outlined"
                                    sx={{ borderRadius: '5px', color: 'white', fontSize: '14px', lineHeight: '24px', fontWeight: '400', backgroundColor: 'rgba(58, 32, 109, 1)', textTransform: 'none', width: '70%', height: '100%', '&:hover': { backgroundColor: 'rgba(58, 32, 109, 0.8)' }, marginTop: '24px' }}
                                    onClick={() => history('/signup')}
                                    style={{ border: 'none' }}
                                >{t('homepage.signupnow')}</Button>
                                <Button
                                    type="button"
                                    variant="outlined"
                                    sx={{ borderRadius: '5px', color: 'black', fontSize: '14px', lineHeight: '24px', fontWeight: '600', backgroundColor: 'rgba(240, 190, 14, 1)', textTransform: 'none', width: '70%', height: '100%', '&:hover': { backgroundColor: 'rgba(240, 190, 14, 0.8)' }, marginTop: '10px' }}
                                    onClick={() => handleWatchDemo()}
                                    style={{ border: 'none' }}
                                >{t('homepage.watch_demo')}</Button>
                            </Box>
                            <img src={process.env.PUBLIC_URL + '/Images/Macbook.svg'} style={{ marginTop: '24px', zIndex: '2' }} alt="" />
                            <img src={process.env.PUBLIC_URL + '/Images/Vector.svg'} style={{ position: 'absolute', width: '60%', left: '0', top: '340px', zIndex: '1' }} alt="" />
                        </Box>
                        <Box sx={{ backgroundColor: 'white', width: '100%', marginBottom: '30px', display: 'flex', flexDirection: 'column', textAlign: 'center', marginTop: '50px' }}>
                            <p style={{ margin: 0, fontWeight: 500, fontSize: '16px', lineHeight: '24px', marginLeft: '10px', marginRight: '10px', color: 'black', fontFamily: theme.typography.fontFamily }}>
                                {t('homepage.choosefromover').replace('{brandCount}', mBrandCount)}
                            </p>
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '30px' }}>
                                <img src={process.env.PUBLIC_URL + '/Images/casimba.svg'} style={{ width: '50px', paddingRight: '30px' }} alt="Casimba Casino" />
                                <img src={process.env.PUBLIC_URL + '/Images/playojo.svg'} style={{ width: '40px', paddingRight: '30px' }} alt="PlayOJO" />
                                <img src={process.env.PUBLIC_URL + '/Images/platincasino.svg'} style={{ width: '110px', paddingRight: '30px' }} alt="Platin Casino" />
                                <img src={process.env.PUBLIC_URL + '/Images/kingbilly.svg'} style={{ width: '45px', paddingRight: '10px', marginBottom: '10px' }} alt="King Billy Casino" />
                            </Box>

                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
                                <img src={process.env.PUBLIC_URL + '/Images/Rizk.svg'} style={{ width: '60px', paddingRight: '20px' }} alt="Rizk" />
                                <img src={process.env.PUBLIC_URL + '/Images/betnacional.svg'} style={{ width: '80px', paddingRight: '20px' }} alt="Bet Nacional" />
                                <img src={process.env.PUBLIC_URL + '/Images/mansion.svg'} style={{ width: '80px', paddingRight: '30px' }} alt="Mansion Casino" />
                                <img src={process.env.PUBLIC_URL + '/Images/vulkanvegas.svg'} style={{ width: '50px', paddingRight: '10px' }} alt="Vulkan Casino" />
                            </Box>

                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
                                <img src={process.env.PUBLIC_URL + '/Images/wazamba.svg'} style={{ width: '90px', paddingRight: '20px' }} alt="Wazamba Casino" />
                                <img src={process.env.PUBLIC_URL + '/Images/n1casino.svg'} style={{ width: '80px', paddingRight: '20px' }} alt="N1 Casino" />
                                <img src={process.env.PUBLIC_URL + '/Images/bets10.svg'} style={{ width: '50px', paddingRight: '20px' }} alt="Bets10" />
                                <img src={process.env.PUBLIC_URL + '/Images/optibet.svg'} style={{ width: '70px', paddingRight: '10px' }} alt="Optibet" />
                            </Box>

                        </Box>
                    </Box>
                    <Box id='Howitworks' sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'white', flexDirection: 'column', width: '100%', zIndex: '1', marginBottom: '100px', position: 'relative', textAlign: 'center' }}>
                        <p style={{ margin: 0, fontWeight: 500, fontSize: '26px', lineHeight: '38px', marginTop: '30px', fontFamily: theme.typography.fontFamily, color: 'rgba(58, 32, 109, 1)' }}>
                            {t('homepage.howitworks')}
                        </p>
                        <p style={{ margin: 0, fontWeight: 400, fontSize: '15px', padding: '10px', textAlign: 'justify', lineHeight: '20px', marginTop: '5px', marginLeft: '10px', marginRight: '10px', fontFamily: theme.typography.fontFamily }}>
                            <div dangerouslySetInnerHTML={{ __html: t('homepage.veryfew_youcanfinally') }} />
                        </p>
                        <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', marginTop: '24px' }}>
                            <Box sx={{ boxShadow: 2, display: 'flex', flexDirection: 'column', textAlign: 'center', alignItems: 'center', borderRadius: '16px', zIndex: '4', marginLeft: '24px', marginRight: '24px' }}>
                                <p style={{ margin: 0, fontWeight: 600, color: 'rgba(255, 197, 0, 1)', fontSize: '26px', lineHeight: '30px', marginTop: '24px', fontFamily: theme.typography.fontFamily }}>1</p>
                                <img src={process.env.PUBLIC_URL + '/Images/screenshot1.svg'} style={{ marginTop: '24px', width: '70%' }} alt="" />
                                <p style={{ fontWeight: 400, color: 'black', fontSize: '14px', lineHeight: '20px', margin: '24px', fontFamily: theme.typography.fontFamily }}>{t('homepage.choosewebsite')}</p>
                            </Box>
                            <Box sx={{ boxShadow: 2, display: 'flex', flexDirection: 'column', textAlign: 'center', alignItems: 'center', borderRadius: '16px', zIndex: '4', marginTop: '24px', marginLeft: '24px', marginRight: '24px' }}>
                                <p style={{ margin: 0, fontWeight: 600, color: 'rgba(255, 197, 0, 1)', fontSize: '26px', lineHeight: '30px', marginTop: '24px', fontFamily: theme.typography.fontFamily }}>2</p>
                                <img src={process.env.PUBLIC_URL + '/Images/screenshot2.svg'} style={{ marginTop: '24px', width: '70%' }} alt="" />
                                <p style={{ fontWeight: 400, color: 'black', fontSize: '14px', lineHeight: '20px', margin: '24px', fontFamily: theme.typography.fontFamily }}>{t('homepage.createyouraccount')}</p>
                            </Box>
                            <Box sx={{ boxShadow: 2, display: 'flex', flexDirection: 'column', textAlign: 'center', alignItems: 'center', borderRadius: '16px', zIndex: '4', marginTop: '24px', marginLeft: '24px', marginRight: '24px' }}>
                                <p style={{ margin: 0, fontWeight: 600, color: 'rgba(255, 197, 0, 1)', fontSize: '26px', lineHeight: '30px', marginTop: '24px', fontFamily: theme.typography.fontFamily }}>3</p>
                                <img src={process.env.PUBLIC_URL + '/Images/screenshot3.svg'} style={{ marginTop: '24px', width: '70%' }} alt="" />
                                <p style={{ fontWeight: 400, color: 'black', fontSize: '14px', lineHeight: '20px', margin: '24px', fontFamily: theme.typography.fontFamily }}>{t('homepage.startseeingyourcashback')}</p>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'center', width: '70%', marginTop: '70px' }}>
                            <Button
                                type="button"
                                variant="outlined"
                                sx={{
                                    borderRadius: '5px',
                                    color: 'white',
                                    fontSize: '14px',
                                    lineHeight: '24px',
                                    fontWeight: '400',
                                    backgroundColor: 'rgba(58, 32, 109, 1)',
                                    textTransform: 'none',
                                    padding: '5px 15px',
                                    '&:hover': { backgroundColor: 'rgba(58, 32, 109, 0.8)' }
                                }}
                                onClick={() => history('/signup')}
                                style={{ border: 'none' }}
                            >{t('homepage.signupnow')}</Button>
                        </Box>
                    </Box>
                    <Box id='OneAccount' sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', backgroundColor: 'white', position: 'relative' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', marginLeft: '24px', marginRight: '24px', textAlign: 'center' }}>
                            <p style={{ ...styles.textTitle, ...{ fontSize: '26px', lineHeight: '38px' }, fontFamily: theme.typography.fontFamily }}>
                                {t('homepage.oneaccount1')}
                            </p>
                            <p style={{ ...styles.textLabel, ...{ fontSize: '14px', lineHeight: '24px', marginTop: '10px', textAlign: 'justify' }, fontFamily: theme.typography.fontFamily }}>
                                <div dangerouslySetInnerHTML={{ __html: t('homepage.watchallaccount') }} />
                            </p>
                        </Box>
                        <img src={process.env.PUBLIC_URL + '/Images/Mock Ups.png'} style={{ zIndex: '1', width: '92%' }} alt="" />
                        <img src={process.env.PUBLIC_URL + '/Images/Vector1.svg'} style={{ position: 'absolute', right: '0', zIndex: '2', top: '250px', width: '80%' }} alt="" />
                    </Box>
                    <Box id='FAQs' sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', backgroundColor: 'white', textAlign: 'center' }}>
                        <p style={{ margin: 0, fontWeight: 500, fontSize: '26px', lineHeight: '26px', marginTop: '70px', fontFamily: theme.typography.fontFamily, color: 'rgba(58, 32, 109, 1)' }}>
                            {t('homepage.frequentlyaskedquestions')}
                        </p>
                        <p style={{ margin: 0, fontWeight: 400, fontSize: '16px', lineHeight: '20px', marginTop: '20px', marginLeft: '20px', marginRight: '20px', fontFamily: theme.typography.fontFamily, color: 'black' }}>
                            {t('homepage.everythingyouneed')}
                        </p>
                        <Box sx={{ zIndex: '3' }}>
                            <Box sx={{ marginTop: '40px' }}>

                            </Box>
                            {FAQs_List.map((item, index) => (
                                <Box sx={{ marginLeft: '24px', marginRight: '24px' }}>
                                    <Box key={index} sx={{ backgroundColor: 'white', height: '60px', borderStyle: 'solid', borderWidth: '0 0 1px 0', borderColor: 'rgba(0,0,0,0.1)', display: 'flex', alignItems: 'center', justifyContent: 'space-between', cursor: 'pointer' }} onClick={() => handleStatusFAQs(index)}>
                                        <p style={{ margin: 0, fontWeight: 400, fontSize: '15px', lineHeight: '16px', marginLeft: '24px', textAlign: 'start', fontFamily: theme.typography.fontFamily, color: 'rgba(58, 46, 82, 1)' }}>
                                            {t(`faqlist_homepage_question.${item.Question}`)}
                                        </p>
                                        <Box sx={{ marginRight: '24px' }}>
                                            {mSelectedIndexFAQsBox === index ? <img src={process.env.PUBLIC_URL + '/Images/iconArrowUp.png'} alt="" style={{ opacity: '0.5' }} /> : <img src={process.env.PUBLIC_URL + '/Images/iconArrowDown.png'} alt="" style={{ opacity: '0.5' }} />}
                                        </Box>
                                    </Box>

                                    <Collapse in={mSelectedIndexFAQsBox === index} timeout={300}>
                                        <Box sx={{ borderColor: 'rgba(0,0,0,0.1)', borderWidth: '0 0 1px 0', borderStyle: 'solid', borderRadius: index === FAQs_List.length - 1 ? '0px 0px 8px 8px' : '0', maxHeight: mSelectedIndexFAQsBox === index ? '1000px' : '0', opacity: mSelectedIndexFAQsBox === index ? '1' : '0', overflow: 'hidden', textAlign: 'start', transition: 'max-height 1.5s ease-out, opacity 1.5s ease-out' }}>
                                            <p style={{ fontWeight: 400, fontSize: '14px', lineHeight: '16px', margin: '24px', fontFamily: theme.typography.fontFamily, color: 'rgba(58, 46, 82, 0.8)' }}>
                                                <span style={{}}>{t(`faqlist_homepage_answer.${item.Answer}`)}</span>
                                                {item.Answer1 && (
                                                    <>
                                                        <br /><br />
                                                        {t(`faqlist_homepage_answer.${item.Answer1}`)}
                                                    </>
                                                )}
                                            </p>
                                        </Box>
                                    </Collapse>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                    <Box sx={{ marginTop: '240px', display: 'flex', justifyContent: 'center', position: 'relative' }}>
                        <img src={process.env.PUBLIC_URL + '/Images/Mark Group1.svg'} style={{ position: 'absolute', left: '0', zIndex: '-1', top: '-260px', width: '310px' }} alt="" />
                        <Box sx={{
                            backgroundColor: 'white',
                            boxShadow: `-14.3363px 43.1971px 52.8651px 0 rgba(81, 78, 120, 0.18), 1.5458px 2.3187px 0px 0px rgba(255, 255, 255, 0.6)`,
                            borderRadius: '16px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            margin: '0 24px',
                            width: '100%'
                        }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', position: 'relative', justifyContent: 'center', paddingLeft: '24px', paddingRight: '24px' }}>
                                <img src={process.env.PUBLIC_URL + '/Images/screenAsset.svg'} style={{ position: 'absolute', right: '0', top: '-30px', width: '310px' }} alt="" />
                                <p style={{ fontWeight: 500, fontSize: '26px', color: 'rgba(58, 32, 109, 1)', marginTop: '200px', lineHeight: '28px', fontFamily: theme.typography.fontFamily }}>
                                    <div dangerouslySetInnerHTML={{ __html: t(`homepage.ready_to_earn`) }} />
                                </p>
                                <Button
                                    type="button"
                                    variant="outlined"
                                    sx={{
                                        borderRadius: '5px',
                                        color: 'white',
                                        fontSize: '14px',
                                        lineHeight: '24px',
                                        fontWeight: '400',
                                        backgroundColor: 'rgba(58, 32, 109, 1)',
                                        textTransform: 'none',
                                        '&:hover': { backgroundColor: 'rgba(58, 32, 109, 0.8)' },
                                        margin: '24px 0'
                                    }}
                                    onClick={() => history('/signup')}
                                    style={{ border: 'none' }}
                                >{t('homepage.signupnow')}</Button>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{ marginTop: '80px', backgroundColor: 'rgba(24, 14, 46, 1)', width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                        <Box sx={{ marginTop: '30px', display: 'flex', justifyContent: 'center' }}>
                            <img src={process.env.PUBLIC_URL + '/Images/multifunlogo.svg'} style={{ width: '50%' }} alt="" />
                        </Box>
                        <Box sx={{ display: 'flex', marginTop: '30px', justifyContent: 'space-between' }}>
                            <p style={{ margin: 0, fontWeight: 400, fontSize: '14px', lineHeight: '24px' }}>
                                <Link style={{ color: 'white', textDecoration: 'none', cursor: 'pointer', fontFamily: theme.typography.fontFamily }} onClick={() => history('/signin')}>{t('homepage.log_in')}</Link>
                            </p>
                            <p style={{ margin: 0, fontWeight: 400, fontSize: '14px', lineHeight: '24px', marginLeft: '20px' }}>
                                <Link style={{ color: 'white', textDecoration: 'none', cursor: 'pointer', fontFamily: theme.typography.fontFamily }} onClick={() => history('/signup')}>{t('homepage.signup')}</Link>
                            </p>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '10px', textAlign: 'center' }}>
                            <p style={{ margin: 0, fontWeight: 400, fontSize: '14px', lineHeight: '24px' }}>
                                <Link style={{ color: 'white', textDecoration: 'none', cursor: 'pointer', fontFamily: theme.typography.fontFamily }} onClick={() => history('/privacypolicy')}>{t('homepage.privacy_policy')}</Link>
                            </p>
                            <p style={{ margin: 0, fontWeight: 400, fontSize: '14px', lineHeight: '24px', marginTop: '10px' }}>
                                <Link style={{ color: 'white', textDecoration: 'none', cursor: 'pointer', fontFamily: theme.typography.fontFamily }} onClick={() => history('/termscondition')}>{t('homepage.term_conditions')}</Link>
                            </p>
                            <p style={{ margin: 0, fontWeight: 400, fontSize: '14px', lineHeight: '24px', marginTop: '10px' }}>
                                <Link style={{ color: 'white', textDecoration: 'none', cursor: 'pointer', fontFamily: theme.typography.fontFamily }} onClick={() => history('/responsiblegambling')}>{t('homepage.responsible_gambling')}</Link>
                            </p>
                        </Box>
                        <Box sx={{ display: 'flex', marginTop: '10px', justifyContent: 'space-between' }}>
                            <p style={{ margin: 0, fontWeight: 400, fontSize: '14px', lineHeight: '24px' }}>
                                <Link style={{ color: 'white', textDecoration: 'none', cursor: 'pointer', fontFamily: theme.typography.fontFamily }} onClick={() => { scrollToSection('FAQs') }}>{t('homepage.faqs')}</Link>
                            </p>
                            <p style={{ margin: 0, fontWeight: 400, fontSize: '14px', lineHeight: '24px', marginLeft: '10px' }}>
                                <Link style={{ color: 'white', textDecoration: 'none', cursor: 'pointer', fontFamily: theme.typography.fontFamily }} onClick={() => handleContactClick()}>{t('homepage.contact_us')}</Link>
                            </p>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', marginTop: '0px', borderTop: 'solid 1px rgba(209, 208, 208, 1)', margin: '10px', alignItems: 'center' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <p style={{ margin: '10px', fontWeight: 400, fontSize: '14px', lineHeight: '24px', textAlign: 'center', fontFamily: theme.typography.fontFamily, color: 'white' }}>
                                    © 2023.  Multifun. {t('homepage.all_rights_reserved')}.
                                </p>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '30px', marginBottom: '30px' }}>
                                <img src={process.env.PUBLIC_URL + '/Images/gameCare.png'} style={{ width: '65px' }} alt="" />
                                <img src={process.env.PUBLIC_URL + '/Images/gamblingtherapy.png'} style={{ width: '55px', marginLeft: '30px' }} alt="" />
                                <img src={process.env.PUBLIC_URL + '/Images/18Plus.png'} style={{ width: '45px', marginLeft: '30px' }} alt="" />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            )}
        </Box>
    );
}

const styles = {
    link: { cursor: 'pointer', color: 'rgba(58, 32, 109, 1)', lineHeight: '24px', textDecoration: 'none', fontWeight: '600' },
    textTitle: { margin: 0, fontWeight: 500, color: 'rgba(58, 32, 109, 1)' },
    textLabel: { margin: 0, fontWeight: 400, color: 'black' }
}

export default PublicWebsite;