import { Box, Button, Grid, styled, MenuItem, CardContent, Select, FormControl, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Icon from "../../components/Icon";
import { useNavigate } from "react-router-dom";
import { S3Url } from "../../share/config";
import axios from "axios";
import { getStoredAuthToken, storeAccessAuthToken } from "../../utils/authToken";
import { AuthContext } from "../../contexts/AuthContext";
import alertSZ from "../../utils/alert";
import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { useMediaQuery } from 'react-responsive';
import Swal from "sweetalert2";
import { Helmet } from "react-helmet";
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import BackDrop from "../../utils/Backdrop";
import { useTranslation } from 'react-i18next'
import { API_R_DATA_DASHBOARD, API_R_RECENT_CASHBACK, API_U_STATUS_REFERFRIEND } from "../../share/api";
import jwt_decode from 'jwt-decode';
import { IoMailOutline } from "react-icons/io5";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MobileTooltip from "../../utils/MobileTooltilp";
import { useTheme } from '@mui/material/styles';

const DashboardPage = () => {

  const theme = useTheme();
  const { isLogin } = useContext(AuthContext);
  const history = useNavigate();

  const authtoken = getStoredAuthToken();
  const email = authtoken ? jwt_decode(authtoken).email : null;
  if (!isLogin || !email) {
    history('/signin');
  }

  const { t } = useTranslation();
  const mBreakPoint0_1280 = useMediaQuery({ query: '(min-width: 0px) and (max-width: 1280px)' });
  const mBreakPoint1281_1366 = useMediaQuery({ query: '(min-width: 1281px) and (max-width: 1768px)' });
  const { mSearchContextValue, setSearchContextValue } = useContext(AuthContext);
  const { setDealType } = useContext(AuthContext);
  setDealType(0);
  const [mCheckGamingButton, setCheckGamingButton] = useState(1);
  const [mLoadingDataFromServer, setLoadingDataFromServer] = React.useState(true);
  const [mGamingData, setGamingData] = React.useState(null);
  const [mBrandANDgamingData, setBrandANDgamingData] = useState(null)
  const [mUserCashbackThisMonth, setUserCashbackThisMonth] = useState(0);
  const [mUserRecentActivity, setUserRecentActivity] = useState(null);

  const ToolTipForDesktop = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: 'grey',
      color: 'white',
      borderRadius: '8px',
      fontSize: mBreakPoint1281_1366 ? '8px' : '10px',
      maxWidth: '500px',
    },
  }));

  const [mFilteGamingData, setFilterGamingData] = useState(mGamingData);
  useEffect(() => {
    setFilterGamingData(mGamingData?.filter(value => (
      value.profileEmail.toString().toLowerCase().includes(mSearchContextValue.trim().toLowerCase())
      || value.brandName.toString().toLowerCase().includes(mSearchContextValue.trim().toLowerCase())
      || value.LastCashback.toString().toLowerCase().includes(mSearchContextValue.trim().toLowerCase())
      || value.TotalCashback.toString().toLowerCase().includes(mSearchContextValue.trim().toLowerCase()))));
  }, [mSearchContextValue, mGamingData])

  useEffect(() => {
    setSearchContextValue('')
  }, [setSearchContextValue])
  useEffect(() => {

    const getData_Recent_Cashback = async () => {
      try {
        const response = await axios.post(API_R_RECENT_CASHBACK, {
          email,
        });
        const Tempbody = response?.data;
        const newAccessToken = Tempbody?.newAccessToken;
        if (newAccessToken !== undefined) {
          storeAccessAuthToken(newAccessToken)
        }
        if (response.status === 200) {
          setUserRecentActivity(Tempbody.recentActivity);
          setUserCashbackThisMonth(Tempbody.cashbackThisMonth)
          return;
        }
      } catch (error) {
        const text = error.response.data;
        alertSZ(t(`bakend.${text}`));
        return;
      }
    }

    if (mBreakPoint0_1280 && isLogin && email) {
      getData_Recent_Cashback()
    }
  }, [email, isLogin, mBreakPoint0_1280, t])

  useEffect(() => {
    const getDashboardData = async () => {
      setLoadingDataFromServer(true);
      await axios.post(API_R_DATA_DASHBOARD, {
        email, mCheckGamingButton
      }).then(res => {
        const body = res.data;
        setLoadingDataFromServer(false);
        if (res.status === 200) {
          setGamingData(body?.table);
          setBrandANDgamingData(body);
          return;
        }
      }).catch(e => {
        history('/signin')
        return
      })
    }
    if (isLogin && email) {
      getDashboardData();
    }
  }, [mCheckGamingButton, email, history, isLogin])

  const [activeButton, setActiveButton] = React.useState(t('sidebar.casinos'));

  const handleGotoCasino = (url, additioinalLink, PayloadID, status, affSys) => {
    const extralink = additioinalLink ? additioinalLink : '';
    if (status) {
      window.open(url + extralink, '_blank');
    }
    else {
      const b = url.includes('?');
      if (affSys === 'MyAffiliates') {
        window.open(url + `${b ? '&' : '?'}payload=${PayloadID}` + extralink, '_blank');
      }
      if (affSys === 'IncomeAccess') {
        window.open(url + `${b ? '&' : '?'}c=${PayloadID}` + extralink, '_blank');
      }
      if (affSys === 'VPartners') {
        window.open(url + `${b ? '&' : '?'}sub_id=${PayloadID}` + extralink, '_blank');
      }
      if (affSys === 'ReferOn') {
        window.open(url + `${b ? '&' : '?'}subid=${PayloadID}` + extralink, '_blank');
      }
      if (affSys === 'Smartico') {
        window.open(url + `${b ? '&' : '?'}afp=${PayloadID}` + extralink, '_blank');
      }
      if (affSys === 'Affilka') {
        window.open(url + `${b ? '&' : '?'}var=${PayloadID}` + extralink, '_blank');
      }
      if (affSys === 'EGO') {
        window.open(url + `${b ? '&' : '?'}dyn_id=${PayloadID}` + extralink, '_blank');
      }
      if (affSys === 'CellXpert') {
        window.open(url + `${b ? '&' : '?'}afp=${PayloadID}` + extralink, '_blank');
      }
    }
  };


  const ShiningSyncProblemIcon = styled(SyncProblemIcon)({
    animation: 'shining 1.5s infinite',
    '@keyframes shining': {
      '0%': {
        color: 'black',
      },
      '50%': {
        color: '#ffc500',
      },
      '100%': {
        color: 'black',
      },
    },
  });

  const CashbackButton = styled(Button)({
    backgroundColor: 'rgba(39, 174, 96, 0.15)',
    borderRadius: '20px',
    color: '#27AE60',
    fontWeight: 700,
    fontSize: '20px',
    fontStyle: 'normal',
    lineHeight: '120%',
    textTransform: 'none',
    padding: '10px 20px',
    '&:hover': {
      backgroundColor: 'rgba(39, 174, 96, 0.2)',
    },
  });
  const ResigterButton = styled(Button)({
    backgroundColor: '#FFFFFF',
    color: '#6861AB',
    borderRadius: mBreakPoint1281_1366 ? '5px' : '8px',
    width: '100%',
    fontWeight: 400,
    alignItems: 'center',
    textTransform: 'none',
    fontSize: mBreakPoint1281_1366 ? '9px' : '12px',
    lineHeight: '100%',
    padding: '13px',
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,0.8)',
    },
  });
  const ReferFriendButton = styled(Button)({
    borderRadius: '100px',
    backgroundColor: 'rgba(108, 61, 158, 0.15)',
    color: '#6C3D9E',
    fontWeight: 600,
    fontSize: mBreakPoint1281_1366 ? '6px' : '16px',
    padding: '10px 20px ',
    '&:hover': {
      backgroundColor: 'rgba(108, 61, 158, 0.2)',
    },
  });

  const SelectButton = styled(Button)({
    color: 'white',
    fontSize: mBreakPoint1281_1366 ? '9px' : '12px',
    fontWeight: 400,
    height: mBreakPoint1281_1366 ? '50%' : '80%',
    letterSpacing: '0.1px',
    padding: '15px 14px',
    textTransform: 'none',
    flex: '1',
    margin: '0 15px 0 0',
    borderRadius: mBreakPoint1281_1366 ? '5px' : '8px',
    backgroundColor: 'rgba(99,57,143,1)',
    '&.active': {
      backgroundColor: '#ffc500'
    },
    '&:hover': {
      backgroundColor: 'rgba(99,57,143,0.8)',
    },
  });

  const [mSelectedAccount, setSelectedAccount] = useState(0);
  const handleSelectAccont = (idx) => {
    if (mSelectedAccount === idx) {
      setSelectedAccount(null); // Deselect if the same item is clicked again
    } else {
      setSelectedAccount(idx);
    }
  }

  const SelectBox = styled(Box)(({ index }) => ({
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: mBreakPoint1281_1366 ? '3px' : '5px',
      height: '100%',
      background: 'linear-gradient(180deg, #6C3D9E 20%, #E91287 100%)',
      borderRadius: '6px 0 0 6px',
      opacity: mSelectedAccount === index ? 1 : 0,
      transition: 'opacity 0.3s ease-in-out',
    },
    '&:hover::before': {
      opacity: 1,
    },
    '&:hover': {
      cursor: 'pointer',
      borderRadius: '6px',
      backgroundColor: 'rgba(200,200,200,0.2)'
    },
    borderRadius: '6px',
  }));

  const StyledTypography = styled(Typography)({
    fontWeight: 400,
    letterSpacing: '-0.3px',
    color: 'rgba(24, 24, 25, 0.7)',
    fontSize: mBreakPoint1281_1366 ? '9px' : '11px',
  });

  const StyledBox = styled(Box)({
    display: 'flex',
    justifyContent: 'start',
    variant: "overline",
    fontWeight: '300',
    fontStyle: 'normal',
    fontSize: mBreakPoint1281_1366 ? '9px' : '11px',
    lineHeight: '140%',
    letterSpacing: '0.5px',
    textTransform: "none",
    fontFamily: theme.typography.fontFamily
  });

  const handleGotoManualRegAccount = () => {
    if (mBrandANDgamingData?.signUp) {
      const affiliateLinke = mBrandANDgamingData?.gamingData?.affiliateLink;
      const additioinalLink = mBrandANDgamingData?.gamingData?.additioinalLink ? mBrandANDgamingData?.gamingData?.additioinalLink : '';
      window.open(affiliateLinke + additioinalLink, '_blank');
    } else {
      const data = {
        brandName: mBrandANDgamingData?.brandData?.BrandName,
        logoUrl: mBrandANDgamingData?.brandData?.LogoUrl,
        affiliateLink: mBrandANDgamingData?.gamingData?.affiliateLink,
        additioinalLink: mBrandANDgamingData?.gamingData?.additioinalLink,
      }
      history(`/dashboard/register-to?brand=${encodeURIComponent(JSON.stringify(data))}`)
    }
  }

  const handleReferAndfriend = async () => {
    Swal.fire({
      title: t('mainlayout.refer_a_friend'),
      text: t('mainlayout.referfriendtext'),
      icon: t('mainlayout.question'),
      showCancelButton: true,
      confirmButtonText: t('mainlayout.referagree'),
      cancelButtonText: t('mainlayout.refernot')
    }).then(async (result) => {
      if (result.isConfirmed) {

        const mYesorNo = true;
        await axios.post(API_U_STATUS_REFERFRIEND, { email, mYesorNo });

      } else if (result.dismiss === Swal.DismissReason.cancel) {

        const mYesorNo = false;
        await axios.post(API_U_STATUS_REFERFRIEND, { email, mYesorNo });

      }
    });
  }

  const [currentSelectedItem, setCurrentSelectedItem] = useState();
  const handleForMobileTooltip = (idx) => {
    setCurrentSelectedItem(idx)

    setTimeout(() => {
      setCurrentSelectedItem(-1);
    }, 5000);
  }

  return (
    <Box sx={{ height: '92%', fontFamily: theme.typography.fontFamily }}>
      <Helmet>
        <title>{t('sidebar.dashboard')} - Multifun</title>
      </Helmet>
      {!mBreakPoint0_1280 && (
        <Box sx={{ paddingRight: '15px', paddingLeft: '15px', backgroundColor: 'rgba(250, 250, 250, 0.7)', height: '100%' }}>
          <Box sx={{ backgroundColor: '#5B2F8A', position: "relative", zIndex: 1, borderRadius: mBreakPoint1281_1366 ? '8px' : '16px', height: mBreakPoint1281_1366 ? '27%' : '30%', padding: "24px", display: 'flex', justifyContent: 'space-between' }}>
            <img src='/Images/Mask group.png' style={{ position: 'absolute', right: 0, top: 0, height: '100%', width: '100%', borderRadius: '16px' }} alt="" />
            <Box sx={{ width: '69%', zIndex: 10 }}>
              <Box sx={{ height: '70%' }}>
                <Box sx={{ display: 'flex', justifyContent: 'start' }}>
                  <p style={{
                    fontWeight: 600,
                    color: '#ffc500',
                    fontSize: mBreakPoint1281_1366 ? '22px' : '26px',
                    lineHeight: mBreakPoint1281_1366 ? '20px' : '46px',
                    letterSpacing: '0.5px',
                    margin: '0px',
                    textTransform: 'none',
                    fontFamily: theme.typography.fontFamily,
                  }}>{t('dashboard.featured_brand')} - {mBrandANDgamingData?.brandData?.BrandName}
                  </p>
                </Box>
                <Box sx={{ marginTop: '2px' }}>
                  <p style={{
                    margin: 0,
                    fontWeight: 600,
                    color: 'white',
                    fontSize: mBreakPoint1281_1366 ? '13px' : '18px',
                    letterSpacing: '0.1px',
                    fontFamily: theme.typography.fontFamily,
                  }}>{`${mBrandANDgamingData?.gamingData?.promotions}`}</p>
                  <p style={{
                    margin: 0,
                    fontWeight: 600,
                    color: 'white',
                    fontSize: mBreakPoint1281_1366 ? '13px' : '18px',
                    letterSpacing: '0.1px',
                    fontFamily: theme.typography.fontFamily,
                  }}>{`💰 Multifun ${t('dashboard.cashback')}: ${mBrandANDgamingData?.gamingData?.visibleCashback}`}
                  </p>
                  <ToolTipForDesktop title={`${mBrandANDgamingData?.brandData?.BrandDescription}`} arrow>
                    <Box sx={{ marginTop: '10px' }}>
                      <p style={{ cursor: 'pointer', margin: 0, fontWeight: 400, fontSize: mBreakPoint1281_1366 ? '9px' : '11px', letterSpacing: '0.1px', color: '#FFFFFF', paddingRight: '10px', fontFamily: theme.typography.fontFamily }}>{`${mBrandANDgamingData?.brandData?.BrandDescription.substring(0, 700)}`}</p>
                    </Box>
                  </ToolTipForDesktop>
                </Box>
              </Box>
            </Box>
            <Box sx={{ width: '31%', display: 'flex', justifyContent: 'space-between', flexDirection: 'column', zIndex: 10 }}>
              <Box sx={{ height: '70%' }}>
                <img src={`${S3Url}/images/${mBrandANDgamingData?.brandData?.LogoUrl}`} alt="" style={{ width: '100%', height: '100%', borderRadius: mBreakPoint1281_1366 ? '8px' : '16px' }} />
              </Box>
              <Box sx={{ height: '25%', display: 'flex', flexDirection: 'column', justifyContent: 'end' }}>
                <ResigterButton
                  startIcon={
                    mBrandANDgamingData?.signUp ? <PlayCircleOutlineIcon /> : <Icon name='register-account' />
                  }
                  sx={{ margin: 0, height: '70%' }}
                  onClick={() => handleGotoManualRegAccount()}
                > {mBrandANDgamingData?.signUp ? t('dashboard.play_now') : t('dashboard.register_account')}</ResigterButton>
              </Box>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', height: '7%' }}>
            <SelectButton
              startIcon={
                <Icon sx={{ margin: 0 }} name='casinos-button' />
              }
              sx={{ marginLeft: '3px' }}
              onClick={() => { setActiveButton(t('sidebar.casinos')); setCheckGamingButton(1) }}
              className={activeButton === t('sidebar.casinos') ? 'active' : ''}
            >{t('sidebar.casinos')}</SelectButton>
            <SelectButton
              startIcon={
                <Icon name='sportsbook-button' />
              }
              onClick={() => { setActiveButton(t('sidebar.sportsbook')); setCheckGamingButton(2) }}
              className={activeButton === t('sidebar.sportsbook') ? 'active' : ''}
            >{t('sidebar.sportsbook')}</SelectButton>
            <SelectButton
              startIcon={
                <Icon name='bingo-button' />
              }
              onClick={() => { setActiveButton(t('sidebar.bingo')); setCheckGamingButton(3) }}
              className={activeButton === t('sidebar.bingo') ? 'active' : ''}
            >{t('sidebar.bingo')}</SelectButton>
            <SelectButton
              startIcon={
                <Icon name='poker-button' />
              }
              onClick={() => { setActiveButton(t('sidebar.poker')); setCheckGamingButton(4) }}
              className={activeButton === t('sidebar.poker') ? 'active' : ''}
            >{t('sidebar.poker')}</SelectButton>
            <SelectButton
              startIcon={
                <Icon name='fantasy-button' />
              }
              sx={{ marginRight: '3px' }}
              onClick={() => { setActiveButton(t('sidebar.fantasy')); setCheckGamingButton(5) }}
              className={activeButton === t('sidebar.fantasy') ? 'active' : ''}
            >{t('sidebar.fantasy')}</SelectButton>
          </Box>
          {mFilteGamingData?.length > 0 && (
            <>
              <Box sx={{ borderBottom: '1px solid rgba(0, 0, 0, 0.1)', height: '6%', display: 'flex', flexDirection: 'column', justifyContent: 'end' }}>
                <Box sx={{ height: '40px', paddingTop: '14px', paddingLeft: '16px', paddingRight: '16px', paddingBottom: '5px', borderBottom: '2px solid #6C3D9E', display: 'inline', maxWidth: '25%' }}>
                  <p style={{ fontWeight: 600, display: 'flex', fontSize: mBreakPoint1281_1366 ? '9px' : '12px', letterSpacing: '-0.3px', color: '#181819', fontFamily: theme.typography.fontFamily }}>{t('dashboard.recent_activity')}: {activeButton}</p>
                </Box>
              </Box>
              <Box sx={{ marginTop: '10px' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <StyledTypography style={{ width: '13%', marginLeft: '10px' }}>
                    {mCheckGamingButton === 1 ? t('sidebar.casinos') : mCheckGamingButton === 2 ? t('sidebar.sportsbook') : mCheckGamingButton === 3 ? t('sidebar.bingo') : mCheckGamingButton === 4 ? t('sidebar.poker') : mCheckGamingButton === 5 ? t('sidebar.fantasy') : ''}
                  </StyledTypography>
                  <StyledTypography style={{ width: '13%' }}>{t('dashboard.brand_name')}</StyledTypography>
                  <StyledTypography style={{ width: '18%' }}>{t('dashboard.email')}</StyledTypography>
                  <StyledTypography style={{ width: '14%' }}>{t('dashboard.status')}</StyledTypography>
                  <StyledTypography style={{ width: '14%' }}>{t('dashboard.last_cashback')}</StyledTypography>
                  <StyledTypography style={{ width: '14%' }}>{t('dashboard.total_cashback')}</StyledTypography>
                  <StyledTypography style={{ width: '14%' }}>{t('dashboard.play_now')}</StyledTypography>
                </Box>
                {mFilteGamingData?.map((item, idx) => (
                  <SelectBox index={idx} onClick={() => handleSelectAccont(idx)} sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '8px', backgroundColor: 'rgba(255,255,255,1)', paddingTop: mBreakPoint1281_1366 ? '1px' : '3px', paddingBottom: mBreakPoint1281_1366 ? '1px' : '3px', alignItems: 'center' }}>
                    <Box sx={{ width: '13%', display: 'flex', flexDirection: 'row', justifyContent: "start", paddingLeft: '8px', position: 'relative', alignItems: 'center' }}>
                      <img alt="green iguana" src={`${S3Url}/images/${item.LogoUrl}`} style={{ height: mBreakPoint1281_1366 ? '25px' : '45px', borderRadius: mBreakPoint1281_1366 ? '6px' : '8px' }} />
                      {item.status === false && (
                        <ToolTipForDesktop title={`${t('dashboard.tooltip_sync_time').replaceAll('{BrandName}', item.brandName)}`} arrow>
                          <span style={{ marginLeft: '10px', zIndex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#ffc500', width: mBreakPoint1281_1366 ? '15px' : '24px', height: mBreakPoint1281_1366 ? '15px' : '24px', borderRadius: '50%', cursor: 'pointer', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)', pointerEvents: 'auto' }}>
                            <ShiningSyncProblemIcon style={{ fontSize: mBreakPoint1281_1366 ? '10px' : '16px' }} />
                          </span>
                        </ToolTipForDesktop>
                      )}
                    </Box>
                    <StyledBox width={'13%'} color={"black"}>
                      {item.brandName}
                    </StyledBox>
                    <ToolTipForDesktop title={item.profileEmail} arrow>
                      <StyledBox width={'18%'} color={"black"}>
                        {item.profileEmail?.split(', ').length === 1 ? `${item.profileEmail.substring(0, 23)}${item.profileEmail.length > 23 ? '...' : ''}` : (item.profileEmail?.split(', ')[0]).substring(0, 23) + '... +' + (item.profileEmail?.split(', ').length - 1)}
                      </StyledBox>
                    </ToolTipForDesktop>
                    <StyledBox width={'14%'} color={item.status ? '#35b34b' : "black"}>
                      {item.status ? t('dashboard.synced') : `${t('dashboard.syncing')}...`}
                    </StyledBox>
                    <StyledBox width={'14%'} color={"black"}>
                      €{item.LastCashback?.toFixed(2)}
                    </StyledBox>
                    <StyledBox width={'14%'} color={"black"}>
                      €{item.TotalCashback.toFixed(2)}
                    </StyledBox>
                    <StyledBox width={'14%'} color={"black"}>
                      <Button sx={{
                        width: '90%', padding: mBreakPoint1281_1366 ? '1px 8px' : '3px 12px', color: "#229427", fontWeight: "600", fontSize: mBreakPoint1281_1366 ? '9px' : '11px', backgroundColor: "rgba(34, 148, 39, 0.2)",
                        borderRadius: mBreakPoint1281_1366 ? '6px' : "16px", textTransform: 'none', '&:hover': { backgroundColor: "rgba(34, 148, 39, 0.4)" }
                      }}
                        onClick={() => handleGotoCasino(item.affiliateLink, item.additionalLink, item.PayloadID, item.status, item.affSys)}
                      >{t('dashboard.visit_website')}</Button>
                    </StyledBox>
                  </SelectBox>
                ))}
              </Box>
            </>
          )}
          {mFilteGamingData?.length === 0 && (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', height: '60%' }}>
              <p style={{ fontSize: '11px', color: 'grey', fontWeight: '400px', fontFamily: theme.typography.fontFamily }}>
                {t('dashboard.no_cashback_set_up')}.<span style={{ color: '#5B2F8A', fontSize: '11px' }}> <a href="https://www.multifun.io/getcashback?deal=casino" style={{ textDecoration: 'none' }}>{t('dashboard.get_started')}</a></span> {t('dashboard.with_your_first')}!
              </p>

            </Box>
          )}
          <Box>
            <BackDrop open={mLoadingDataFromServer} />
          </Box>
        </Box>
      )}
      {mBreakPoint0_1280 && (
        <Box sx={{ padding: 2, backgroundColor: '#edecef' }}>
          <Box sx={{ backgroundColor: '#FFFFFF', textAlign: 'center', borderRadius: '8px', boxShadow: '6px 6px 50px rgba(0, 0, 0, 0.05)', padding: '15px 20px', margin: 'auto' }}>
            <CashbackButton sx={{ width: '100%' }}>
              {t('dashboard.cashback')}
            </CashbackButton>
            <p style={{ fontWeight: 500, fontSize: '40px', color: 'rgba(0,0,0,1)', margin: 0, marginTop: '10px', fontFamily: theme.typography.fontFamily }}>€{mUserCashbackThisMonth.toFixed(2)}</p>
            <p style={{ fontWeight: 400, fontSize: '13px', color: '#181818', marginTop: '8px', fontFamily: theme.typography.fontFamily }}>{t('mainlayout.this_month')}</p>
          </Box>
          <Box sx={{ marginTop: '15px', backgroundColor: '#ffc500', borderWidth: '1px', borderColor: '#ffc500', borderStyle: 'solid', borderRadius: '8px' }}>
            <FormControl fullWidth backgroundColor={'white'} overflow={'auto'} >
              <Select
                value={mCheckGamingButton}
                onChange={(e) => { setCheckGamingButton(e.target.value) }}
                displayEmpty
                inputProps={{ 'aria-label': 'Without label' }}
                sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 'none', color: 'rgba(0,0,0,0.8)', borderRadius: '8px' }, width: '100%', '.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': { padding: '13px 14px' }, '.css-hfutr2-MuiSvgIcon-root-MuiSelect-icon': { color: 'white' }, '.css-bpeome-MuiSvgIcon-root-MuiSelect-icon': { color: 'white' }, height: '40px', color: 'white' }}

              >
                <MenuItem value={1} display={'flex'} style={{ backgroundColor: '#ffc500' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Box sx={{ marginLeft: '15px', display: 'flex', alignItems: 'center' }}>
                      <img width="25vh" src="../../Images/casino.svg" sx={{ marginRight: '10px' }} alt="" />
                    </Box>
                    <Box sx={{ marginLeft: '15px', color: 'white' }}>
                      {t('sidebar.casinos')}
                    </Box>
                  </Box>
                </MenuItem>
                <MenuItem value={2} display={'flex'} style={{ backgroundColor: '#ffc500' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Box sx={{ marginLeft: '15px', display: 'flex', alignItems: 'center' }}>
                      <img width="25vh" src="../../Images/sportsbook.svg" sx={{ marginRight: '10px' }} alt="" />
                    </Box>
                    <Box sx={{ marginLeft: '15px', color: 'white' }}>
                      {t('sidebar.sportsbook')}
                    </Box>
                  </Box>
                </MenuItem>
                <MenuItem value={3} display={'flex'} style={{ backgroundColor: '#ffc500' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Box sx={{ marginLeft: '15px', display: 'flex', alignItems: 'center' }}>
                      <img width="25vh" src="../../Images/bingo.svg" sx={{ marginRight: '10px' }} alt="" />
                    </Box>
                    <Box sx={{ marginLeft: '15px', color: 'white' }}>
                      {t('sidebar.bingo')}
                    </Box>
                  </Box>
                </MenuItem>
                <MenuItem value={4} display={'flex'} style={{ backgroundColor: '#ffc500' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Box sx={{ marginLeft: '15px', display: 'flex', alignItems: 'center' }}>
                      <img width="25vh" src="../../Images/poker.svg" sx={{ marginRight: '10px' }} alt="" />
                    </Box>
                    <Box sx={{ marginLeft: '15px', color: 'white' }}>
                      {t('sidebar.poker')}
                    </Box>
                  </Box>
                </MenuItem>
                <MenuItem value={5} display={'flex'} style={{ backgroundColor: '#ffc500' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Box sx={{ marginLeft: '15px', display: 'flex', alignItems: 'center' }}>
                      <img width="25vh" src="../../Images/fantasy.svg" sx={{ marginRight: '10px' }} alt="" />
                    </Box>
                    <Box sx={{ marginLeft: '15px', color: 'white' }}>
                      {t('sidebar.fantasy')}
                    </Box>
                  </Box>
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ borderRadius: '16px', padding: '15px 15px 0 15px', backgroundColor: 'black', marginTop: '15px' }}>
            <img src={`${S3Url}/images/${mBrandANDgamingData?.brandData?.LogoUrl}`} style={{ borderRadius: '16px', width: '100%' }} alt="" />
            <Box display={`flex`} flexDirection={`column`} justifyContent={`center`} alignItems={`center`}>
              <p style={{ fontWeight: 700, color: '#ffc500', fontSize: '14px', lineHeight: '30px', letterSpacing: '0.5px', margin: 0, textAlign: 'center', fontFamily: theme.typography.fontFamily }}>{t('dashboard.featured_brand')} - {mBrandANDgamingData?.brandData?.BrandName}</p>
              <p style={{ fontWeight: 700, color: 'white', fontSize: '16px', lineHeight: '30px', letterSpacing: '0.5px', margin: 0, textAlign: 'center', fontFamily: theme.typography.fontFamily }}>{`💰 Multifun ${t('dashboard.cashback')}: ${mBrandANDgamingData?.gamingData?.visibleCashback}`}</p>
            </Box>
            <Button sx={{
              width: '100%',
              padding: '6px 7px',
              color: "rgb(0,245,11)",
              fontWeight: "600",
              fontSize: '14px',
              backgroundColor: "rgba(34, 148, 39, 0.2)",
              borderRadius: "16px",
              textTransform: 'none',
              marginBottom: '15px',
              marginTop: '5px',
              '&:hover': { backgroundColor: "rgba(34, 148, 39, 0.2)" }
            }}
              onClick={() => handleGotoManualRegAccount()}
            >{mBrandANDgamingData?.signUp ? t('dashboard.play_now') : t('dashboard.register_account')}
            </Button>
          </Box>
          {mFilteGamingData?.length === 0 && (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', height: '200px' }}>
              <p style={{ fontSize: '13px', color: 'grey', fontWeight: '400px', fontFamily: theme.typography.fontFamily }}>
                {t('dashboard.no_cashback_set_up')}.<span style={{ color: '#5B2F8A', fontSize: '13px' }}> <a href="https://www.multifun.io/getcashback?deal=casino" style={{ textDecoration: 'none' }}>{t('dashboard.get_started')}</a></span> {t('dashboard.with_your_first')}!
              </p>

            </Box>
          )}
          <Box sx={{ marginTop: '15px' }}>
            {mFilteGamingData?.map((item, idx) => (
              <Box
                key={idx}
                sx={{
                  borderRadius: '16px',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: '15px',
                  backgroundColor: '#3c2653'
                  //backgroundColor:'rgba(248, 248, 248, 1)'
                }}
              >
                <Grid item xs={12} md={4} textAlign="center" style={{ padding: '15px 15px 0 15px' }} >
                  <img alt="green iguana" src={S3Url + '/images/' + item.LogoUrl} style={{ width: '100%', borderRadius: '16px' }} />
                </Grid>
                <Grid item xs={12} md={4} style={{ padding: '0 15px' }} display={'flex'} justifyContent={'space-between'} width={'100%'} marginTop={'10px'}>
                  <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontFamily: theme.typography.fontFamily }}
                    sx={{ fontSize: '11px', fontWeight: '400', letterSpacing: '-0.3px', textAlign: 'center', textTransform: 'none', color: 'white' }}
                    onClick={() => handleForMobileTooltip(idx)}
                  >
                    < Tooltip title={item.profileEmail} open={idx === currentSelectedItem ? true : false} />
                    <IoMailOutline style={{ fontSize: '15px', color: 'white', marginRight: '5px' }} />
                    {item.profileEmail?.split(', ').length === 1 ? `${item.profileEmail.substring(0, 23)}${item.profileEmail.length > 23 ? '...' : ''}` : (item.profileEmail?.split(', ')[0]).substring(0, 23) + '... +' + (item.profileEmail?.split(', ').length - 1)}
                  </Box>
                  <Box style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: item.status ? 'rgba(208, 240, 210, 1)' : 'rgba(244, 246, 101, 1)',
                    borderRadius: '16px',
                    padding: '1px 10px', fontFamily: theme.typography.fontFamily
                  }}
                    sx={{ fontSize: '11px', color: 'black', fontWeight: '400', letterSpacing: '-0.3px', textAlign: 'center', textTransform: 'none' }}
                  >
                    {item.status ?
                      <CheckCircleIcon style={{ color: 'green', fontSize: '15px', marginRight: '5px' }} />
                      :
                      <Box sx={{ marginRight: '5px' }}>
                        <MobileTooltip title={`${t('dashboard.tooltip_sync_time').replaceAll('{BrandName}', item.brandName)}`} flag={1} arrow />
                      </Box>
                    }
                    {item.status ? t('dashboard.synced') : t('dashboard.syncing') + '...'}
                  </Box>
                </Grid>
                <Grid item xs={12} md={4} style={{ padding: '0 15px' }} display={'flex'} justifyContent={'space-between'} width={'100%'} marginTop={'15px'}>
                  <Box style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'rgba(119, 86, 164, 1)',
                    borderRadius: '16px',
                    padding: '10px 24px',
                    width: '100%',
                    marginRight: '24px'
                  }}>
                    <p style={{ fontSize: '10px', color: 'white', fontWeight: '400', letterSpacing: '-0.3px', textTransform: 'none', margin: 0, textAlign: 'center', fontFamily: theme.typography.fontFamily }}>{t('dashboard.last_cashback')}</p>
                    <p style={{ fontSize: '16px', color: 'white', fontWeight: '500', letterSpacing: '-0.3px', textTransform: 'none', margin: 0, marginTop: '10px', textAlign: 'center', fontFamily: theme.typography.fontFamily }}>€{item.LastCashback.toFixed(2)}</p>
                  </Box>
                  <Box style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'rgba(246, 140, 37, 1)',
                    borderRadius: '16px',
                    padding: '10px 24px',
                    width: '100%'
                  }}>
                    <p style={{ fontSize: '10px', color: 'white', fontWeight: '400', letterSpacing: '-0.3px', textTransform: 'none', margin: 0, textAlign: 'center', fontFamily: theme.typography.fontFamily }}>{t('dashboard.total_cashback')}</p>
                    <p style={{ fontSize: '16px', color: 'white', fontWeight: '500', letterSpacing: '-0.3px', textTransform: 'none', margin: 0, marginTop: '10px', textAlign: 'center', fontFamily: theme.typography.fontFamily }}>€{item.TotalCashback.toFixed(2)}</p>
                  </Box>
                </Grid>
                <Grid item xs={12} textAlign="center" style={{ marginTop: '15px', marginBottom: '15px', width: '100%', padding: '0 15px' }}>
                  <Button sx={{ width: '100%', padding: '6px 7px', color: "rgb(0,245,11)", fontWeight: "600", fontSize: '14px', backgroundColor: "rgba(34, 148, 39, 0.2)", borderRadius: "100px", textTransform: 'none', '&:hover': { backgroundColor: "rgba(34, 148, 39, 0.4)" } }}
                    onClick={() => handleGotoCasino(item.affiliateLink, item.additionalLink, item.PayloadID, item.status, item.affSys)}
                  >{t('dashboard.visit_website')}</Button>
                </Grid>
              </Box>
            ))}
          </Box>
          <Box sx={{ marginTop: '15px', marginBottom: '15px' }}>
            <Box sx={{ backgroundColor: '#FFFFFF', textAlign: 'center', borderRadius: '8px', boxShadow: '6px 6px 50px rgba(0, 0, 0, 0.05)' }}>
              <CardContent sx={{ backgroundColor: 'white', borderRadius: '4px', textAlign: 'center' }}>
                <ReferFriendButton sx={{ width: '100%', textTransform: 'none' }}
                  onClick={() => handleReferAndfriend()}
                >{t('mainlayout.refer_a_friend')}</ReferFriendButton>
                <p style={{ color: 'rgba(108, 61, 158, 1)', fontWeight: 500, fontSize: '38px', padding: 0, margin: 0, marginTop: '15px', fontFamily: theme.typography.fontFamily }}>€25</p>
                <p style={{ color: '#181818', fontSize: '13px', fontWeight: 400, fontFamily: theme.typography.fontFamily }}>{t('mainlayout.for_each_referral')}</p>
              </CardContent>
            </Box>
          </Box>
          <Box>
            <Box sx={{ marginBottom: '15px', overflow: 'auto', backgroundColor: '#FFFFFF', textAlign: 'center', borderRadius: '8px', boxShadow: '6px 6px 50px rgba(0, 0, 0, 0.05)' }} >
              <CardContent sx={{ backgroundColor: 'white', borderRadius: '4px', textAlign: 'center', minHeight: '230px' }}>
                <ReferFriendButton sx={{ width: '100%', textTransform: 'none', marginBottom: '10px' }}>{t('dashboard.recent_activity')}</ReferFriendButton>
                {mUserRecentActivity?.map((item, idx) => (
                  <div style={{ marginBottom: '15px' }}>
                    {item?.type === true && (
                      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-start' }}>
                        <p style={{ fontWeight: 400, letterSpacing: '-0.3px', color: item?.type ? 'green' : 'red', fontSize: '12px', margin: 0, marginLeft: '5px', fontFamily: theme.typography.fontFamily }}>{item?.type ? `+€${(item.Cashback).toFixed(2)}` : `-€${item.Cashback * 98 / 100}`}</p>
                        <p style={{ fontWeight: 400, letterSpacing: '-0.3px', color: 'rgba(0,0,0,0.53)', fontSize: '11px', margin: 0, marginLeft: '5px', fontFamily: theme.typography.fontFamily }}> {t('mainlayout.from')} {item.BrandName}</p>
                      </Box>
                    )}
                    {item?.type === false && item.BrandName === 'referralcashback' && (
                      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                        <p style={{ fontWeight: 400, letterSpacing: '-0.3px', color: 'green', fontSize: '12px', margin: 0, marginLeft: '5px', fontFamily: theme.typography.fontFamily }}>+€{item.Cashback}</p>
                        <p style={{ fontWeight: 400, letterSpacing: '-0.3px', color: 'rgba(0,0,0,0.53)', fontSize: '12px', margin: 0, marginLeft: '5px', fontFamily: theme.typography.fontFamily }}>{t('mainlayout.referral_cashback_from')} {item.PayloadID}</p>
                      </Box>
                    )}
                    {item?.type === false && item.BrandName !== 'referralcashback' && (
                      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                        <p style={{ fontWeight: 400, letterSpacing: '-0.3px', color: 'red', fontSize: '12px', margin: 0, marginLeft: '5px', fontFamily: theme.typography.fontFamily }}>-€{item.Cashback * 98 / 100}</p>
                        <p style={{ fontWeight: 400, letterSpacing: '-0.3px', color: 'rgba(0,0,0,0.53)', fontSize: '12px', margin: 0, marginLeft: '5px', fontFamily: theme.typography.fontFamily }}>{t('mainlayout.withdrawal_to')} {parseInt(item?.PayloadID) === 1 ? 'Neteller' : parseInt(item?.PayloadID) === 2 ? 'Skrill' : parseInt(item?.PayloadID) === 3 ? 'USDT ERC20' : parseInt(item?.PayloadID) === 4 ? 'USDT TRC20' : parseInt(item?.PayloadID) === 5 ? 'Bitcoin' : parseInt(item?.PayloadID) === 6 ? 'Ethereum' : ''}</p>
                      </Box>
                    )}
                  </div>
                ))}
              </CardContent>
            </Box>
          </Box>
          <BackDrop open={mLoadingDataFromServer} />
        </Box>
      )}
    </Box>
  )
}

export default DashboardPage;