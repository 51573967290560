import { createContext, useState } from "react";
import { getLocaleInfo } from "../utils/authToken";

export const AuthContext = createContext({
    isAuthenticated: false,
    authToken: "",
    authUser: {}
})

export const AuthProvider = ({ children }) => {
    const [isLogin, setIsLogin] = useState(true)
    const [authToken, setAuthToken] = useState('')
    const [authUser, setAuthUser] = useState({})
    const [mSearchContextValue, setSearchContextValue] = useState('')
    const [mSearchCountry, setSearchCountry] = useState(0);
    const [mSearchHighestCashback, setSearchHighestCashback] = useState(1);
    const [mDealType, setDealType] = useState(1);
    const [mRememberMe, setRememberMe] = useState(false);
    const [mLanguage, setLanguage] = useState(getLocaleInfo() ? getLocaleInfo() : 'en');
    const [mStatusAccountMode, setStatusAccountMode] = useState(false);


    return (
        <AuthContext.Provider value={{
            isLogin,
            setIsLogin,
            authToken,
            setAuthToken,
            authUser,
            setAuthUser,
            mSearchContextValue,
            setSearchContextValue,
            mSearchCountry,
            setSearchCountry,
            mSearchHighestCashback,
            setSearchHighestCashback,
            mDealType,
            setDealType,
            mRememberMe,
            setRememberMe,
            mLanguage,
            setLanguage,
            mStatusAccountMode,
            setStatusAccountMode
        }}>
            {children}
        </AuthContext.Provider>
    )
}
