import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Grid, styled, MenuItem, FormControl, Select, Typography } from "@mui/material";
import Icon from "../../components/Icon";
import InfoIcon from '@mui/icons-material/Info';
import { useNavigate } from "react-router-dom";
import { S3Url } from "../../share/config";
import axios from 'axios';
import { getRefreshAuthToken, getStoredAuthToken } from "../../utils/authToken";
import jwt_decode from 'jwt-decode';
import { countries } from "country-flags-svg";
import { AuthContext } from "../../contexts/AuthContext";
import Swal from "sweetalert2";
import { useMediaQuery } from 'react-responsive'
import { Helmet } from "react-helmet";
import StarIcon from '@mui/icons-material/Star';
import BackDrop from "../../utils/Backdrop";
import { useTranslation } from "react-i18next";
import alertSZ from "../../utils/alert";
import { API_R_DEALS } from "../../share/api";
import CustomPagination from "../../utils/CustomPagination";
import { useTheme } from '@mui/material/styles';


const Casinos_RegAccount = () => {

    const { isLogin } = useContext(AuthContext);
    const history = useNavigate();

    const authtoken = getStoredAuthToken();
    const email = authtoken ? jwt_decode(authtoken).email : null;
    if (!isLogin || !email) {
        history('/signin');
    }

    const { t } = useTranslation();
    const theme = useTheme();
    const mBreakPoint0_1280 = useMediaQuery({ query: '(min-width: 0px) and (max-width: 1280px)' });
    const mBreakPoint1281_1366 = useMediaQuery({ query: '(min-width: 1281px) and (max-width: 1768px)' });

    const [mCasinoData, setCasinoData] = React.useState();
    const [mLoadingDataFromServer, setLoadingDataFromServer] = React.useState(false);

    const { mSearchContextValue, setSearchContextValue } = useContext(AuthContext);
    const { mSearchCountry, setSearchCountry } = useContext(AuthContext);
    const { mSearchHighestCashback } = useContext(AuthContext);
    const { mDealType, setDealType } = useContext(AuthContext);

    const [mFilteGamingData, setFilterGamingData] = useState([]);
    const [currentPage, setCurrentPage] = React.useState(0);
    const itemPerPage = 10;
    const startIndex = currentPage * itemPerPage;
    const endIndex = startIndex + itemPerPage;
    const [disPlayItems, setDisplayItems] = useState([]);
    const [inputCurrentPage, setInputCurrentPage] = React.useState();
    const [mSelectedAccount, setSelectedAccount] = useState(0);
    const [cashbackIdx, setCashbackIdx] = React.useState(1);
    const [selCountry, setSelCountry] = React.useState(0);
    const [mCurrentSession, setCurrentSession] = useState('');

    const SelectButton = styled(Button)({
        color: 'white',
        fontSize: mBreakPoint1281_1366 ? '9px' : '12px',
        fontWeight: 400,
        height: mBreakPoint1281_1366 ? '35px' : '80%',
        letterSpacing: '0.1px',
        padding: '15px 14px',
        textTransform: 'none',
        flex: '1',
        margin: '0 15px 0 0',
        borderRadius: mBreakPoint1281_1366 ? '5px' : '8px',
        backgroundColor: 'rgba(99,57,143,1)',
        '&.active': {
            backgroundColor: '#ffc500'
        },
        '&:hover': {
            backgroundColor: 'rgba(99,57,143,0.8)',
        },
    });

    const handleSelectAccont = (idx) => {
        if (mSelectedAccount === idx) {
            setSelectedAccount(null); // Deselect if the same item is clicked again
        } else {
            setSelectedAccount(idx);
        }
    }

    const SelectBox = styled(Box)(({ index }) => ({
        position: 'relative',
        '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: mBreakPoint1281_1366 ? '3px' : '5px',
            height: '100%',
            background: 'linear-gradient(180deg, #6C3D9E 20%, #E91287 100%)',
            borderRadius: '6px 0 0 6px',
            opacity: mSelectedAccount === index ? 1 : 0,
            transition: 'opacity 0.3s ease-in-out',
        },
        '&:hover::before': {
            opacity: 1,
        },
        '&:hover': {
            cursor: 'pointer',
            borderRadius: '6px',
            backgroundColor: 'rgba(200,200,200,0.2)'
        },
        borderRadius: '6px',
    }));

    const StyledTypography = styled(Typography)({
        fontWeight: 400,
        letterSpacing: '-0.3px',
        color: 'rgba(24, 24, 25, 0.7)',
        fontSize: mBreakPoint1281_1366 ? '9px' : '11px',
    });

    const StyledBox = styled(Box)({
        display: 'flex',
        justifyContent: 'start',
        variant: "overline",
        fontWeight: '300',
        fontStyle: 'normal',
        fontSize: mBreakPoint1281_1366 ? '9px' : '11px',
        lineHeight: '140%',
        letterSpacing: '0.5px',
        textTransform: "none", fontFamily: theme.typography.fontFamily
    });


    const handleChangeCashback = (e) => {
        setCashbackIdx(e.target.value);
    };

    const handleChangeSelCountry = (e) => {
        if (mCurrentSession !== getRefreshAuthToken()) {
            Swal.fire({
                title: t('mainlayout.alert'),
                text: t('mainlayout.chaning_to_another_country'),
                icon: t('mainlayout.question'),
                confirmButtonText: t('mainlayout.i_understand'),
            }).then((result) => {
                if (result.isConfirmed) {
                    setCurrentSession(getRefreshAuthToken());
                    setSelCountry(e.target.value);
                    setSearchCountry(e.target.value);
                    return;

                }
            });
        } else {
            setSelCountry(e.target.value);
            setSearchCountry(e.target.value);
        }
    };

    const handleChangeTypeCasino = (e) => {
        setDealType(e.target.value)
    };

    useEffect(() => {
        setCurrentPage(0);
        setFilterGamingData(mCasinoData?.filter(value => {
            // Check other conditions for inclusion (featured, search criteria)
            return (
                value.featured === true ||
                value.Data.promotions?.toString().toLowerCase().includes(mSearchContextValue.trim().toLowerCase()) ||
                value.brandName.toString().toLowerCase().includes(mSearchContextValue.trim().toLowerCase()) ||
                value.Data.cashbackRate?.toString().toLowerCase().includes(mSearchContextValue.trim().toLowerCase())
            );
        }));
    }, [mSearchContextValue, mCasinoData]);
    useEffect(() => {
        setSelCountry(mSearchCountry);
    }, [mSearchCountry]);

    useEffect(() => {
        setCashbackIdx(mSearchHighestCashback)
    }, [mSearchHighestCashback]);

    useEffect(() => {
        setSearchContextValue('')
    }, [setSearchContextValue])

    useEffect(() => {
        // eslint-disable-next-line array-callback-return
        mFilteGamingData?.sort((a, b) => {
            if (a.featured && !b.featured) {
                return -1;
            } else if (!a.featured && b.featured) {
                return 1;
            }

            if (a.Data.cashbackRate !== b.Data.cashbackRate) {
                return cashbackIdx === 1 ? b.Data.cashbackRate - a.Data.cashbackRate : a.Data.cashbackRate - b.Data.cashbackRate;
            }

            if (a.Data.cashbackRate === b.Data.cashbackRate) {
                return b.Data.cpa - a.Data.cpa;
            }
        });

        const displayItemsTemp = mFilteGamingData?.slice(startIndex, endIndex);
        setDisplayItems(displayItemsTemp);

    }, [mFilteGamingData, cashbackIdx, startIndex, endIndex]);


    useEffect(() => {
        const getData = async () => {

            setLoadingDataFromServer(true);
            const gamingType = mDealType;
            const country = countries[selCountry].name;
            await axios.post(API_R_DEALS, {
                email, gamingType, country
            }).then(res => {
                const body = res.data;
                setLoadingDataFromServer(false);
                if (res.status === 200) {
                    setCasinoData(body);
                    return;
                }
            }).catch(e => {
                const text = e.response.data;
                alertSZ(t(`backend.${text}`))
                return
            })
        }
        if (isLogin && email) {
            getData();
        }
    }, [selCountry, mDealType, email, t, isLogin]);

    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage - 1);
        setTimeout(() => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }, 500);
    }
    const handleInputChange = (e) => {
        setInputCurrentPage('')
        const inputValue = e.target.value;

        const page = parseInt(inputValue, 10);
        if (page >= 1 && page <= Math.ceil((mCasinoData ? mCasinoData.length : 0) / itemPerPage)) {
            setInputCurrentPage(page);
        }
    }

    const handleGoButtonClick = () => {
        const page = parseInt(inputCurrentPage, 10);
        if (page >= 1 && page <= Math.ceil((mCasinoData ? mCasinoData.length : 0) / itemPerPage)) {
            setCurrentPage(page - 1);
        }
    };

    const handleGotoManualRegAccount = (item) => {
        const data = {
            brandName: item.brandName,
            logoUrl: item.logoUrl,
            affiliateLink: item.Data.affiliateLink,
            additionalLink: item.Data.additionalLink,
        }
        history(`/getcashback/register-to?brand=${encodeURIComponent(JSON.stringify(data))}`)
    }
    return (
        <Box sx={{ fontFamily: theme.typography.fontFamily }}>
            <Helmet>
                <title>{t('getcashback.getcashback')} - Multifun</title>
            </Helmet>
            {!mBreakPoint0_1280 && (
                <Box sx={{ paddingRight: '10px', paddingLeft: '10px', backgroundColor: 'rgba(250, 250, 250, 0.7)', position: 'relative', height: '92vh' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '3px' }}>
                        <SelectButton
                            startIcon={
                                <Icon name='casinos-button' />
                            }
                            sx={{ marginLeft: '3px' }}
                            className={mDealType === 1 ? `active` : ``}
                            onClick={() => { history('/getcashback?deal=casino'); setDealType(1) }}
                        >{t('sidebar.casinos')}</SelectButton>
                        <SelectButton
                            startIcon={
                                <Icon name='sportsbook-button' />
                            }
                            className={mDealType === 2 ? `active` : ``}
                            onClick={() => { history('/getcashback?deal=sportsbook'); setDealType(2) }}
                        >{t('sidebar.sportsbook')}</SelectButton>
                        <SelectButton
                            startIcon={
                                <Icon name='bingo-button' />
                            }
                            className={mDealType === 3 ? `active` : ``}
                            onClick={() => { history('/getcashback?deal=bingo'); setDealType(3) }}
                        >{t('sidebar.bingo')}</SelectButton>
                        <SelectButton
                            startIcon={
                                <Icon name='poker-button' />
                            }
                            className={mDealType === 4 ? `active` : ``}
                            onClick={() => { history('/getcashback?deal=poker'); setDealType(4) }}
                        >{t('sidebar.poker')}</SelectButton>
                        <SelectButton
                            startIcon={
                                <Icon name='fantasy-button' />
                            }
                            sx={{ marginRight: '1px' }}
                            className={mDealType === 5 ? `active` : ``}
                            onClick={() => { history('/getcashback?deal=fantasy'); setDealType(5) }}
                        >{t('sidebar.fantasy')}</SelectButton>
                    </Box>
                    <Box sx={{ marginTop: '10px' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingTop: '8px' }}>
                            <StyledTypography style={{ width: '11%', marginLeft: '4px' }}>
                                {mDealType === 1 ? t('sidebar.casinos') : mDealType === 2 ? t('sidebar.sportsbook') : mDealType === 3 ? t('sidebar.bingo') : mDealType === 4 ? t('sidebar.poker') : mDealType === 5 ? t('sidebar.fantasy') : ''}
                            </StyledTypography>
                            <StyledTypography style={{ width: '3%' }}></StyledTypography>
                            <StyledTypography style={{ width: '17%' }}>{t('dashboard.brand_name')}</StyledTypography>
                            <StyledTypography style={{ width: '45%' }}>{t('getcashback.promotion')}</StyledTypography>
                            <StyledTypography style={{ width: '10%' }}>{t('dashboard.cashback')}</StyledTypography>
                            <StyledTypography style={{ width: '2%' }}></StyledTypography>
                            <StyledTypography style={{ width: '10%' }}>{t('getcashback.account')}</StyledTypography>
                        </Box>
                        <Box>
                            {disPlayItems?.map((item, idx) => (
                                <SelectBox index={idx} onClick={() => handleSelectAccont(idx)} sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '8px', backgroundColor: 'rgba(255,255,255,1)', paddingTop: mBreakPoint1281_1366 ? '1px' : '3px', paddingBottom: mBreakPoint1281_1366 ? '1px' : '3px', alignItems: 'center' }}>
                                    <Box sx={{ width: '11%', display: 'flex', justifyContent: "start", paddingLeft: '8px', alignItems: 'center' }}>
                                        <img alt="green iguana" src={S3Url + '/images/' + item.logoUrl} style={{ height: mBreakPoint1281_1366 ? '25px' : '40px', borderRadius: mBreakPoint1281_1366 ? '5px' : '8px' }} />
                                    </Box>
                                    <StyledBox width={'3%'} color={"black"}>
                                        {item.featured === true && (
                                            <StarIcon style={{ color: '#ffc500', fontSize: mBreakPoint1281_1366 ? '20px' : '24px' }} />
                                        )}
                                    </StyledBox>
                                    <StyledBox width={'17%'} color={"black"}>
                                        {item.brandName}
                                    </StyledBox>
                                    <StyledBox width={'45%'} color={"black"}>
                                        {item.Data.promotions}
                                    </StyledBox>
                                    <StyledBox width={'10%'} color={"black"}>
                                        {item.Data.visibleCashback}
                                    </StyledBox>
                                    <StyledBox width={'2%'} color={"black"}>
                                        <InfoIcon sx={{ color: '#057c7d', fontSize: mBreakPoint1281_1366 ? '18px' : '24px' }}
                                            onClick={() => { history(`/reviewdashboard?gameItem=${encodeURIComponent(JSON.stringify(item))}&mCheckGamingButton=${mDealType}`) }}
                                        />
                                    </StyledBox>
                                    <StyledBox width={'10%'} color={"black"}>
                                        <Button sx={{ width: '100%', padding: mBreakPoint1281_1366 ? '1px 12px' : '3px 12px', marginRight: '10px', color: "#229427", fontWeight: "600", fontSize: mBreakPoint1281_1366 ? '9px' : '11px', backgroundColor: "rgba(34, 148, 39, 0.2)", borderRadius: mBreakPoint1281_1366 ? '7px' : "16px", textTransform: 'none', marginLeft: '5px', '&:hover': { backgroundColor: "rgba(34, 148, 39, 0.4)" } }}
                                            onClick={() => handleGotoManualRegAccount(item)}
                                        >{t('getcashback.register')}</Button>
                                    </StyledBox>
                                </SelectBox>

                            ))}
                        </Box>
                    </Box>
                    {disPlayItems?.length > 0 && (
                        <Box sx={{ display: 'flex', padding: mBreakPoint1281_1366 ? "3px 10px" : "3px 20px", backgroundColor: '#F1F2F2', borderRadius: '44px', bottom: '25px', right: '24px', position: 'absolute' }}>
                            <CustomPagination
                                pageCount={Math.ceil(mFilteGamingData?.length / itemPerPage)}
                                currentPage={currentPage}
                                onChange={handleChangePage}
                                inputCurrentPage={inputCurrentPage}
                                handleInputChange={handleInputChange}
                                handleGoButtonClick={handleGoButtonClick}
                            />
                        </Box>)}
                    <BackDrop open={mLoadingDataFromServer} />
                </Box>
            )}
            {mBreakPoint0_1280 && (
                <Grid container sx={{ backgroundColor: '#E5E5E5' }}>
                    <Grid item xs={12}>
                        <Box sx={{ padding: '15px', backgroundColor: '#3a206d', minHeight: '100vh' }}>
                            <Box sx={{ width: '100%', backgroundColor: 'white', borderRadius: '8px' }}>
                                <FormControl fullWidth backgroundColor={'white'}>
                                    <Select
                                        value={selCountry}
                                        onChange={handleChangeSelCountry}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 'none', color: 'rgba(0,0,0,0.2)', borderRadius: '8px' }, width: '100%', '.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': { padding: '13px 14px' }, height: '40px' }}

                                    >
                                        {countries && countries?.map((item, index) => (
                                            <MenuItem value={index} display={'flex'}>
                                                <Box sx={{ display: 'flex', justifyContent: 'start' }}>
                                                    <Box sx={{ marginRight: '5px', display: 'flex', paddingLeft: '15px' }}>
                                                        <img width="30vh" src={item.flag} sx={{ marginRight: '10px' }} alt="" />
                                                    </Box>
                                                    <Box sx={{ marginLeft: '5px', display: 'flex' }}>
                                                        {item.name}
                                                    </Box>
                                                </Box>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box sx={{ marginTop: '15px', width: '100%', backgroundColor: 'white', borderRadius: '8px' }}>
                                <FormControl fullWidth backgroundColor={'white'}>
                                    <Select
                                        value={cashbackIdx}
                                        onChange={handleChangeCashback}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 'none', color: 'rgba(0,0,0,0.2)', borderRadius: '8px' }, width: '100%', '.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': { padding: '13px 14px' }, height: '40px' }}
                                    >
                                        <MenuItem value={1}>
                                            <Box sx={{ display: 'flex', paddingLeft: '15px' }}>
                                                {t('mainlayout.highest_cashback')}
                                            </Box>
                                        </MenuItem>
                                        <MenuItem value={2}>
                                            <Box sx={{ display: 'flex', paddingLeft: '15px' }}>
                                                {t('mainlayout.lowest_cashback')}
                                            </Box>
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box sx={{ marginTop: '15px', backgroundColor: '#ffc500', borderRadius: '8px' }}>
                                <FormControl fullWidth backgroundColor={'white'} overflow={'auto'}>
                                    <Select
                                        value={mDealType}
                                        onChange={(e) => { handleChangeTypeCasino(e) }}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 'none', color: 'rgba(0,0,0,0.8)', borderRadius: '8px' }, width: '100%', '.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': { padding: '13px 14px' }, '.css-hfutr2-MuiSvgIcon-root-MuiSelect-icon': { color: 'white' }, '.css-bpeome-MuiSvgIcon-root-MuiSelect-icon': { color: 'white' }, height: '40px', color: 'white' }}
                                    >
                                        <MenuItem value={1} display={'flex'} borderRadius={'16px'} style={{ backgroundColor: '#ffc500' }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <Box sx={{ marginLeft: '15px', display: 'flex', alignItems: 'center' }}>
                                                    <img width="25vh" src="../../Images/casino.svg" sx={{ marginRight: '10px' }} alt="" />
                                                </Box>
                                                <Box sx={{ marginLeft: '15px', color: 'white' }}>
                                                    {t('sidebar.casinos')}
                                                </Box>
                                            </Box>
                                        </MenuItem>
                                        <MenuItem value={2} display={'flex'} style={{ backgroundColor: '#ffc500' }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <Box sx={{ marginLeft: '15px', display: 'flex', alignItems: 'center' }}>
                                                    <img width="25vh" src="../../Images/sportsbook.svg" sx={{ marginRight: '10px' }} alt="" />
                                                </Box>
                                                <Box sx={{ marginLeft: '15px', color: 'white' }}>
                                                    {t('sidebar.sportsbook')}
                                                </Box>
                                            </Box>
                                        </MenuItem>
                                        <MenuItem value={3} display={'flex'} style={{ backgroundColor: '#ffc500' }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <Box sx={{ marginLeft: '15px', display: 'flex', alignItems: 'center' }}>
                                                    <img width="25vh" src="../../Images/bingo.svg" sx={{ marginRight: '10px' }} alt="" />
                                                </Box>
                                                <Box sx={{ marginLeft: '15px', color: 'white' }}>
                                                    {t('sidebar.bingo')}
                                                </Box>
                                            </Box>
                                        </MenuItem>
                                        <MenuItem value={4} display={'flex'} style={{ backgroundColor: '#ffc500' }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <Box sx={{ marginLeft: '15px', display: 'flex', alignItems: 'center' }}>
                                                    <img width="25vh" src="../../Images/poker.svg" sx={{ marginRight: '10px' }} alt="" />
                                                </Box>
                                                <Box sx={{ marginLeft: '15px', color: 'white' }}>
                                                    {t('sidebar.poker')}
                                                </Box>
                                            </Box>
                                        </MenuItem>
                                        <MenuItem value={5} display={'flex'} style={{ backgroundColor: '#ffc500' }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <Box sx={{ marginLeft: '15px', display: 'flex', alignItems: 'center' }}>
                                                    <img width="25vh" src="../../Images/fantasy.svg" sx={{ marginRight: '10px' }} alt="" />
                                                </Box>
                                                <Box sx={{ marginLeft: '15px', color: 'white' }}>
                                                    {t('sidebar.fantasy')}
                                                </Box>
                                            </Box>
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box sx={{ marginTop: '15px', fontFamily: theme.typography.fontFamily }}>
                                {disPlayItems?.map((item, idx) => (
                                    <Box
                                        key={idx}
                                        sx={{
                                            borderRadius: '16px',
                                            width: '100%',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            marginTop: '15px',
                                            textAlign: 'center',
                                            backgroundColor: 'rgba(248, 248, 248, 1)'
                                        }}
                                    >
                                        <Grid item xs={12} md={4} textAlign="center" style={{ padding: '15px 15px 0 15px', position: 'relative', display: 'inline-block' }} >
                                            <img alt="green iguana" src={S3Url + '/images/' + item.logoUrl} style={{ width: '100%', borderRadius: '16px' }} />
                                            {item.featured === true && (
                                                <StarIcon
                                                    style={{
                                                        color: '#ffc500',
                                                        position: 'absolute',
                                                        top: '25px',
                                                        left: '25px'
                                                    }}
                                                />
                                            )}
                                        </Grid>
                                        <Grid item xs={12} md={4} textAlign="start" style={{ padding: '0px 15px 0px 15px', marginTop: '15px', width: '100%' }} >
                                            <p style={{ overflow: 'auto', whiteSpace: 'nowrap', textAlign: 'start', fontSize: '10px', fontWeight: '600', letterSpacing: '-0.3px', textTransform: 'none', margin: 0, color: 'black' }}>{item.Data.promotions}</p>
                                            <p style={{ overflow: 'auto', whiteSpace: 'nowrap', textAlign: 'start', fontSize: '10px', fontWeight: '600', letterSpacing: '-0.3px', textTransform: 'none', margin: 0, marginTop: '15px', color: 'black' }}>💰 {item.Data.visibleCashback} Cashback</p>
                                        </Grid>
                                        <Grid item xs={12} textAlign="center" style={{
                                            marginTop: '15px',
                                            marginBottom: '15px',
                                            width: '100%',
                                            padding: '0 15px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            flexDirection: 'row',
                                            alignItems: 'center'
                                        }}>
                                            <Button sx={{
                                                width: '100%',
                                                padding: '6px 7px',
                                                color: "#229427",
                                                fontWeight: "600",
                                                fontSize: '14px',
                                                backgroundColor: "rgba(34, 148, 39, 0.2)",
                                                borderRadius: "8px",
                                                textTransform: 'none',
                                                marginRight: '15px',
                                                '&:hover': { backgroundColor: "rgba(34, 148, 39, 0.4)" }
                                            }}
                                                onClick={() => handleGotoManualRegAccount(item)}
                                            >{t('getcashback.register')}</Button>
                                            <Box sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                padding: '8px',
                                                backgroundColor: "rgba(34, 148, 39, 0.2)",
                                                borderRadius: "8px"
                                            }}>
                                                <InfoIcon sx={{ color: '#057c7d', fontSize: '21px' }}
                                                    onClick={() => { history(`/reviewdashboard?gameItem=${encodeURIComponent(JSON.stringify(item))}&mCheckGamingButton=${mDealType}`) }}
                                                />
                                            </Box>
                                        </Grid>
                                    </Box>
                                ))}
                            </Box>
                            {disPlayItems?.length > 0 && (
                                <Box sx={{ marginTop: '15px', display: 'flex', justifyContent: 'center' }}>
                                    <CustomPagination
                                        pageCount={Math.ceil(mFilteGamingData?.length / itemPerPage)}
                                        currentPage={currentPage}
                                        onChange={handleChangePage}
                                        inputCurrentPage={inputCurrentPage}
                                        handleInputChange={handleInputChange}
                                        handleGoButtonClick={handleGoButtonClick}
                                    />
                                </Box>
                            )}
                        </Box>
                        <BackDrop open={mLoadingDataFromServer} />
                    </Grid>
                </Grid>
            )}
        </Box>
    )
}

export default Casinos_RegAccount;