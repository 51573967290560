import Swal from 'sweetalert2';

const alertSZ = async (body) => {
  Swal.fire({
    text: body,
    color:'black',
    position: 'top-end',
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    customClass: {
      content: 'custom-swal-content',
    },
  });
}
export default alertSZ;