import React from "react";
import { createBrowserRouter} from 'react-router-dom';
import Mainlayout from "../components/layouts/mainlayout";
import DashboardPage from "../pages/dashboard/dashboard";
import Accounts from "../pages/Accounts/Account";
import CasinosRegAccount from "../pages/NewRegisterAccount/GetCashback";
import PaymentSetting from "../pages/PaymentSettings/PaymentSetting";
import AccountSetting from "../pages/AccountSetting/AccountSetting";
import ManualRegAccount from "../pages/NewRegisterAccount/RegAccount";
import LandingPage from "../pages/Login/LoginPage";
import Signup from "../pages/Login/Signup";
import ReviewDashboard from "../pages/review/reviewDashboard";
import PublicWebsite from "../pages/Login/PublicWebsite";
import PrivacyPolicy from "../pages/Login/Privacy";
import TermsAndCondition from "../pages/Login/Terms";
import ResponsibleGambling from "../pages/Login/ResponsibleGambling";
import HelpSection from "../pages/Help/Help";
import DownloadVideo from '../pages/Video/DownloadVideo'
import ResetPassword from "../pages/Login/ResetPassword";
import Password from "../pages/Login/Password";
import ResendVerifyEmail from "../pages/Login/ResendVerifyEmail";


const routes = createBrowserRouter([
  
    {
        path: '/',
        element: <Mainlayout />,
        children: [
          {
            path: 'dashboard',
            element: <DashboardPage />,
          },
          {
            path: 'accounts',
            element: <Accounts />,
          },
          {
            path: 'getcashback',
            element: <CasinosRegAccount />,
          },
          {
            path : 'payments',
            element : <PaymentSetting/>
          },
          {
            path: 'account-setting',
            element : <AccountSetting/>
          },
          {
            path: 'help',
            element : <HelpSection/>
          },
          {
            path: 'getcashback/register-to',
            element: <ManualRegAccount/>
          },
          {
            path: 'dashboard/register-to',
            element: <ManualRegAccount/>
          },
          {
            path: 'review/register-to',
            element: <ManualRegAccount/>
          },
          {
            path: 'reviewdashboard',
            element: <ReviewDashboard/>
          },
        ],
    },
    {
      path : '/',
      index : true,
      element : <PublicWebsite />,
    },
    {
      path : '/:countryCode',
      element : <PublicWebsite />,
    },
    {
      path: '/privacypolicy',
      element : <PrivacyPolicy />,
    },
    {
      path: '/termscondition',
      element : <TermsAndCondition />,
    },
    {
      path: '/responsiblegambling',
      element : <ResponsibleGambling />,
    },
    {
      path: '/signin',
      element: <LandingPage />,
    },
    {
      path: '/signup',
      element: <Signup />,
    },
    {
      path: '/verifyemail',
      element: <ResendVerifyEmail />,
    },
    {
      path:'/reset-password',
      element: <ResetPassword />,
    },
    {
      path:'/reset-password/:vcode',
      element: <Password />,
    },
    {
      path: '/video/affmanager-demo',
      element: <DownloadVideo />,
    }
])

export default routes;