import { Link, IconButton, Box, Button, MenuItem, OutlinedInput, CircularProgress, Select, Backdrop, Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import alertSZ from "../../utils/alert";
import { countries } from "country-flags-svg";
import { useEffect } from "react";
import { useMediaQuery } from 'react-responsive'
import { IoMailOutline } from "react-icons/io5";
import { IoPersonOutline } from "react-icons/io5";
import { RiLockPasswordLine } from "react-icons/ri";
import { AiOutlineEye } from "react-icons/ai";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { Helmet } from "react-helmet";
import { getGEOinfo_ipstack } from "../../utils/getGEO";
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next";
import { API_CR_SIGN_UP } from "../../share/api";
import { getLocaleInfo, getReferralCode, getRememberPath, removeReferralCode, setEmailVerify, storeLocaleInfo } from "../../utils/authToken";
import { localizationCountry } from "../../utils/localization";
import { useTheme } from '@mui/material/styles';
const useStyles = makeStyles({
    input: {
        WebkitBoxShadow: '0 0 0 1000px white inset',
    },
});


const Signup = () => {

    const [showPage, setShowPage] = useState(false);
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const [mEmail, setEmail] = useState();
    const [mPwd, setPwd] = useState();
    const [mConfirmPwd, setConfirmPwd] = useState();
    const [mFirstName, setFirstName] = useState()
    const [mLastName, setLastName] = useState()
    const history = useNavigate()
    const [mRequiredMark, setRequiredMark] = useState(true);
    const mBreakPoint0_1280 = useMediaQuery({ query: '(min-width: 0px) and (max-width: 1280px)' });


    const launageIndex = getLocaleInfo() ? localizationCountry.findIndex(item => item.languageCode === getLocaleInfo()) : 0
    const [mLanguageCountryIndex, setLanguageCountryIndex] = useState(launageIndex);
    const [mStateForRegButton, setStateForRegButton] = React.useState(false)
    const [mState, setState] = useState('');
    const [mIpAddr, setIpAddr] = useState('');
    const [mCountryIndex, setCountryIndex] = useState(0);

    const [mLoadingDataFromServer, setLoadingDataFromServer] = useState(false);
    const [showPassword, setShowPassword] = React.useState(false);
    const [referralCodeFromInput, setReferralCode] = useState('');
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
    const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);
    const handleMouseDownConfirmPassword = (event) => {
        event.preventDefault();
    };

    const styleClass = useStyles();
    const getCountryCodewithName = (countryName) => {
        const countryCode = Object.keys(countries).find(
            (code) => countries[code].name === countryName
        );
        return countryCode || "Unknown";
    }

    useEffect(() => {
        const lanuageCode = localizationCountry[mLanguageCountryIndex] ? localizationCountry[mLanguageCountryIndex].languageCode : 'en';
        storeLocaleInfo(lanuageCode)
        i18n.changeLanguage(lanuageCode);
    }, [i18n, mLanguageCountryIndex]);

    useEffect(() => {
        const getLocale = async () => {
            setLoadingDataFromServer(true)
            const result = await getGEOinfo_ipstack();
            setState(result.region_name ? result.region_name : ' ');
            setIpAddr(result.ip ? result.ip : ' ');
            setCountryIndex(getCountryCodewithName(result.country_name));
            setLoadingDataFromServer(false);
        }
        getLocale();
    }, [])

    useEffect(() => {
        const url = getRememberPath();
        if (url) {
            window.open(url, '_self');
            const delay = 1000;

            const timerId = setTimeout(() => {
                setShowPage(true);
            }, delay);

            return () => clearTimeout(timerId);
        } else {
            setShowPage(true);
        }
    }, []);

    const handleKeyPress = (event) => {

        const regex = /^[a-zA-Z0-9\b]+$/;
        if (!regex.test(event.key)) {
            event.preventDefault();
        }

        if (event && event.key === 'Enter') {
            event.preventDefault();
            handleChange(event);
        }
    };

    useEffect(() => {
        const mReferralCode = getReferralCode();
        if (mReferralCode) {
            setReferralCode(mReferralCode)
        }
    }, [])
    const handleChange = async (event) => {
        if (!mEmail || !mFirstName || !mLastName || !mPwd || !mConfirmPwd) {
            setRequiredMark(false)
            return
        }

        if (mPwd !== mConfirmPwd) {
            alertSZ(t('backend.password_do_not_match'));
            return false;
        }


        const mCountryName = countries[mCountryIndex].name;
        const langCode = localizationCountry[mLanguageCountryIndex] ? localizationCountry[mLanguageCountryIndex].languageCode : 'en';

        if (referralCodeFromInput) {
            if (/^[A-Z0-9]{6}$/.test(referralCodeFromInput)) {
                setIsValidFormat(true);
            } else {
                setIsValidFormat(false);
                alertSZ(t('signuppage.invalid_format_please_enter_6_alphanumeric_characters'));
                return
            }
        }

        let mReferralCode = '';
        if (!referralCodeFromInput) {
            mReferralCode = 'None';
        } else {
            mReferralCode = referralCodeFromInput;
        }
        
        setStateForRegButton(true)
        await axios.post(API_CR_SIGN_UP, {
            mEmail,
            mFirstName,
            mLastName,
            mCountryName,
            mPwd,
            mConfirmPwd,
            mState,
            mIpAddr,
            langCode,
            mReferralCode
        }).then(res => {
            const body = res.data;
            setStateForRegButton(false)
            if (res.status === 200) {
                alertSZ(t(`backend.${body}`))
                setEmailVerify(mEmail);
                removeReferralCode();
                history('/verifyemail');
            }
        }).catch(e => {
            setStateForRegButton(false)
            const text = e.response.data;
            alertSZ(t(`backend.${text}`))
            return;
        })

    }

    const [isValidFormat, setIsValidFormat] = useState(true);

    const handleInputChange = (e) => {
        const value = e.target.value.toUpperCase(); // Convert to uppercase
        if (/^[a-zA-Z0-9]{0,6}$/.test(value)) {
            setIsValidFormat(true);
            setReferralCode(value);
        } else {
            setIsValidFormat(false);
        }
    };
    return (
        <Box>
            <Helmet>
                <title>Sign up - Multifun</title>
            </Helmet>
            {!mBreakPoint0_1280 && showPage && (
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    backgroundColor: 'white',
                    overflow: 'hidden',
                    height: '100vh',
                }}>
                    <Box sx={{
                        width: '100%',
                        overflow: 'hidden',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        position: 'relative',
                        alignItems: 'center',
                        backgroundImage: 'url("/Images/Ellipse1.png")',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat'
                    }}>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '400px' }}>
                            <img src={process.env.PUBLIC_URL + '/Images/multifunlogo.svg'}
                                alt="logo"
                                style={{
                                    width: '250px',
                                    cursor: 'pointer'
                                }} onClick={() => history('/')} />
                            <Box sx={{
                                marginTop: '100px',
                                paddingLeft: '24px', paddingRight: '24px',
                                borderColor: 'rgba(0,0,0,0.1)',
                                borderWidth: '1px',
                                borderStyle: 'solid',
                                backgroundColor: 'rgba(255, 255, 255, 1)',
                                borderRadius: '8px',
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: 'center',
                                zIndex: '2'
                            }}>
                                <p style={{
                                    margin: 0,
                                    fontWeight: 500,
                                    fontSize: '25px',
                                    lineHeight: '36px',
                                    marginTop: '24px', fontFamily: theme.typography.fontFamily
                                }}
                                >
                                    <span style={{ color: 'black' }}>{t('homepage.signup')}</span>
                                </p>
                                <Box sx={{ marginTop: '24px', width: '100%' }}>
                                    <OutlinedInput
                                        placeholder={t('loginpage.email')}
                                        sx={{
                                            padding: 0,
                                            backgroundColor: "#FFFFFF",
                                            borderColor: 'black',
                                            border: 'border: 1px solid #B4B4B4',
                                            borderRadius: '8px', fontSize: '14px',
                                            fontWeight: '400',
                                            lineHeight: '21px',
                                            width: '100%',
                                            height: '45px'
                                        }}

                                        classes={{ input: styleClass.input }}
                                        startAdornment={
                                            <IoMailOutline style={{ fontSize: '25px', color: 'grey', marginLeft: '15px', marginRight: '15px' }} />
                                        }
                                        value={mEmail}
                                        onChange={(e) => setEmail(e.target.value.toLowerCase())}
                                        inputProps={{
                                            style: {
                                                color: 'rgba(0,0,0,1)',
                                                textTransform: 'lowercase',
                                            }
                                        }}
                                    />
                                    <Box sx={{ color: 'red' }}>
                                        {!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(mEmail) && !mRequiredMark && 'Invalid email'}
                                    </Box>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                                    <Box sx={{ width: '48%' }}>
                                        <OutlinedInput
                                            placeholder={t('signuppage.first_name')}
                                            sx={{ padding: 0, backgroundColor: "#FFFFFF", borderColor: 'black', border: 'border: 1px solid #B4B4B4', borderRadius: '8px', fontSize: '14px', fontWeight: '400', lineHeight: '21px', width: '100%', height: '45px' }}
                                            classes={{ input: styleClass.input }}
                                            startAdornment={
                                                <IoPersonOutline style={{ fontSize: '25px', color: 'grey', marginLeft: '15px', marginRight: '15px' }} />
                                            }

                                            value={mFirstName}
                                            onChange={(e) => setFirstName(e.target.value)}
                                        />
                                        <Box sx={{ color: 'red' }}>
                                            {!mFirstName && !mRequiredMark && 'required'}
                                        </Box>
                                    </Box>
                                    <Box sx={{ width: '48%' }}>
                                        <OutlinedInput
                                            placeholder={t('signuppage.last_name')}
                                            sx={{ padding: 0, backgroundColor: "#FFFFFF", borderColor: 'black', border: 'border: 1px solid #B4B4B4', borderRadius: '8px', fontSize: '14px', fontWeight: '400', lineHeight: '21px', width: '100%', height: '45px' }}
                                            classes={{ input: styleClass.input }}
                                            startAdornment={
                                                <IoPersonOutline style={{ fontSize: '25px', color: 'grey', marginLeft: '15px', marginRight: '15px' }} />
                                            }
                                            value={mLastName}
                                            onChange={(e) => setLastName(e.target.value)}
                                        />
                                        <Box sx={{ color: 'red' }}>
                                            {!mLastName && !mRequiredMark && 'required'}
                                        </Box>
                                    </Box>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'end', flexDirection: 'column', marginTop: '10px', width: '100%' }}>
                                    <Select
                                        value={mCountryIndex}
                                        onChange={(e) => setCountryIndex(e.target.value)}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        sx={{ height: '45px', boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 1, color: 'rgba(0,0,0,0.2)', borderRadius: '8px' }, width: '100%', '.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': { padding: '13px 14px' } }}

                                    >
                                        {countries && countries?.map((item, index) => (
                                            <MenuItem value={index} display={'flex'}>
                                                <Box sx={{ display: 'flex', justifyContent: 'start' }}>
                                                    <Box sx={{ marginRight: '5px', display: 'flex' }}>
                                                        <img width='35vh' src={item.flag} sx={{ marginRight: '10px' }} alt="" />
                                                    </Box>
                                                    <Box sx={{ marginLeft: '5px', display: 'flex' }}>
                                                        {item.name}
                                                    </Box>
                                                </Box>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center', marginTop: '10px', width: '100%' }}>
                                    <Select
                                        value={mLanguageCountryIndex}
                                        onChange={(e) => setLanguageCountryIndex(e.target.value)}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        sx={{ height: '45px', boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 1, color: 'rgba(0,0,0,0.2)', borderRadius: '8px' }, width: '100%', '.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': { padding: '13px 14px' } }}

                                    >
                                        {localizationCountry?.map((item, index) => (
                                            <MenuItem value={index} display={'flex'}>
                                                <Box sx={{ display: 'flex', justifyContent: 'start' }}>
                                                    <Box sx={{ marginRight: '5px', display: 'flex' }}>
                                                        <img
                                                            width={'30vw'}
                                                            src={countries?.find(ITM => ITM.name === item.countryName)?.flag} sx={{ marginRight: '10px' }}
                                                            alt=""
                                                        />
                                                    </Box>
                                                    <Box sx={{
                                                        marginLeft: '5px',
                                                        display: 'flex',
                                                        fontSize: '14px',
                                                        fontWeight: '300'
                                                    }}
                                                    >
                                                        {item.languageName} Language
                                                    </Box>
                                                </Box>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Box>
                                <Box sx={{ marginTop: '10px', width: '100%' }}>
                                    <OutlinedInput
                                        id="outlined-adornment-password"
                                        type={showPassword ? 'text' : 'password'}
                                        placeholder={t('signuppage.password')}
                                        classes={{ input: styleClass.input }}
                                        startAdornment={
                                            <RiLockPasswordLine style={{ fontSize: '25px', color: 'grey', marginLeft: '15px', marginRight: '15px' }} />
                                        }
                                        endAdornment={
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {showPassword ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                                            </IconButton>
                                        }
                                        sx={{ padding: 0, backgroundColor: "#FFFFFF", border: 'border: 1px solid #B4B4B4', borderRadius: '8px', fontSize: '14px', fontWeight: '400', lineHeight: '21px', width: '100%', height: '45px' }}
                                        value={mPwd}

                                        onChange={(e) => setPwd(e.target.value)}
                                    />
                                    <Box sx={{ color: 'red' }}>
                                        {!mPwd && !mRequiredMark && 'required'}
                                    </Box>
                                </Box>
                                <Box sx={{ marginTop: '10px', width: '100%' }}>
                                    <OutlinedInput
                                        id="outlined-adornment-password"
                                        type={showConfirmPassword ? 'text' : 'password'}
                                        placeholder={t('signuppage.confirm_password')}
                                        classes={{ input: styleClass.input }}
                                        startAdornment={
                                            <RiLockPasswordLine style={{ fontSize: '25px', color: 'grey', marginLeft: '15px', marginRight: '15px' }} />
                                        }
                                        endAdornment={
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowConfirmPassword}
                                                onMouseDown={handleMouseDownConfirmPassword}
                                            >
                                                {showConfirmPassword ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                                            </IconButton>
                                        }
                                        sx={{ padding: 0, backgroundColor: "#FFFFFF", border: 'border: 1px solid #B4B4B4', borderRadius: '8px', fontSize: '14px', fontWeight: '400', lineHeight: '21px', width: '100%', height: '45px' }}
                                        value={mConfirmPwd}
                                        onKeyPress={handleKeyPress}
                                        onChange={(e) => setConfirmPwd(e.target.value)}
                                    />
                                    <Box sx={{ color: 'red' }}>
                                        {!mConfirmPwd && !mRequiredMark && 'required'}
                                    </Box>
                                </Box>
                                <Box sx={{ marginTop: '10px', width: '100%', marginBottom: '10px', display: 'flex', flexDirection: 'row', alignItems: 'center  ' }}>
                                    <Accordion sx={{ border: 'none', width: '100%' }}>
                                        <AccordionSummary
                                            aria-controls="panel1-content"
                                            id="panel1-header"
                                        >
                                            <Typography style={{ color: 'grey', fontSize: '12px' }}>{t('signuppage.i_have_referral_code')}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <OutlinedInput
                                                type={'text'}
                                                placeholder={t('signuppage.referral_code')}
                                                classes={{ input: styleClass.input }}
                                                sx={{ padding: 0, backgroundColor: "#FFFFFF", border: 'border: 1px solid #B4B4B4', borderRadius: '8px', fontSize: '14px', fontWeight: '400', lineHeight: '21px', width: '100%', height: '45px' }}
                                                value={referralCodeFromInput}
                                                onKeyPress={handleKeyPress}
                                                onChange={handleInputChange}
                                                error={!isValidFormat}
                                                helperText={!isValidFormat && 'Invalid format. Please enter 6 alphanumeric characters.'}
                                            />
                                        </AccordionDetails>
                                    </Accordion>
                                </Box>
                                <Button
                                    type="button"
                                    fullWidth
                                    variant="contained"
                                    sx={{ marginTop: '10x', height: '45px', borderRadius: '8px', backgroundColor: 'rgba(58, 32, 109, 1)', textTransform: 'none' }}
                                    onClick={handleChange}
                                >{(mStateForRegButton ? <CircularProgress sx={{ color: 'white' }} /> : t('homepage.signup'))}</Button>
                                <Box sx={{ marginTop: '15px', marginBottom: '35px', display: 'flex', justifyContent: 'center' }}>
                                    <Link
                                        onClick={() => history('/signin')}
                                        variant="body2"
                                        style={{ cursor: 'pointer', textDecoration: 'none' }}
                                    >
                                        <p style={{ margin: 0, fontWeight: 500, fontSize: '12px', lineHeight: '18px', color: 'rgba(101, 101, 101, 1)' }}>
                                            {t('signuppage.already_have_account')}<span style={{ fontWeight: '500', color: 'black', lineHeight: '20px' }}> {t('loginpage.signin')}</span>
                                        </p>
                                    </Link>
                                </Box>
                                <Backdrop
                                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, opacity: '0.8 !important', backgroundColor: '#000000' }}
                                    open={mLoadingDataFromServer}
                                >
                                    <Box>
                                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                            <CircularProgress color={'warning'} size={'80px'} />
                                        </Box>
                                        <Box>
                                            <p style={{ margin: 0, marginTop: '20px', fontSize: '20px', fontWeight: 400, lineHeight: '30px', color: 'white', textAlign: 'center' }}>
                                                {t('custom.loading_data_please_wait')}...
                                            </p>
                                        </Box>
                                    </Box>
                                </Backdrop>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            )}
            {mBreakPoint0_1280 && showPage && (
                <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', backgroundColor: 'rgba(58, 32, 109, 1)', minHeight: '100vh', paddingLeft: '24px', paddingRight: '24px' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <img src={process.env.PUBLIC_URL + '/Images/multifunlogo.svg'} onClick={() => history('/')} style={{ width: '60%' }} alt="" />
                    </Box>
                    <Box sx={{ marginTop: '24px', paddingLeft: '24px', paddingRight: '24px', borderColor: 'rgba(0,0,0,0.1)', borderWidth: '1px', borderStyle: 'solid', backgroundColor: 'rgba(255, 255, 255, 1)', borderRadius: '8px', display: "flex", flexDirection: "column", alignItems: "center", justifyContent: 'center' }}>
                        <p style={{ margin: 0, fontWeight: 500, fontSize: '25px', lineHeight: '36px', marginTop: '24px', fontFamily: theme.typography.fontFamily }}>
                            <span style={{ color: 'black' }}>Sign up</span>
                        </p>
                        <Box sx={{ width: '100%' }}>
                            <Box sx={{ marginTop: '24px' }}>
                                <OutlinedInput
                                    placeholder="Email"
                                    sx={{ padding: 0, backgroundColor: "#FFFFFF", borderColor: 'black', border: 'border: 1px solid #B4B4B4', borderRadius: '8px', fontSize: '14px', fontWeight: '400', lineHeight: '21px', width: '100%', height: '45px' }}
                                    inputProps={{
                                        style: {
                                            color: 'rgba(0,0,0,1)', textTransform: 'lowercase'
                                        }
                                    }}
                                    startAdornment={
                                        <IconButton
                                            variant='outline'
                                            colorScheme='teal'
                                            aria-label='Send email'
                                        >
                                            <IoMailOutline />
                                        </IconButton>
                                    }
                                    value={mEmail}
                                    onChange={(e) => setEmail(e.target.value.toLowerCase())}
                                />
                                <Box sx={{ color: 'red' }}>
                                    {!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(mEmail) && !mRequiredMark && 'Invalid email'}
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                                <Box sx={{ width: '48%' }}>
                                    <OutlinedInput
                                        placeholder="First Name"
                                        sx={{ padding: 0, backgroundColor: "#FFFFFF", borderColor: 'black', border: 'border: 1px solid #B4B4B4', borderRadius: '8px', fontSize: '14px', fontWeight: '400', lineHeight: '21px', width: '100%', height: '45px' }}
                                        startAdornment={
                                            <IconButton
                                                variant='outline'
                                                colorScheme='teal'
                                                aria-label='Send email'
                                            >
                                                <IoPersonOutline />
                                            </IconButton>
                                        }

                                        value={mFirstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                    />
                                    <Box sx={{ color: 'red' }}>
                                        {!mFirstName && !mRequiredMark && 'required'}
                                    </Box>
                                </Box>
                                <Box sx={{ width: '48%' }}>
                                    <OutlinedInput
                                        placeholder="Last Name"
                                        sx={{ padding: 0, backgroundColor: "#FFFFFF", borderColor: 'black', border: 'border: 1px solid #B4B4B4', borderRadius: '8px', fontSize: '14px', fontWeight: '400', lineHeight: '21px', width: '100%', height: '45px' }}
                                        startAdornment={
                                            <IconButton
                                                variant='outline'
                                                colorScheme='teal'
                                                aria-label='Send email'
                                            >
                                                <IoPersonOutline />
                                            </IconButton>
                                        }
                                        value={mLastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                    />
                                    <Box sx={{ color: 'red' }}>
                                        {!mLastName && !mRequiredMark && 'required'}
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'end', flexDirection: 'column', marginTop: '10px', width: '100%' }}>
                                <Select
                                    value={mCountryIndex}
                                    onChange={(e) => setCountryIndex(e.target.value)}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    sx={{ height: '45px', boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 1, color: 'rgba(0,0,0,0.2)', borderRadius: '8px' }, width: '100%', '.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': { padding: '13px 14px' } }}

                                >
                                    {countries && countries?.map((item, index) => (
                                        <MenuItem value={index} display={'flex'}>
                                            <Box sx={{ display: 'flex', justifyContent: 'start' }}>
                                                <Box sx={{ marginRight: '5px', display: 'flex' }}>
                                                    <img width='35vh' src={item.flag} sx={{ marginRight: '10px' }} alt="" />
                                                </Box>
                                                <Box sx={{ marginLeft: '5px', display: 'flex' }}>
                                                    {item.name}
                                                </Box>
                                            </Box>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center', marginTop: '10px', width: '100%' }}>
                                <Select
                                    value={mLanguageCountryIndex}
                                    onChange={(e) => setLanguageCountryIndex(e.target.value)}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    sx={{ height: '45px', boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 1, color: 'rgba(0,0,0,0.2)', borderRadius: '8px' }, width: '100%', '.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': { padding: '13px 14px' } }}

                                >
                                    {localizationCountry?.map((item, index) => (
                                        <MenuItem value={index} display={'flex'}>
                                            <Box sx={{ display: 'flex', justifyContent: 'start' }}>
                                                <Box sx={{ marginRight: '5px', display: 'flex' }}>
                                                    <img
                                                        width={'30vw'}
                                                        src={countries?.find(ITM => ITM.name === item.countryName)?.flag} sx={{ marginRight: '10px' }}
                                                        alt=""
                                                    />
                                                </Box>
                                                <Box sx={{
                                                    marginLeft: '5px',
                                                    display: 'flex',
                                                    fontSize: '16px'
                                                }}
                                                >
                                                    {item.languageName} Language
                                                </Box>
                                            </Box>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Box>
                            <Box sx={{ marginTop: '10px' }}>
                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    type={showPassword ? 'text' : 'password'}
                                    placeholder="Password"
                                    startAdornment={
                                        <IconButton
                                            variant='outline'
                                            colorScheme='teal'
                                        >
                                            <RiLockPasswordLine />
                                        </IconButton>
                                    }
                                    endAdornment={
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {showPassword ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                                        </IconButton>
                                    }
                                    sx={{ padding: 0, backgroundColor: "#FFFFFF", border: 'border: 1px solid #B4B4B4', borderRadius: '8px', fontSize: '14px', fontWeight: '400', lineHeight: '21px', width: '100%', height: '45px' }}
                                    value={mPwd}

                                    onChange={(e) => setPwd(e.target.value)}
                                />
                                <Box sx={{ color: 'red' }}>
                                    {!mPwd && !mRequiredMark && 'required'}
                                </Box>
                            </Box>
                            <Box sx={{ marginTop: '10px' }}>
                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    placeholder="Confirm Password"
                                    startAdornment={
                                        <IconButton
                                            variant='outline'
                                            colorScheme='teal'
                                        >
                                            <RiLockPasswordLine />
                                        </IconButton>
                                    }
                                    endAdornment={
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowConfirmPassword}
                                            onMouseDown={handleMouseDownConfirmPassword}
                                        >
                                            {showConfirmPassword ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                                        </IconButton>
                                    }
                                    sx={{ padding: 0, backgroundColor: "#FFFFFF", border: 'border: 1px solid #B4B4B4', borderRadius: '8px', fontSize: '14px', fontWeight: '400', lineHeight: '21px', width: '100%', height: '45px' }}
                                    value={mConfirmPwd}
                                    onChange={(e) => setConfirmPwd(e.target.value)}
                                    onKeyPress={handleKeyPress}
                                />
                                <Box sx={{ color: 'red' }}>
                                    {!mConfirmPwd && !mRequiredMark && 'required'}
                                </Box>
                            </Box>
                            <Box sx={{ marginTop: '10px', width: '100%', marginBottom: '10px', display: 'flex', flexDirection: 'row', alignItems: 'center  ' }}>
                                <Accordion sx={{ border: 'none', width: '100%' }}>
                                    <AccordionSummary
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                    >
                                        <Typography style={{ color: 'grey', fontSize: '12px' }}>{t('signuppage.i_have_referral_code')}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <OutlinedInput
                                            type={'text'}
                                            placeholder={t('signuppage.referral_code')}
                                            classes={{ input: styleClass.input }}
                                            sx={{ padding: 0, backgroundColor: "#FFFFFF", border: 'border: 1px solid #B4B4B4', borderRadius: '8px', fontSize: '14px', fontWeight: '400', lineHeight: '21px', width: '100%', height: '45px' }}
                                            value={referralCodeFromInput}
                                            onKeyPress={handleKeyPress}
                                            onChange={handleInputChange}
                                            error={!isValidFormat}
                                            helperText={!isValidFormat && 'Invalid format. Please enter 6 alphanumeric characters.'}
                                        />
                                    </AccordionDetails>
                                </Accordion>
                            </Box>
                            <Button
                                type="button"
                                fullWidth
                                variant="contained"
                                sx={{ marginTop: '10x', height: '45px', borderRadius: '8px', backgroundColor: 'rgba(58, 32, 109, 1)', textTransform: 'none' }}
                                onClick={handleChange}
                            >{(mStateForRegButton ? <CircularProgress sx={{ color: 'white' }} /> : 'Sign up')}</Button>
                            <Box sx={{ marginTop: '15px', marginBottom: '35px', display: 'flex', justifyContent: 'center' }}>
                                <Link
                                    onClick={() => history('/signin')}
                                    variant="body2"
                                    style={{ cursor: 'pointer', textDecoration: 'none' }}
                                >
                                    <p style={{ margin: 0, fontWeight: 500, fontSize: '12px', lineHeight: '18px', color: 'rgba(101, 101, 101, 1)' }}>
                                        {t('signuppage.already_have_account')}<span style={{ fontWeight: '500', color: 'black', lineHeight: '20px' }}> {t('loginpage.signin')}</span>
                                    </p>
                                </Link>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            )
            }
        </Box >
    );
}

export default Signup;