import { Backdrop, Box, CircularProgress } from "@mui/material";
import React from 'react';
import { useMediaQuery } from 'react-responsive'
import { useTranslation } from "react-i18next";

const BackDrop = ({ open }) => {

    const { t } = useTranslation();
    const mBreakPoint1281_1366 = useMediaQuery({ query: '(min-width: 1281px) and (max-width: 1366px)' });

    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, opacity: '0.9 !important', backgroundColor: '#000000' }}
            open={open}
        >
            <Box>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress color={'warning'} size={'40px'} />
                </Box>
                <Box>
                    <p style={{
                        margin: 0,
                        marginTop: '20px',
                        fontSize: mBreakPoint1281_1366 ? '10px' : '16px',
                        fontWeight: 400,
                        lineHeight: '30px',
                        color: 'white',
                        textAlign: 'center',
                        userSelect: 'none'
                    }}>
                        {t('custom.loading_data_please_wait')}...
                    </p>
                </Box>
            </Box>
        </Backdrop>
    );
}

export default BackDrop;