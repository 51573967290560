import React, { useContext, useEffect, useState } from "react";
import { styled, Box, Button, Drawer, OutlinedInput, FormControl, Badge, IconButton, MenuItem, Divider, Menu, Typography, Select, Switch, FormControlLabel } from "@mui/material";
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { Outlet } from "react-router-dom";
import Icon from "../Icon";
import Sidebar from "./sidebar";
import { AuthContext } from "../../contexts/AuthContext";
import { useLocation, useNavigate } from "react-router-dom";
import { getStoredAuthToken, getRefreshAuthToken, storeAccessAuthToken, storeRememberPath, removeStoredAuthToken, storeLocaleInfo } from "../../utils/authToken";
import jwt_decode from "jwt-decode";
import axios from "axios";
import pusher from '../../utils/pusher';
import alertSZ from "../../utils/alert";
import { countries } from "country-flags-svg";
import { useMediaQuery } from 'react-responsive'
import SearchIcon from '@mui/icons-material/Search';
import Swal from "sweetalert2";
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next";
import { API_D_MARKASREADALL, API_D_NOTIFICATION, API_R_GETACCOUNT, API_R_GETNOTIFICATION, API_R_RECENT_CASHBACK, API_U_STATUS_REFERFRIEND } from "../../share/api";
import { useTheme } from '@mui/material/styles';

const useStyles = makeStyles({
    input: {
        WebkitBoxShadow: '0 0 0 1000px white inset',
    },
});

const Mainlayout = () => {

    const { isLogin } = useContext(AuthContext);
    const history = useNavigate();

    const theme = useTheme();
    const authtoken = getStoredAuthToken();
    const email = authtoken ? jwt_decode(authtoken)?.email : null;
    if (!isLogin || !email) {
        history('/signin');
    }

    const { t, i18n } = useTranslation();
    const [langCode, setLangCode] = useState('en');
    const { mSearchContextValue, setSearchContextValue } = useContext(AuthContext);
    const { mStatusAccountMode, setStatusAccountMode } = useContext(AuthContext);
    const { setSearchCountry } = useContext(AuthContext);
    const { setSearchHighestCashback } = useContext(AuthContext);
    const [cashbackIdx, setCashbackIdx] = React.useState(1);
    const mBreakPoint0_1280 = useMediaQuery({ query: '(min-width: 0px) and (max-width: 1280px)' });
    const mBreakPoint1281_1366 = useMediaQuery({ query: '(min-width: 1281px) and (max-width: 1768px)' });
    const { pathname } = useLocation();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [isShowCountrySearch, setIsShowCountrySearch] = useState(false);
    const [mNotificationData, setNotificationData] = useState(null);
    const [mUserCashbackThisMonth, setUserCashbackThisMonth] = useState(0);
    const [mUserRecentActivity, setUserRecentActivity] = useState(null);
    const [mUserFirstName, setUserFirstName] = useState(null);
    const [openDrawer, setOpenDrawer] = React.useState(false);

    const styleClass = useStyles();

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (event) => {
        if (mNotificationData && mNotificationData?.length > 0) {
            setAnchorEl(event.currentTarget);
        }
    };

    const handleNotificationBar = async (item, index) => {
        setNotificationData([...mNotificationData].map(object => {
            if (object === item) {
                return {
                    ...object,
                    readOrunread: true,
                }
            } else {
                return object;
            }
        }))

        const id = item._id;
        await axios.post(API_D_NOTIFICATION, { id })
    }

    const goPrevPage = () => {
        history(-1);
    }

    useEffect(() => {
        storeLocaleInfo(langCode)
        i18n.changeLanguage(langCode);
    }, [i18n, langCode]);

    useEffect(() => {
        if (isLogin && email) {

            const channel = pusher.subscribe('multifunChannel');

            channel.bind('AddData', async (data) => {
                try {
                    const response = await axios.post(API_R_GETNOTIFICATION, {
                        email
                    }, {
                        headers: {
                            Authorization: `Bearer ${getStoredAuthToken()} ${getRefreshAuthToken()}`,
                        },
                    });

                    const Tempbody = response?.data;
                    const body = Tempbody?.data;
                    const newAccessToken = Tempbody?.newAccessToken
                    if (newAccessToken !== undefined) {
                        storeAccessAuthToken(newAccessToken)
                    }

                    if (response.status === 208) {
                        alertSZ(t('backend.the_email_address_you_provided_does_not_exist'));
                        return;
                    }

                    if (response.status === 200) {
                        setNotificationData(body);
                        return;
                    }
                } catch (error) {
                    history('/signin')
                    return;
                }
            });

            return () => {
                channel.unbind('AddData');
                pusher.unsubscribe('multifunChannel');
            };
        }
    }, [email, history, isLogin, t])

    const getCountryCodewithName = (countryName) => {
        const countryCode = Object.keys(countries).find(
            (code) => countries[code].name === countryName
        );
        return countryCode || "Unknown";
    }

    useEffect(() => {
        if (isLogin && email) {
            storeRememberPath(window.location.href);
            if (pathname.toLocaleLowerCase() === '/getcashback') {
                setIsShowCountrySearch(true);
            } else {
                setIsShowCountrySearch(false);
            }

            const getUserNotification = async () => {
                try {
                    const response = await axios.post(API_R_GETNOTIFICATION, {
                        email
                    }, {
                        headers: {
                            Authorization: `Bearer ${getStoredAuthToken()} ${getRefreshAuthToken()}`,
                        },
                    });

                    const Tempbody = response?.data;
                    const body = Tempbody?.data;
                    const newAccessToken = Tempbody?.newAccessToken
                    if (newAccessToken !== undefined) {
                        storeAccessAuthToken(newAccessToken)
                    }

                    if (response.status === 200) {
                        setNotificationData(body);
                        return;
                    }
                } catch (error) {
                    await alertSZ(t('backend.session_expired_please_login_again'));
                    removeStoredAuthToken();
                    history(`/signin?redirecturl=${encodeURIComponent(window.location.href)}`);
                    return;
                }
            };

            const getData_Recent_Cashback = async () => {
                try {
                    const response = await axios.post(API_R_RECENT_CASHBACK, {
                        email,
                    });

                    const Tempbody = response?.data;
                    const newAccessToken = Tempbody?.newAccessToken;
                    if (newAccessToken !== undefined) {
                        storeAccessAuthToken(newAccessToken)
                    }
                    if (response.status === 200) {
                        setUserRecentActivity(Tempbody.recentActivity);
                        setUserCashbackThisMonth(Tempbody.cashbackThisMonth)
                        return;
                    }
                } catch (error) {
                    history('/signin')
                    return;
                }
            };

            const getUserAccount = async () => {
                await axios.post(API_R_GETACCOUNT, { email })
                    .then(res => {
                        const body = res.data;
                        if (res.status === 200) {
                            setSelCountry(parseInt(getCountryCodewithName(body?.countryName)));
                            setSearchCountry(parseInt(getCountryCodewithName(body?.countryName)));
                            setUserFirstName(body?.firstName);
                            setLangCode(body?.langCode);
                            return;
                        }
                    }).catch(e => {
                        history('/signin')
                        return
                    })
            };

            if (!mBreakPoint0_1280) {
                getData_Recent_Cashback();
            }
            getUserAccount();
            getUserNotification();
        }
    }, [pathname, email, history, setSearchCountry, mBreakPoint0_1280, t, isLogin]);

    const CashbackButton = styled(Button)({
        backgroundColor: 'rgba(39, 174, 96, 0.15)',
        borderRadius: '100px',
        color: '#27AE60',
        fontWeight: 700,
        alignItems: 'center',
        fontSize: mBreakPoint1281_1366 ? '11px' : '18px',
        fontStyle: 'normal',
        lineHeight: '140%',
        textTransform: 'none',
        padding: '10px  24px',
        '&:hover': {
            backgroundColor: 'rgba(39, 174, 96, 0.2)',
        },
        letterSpacing: '0.1px'
    });
    const ReferFriendButton = styled(Button)({
        borderRadius: '100px',
        backgroundColor: 'rgba(206,183,231,0.15)',
        color: '#6C3D9E',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: mBreakPoint1281_1366 ? '9px' : '12px',
        lineHeight: '140%',
        '&:hover': {
            backgroundColor: 'rgba(108, 61, 158, 0.2)',
        },
    });

    const handleDrawerOpen = () => {
        setOpenDrawer(true);
    }

    const handleCancelOpen = () => {
        setOpenDrawer(false);
    }

    const handleChangeCashback = (e) => {
        setCashbackIdx(e.target.value);
        setSearchHighestCashback(e.target.value)
    };
    const [selCountry, setSelCountry] = React.useState(0);

    const [mCurrentSession, setCurrentSession] = useState('');
    const handleChangeSelCountry = (e) => {
        if (mCurrentSession !== getRefreshAuthToken()) {
            Swal.fire({
                title: t('mainlayout.alert'),
                text: t('mainlayout.chaning_to_another_country'),
                icon: t('mainlayout.question'),
                confirmButtonText: t('mainlayout.i_understand'),
            }).then((result) => {
                if (result.isConfirmed) {
                    setCurrentSession(getRefreshAuthToken());
                    setSelCountry(e.target.value);
                    setSearchCountry(parseInt(e.target.value))
                    return;

                }
            });
        } else {
            setSelCountry(e.target.value);
            setSearchCountry(parseInt(e.target.value))
        }
    };

    const SelectSaveButton = styled(Button)({
        height: mBreakPoint1281_1366 ? '20%' : '30%',
        color: '#6C3D9E',
        width: '90%',
        fontSize: mBreakPoint1281_1366 ? '9px' : '14px',
        fontWeight: 600,
        letterSpacing: '0.5px',
        textTransform: 'none',
        borderRadius: '16px',
        backgroundColor: 'rgba(50.2, 50.2, 50.2, 0.2)',
        '&.inactive': {
            backgroundColor: 'rgba(50.2, 50.2, 50.2, 0.05)'
        },

    });

    const handleReadAll = async () => {

        await axios.post(API_D_MARKASREADALL, {
            email,
        }).then(async (res) => {
            setAnchorEl(null)
            const body = res.data;
            if (res.status === 208) {
                alertSZ(t(`backend.${body}`))
                return;
            }

            if (res.status === 200) {
                try {
                    const response = await axios.post(API_R_GETNOTIFICATION, {
                        email
                    }, {
                        headers: {
                            Authorization: `Bearer ${getStoredAuthToken()} ${getRefreshAuthToken()}`,
                        },
                    });

                    const Tempbody = response?.data;
                    const body = Tempbody?.data;
                    const newAccessToken = Tempbody?.newAccessToken
                    if (newAccessToken !== undefined) {
                        storeAccessAuthToken(newAccessToken)
                    }
                    if (response.status === 200) {
                        setNotificationData(body);
                        return;
                    }
                } catch (error) {
                    history('/signin')
                    return;
                }
                return;
            }
        }).catch(e => {
            return
        })
    }


    const handleReferAndfriend = async () => {
        Swal.fire({
            title: t('mainlayout.refer_a_friend'),
            text: t('mainlayout.referfriendtext'),
            icon: t('mainlayout.question'),
            showCancelButton: true,
            confirmButtonText: t('mainlayout.referagree'),
            cancelButtonText: t('mainlayout.refernot')
        }).then(async (result) => {
            if (result.isConfirmed) {

                const mYesorNo = true;
                await axios.post(API_U_STATUS_REFERFRIEND, { email, mYesorNo });

            } else if (result.dismiss === Swal.DismissReason.cancel) {

                const mYesorNo = false;
                await axios.post(API_U_STATUS_REFERFRIEND, { email, mYesorNo });

            }
        });
    }

    const MaterialUISwitch = styled(Switch)(({ theme }) => ({
        width: 62,
        height: 34,
        padding: 7,
        '& .MuiSwitch-switchBase': {
            margin: 1,
            padding: 0,
            transform: 'translateX(6px)',
            '&.Mui-checked': {
                color: '#fff',
                transform: 'translateX(22px)',
                '& .MuiSwitch-thumb:before': {
                },
                '& + .MuiSwitch-track': {
                    opacity: 1,
                    backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : 'grey',
                },
            },
        },
        '& .MuiSwitch-thumb': {
            backgroundColor: theme.palette.mode === 'dark' ? 'white' : 'rgba(99,57,143,1)',
            width: 32,
            height: 32,
            '&::before': {
                content: "''",
                position: 'absolute',
                width: '100%',
                height: '100%',
                left: 0,
                top: 0,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
            },
        },
        '& .MuiSwitch-track': {
            opacity: 1,
            backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : 'grey',
            borderRadius: 20 / 2,
        },
    }));

    const handleSwitchChange = (event) => {
        if (event.target.checked) {
            setStatusAccountMode(true);
        } else {
            setStatusAccountMode(false);
        }
    };
    return (
        <Box height={'100vh'} >
            {!mBreakPoint0_1280 && (
                <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'row', backgroundColor: 'rgba(250, 250, 250, 0.7)', overflow: 'hidden' }}>
                    <Drawer
                        sx={{
                            width: '15%',
                            flexShrink: 0,
                            '& .MuiDrawer-paper': {
                                width: '15%',
                                boxSizing: 'border-box',
                            },
                        }}
                        variant="persistent"
                        anchor="left"
                        open={true}
                    >
                        <Sidebar handleCancelOpen={handleCancelOpen} />
                    </Drawer>
                    <Box sx={{ width: '68%' }}>
                        <Box sx={{ height: '8%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingLeft: '18px', paddingRight: '15px' }}>
                            <Button startIcon={
                                <Icon name='arrow-left' />
                            }
                                sx={{ padding: 0, width: '24px', maxWidth: '24px', minWidth: '24px' }}
                                onClick={() => goPrevPage()}
                            />
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '67%', }}>
                                {isShowCountrySearch && (
                                    <Box sx={{ width: '33%', backgroundColor: 'white' }}>
                                        <FormControl fullWidth backgroundColor={'white'} overflow={'auto'}>
                                            <Select
                                                value={selCountry}
                                                onChange={handleChangeSelCountry}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                                sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 1, color: 'rgba(0,0,0,0.2)', borderRadius: mBreakPoint1281_1366 ? '5px' : '8px' }, width: '100%', '.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': { padding: '13px 14px' }, height: mBreakPoint1281_1366 ? '30px' : '35px' }}

                                            >
                                                {countries && countries?.map((item, index) => (
                                                    <MenuItem value={index} display={'flex'} alignItems={'center'}>
                                                        <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                                                            <Box sx={{ display: 'flex', marginRight: '5px', paddingLeft: '5px' }}>
                                                                <img width={mBreakPoint1281_1366 ? '20vh' : "25vh"} src={item.flag} sx={{ marginRight: '10px' }} alt="" />
                                                            </Box>
                                                            <Box sx={{ display: 'flex', marginLeft: '5px', fontSize: mBreakPoint1281_1366 ? '9px' : '12px' }}>
                                                                {item.name}
                                                            </Box>
                                                        </Box>
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                )}
                                {isShowCountrySearch && (
                                    <Box sx={{ width: '33%', backgroundColor: 'white' }}>
                                        <FormControl fullWidth backgroundColor={'white'}>
                                            <Select
                                                value={cashbackIdx}
                                                onChange={handleChangeCashback}
                                                displayEmpty
                                                inputProps={{ 'aria-label': 'Without label' }}
                                                sx={{ borderRadius: '8px', boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 1, color: 'rgba(0,0,0,0.2)', borderRadius: mBreakPoint1281_1366 ? '5px' : '8px' }, width: '100%', '.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': { padding: '13px 14px' }, height: mBreakPoint1281_1366 ? '30px' : '35px' }}
                                            >
                                                <MenuItem value={1}>
                                                    <Box sx={{ display: 'flex', justifyContent: 'start', paddingLeft: '5px', fontSize: mBreakPoint1281_1366 ? '9px' : '12px' }}>
                                                        {t('mainlayout.highest_cashback')}
                                                    </Box>
                                                </MenuItem>
                                                <MenuItem value={2}>
                                                    <Box sx={{ display: 'flex', justifyContent: 'start', paddingLeft: '5px', fontSize: mBreakPoint1281_1366 ? '9px' : '12px' }}>
                                                        {t('mainlayout.lowest_cashback')}
                                                    </Box>
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Box>
                                )}
                                {isShowCountrySearch && (
                                    <OutlinedInput
                                        placeholder={t('mainlayout.search')}
                                        autoFocus={false}
                                        sx={{
                                            width: '33%',
                                            fontStyle: 'normal',
                                            fontWeight: 400,
                                            fontSize: mBreakPoint1281_1366 ? '10px' : '12px',
                                            lineHeight: '140%',
                                            letterSpacing: '-0.05px',
                                            color: 'rgba(24, 24, 25, 0.8)',
                                            border: '1px solid rgba(0, 0, 0, 0.03)',
                                            boxShadow: '20px 20px 80px rgba(0, 0, 0, 0.05)',
                                            height: mBreakPoint1281_1366 ? '30px' : '35px',
                                            borderRadius: mBreakPoint1281_1366 ? '5px' : '8px',
                                            backgroundColor: 'white',
                                            '.css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input': {
                                                padding: 0
                                            },
                                            '.css-1ixds2g': {
                                                padding: 0
                                            }
                                        }}
                                        classes={{ input: styleClass.input }}
                                        startAdornment={
                                            <SearchIcon style={{ fontSize: mBreakPoint1281_1366 ? '20px' : '25px', color: 'rgba(0,0,0,0.7)', marginRight: '10px' }} />
                                        }
                                        value={mSearchContextValue}
                                        onChange={(e) => {
                                            setSearchContextValue(e.target.value)
                                        }}
                                    />
                                )}
                            </Box>
                            {!isShowCountrySearch && !(pathname.split('/')[1].toLocaleLowerCase() === 'dashboard' || pathname.split('/')[1].toLocaleLowerCase() === 'help' || pathname.split('/')[1].toLocaleLowerCase() === 'reviewdashboard' || pathname.split('/')[1].toLocaleLowerCase() === 'payments' || pathname.split('/')[1].toLocaleLowerCase() === 'account-setting' || pathname.toLocaleLowerCase() === '/getcashback/register-to') && (
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <FormControlLabel
                                        control={<MaterialUISwitch checked={mStatusAccountMode} onClick={(e) => handleSwitchChange(e)} />}
                                        labelPlacement="start"
                                        sx={{
                                            '.MuiFormControlLabel-label': {
                                                fontWeight: '400',
                                                color: 'rgba(24, 24, 25, 0.9)',
                                                fontSize: '12px'
                                            },
                                            m: 1
                                        }}
                                        label={mStatusAccountMode ? t('youraccount.switch_to_casinos') : t('youraccount.switch_to_refer')}
                                    />
                                    <OutlinedInput
                                        placeholder={t('mainlayout.search')}
                                        autoFocus={false}
                                        sx={{
                                            width: '200px',
                                            fontStyle: 'normal',
                                            fontWeight: 400,
                                            fontSize: mBreakPoint1281_1366 ? '11px' : '14px',
                                            lineHeight: '140%',
                                            letterSpacing: '-0.05px',
                                            color: 'rgba(24, 24, 25, 0.8)',
                                            border: '1px solid rgba(0, 0, 0, 0.03)',
                                            boxShadow: '20px 20px 80px rgba(0, 0, 0, 0.05)',
                                            height: mBreakPoint1281_1366 ? '30px' : '40px',
                                            borderRadius: mBreakPoint1281_1366 ? '5px' : '8px',
                                            backgroundColor: 'white',
                                            '.css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input': {
                                                padding: 0
                                            },
                                            '.css-1ixds2g': {
                                                padding: 0
                                            }
                                        }}
                                        classes={{ input: styleClass.input }}
                                        startAdornment={
                                            <SearchIcon style={{ fontSize: mBreakPoint1281_1366 ? '20px' : '25px', color: 'rgba(0,0,0,0.7)', marginRight: '10px' }} />
                                        }
                                        value={mSearchContextValue}
                                        onChange={(e) => {
                                            setSearchContextValue(e.target.value)
                                        }}
                                    />
                                </Box>

                            )}
                        </Box>
                        <Outlet />
                    </Box>
                    <Box sx={{ width: '17%', backgroundColor: 'rgba(236,236,243,1)', paddingLeft: '18px', paddingRight: '18px' }}>
                        <Box sx={{ height: mBreakPoint1281_1366 ? '7%' : '8%', display: 'flex', justifyContent: 'space-between' }}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <p style={{ fontStyle: 'normal', fontSize: mBreakPoint1281_1366 ? '9px' : '12px', lineHeight: '140%', letterSpacing: '0.1px', color: 'rgba(24, 24, 25, 0.9)', fontWeight: 400, margin: 0, fontFamily: theme.typography.fontFamily }}>{t('mainlayout.hello')} {mUserFirstName}</p>
                            </Box>
                            <Box sx={{ marginTop: 0, display: 'flex', alignItems: 'center' }}>
                                <IconButton aria-label="notification" variant="dot" sx={{ height: '30%', margin: 0, padding: 0, marginRight: '5px' }}
                                    onClick={handleClick}
                                >
                                    <Badge variant={mNotificationData?.some(item => item.readOrunread === false) ? 'dot' : "standard"} color="secondary" >
                                        <NotificationsNoneIcon color="action" style={{ fontSize: mBreakPoint1281_1366 ? "16px" : '22px' }} />
                                    </Badge>
                                </IconButton>
                                <Menu
                                    anchorEl={anchorEl}
                                    id="Notification"
                                    open={open}
                                    onClose={handleClose}
                                    PaperProps={{
                                        elevation: 0,
                                        sx: {
                                            borderRadius: '8px',
                                            width: mBreakPoint1281_1366 ? '250px' : '350px',
                                            maxHeight: '600px',
                                            overflow: 'enable',
                                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                            mt: 1.5,
                                            '& .MuiAvatar-root': {
                                                width: 32,
                                                height: 32,
                                                ml: -0.5,
                                                mr: 1,
                                            },
                                            '&:before': {
                                                content: '""',
                                                display: 'block',
                                                position: 'absolute',
                                                top: 0,
                                                right: 14,
                                                width: 10,
                                                height: 10,
                                                bgcolor: 'background.paper',
                                                transform: 'translateY(-50%) rotate(45deg)',
                                                zIndex: 0,
                                            },
                                        },
                                    }}
                                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                >

                                    {mNotificationData?.map((item, index) => (
                                        <Box sx={{ backgroundColor: item.readOrunread ? 'white' : 'off-white', borderRadius: item.readOrunread ? '8px' : '0', boxShadow: item.readOrunread ? '6px 6px 50px rgba(0, 0, 0, 0.05)' : 0 }}>
                                            <MenuItem onClick={() => handleNotificationBar(item, index)} sx={{
                                                width: 'auto',
                                                whiteSpace: 'pre-wrap',
                                                wordWrap: 'break-word',
                                            }}>
                                                <Typography variant="body2">
                                                    <p style={{ fontWeight: 500, fontSize: mBreakPoint1281_1366 ? '9px' : '12px', color: '#181818' }}>{`${item.regDate.split('T')[0]} ${item.regDate.split('T')[1].slice(0, 8)}`}</p>
                                                    <p style={{ fontWeight: 600, fontSize: mBreakPoint1281_1366 ? '13px' : '14px', color: '#181818' }}>{t(`backend.${item.data.Title}`).replaceAll('{BrandName}', item.brandName).replaceAll('{cashback}', item.cashback)}</p>
                                                    <p style={{ fontWeight: 400, fontSize: mBreakPoint1281_1366 ? '9px' : '12px', color: '#181818' }}>{t(`backend.${item.data.Message}`).replaceAll('{BrandName}', item.brandName).replaceAll('{cashback}', item.cashback)}</p>
                                                </Typography>
                                            </MenuItem>
                                            <Divider />
                                        </Box>
                                    ))}
                                    {mNotificationData?.length > 0 && (
                                        <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                            <SelectSaveButton
                                                onClick={(e) => handleReadAll()}
                                            >{t('mainlayout.mark_as_read_all')}</SelectSaveButton>
                                        </Box>
                                    )}
                                </Menu>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '17%', backgroundColor: 'white', borderRadius: '5px', boxShadow: '6px 6px 50px rgba(0, 0, 0, 0.05)' }}>
                            <Box sx={{ height: '30%', paddingLeft: '10px', paddingRight: '10px', display: 'flex', justifyContent: 'center' }}>
                                <CashbackButton sx={{ width: '100%', marginTop: '15px' }}>
                                    {t('dashboard.cashback')}
                                </CashbackButton>
                            </Box>
                            <Box sx={{ height: '40%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <p style={{ fontWeight: 500, fontSize: mBreakPoint1281_1366 ? '26px' : '36px', color: 'rgba(0,0,0,1)', margin: 0, marginTop: '16px', letterSpacing: '0.1px', fontFamily: theme.typography.fontFamily }}>€{mUserCashbackThisMonth.toFixed(2)}</p>
                            </Box>
                            <Box sx={{ height: '30%', display: 'flex', justifyContent: 'center' }}>
                                <p style={{ fontWeight: 400, fontSize: mBreakPoint1281_1366 ? '8px' : '12px', color: '#181818', margin: 0, marginTop: '15px', letterSpacing: '0.1px', fontFamily: theme.typography.fontFamily }}>{t('mainlayout.this_month')}</p>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '4%' }}>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '18%', backgroundColor: 'white', borderRadius: '5px', boxShadow: '6px 6px 50px rgba(0, 0, 0, 0.05)' }}>
                            <Box sx={{ height: '30%', paddingRight: '10px', paddingLeft: '10px', display: 'flex', justifyContent: 'center' }}>
                                <ReferFriendButton sx={{ width: '100%', marginTop: '13px', textTransform: 'none' }}
                                    onClick={() => handleReferAndfriend()}
                                >{t('mainlayout.refer_friend_and_earn')}</ReferFriendButton>
                            </Box>
                            <Box sx={{ height: '40%', display: 'flex', justifyContent: 'center' }}>
                                <p style={{ margin: 0, marginTop: '15px', color: 'rgba(108, 61, 158, 1)', fontWeight: 500, fontSize: mBreakPoint1281_1366 ? '24px' : '34px', padding: 0, fontFamily: theme.typography.fontFamily }}>€25</p>
                            </Box>
                            <Box sx={{ height: '30%', display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                                <p style={{ margin: 0, marginTop: '15px', color: '#181818', fontSize: mBreakPoint1281_1366 ? '9px' : '12px', fontWeight: 400, fontFamily: theme.typography.fontFamily }}>{t('mainlayout.for_each_referral')}</p>
                            </Box>
                        </Box>
                        <Box sx={{ height: '55%' }}>
                            <Box sx={{ height: '10%', display: 'flex', justifyContent: 'space-between' }}>
                            </Box>
                            <Box sx={{ height: '90%', backgroundColor: 'white', borderRadius: '5px', boxShadow: '6px 6px 50px rgba(0, 0, 0, 0.05)', overflow: 'none' }}>
                                <Box sx={{ height: '11%', paddingRight: '10px', paddingLeft: '10px', display: 'flex', justifyContent: 'center' }}>
                                    <ReferFriendButton sx={{ width: '100%', marginTop: '13px', textTransform: 'none' }}>{t('dashboard.recent_activity')}</ReferFriendButton>
                                </Box>
                                <Box sx={{ marginTop: '10px', height: '84%', paddingLeft: '10px', paddingRight: '10px' }}>
                                    {mUserRecentActivity?.map((item, idx) => (
                                        <div style={{ marginBottom: '15px' }}>
                                            {item?.type === true && (
                                                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                                                    <p style={{ fontWeight: 400, letterSpacing: '-0.3px', color: 'green', fontSize: mBreakPoint1281_1366 ? '9px' : '12px', margin: 0, marginLeft: '5px', fontFamily: theme.typography.fontFamily }}>{`+€${item.Cashback}`}</p>
                                                    <p style={{ fontWeight: 400, letterSpacing: '-0.3px', color: 'rgba(0,0,0,0.53)', fontSize: mBreakPoint1281_1366 ? '9px' : '12px', margin: 0, marginLeft: '5px', fontFamily: theme.typography.fontFamily }}> {t('mainlayout.from')} {item.BrandName}</p>
                                                </Box>
                                            )}
                                            {item?.type === false && item.BrandName === 'referralcashback' && (
                                                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                                                    <p style={{ fontWeight: 400, letterSpacing: '-0.3px', color: 'green', fontSize: mBreakPoint1281_1366 ? '9px' : '12px', margin: 0, marginLeft: '5px', fontFamily: theme.typography.fontFamily }}>+€{item.Cashback}</p>
                                                    <p style={{ fontWeight: 400, letterSpacing: '-0.3px', color: 'rgba(0,0,0,0.53)', fontSize: mBreakPoint1281_1366 ? '9px' : '12px', margin: 0, marginLeft: '5px', fontFamily: theme.typography.fontFamily }}>{t('mainlayout.referral_cashback_from')} {item.PayloadID}</p>
                                                </Box>
                                            )}
                                            {item?.type === false && item.BrandName !== 'referralcashback' && (
                                                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                                                    <p style={{ fontWeight: 400, letterSpacing: '-0.3px', color: 'red', fontSize: mBreakPoint1281_1366 ? '9px' : '12px', margin: 0, marginLeft: '5px', fontFamily: theme.typography.fontFamily }}>-€{item.Cashback * 98 / 100}</p>
                                                    <p style={{ fontWeight: 400, letterSpacing: '-0.3px', color: 'rgba(0,0,0,0.53)', fontSize: mBreakPoint1281_1366 ? '9px' : '12px', margin: 0, marginLeft: '5px', fontFamily: theme.typography.fontFamily }}>{t('mainlayout.withdrawal_to')} {parseInt(item?.PayloadID) === 1 ? 'Neteller' : parseInt(item?.PayloadID) === 2 ? 'Skrill' : parseInt(item?.PayloadID) === 3 ? 'USDT ERC20' : parseInt(item?.PayloadID) === 4 ? 'USDT TRC20' : parseInt(item?.PayloadID) === 5 ? 'Bitcoin' : parseInt(item?.PayloadID) === 6 ? 'Ethereum' : ''}</p>
                                                </Box>
                                            )}
                                        </div>
                                    ))}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            )}
            {mBreakPoint0_1280 && (
                <Box>
                    <Drawer
                        sx={{
                            width: '100%',
                            flexShrink: 0,
                            '& .MuiDrawer-paper': {
                                width: '100%',
                                boxSizing: 'border-box',
                                borderRight: 'none',
                            },
                        }}
                        variant="persistent"
                        anchor="left"
                        open={openDrawer}
                    >
                        <Sidebar handleCancelOpen={handleCancelOpen} />
                    </Drawer>
                    <Box sx={{ p: 2, backgroundColor: 'white', borderBottom: '1px solid rgba(24, 24, 24, 0.1)' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '10px' }}>
                            <Box>
                                <Button startIcon={<Icon name='menu' color='black' />}
                                    onClick={handleDrawerOpen}
                                    sx={{ padding: 0, width: '24px', maxWidth: '24px', minWidth: '24px', marginRight: '24px' }}
                                />
                                <p style={{ display: 'inline', fontSize: '13px', lineHeight: '16.8px', fontWeight: 400, marginRight: '25px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontFamily: theme.typography.fontFamily }}>{t('mainlayout.hello')} {mUserFirstName}</p>
                            </Box>
                            <Box>
                                <IconButton aria-label="notification" variant="dot"
                                    onClick={handleClick}
                                >
                                    <Badge variant={mNotificationData?.some(item => item.readOrunread === false) ? 'dot' : "standard"} color="secondary">
                                        <NotificationsNoneIcon color="action" />
                                    </Badge>
                                </IconButton>
                                <Menu
                                    anchorEl={anchorEl}
                                    id="Notification"
                                    open={open}
                                    onClose={handleClose}
                                    PaperProps={{
                                        elevation: 0,
                                        sx: {
                                            borderRadius: '8px',
                                            width: '400px',
                                            maxHeight: '600px',
                                            overflow: 'enable',
                                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                            mt: 1.5,
                                            '& .MuiAvatar-root': {
                                                width: 32,
                                                height: 32,
                                                ml: -0.5,
                                                mr: 1,
                                            },
                                            '&:before': {
                                                content: '""',
                                                display: 'block',
                                                position: 'absolute',
                                                top: 0,
                                                right: 14,
                                                width: 10,
                                                height: 10,
                                                bgcolor: 'background.paper',
                                                transform: 'translateY(-50%) rotate(45deg)',
                                                zIndex: 0,
                                            },
                                        },
                                    }}
                                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                >
                                    {mNotificationData?.map((item, index) => (
                                        <Box sx={{ backgroundColor: item.readOrunread ? 'white' : 'off-white', borderRadius: item.readOrunread ? '8px' : '0', boxShadow: item.readOrunread ? '6px 6px 50px rgba(0, 0, 0, 0.05)' : 0 }}>
                                            <MenuItem onClick={() => handleNotificationBar(item, index)} sx={{
                                                width: 'auto',
                                                whiteSpace: 'pre-wrap',
                                                wordWrap: 'break-word',
                                            }}>
                                                <Typography variant="body2">
                                                    <p style={{ fontWeight: 600, fontSize: 12, color: '#181818' }}>{`${item.regDate.split('T')[0]} ${item.regDate.split('T')[1].slice(0, 8)}`}</p>
                                                    <p style={{ fontWeight: 600, fontSize: 16, color: '#181818' }}>{t(`backend.${item.data.Title}`).replaceAll('{BrandName}', item.brandName).replaceAll('{cashback}', item.cashback)}</p>
                                                    <p style={{ fontWeight: 400, fontSize: 12, color: '#181818' }}>{t(`backend.${item.data.Message}`).replaceAll('{BrandName}', item.brandName).replaceAll('{cashback}', item.cashback)}</p>
                                                </Typography>
                                            </MenuItem>
                                            <Divider />
                                        </Box>
                                    ))}
                                    {mNotificationData?.length > 0 && (
                                        <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                            <SelectSaveButton
                                                onClick={(e) => handleReadAll()}
                                            >{t('mainlayout.mark_as_read_all')}</SelectSaveButton>
                                        </Box>
                                    )}

                                </Menu>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            {!(pathname.split('/')[1].toLocaleLowerCase() === 'dashboard' || pathname.split('/')[1].toLocaleLowerCase() === 'help' || pathname.split('/')[1].toLocaleLowerCase() === 'payments' || pathname.split('/')[1].toLocaleLowerCase() === 'reviewdashboard' || pathname.split('/')[1].toLocaleLowerCase() === 'account-setting' || pathname.toLocaleLowerCase() === '/getcashback/register-to') && (
                                <Box>
                                    <FormControlLabel
                                        control={<MaterialUISwitch checked={mStatusAccountMode} onClick={(e) => handleSwitchChange(e)} />}
                                        labelPlacement="start"
                                        sx={{
                                            '.MuiFormControlLabel-label': {
                                                fontWeight: '400',
                                                color: 'rgba(24, 24, 25, 0.9)',
                                                fontSize: '12px'
                                            },
                                            m: 1
                                        }}
                                        label={mStatusAccountMode ? t('youraccount.switch_to_casinos') : t('youraccount.switch_to_refer')}
                                    />
                                    <OutlinedInput
                                        placeholder={t('mainlayout.search')}
                                        sx={{
                                            fontStyle: 'normal',
                                            fontWeight: 400,
                                            fontSize: '14px',
                                            lineHeight: '140%',
                                            letterSpacing: '-0.05px',
                                            color: 'rgba(24, 24, 25, 0.8)',
                                            border: '1px solid rgba(0, 0, 0, 0.03)',
                                            boxShadow: '20px 20px 80px rgba(0, 0, 0, 0.05)',
                                            height: '40px',
                                            borderRadius: '8px',
                                            backgroundColor: 'white',
                                            width: '100%',
                                            '.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
                                                padding: '0',
                                                paddingLeft: '20px'
                                            }
                                        }}
                                        classes={{ input: styleClass.input }}
                                        endAdornment={
                                            <SearchIcon style={{ fontSize: '25px', color: 'rgba(0,0,0,0.7)', marginRight: '5px' }} />
                                        }
                                        value={mSearchContextValue}
                                        onChange={(e) => setSearchContextValue(e.target.value)}
                                    />
                                </Box>
                            )}
                        </Box>
                    </Box>
                    <Outlet />
                </Box>
            )
            }
        </Box >
    );
};

export default Mainlayout;