import React, { useState } from 'react';
//import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { Typography } from '@mui/material/';
import InfoIcon from '@mui/icons-material/Info';

// const ShiningSyncProblemIcon = styled(SyncProblemIcon)({
//   animation: 'shining 1.5s infinite',
//   '@keyframes shining': {
//     '0%': {
//       color: 'black',
//     },
//     '50%': {
//       color: '#ffc500',
//     },
//     '100%': {
//       color: 'black',
//     },
//   },
// });

const ShiningInfoIcon = styled(InfoIcon)({
  animation: 'shining 1.5s infinite',
  '@keyframes shining': {
    '0%': {
      color: 'black',
    },
    '50%': {
      color: '#ffc500',
    },
    '100%': {
      color: 'black',
    },
  },
});

const MobileTooltip = ({ title, flag }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleClickAway = () => {
    setIsOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Tooltip
        title={
          <Typography variant="body2" sx={{ fontSize: '10px' }}>
            {title}
          </Typography>
        }

        open={isOpen}
        placement="top"
        arrow
      >

        <span style={{ marginLeft: flag === 1 ? '0' : '5px', zIndex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#ffc500', width: flag === 1 ? '15px' : '20px', height: flag === 1 ? '15px' : '20px', borderRadius: '50%', cursor: 'pointer', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)', pointerEvents: 'auto' }} onClick={handleToggle}>
          {flag === 1 ? <ShiningInfoIcon style={{ fontSize: '15px' }} /> : flag === 2 ? <ShiningInfoIcon style={{ fontSize: '16px' }} /> : <></>}
        </span>
      </Tooltip>
    </ClickAwayListener>
  );
};


export default MobileTooltip;