export const getStoredAuthToken = () => localStorage.getItem('accessToken');
export const getRefreshAuthToken = () => localStorage.getItem('refreshToken');
export const getRememberPath = () => localStorage.getItem('path');

export const storeAccessAuthToken = token => localStorage.setItem('accessToken', token);
export const storeRefreshAuthToken = token => localStorage.setItem('refreshToken', token);
export const storeRememberPath = token => localStorage.setItem('path', token);

export const removeStoredAuthToken = () => { localStorage.removeItem('accessToken'); localStorage.removeItem('refreshToken'); localStorage.removeItem('path'); }
export const removeRememberPath = () => { localStorage.removeItem('path'); }

export const storeLocaleInfo = locale => localStorage.setItem('localeInfo', locale);
export const getLocaleInfo = () => localStorage.getItem('localeInfo');

export const getClickID = () => localStorage.getItem('bemob_click_id');
export const setClickID = CID => localStorage.setItem('bemob_click_id', CID);

export const getReferralCode = () => localStorage.getItem('refCode');
export const setReferralCode = refCode => localStorage.setItem('refCode', refCode);
export const removeReferralCode = () => localStorage.removeItem('refCode');

export const getEmailVerify = () => {
    const encodedEmail = localStorage.getItem('email');
    return encodedEmail ? decodeURIComponent(encodedEmail) : null;
};

export const setEmailVerify = (email) => {
    const encodedEmail = encodeURIComponent(email);
    localStorage.setItem('email', encodedEmail);
};