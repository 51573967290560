import { Link, IconButton, Box, Button, OutlinedInput, CircularProgress } from "@mui/material";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from 'axios';
import alertSZ from "../../utils/alert";
import { useMediaQuery } from 'react-responsive'
import { Helmet } from "react-helmet";
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next";
import { API_U_RESET_PASSWORDCONFIRM } from "../../share/api";
import { RiLockPasswordLine } from "react-icons/ri";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useTheme } from "@mui/styles";

const useStyles = makeStyles({
    input: {
        WebkitBoxShadow: '0 0 0 1000px white inset',
    },
});

const Password = () => {

    const history = useNavigate()
    const mBreakPoint0_900 = useMediaQuery({ query: '(min-width: 0px) and (max-width: 900px)' });
    const mBreakPoint900_1024 = useMediaQuery({ query: '(min-width: 901px) and (max-width: 1024px)' });
    const mBreakPoint1025_1280 = useMediaQuery({ query: '(min-width: 1025px) and (max-width: 1280px)' });
    const mBreakPoint1281_1366 = useMediaQuery({ query: '(min-width: 1281px) and (max-width: 1366px)' });
    const mBreakPoint1367_1440 = useMediaQuery({ query: '(min-width: 1367px) and (max-width: 1440px)' });
    const mBreakPoint1441_1680 = useMediaQuery({ query: '(min-width: 1441px) and (max-width: 1680px)' });

    const [mStateForRegButton, setStateForRegButton] = React.useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
    const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);
    const [mConfirmPwd, setConfirmPwd] = useState();
    const handleMouseDownConfirmPassword = (event) => {
        event.preventDefault();
    };
    const { t } = useTranslation();
    const styleClass = useStyles();
    const { vcode } = useParams();

    const handleKeyPress = (event) => {
        if (event && event.key === 'Enter') {
            event.preventDefault();
            handleChange(event);
        }
    };
    
    const handleChange = async () => {
        if(!mConfirmPwd){
            alertSZ(t('setting.please_provide_me_a_password'));
            return
        }
        setStateForRegButton(true)

        const password = mConfirmPwd;
        await axios.post(
            API_U_RESET_PASSWORDCONFIRM,
            {
                password, vcode
            }).then(res => {
                const body = res.data;
                setStateForRegButton(false)
                if (res.status === 200) {
                    alertSZ(t(`backend.${body}`))
                }
            }).catch(e => {
                setStateForRegButton(false)
                const text = e.response.data;
                alertSZ(t(`backend.${text}`))
                return;
            })

    }
    const theme = useTheme();
    return (
        <Box sx={{fontFamily: theme.typography.fontFamily}}>
            <Helmet>
                <title>{t('resetpassword.reset_password')} - Multifun</title>
            </Helmet>
            {!mBreakPoint0_900 && (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundImage: process.env.PUBLIC_URL + '/Images/Ellipse.png', backgroundColor: 'white', width: '100vw', height: '100vh', overflow: 'hidden' }}>
                    <Box sx={{ width: '100%', overflow: 'hidden', position: 'relaitve', display: 'flex', justifyContent: 'center' }}>
                        <img src={process.env.PUBLIC_URL + '/Images/Ellipse.png'} alt="logo" style={{ position: 'absolute', right: 0, top: 0, width: '100%', height: '60%' }} />
                        <img src={process.env.PUBLIC_URL + '/Images/multifunlogo.svg'} alt="logo" style={{ position: 'absolute', top: mBreakPoint1025_1280 || mBreakPoint900_1024 ? '80px' : '120px', width: '13%', cursor: 'pointer', transition: 'transform 0.2s', ":hover": { transform: 'scale(1.1)' } }} onClick={() => history('/')} />
                        <img src={process.env.PUBLIC_URL + '/Images/gold6.png'} alt="logo" style={{ position: 'absolute', top: mBreakPoint900_1024 ? '98px' : '70px', width: mBreakPoint1281_1366 || mBreakPoint1025_1280 || mBreakPoint900_1024 ? '10%' : '13%', left: mBreakPoint1281_1366 || mBreakPoint1025_1280 ? '320px' : mBreakPoint900_1024 ? '209px' : '380px' }} />
                        <img src={process.env.PUBLIC_URL + '/Images/gold7.png'} alt="logo" style={{ position: 'absolute', bottom: mBreakPoint1281_1366 || mBreakPoint1025_1280 || mBreakPoint900_1024 ? '90px' : '130px', right: mBreakPoint900_1024 ? '160px' : '170px', width: mBreakPoint1281_1366 || mBreakPoint1025_1280 || mBreakPoint900_1024 ? '13%' : '15%' }} />
                        <img src={process.env.PUBLIC_URL + '/Images/redJackBig.png'} alt="logo" style={{ position: 'absolute', bottom: mBreakPoint1441_1680 ? '229px' : mBreakPoint1367_1440 ? '235px' : mBreakPoint1281_1366 ? '205px' : mBreakPoint1025_1280 ? '185px' : mBreakPoint900_1024 ? '225px' : '295px', left: mBreakPoint900_1024 ? '115px' : '170px', width: mBreakPoint1281_1366 || mBreakPoint1025_1280 || mBreakPoint900_1024 ? '13%' : '16%' }} />
                        <Box sx={{ marginTop: mBreakPoint1441_1680 || mBreakPoint1367_1440 || mBreakPoint1281_1366 || mBreakPoint1025_1280 || mBreakPoint900_1024 ? '150px' : '10px', paddingLeft: '24px', paddingRight: '24px', borderColor: 'rgba(0,0,0,0.1)', borderWidth: '1px', borderStyle: 'solid', backgroundColor: 'rgba(255, 255, 255, 1)', borderRadius: '8px', display: "flex", flexDirection: "column", alignItems: "center", justifyContent: 'center', width: '20%', zIndex: '2' }}>
                            <p style={{ margin: 0, fontWeight: 500, fontSize: mBreakPoint1281_1366 || mBreakPoint1025_1280 || mBreakPoint900_1024 ? '20px' : '25px', lineHeight: '36px', marginTop: mBreakPoint1281_1366 || mBreakPoint1025_1280 || mBreakPoint900_1024 ? '20px' : '24px' }}>
                                <span style={{ color: 'black' }}>{t('resetpassword.reset_password')}</span>
                            </p>
                            <Box sx={{ width: '100%' }}>
                                <Box sx={{ marginTop: mBreakPoint1281_1366 || mBreakPoint1025_1280 || mBreakPoint900_1024 ? '5px' : '10px' }}>
                                    <OutlinedInput
                                        id="outlined-adornment-password"
                                        type={showConfirmPassword ? 'text' : 'password'}
                                        placeholder={t('setting.new_password')}
                                        classes={{ input: styleClass.input }}
                                        startAdornment={
                                            <RiLockPasswordLine style={{ fontSize: '25px', color: 'grey', marginLeft: '15px', marginRight: '15px' }} />
                                        }
                                        endAdornment={
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowConfirmPassword}
                                                onMouseDown={handleMouseDownConfirmPassword}
                                            >
                                                {showConfirmPassword ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                                            </IconButton>
                                        }

                                        sx={{ padding: 0, backgroundColor: "#FFFFFF", border: 'border: 1px solid #B4B4B4', borderRadius: '8px', fontSize: mBreakPoint1281_1366 || mBreakPoint1025_1280 || mBreakPoint900_1024 ? '12px' : '14px', fontWeight: '400', lineHeight: '21px', width: '100%', height: mBreakPoint1281_1366 || mBreakPoint1025_1280 || mBreakPoint900_1024 ? '35px' : '45px' }}
                                        value={mConfirmPwd}
                                        onKeyPress={handleKeyPress}
                                        onChange={(e) => setConfirmPwd(e.target.value)}
                                    />
                                </Box>
                                <Button
                                    type="button"
                                    fullWidth
                                    variant="contained"
                                    sx={{ marginTop: mBreakPoint1281_1366 || mBreakPoint1025_1280 || mBreakPoint900_1024 ? '5px' : '24px', height: mBreakPoint1281_1366 || mBreakPoint1025_1280 || mBreakPoint900_1024 ? '35px' : '45px', borderRadius: '8px', backgroundColor: 'rgba(58, 32, 109, 1)', textTransform: 'none' }}
                                    onClick={handleChange}
                                >{(mStateForRegButton ? <CircularProgress sx={{ color: 'white' }} /> : t('setting.update_password'))}</Button>
                                <Box sx={{ marginTop: '15px', marginBottom: mBreakPoint1281_1366 || mBreakPoint1025_1280 || mBreakPoint900_1024 ? '25px' : '35px', display: 'flex', justifyContent: 'center' }}>
                                    <Link
                                        onClick={() => history('/signin')}
                                        variant="body2"
                                        style={{ cursor: 'pointer', textDecoration: 'none' }}
                                    >
                                        <p style={{ margin: 0, fontWeight: 500, fontSize: mBreakPoint1281_1366 || mBreakPoint1025_1280 || mBreakPoint900_1024 ? '10px' : '12px', lineHeight: '18px' }}>
                                            <span style={{ color: 'rgba(101, 101, 101, 1)', fontSize: mBreakPoint1281_1366 || mBreakPoint1025_1280 || mBreakPoint900_1024 ? '10px' : '12px' }}>{t('resetpassword.go_to')}</span><span style={{ color: 'rgba(101, 101, 101, 1)', fontWeight: 700, fontSize: mBreakPoint1281_1366 || mBreakPoint1025_1280 || mBreakPoint900_1024 ? '10px' : '12px' }}> {t('homepage.login')}</span>
                                        </p>
                                    </Link>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            )}
            {mBreakPoint0_900 && (
                <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', backgroundColor: 'rgba(58, 32, 109, 1)', minHeight: '100vh', paddingLeft: '24px', paddingRight: '24px' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <img src={process.env.PUBLIC_URL + '/Images/multifunlogo.svg'} onClick={() => history('/')} style={{ width: '60%' }} alt="" />
                    </Box>
                    <Box sx={{ marginTop: '24px', paddingLeft: '24px', paddingRight: '24px', borderColor: 'rgba(0,0,0,0.1)', borderWidth: '1px', borderStyle: 'solid', backgroundColor: 'rgba(255, 255, 255, 1)', borderRadius: '8px', display: "flex", flexDirection: "column", alignItems: "center", justifyContent: 'center' }}>
                        <p style={{ margin: 0, fontWeight: 500, fontSize: '25px', lineHeight: '36px', marginTop: '24px' }}>
                            <span style={{ color: 'black' }}>{t('resetpassword.reset_password')}</span>
                        </p>
                        <Box sx={{ width: '100%' }}>
                            <Box sx={{ marginTop: mBreakPoint1281_1366 || mBreakPoint1025_1280 || mBreakPoint900_1024 ? '5px' : '10px' }}>
                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    placeholder={t('setting.new_password')}
                                    classes={{ input: styleClass.input }}
                                    startAdornment={
                                        <RiLockPasswordLine style={{ fontSize: '25px', color: 'grey', marginLeft: '15px', marginRight: '15px' }} />
                                    }
                                    endAdornment={
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowConfirmPassword}
                                            onMouseDown={handleMouseDownConfirmPassword}
                                        >
                                            {showConfirmPassword ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                                        </IconButton>
                                    }
                                    sx={{ padding: 0, backgroundColor: "#FFFFFF", border: 'border: 1px solid #B4B4B4', borderRadius: '8px', fontSize: '14px', fontWeight: '400', lineHeight: '21px', width: '100%', height: '45px' }}
                                    value={mConfirmPwd}
                                    onKeyPress={handleKeyPress}
                                    onChange={(e) => setConfirmPwd(e.target.value)}
                                />
                            </Box>
                            <Button
                                type="button"
                                fullWidth
                                variant="contained"
                                sx={{ marginTop: '24px', height: '45px', borderRadius: '8px', backgroundColor: 'rgba(58, 32, 109, 1)', textTransform: 'none' }}
                                onClick={handleChange}
                            >{(mStateForRegButton ? <CircularProgress sx={{ color: 'white' }} /> : t('setting.update_password'))}</Button>
                            <Box sx={{ marginTop: '15px', marginBottom: '35px', display: 'flex', justifyContent: 'center' }}>
                                <Link
                                    onClick={() => history('/signin')}
                                    variant="body2"
                                    style={{ cursor: 'pointer', textDecoration: 'none' }}
                                >
                                    <p style={{ margin: 0, fontWeight: 500, fontSize: '12px', lineHeight: '18px' }}>
                                        <span style={{ color: 'rgba(101, 101, 101, 1)' }}>{t('resetpassword.go_to')}</span><span style={{ color: 'rgba(101, 101, 101, 1)', fontWeight: 700 }}> {t('homepage.login')}</span>
                                    </p>
                                </Link>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            )}
        </Box>
    );
}

export default Password;