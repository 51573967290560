import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import alertSZ from "../../utils/alert";
import { useMediaQuery } from 'react-responsive';
import { Helmet } from "react-helmet";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { getClickID, getEmailVerify } from "../../utils/authToken";
import { API_R_VERIFY, API_U_VERIFY } from "../../share/api";
import { useTheme } from '@mui/material/styles';

const ResendVerifyEmail = () => {

    const history = useNavigate();
    const [mEmail, setEmail] = useState('');
    const mBreakPoint0_1280 = useMediaQuery({ query: '(min-width: 0px) and (max-width: 1280px)' });
    const { t } = useTranslation();
    let intervalId;

    useEffect(() => {
        const email = getEmailVerify();
        if (!email) {
            history('/signin');
        } else {
            setEmail(email);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
        intervalId = setInterval(callAPI_CheckVerifyEmail, 1000);
        return () => {
            clearInterval(intervalId);
        };
    }, [history, mEmail]);

    const callAPI_CheckVerifyEmail = async () => {
        try {
            const res = await axios.post(API_R_VERIFY, { mEmail });
            if (res.status === 200) {
                clearInterval(intervalId);
                history('/signin');
            }
        } catch (error) {
            // Handle error if needed
        }
    }

    useEffect(() => {
        const sendPostBack = async () => {
            const cid = getClickID();
            if (!cid) return;
            const postbackURL = `https://trk.multifun.io/postback?cid=${cid}`;
            await axios.post(postbackURL)
                .then(response => {
                    console.log('Postback fired successfully:', response.data);
                })
                .catch(error => {
                    console.error('Error firing postback:', error);
                });
        }
        sendPostBack();
    }, []);

    const resendVerifyEmail = async () => {
        await axios.post(API_U_VERIFY, {
            mEmail,
        }).then(res => {
        }).catch(e => {
            const text = e.response.data;
            alertSZ(t(`backend.${text}`))
            return;
        })
    }

    
    const theme = useTheme();
    return (
        <Box sx={{ fontFamily: theme.typography.fontFamily }}>
            <Helmet>
                <title>{t('loginpage.signin')} - Multifun</title>
            </Helmet>
            {!mBreakPoint0_1280 && (
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    backgroundColor: 'white',
                    overflow: 'hidden',
                    height: '100vh',
                }}>
                    <Box sx={{
                        width: '100%',
                        overflow: 'hidden',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        position: 'relative',
                        alignItems: 'center',
                        backgroundImage: 'url("/Images/Ellipse1.png")',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat'
                    }}>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                            <img src={process.env.PUBLIC_URL + '/Images/multifunlogo.svg'}
                                alt="logo"
                                style={{
                                    width: '250px',
                                    cursor: 'pointer'
                                }} onClick={() => history('/')} />
                            <Box sx={{
                                marginTop: '100px',
                                paddingLeft: '24px', paddingRight: '24px',
                                borderColor: 'rgba(0,0,0,0.1)',
                                borderWidth: '1px',
                                borderStyle: 'solid',
                                backgroundColor: 'rgba(255, 255, 255, 1)',
                                borderRadius: '8px',
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: 'center',
                                zIndex: '2'
                            }}>
                                <p style={{ margin: 0, fontWeight: 500, fontSize: '25px', marginTop: '24px' }}>
                                    {t('homepage.email_verification_sent')}
                                </p>
                                <p style={{ margin: 0, fontWeight: 400, fontSize: '12px', marginTop: '10px', textAlign: 'center', marginBottom: '24px' }}>
                                    {t('homepage.did_not_receive_the_verification_email')}
                                    <spin style={{ fontWeight: '600', cursor: 'pointer' }}
                                        onClick={() => resendVerifyEmail()}
                                    >&nbsp;&nbsp;{t('homepage.send_again')}</spin>
                                </p>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            )}
            {mBreakPoint0_1280 && (
                <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', backgroundColor: 'rgba(58, 32, 109, 1)', minHeight: '100vh', paddingLeft: '24px', paddingRight: '24px' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <img src={process.env.PUBLIC_URL + '/Images/multifunlogo.svg'} onClick={() => history('/')} alt="" style={{ width: '60%' }} />
                    </Box>
                    <Box sx={{ marginTop: '24px', paddingLeft: '24px', paddingRight: '24px', borderColor: 'rgba(0,0,0,0.1)', borderWidth: '1px', borderStyle: 'solid', backgroundColor: 'rgba(255, 255, 255, 1)', borderRadius: '8px', display: "flex", flexDirection: "column", alignItems: "center", justifyContent: 'center' }}>
                        <p style={{ margin: 0, fontWeight: 500, fontSize: '18px', marginTop: '24px', textAlign: 'center' }}>
                            {t('homepage.email_verification_sent')}
                        </p>
                        <p style={{ margin: 0, fontWeight: 400, fontSize: '12px', marginTop: '10px', textAlign: 'center' }}>
                            {t('homepage.did_not_receive_the_verification_email')}
                        </p>
                        <p style={{ margin: 0, fontWeight: 600, fontSize: '12px', marginBottom: '24px', textAlign: 'center' }}
                            onClick={() => resendVerifyEmail()}
                        >
                            {t('homepage.send_again')}
                        </p>
                    </Box>
                </Box>
            )}
        </Box>
    );
}

export default ResendVerifyEmail;