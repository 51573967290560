import { RouterProvider } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import routes from './routes/routes';
import './App.css';

function App() {

  const theme = createTheme({
    typography: {
      fontFamily: 'Poppins',
    },
  });
////
  return (
    <ThemeProvider theme={theme}>
      <RouterProvider router={routes} />
    </ThemeProvider>
  );
}

export default App;
