export async function getGEOinfo_ipstack() {
    const url = 'https://api.ipstack.com/check?access_key=a28d4ca85419978efc85203f4c625037';
  
    try {
      const response = await fetch(url);
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Fetch error:', error);
      return { countryCode: 'US' };
    }
  }
  