
const localizationCountry = [
    {
        countryName: 'United Kingdom',
        languageName: 'EN',
        languageCode: 'en',
    },
    {
        countryName: 'Germany',
        languageName: 'DE',
        languageCode: 'de',
    },
    {
        countryName: 'Sweden',
        languageName: 'SE',
        languageCode: 'se',

    },
    {
        countryName: 'Finland',
        languageName: 'FI',
        languageCode: 'fi',

    },
    {
        countryName: 'Norway',
        languageName: 'NO',
        languageCode: 'no',

    },
    {
        countryName: 'France',
        languageName: 'FR',
        languageCode: 'fr',
    },
    {
        countryName: 'Italy',
        languageName: 'IT',
        languageCode: 'it',
    },
    {
        countryName: 'Poland',
        languageName: 'PL',
        languageCode: 'pl',
    },
    {
        countryName: 'Czech Republic',
        languageName: 'CZ',
        languageCode: 'cz',
    },
    {
        countryName: 'Spain',
        languageName: 'ES',
        languageCode: 'es'
    },
    {
        countryName: 'Portugal',
        languageName: 'PT',
        languageCode: 'pt',
    },
    {
        countryName: 'Turkey',
        languageName: 'TR',
        languageCode: 'tr',
    },
    {
        countryName: 'Japan',
        languageName: 'JP',
        languageCode: 'jp',
    },
    {
        countryName: 'Russia',
        languageName: 'RU',
        languageCode: 'ru',
    },

]

const language_list = {
    de: [
        'Germany',
        'Austria',
        'Switzerland'
    ],
    es: [
        'Mexico',
        'Colombia',
        'Guatemala',
        'Peru',
        'Ecuador',
        'Chile',
        'Venezuela',
        'Dominican Republic',
        'Bolivia',
        'Costa Rica',
        'El Salvador',
        'Honduras',
        'Nicaragua',
        'Cuba',
        'Panama',
        'Paraguay',
        'Uruguay',
        'Puerto Rico',
    ],
    fr: [
        'France',
        'Benin',
        'Congo',
        'Democratic Republic of Congo',
        'Ivory Coast',
        'Gabon',
        'Guinea',
        'Monaco',
        'Niger',
        'Senegal',
        'Togo'
    ],
    pt: [
        'Brazil',
        'Portugal'
    ],
    it: [
        'Italy'
    ],
    jp: [
        'Japan',
    ],
    pl:[
        'Poland'
    ],
    ru:[
        'Russia',
        'Russian Federation'
    ],
    fi:[
        'Finland',
    ],
    no:[
        'Norway',
    ],
    se:[
        'Sweden',
    ],
    cz:[
        'Czech Republic'
    ],
    tr:[
        'Turkey'
    ]
}
function FindLangCodeByCtyName(country) {

    for (const langCode in language_list) {
        if (language_list[langCode].includes(country)) {
            return langCode;
        }
    }
    return 'en';
}
module.exports = { localizationCountry, FindLangCodeByCtyName, language_list };