/* eslint-disable react/jsx-pascal-case */
import { Box, Button, Grid, styled, MenuItem, CardContent, OutlinedInput, Select, FormControl, CircularProgress, Typography, Tooltip, tooltipClasses } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { getRefreshAuthToken, getStoredAuthToken, storeAccessAuthToken } from "../../utils/authToken";
import jwt_decode from 'jwt-decode';
import { useNavigate } from "react-router-dom";
import alertSZ from "../../utils/alert";
import { useMediaQuery } from 'react-responsive'
import { Helmet } from "react-helmet";
import { validate } from 'bitcoin-address-validation';
import MobileTooltip from "../../utils/MobileTooltilp";
import { AuthContext } from "../../contexts/AuthContext";
import InfoIcon from '@mui/icons-material/Info';
import BackDrop from "../../utils/Backdrop";
import { useTranslation } from "react-i18next";
import { API_R_PAYMENT, API_R_PAYMENTDETAIL, API_U_PAYMENTDETAIL, API_U_PLAYERCASHBACK } from "../../share/api";
import CustomPagination from "../../utils/CustomPagination";
import { useTheme } from '@mui/material/styles';


const PaymentSetting = () => {

  const { isLogin } = useContext(AuthContext);
  const history = useNavigate();
  const authtoken = getStoredAuthToken();
  const email = authtoken ? jwt_decode(authtoken).email : null;
  if (!isLogin || !email) {
    history('/signin');
  }

  const { t } = useTranslation();
  const mBreakPoint0_1280 = useMediaQuery({ query: '(min-width: 0px) and (max-width: 1280px)' });
  const mBreakPoint1281_1366 = useMediaQuery({ query: '(min-width: 1281px) and (max-width: 1768px)' });

  const [mSelectedAccount, setSelectedAccount] = useState(0);
  const handleSelectAccont = (idx) => {
    if (mSelectedAccount === idx) {
      setSelectedAccount(null); // Deselect if the same item is clicked again
    } else {
      setSelectedAccount(idx);
    }
  }

  const SelectBox = styled(Box)(({ index }) => ({
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: mBreakPoint1281_1366 ? '3px' : '5px',
      height: '100%',
      background: 'linear-gradient(180deg, #6C3D9E 20%, #E91287 100%)',
      borderRadius: '6px 0 0 6px',
      opacity: mSelectedAccount === index ? 1 : 0,
      transition: 'opacity 0.3s ease-in-out',
    },
    '&:hover::before': {
      opacity: 1,
    },
    '&:hover': {
      cursor: 'pointer',
      borderRadius: '6px',
      backgroundColor: 'rgba(200,200,200,0.2)'
    },
    borderRadius: '6px',
  }));

  const Button_ = styled(Button)({
    padding: mBreakPoint1281_1366 ? '1px 29px'
      : '15px 29px',
    color: "#FFFFFF",
    fontWeight: "400",
    fontSize: mBreakPoint1281_1366 ? '9px'
      : '14px',
    backgroundColor: "#6C3D9E",
    borderRadius: "8px",
    textTransform: 'none',
    height: mBreakPoint1281_1366 ? '25px'
      : '40px',
    width: '100%',
    '&:hover': { backgroundColor: 'rgba(99,57,143,0.8)' }
  });

  const MenuItem_ = styled(MenuItem)(({ theme }) => ({
    height: mBreakPoint1281_1366 ? '25px'
      : theme.breakpoints.between('1367', '1440') ? '25px'
        : '30px',
    fontSize: mBreakPoint1281_1366 ? '10px'
      : '12px',
  }));

  const ToolTipForDesktop = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: 'grey',
      color: 'white',
      borderRadius: '8px',
      fontSize: mBreakPoint1281_1366 ? '9px'
        : '10px',
      maxWidth: '500px',
    },
  }));
  const ShiningInfoIcon = styled(InfoIcon)({
    animation: 'shining 1.5s infinite',
    '@keyframes shining': {
      '0%': {
        color: 'black',
      },
      '50%': {
        color: '#ffc500',
      },
      '100%': {
        color: 'black',
      },
    },
  });

  const Typography_ = styled(Typography)({
    fontSize: mBreakPoint1281_1366 ? '9px'
      : '12px',
    lineHeight: '140%',
    color: 'black',
    margin: 0
  });

  const [mStateRequestButton, setStateRequestButton] = useState(false);
  const [mLoadingDataFromServer, setLoadingDataFromServer] = React.useState(true);
  const [mCurrentBalance, setCurrentBalance] = useState(0);
  const [mAvailableCashback, setAvailableCashback] = useState(0);
  const [mPaymentMethod, setPaymentMethod] = React.useState(0);


  const [mPlayerPaymentTranscationHistory, setPlayerPaymentTranscation] = useState(null);
  const [currentPage, setCurrentPage] = React.useState(0);
  const itemPerPage = 10;
  const startIndex = currentPage * itemPerPage;
  const endIndex = startIndex + itemPerPage;
  const [disPlayItems, setDisplayItems] = useState([]);
  const [inputCurrentPage, setInputCurrentPage] = useState();
  const [mNetellerEmail, setNetellerEmail] = useState();
  const [mNetellerID, setNetellerID] = useState();

  const [mSkrillEmail, setSkrillEmail] = useState();

  const [mERC20Addr, setERC20Addr] = useState();
  const [mTRC20Addr, setTRC20Addr] = useState();

  const [mBitcoinAddr, setBitcoinAddr] = useState();
  const [mEthereumAddr, setEthereumAddr] = useState();

  const { setDealType } = useContext(AuthContext);
  setDealType(0);

  useEffect(() => {
    setDisplayItems(mPlayerPaymentTranscationHistory?.slice(startIndex, endIndex))
  }, [startIndex, endIndex, mPlayerPaymentTranscationHistory]);

  useEffect(() => {
    setLoadingDataFromServer(true)
    const getPaymentData = async () => {
      await axios.post(API_R_PAYMENT, { // get Current Balance, get Available cashback, get PaymentTransaction history
        email,
      }).then(res => {
        const body = res.data;
        setLoadingDataFromServer(false)
        if (res.status === 200) {
          setCurrentBalance(body?.currentBalance);
          setAvailableCashback(body?.availableCashback);
          setPlayerPaymentTranscation(body?.payTransactionHis);
          return;
        }
      }).catch(e => {
        return
      })
    }
    if (isLogin && email) {
      getPaymentData();
    }
  }, [email, isLogin])

  useEffect(() => {
    const GetPaymentDetail = async () => {
      await axios.post(API_R_PAYMENTDETAIL, {
        email, mPaymentMethod
      }).then(res => {
        const body = res.data;
        if (res.status === 200) {
          switch (mPaymentMethod) {
            case 1:
              setNetellerEmail(body?.data1)
              setNetellerID(body?.data2)
              break;
            case 2:
              setSkrillEmail(body?.data1)
              break;
            case 3:
              setERC20Addr(body?.data1)
              break;
            case 4:
              setTRC20Addr(body?.data1)
              break;
            case 5:
              setBitcoinAddr(body?.data1)
              break;
            case 6:
              setEthereumAddr(body?.data1)
              break;
            default:
              break;
          }
          return;
        }
      }).catch(e => {
        const text = e.response.data;
        alertSZ(t(`backend.${text}`));
        return
      })
    }
    if (isLogin && email) {
      GetPaymentDetail();
    }
  }, [mPaymentMethod, email, t, isLogin])

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage - 1)
  }
  const handleInputChange = (e) => {
    setInputCurrentPage('')
    const inputValue = e.target.value;

    const page = parseInt(inputValue, 10);
    if (page >= 1 && page <= Math.ceil((mPlayerPaymentTranscationHistory ? mPlayerPaymentTranscationHistory.length : 0) / itemPerPage)) {
      setInputCurrentPage(page);
    }
  }

  const handleGoButtonClick = () => {
    const page = parseInt(inputCurrentPage, 10);
    if (page >= 1 && page <= Math.ceil((mPlayerPaymentTranscationHistory ? mPlayerPaymentTranscationHistory.length : 0) / itemPerPage)) {
      setCurrentPage(page - 1);
    }
  };

  const handleSelPyamentOption = (nSelPayment) => {
    if (nSelPayment === 1 || nSelPayment === 2) {
      alertSZ(t('payments.this_payment_method_is_not_available'))
      return;
    }
    setPaymentMethod(nSelPayment);
  }
  const handleSavePaymentDetail = async () => {

    if (mPaymentMethod < 1 && mPaymentMethod > 6) {
      alertSZ(t('payments.please_select_payment_method'));
      return
    }

    let data1 = ' ';
    let data2 = ' ';
    switch (mPaymentMethod) {
      case 1:
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(mNetellerEmail) || !mNetellerID) {
          alertSZ(t('payments.please_provide_the_payment_details'));
          return
        }
        data1 = mNetellerEmail;
        data2 = mNetellerID;
        break;
      case 2:
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(mSkrillEmail)) {
          alertSZ(t('payments.invalid_email_format'));
          return
        }
        data1 = mSkrillEmail;
        break;
      case 3:
        if (!/^(0x)?[0-9a-fA-F]{40}$/i.test(mERC20Addr)) {
          alertSZ(t('payments.invaid_usdt_erc20_address'));
          return;
        }
        data1 = mERC20Addr;
        break;
      case 4:
        if (!mTRC20Addr) {
          alertSZ(t('payments.invaid_usdt_trc20_address'));
          return;
        }
        data1 = mTRC20Addr;
        break;
      case 5:
        if (!validate(mBitcoinAddr)) {
          alertSZ(t('payments.invaid_bitcoin_address'));
          return;
        }
        data1 = mBitcoinAddr;
        break;
      case 6:
        if (!/^(0x)?[0-9a-fA-F]{40}$/i.test(mEthereumAddr)) {
          alertSZ(t('payments.invaid_usdt_erc20_address'));
          return;
        }
        data1 = mEthereumAddr;
        break;
      default:
        break;
    }

    await axios.post(API_U_PAYMENTDETAIL, {
      email, mPaymentMethod, data1, data2
    }).then(res => {
      if (res.status === 200) {
        alertSZ(t('payments.payment_details_saved_successfully'))
        return;
      }
    }).catch(e => {
      return
    })
  }

  const handleRequestCashback = async () => {
    if (!email) {
      alertSZ(t('backend.invalid_value'));
      return
    }

    let data1 = ' ';
    let data2 = ' ';
    switch (mPaymentMethod) {
      case 1:
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(mNetellerEmail) || !mNetellerID) {
          alertSZ(t('payments.please_provide_the_payment_details'));
          return
        }
        data1 = mNetellerEmail;
        data2 = mNetellerID;
        break;
      case 2:
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(mSkrillEmail)) {
          alertSZ(t('payments.invalid_email_format'));
          return
        }
        data1 = mSkrillEmail;
        break;
      case 3:
        if (!/^(0x)?[0-9a-fA-F]{40}$/i.test(mERC20Addr)) {
          alertSZ(t('payments.invaid_usdt_erc20_address'));
          return;
        }
        data1 = mERC20Addr;
        break;
      case 4:
        if (!mTRC20Addr) {
          alertSZ(t('payments.invaid_usdt_trc20_address'));
          return;
        }
        data1 = mTRC20Addr;
        break;
      case 5:
        if (!validate(mBitcoinAddr)) {
          alertSZ(t('payments.invaid_bitcoin_address'));
          return;
        }
        data1 = mBitcoinAddr;
        break;
      case 6:
        if (!/^(0x)?[0-9a-fA-F]{40}$/i.test(mEthereumAddr)) {
          alertSZ(t('payments.invaid_usdt_erc20_address'));
          return;
        }
        data1 = mEthereumAddr;
        break;
      default:
        break;
    }

    setStateRequestButton(true)
    await axios.post(API_U_PLAYERCASHBACK, {
      email, mPaymentMethod, data1, data2
    }, {
      headers: {
        Authorization: `Bearer ${getStoredAuthToken()} ${getRefreshAuthToken()}`,
      },
    }).then(async (res) => {
      setStateRequestButton(false)

      if (res.status === 208) {
        const pendingCashback = res.data;
        alertSZ(t(`backend.you_already_requested_for_withdrawal_kindly`).replaceAll('{mPendingCashback}', pendingCashback));
        return;
      }
      if (res.status === 200) {
        const body = res?.data;
        const newAccessToken = body?.newAccessToken
        if (newAccessToken !== undefined) {
          storeAccessAuthToken(newAccessToken)
        }
        setPlayerPaymentTranscation(body?.payTransactionHis)
      }
    }).catch(e => {
      setStateRequestButton(false);
      const text = e.response.data;
      alertSZ(t(`backend.${text}`));
      return
    })
  }

  const [currentSelectedItem, setCurrentSelectedItem] = useState();
  const handleForMobileTooltip = (idx) => {
    setCurrentSelectedItem(idx)

    setTimeout(() => {
      setCurrentSelectedItem(-1);
    }, 5000);
  }

  const theme = useTheme();
  return (
    <Box sx={{ fontFamily: theme.typography.fontFamily }}>
      <Helmet>
        <title>{t('payments.payment_settings')} - Multifun</title>
      </Helmet>
      {!mBreakPoint0_1280 && (
        <Box sx={{ paddingRight: '24px', paddingLeft: '24px', backgroundColor: 'rgba(250, 250, 250, 0.7)', display: "flex", flexDirection: "column", height: '92vh', position: 'relative' }}>
          <Box sx={{ backgroundColor: '#FFFFFF', borderRadius: '8px' }}>
            <Box sx={{ width: '100%', backgroundImage: "url('/Images/PaymentSettingTopbar.png')", backgroundSize: 'cover', backgroundRepeat: 'no-repeat', borderRadius: '8px 8px 0 0' }}>
              <Box sx={{ marginLeft: '37px', display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', justifyContent: 'start' }} style={{ width: '5%', height: '5vh' }}>
                </Box>
              </Box>
            </Box>
            <Box sx={{ paddingLeft: '24px', paddingRight: '24px' }}>
              <Box sx={{ marginTop: '24px', marginBottom: '24px', display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ width: '48%' }}>
                  <Box sx={{ border: '1px solid #B4B4B4', borderRadius: '8px' }}>
                    <FormControl fullWidth backgroundColor={'white'}>
                      <Select
                        value={mPaymentMethod}
                        onChange={(e) => handleSelPyamentOption(e.target.value)}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        sx={{
                          height: mBreakPoint1281_1366 ? '25px' : '40px',
                          boxShadow: 'none',
                          '.MuiOutlinedInput-notchedOutline': { border: 0 },
                          '& .MuiInputBase-input': {
                            padding: '5px 28px',
                            display: 'flex',
                            alignItems: "center",
                            fontSize: mBreakPoint1281_1366 ? '9px' : '12px'
                          }
                        }}
                      >
                        <MenuItem_ disabled value={0}>
                          <em>{t('payments.select_withdrawal_method')}</em>
                        </MenuItem_>
                        <MenuItem_ value={1}>
                          {t('payments.neteller')}
                        </MenuItem_>
                        <MenuItem_ value={2}>
                          {t('payments.skrill')}
                        </MenuItem_>
                        <MenuItem_ value={3}>
                          {t('payments.usdt_erc20')}
                        </MenuItem_>
                        <MenuItem_ value={4}>
                          {t('payments.usdt_trc20')}
                        </MenuItem_>
                        <MenuItem_ value={5}>
                          {t('payments.bitcoin')}
                        </MenuItem_>
                        <MenuItem_ value={6}>
                          {t('payments.ethereum')}
                        </MenuItem_>
                      </Select>
                    </FormControl>
                  </Box>
                  <Box sx={{ marginTop: '5px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    {mPaymentMethod === 1 && (
                      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%' }}>
                        <OutlinedInput
                          placeholder={t('dashboard.email')}
                          sx={{
                            input: {
                              "&::placeholder": {    // <----- Add this.
                                opacity: 1,
                              },
                            },
                            paddingLeft: '15px',
                            backgroundColor: "#FFFFFF",
                            border: '0px solid grey',
                            borderRadius: '8px',
                            fontSize: mBreakPoint1281_1366 ? '9px'
                              : '12px',
                            fontWeight: '400',
                            lineHeight: '21px',
                            width: '100%',
                            height: mBreakPoint1281_1366 ? '25px'
                              : '40px'
                          }}
                          type="email"
                          value={mNetellerEmail}
                          onChange={(e) => setNetellerEmail(e.target.value)}
                        />
                        <OutlinedInput
                          placeholder={t('payments.id')}
                          sx={{
                            input: {
                              "&::placeholder": {    // <----- Add this.
                                opacity: 1,
                              },
                            },
                            paddingLeft: '15px',
                            backgroundColor: "#FFFFFF",
                            border: '0px solid grey',
                            borderRadius: '8px',
                            fontSize: mBreakPoint1281_1366 ? '9px'
                              : '12px',
                            fontWeight: '400',
                            lineHeight: '21px',
                            width: '100%',
                            height: mBreakPoint1281_1366 ? '25px'
                              : '40px'
                          }}
                          type="id"
                          value={mNetellerID}
                          onChange={(e) => setNetellerID(e.target.value)}
                        />
                      </Box>
                    )}
                    {mPaymentMethod === 2 && (
                      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%' }}>
                        <OutlinedInput
                          placeholder={t('dashboard.email')}
                          sx={{
                            input: {
                              "&::placeholder": {    // <----- Add this.
                                opacity: 1,
                              },
                            },
                            paddingLeft: '15px',
                            backgroundColor: "#FFFFFF",
                            border: '0px solid grey',
                            borderRadius: '8px',
                            fontSize: mBreakPoint1281_1366 ? '9px'
                              : '12px',
                            fontWeight: '400',
                            lineHeight: '21px',
                            width: '100%',
                            height: mBreakPoint1281_1366 ? '25px'
                              : '40px'
                          }}
                          type="email"
                          value={mSkrillEmail}
                          onChange={(e) => setSkrillEmail(e.target.value)}
                        />
                      </Box>
                    )}
                    {mPaymentMethod === 3 && (
                      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%' }}>
                        <OutlinedInput
                          placeholder={t('payments.address')}
                          sx={{
                            input: {
                              "&::placeholder": {    // <----- Add this.
                                opacity: 1,
                              },
                            },
                            paddingLeft: '15px',
                            backgroundColor: "#FFFFFF",
                            border: '0px solid grey',
                            borderRadius: '8px',
                            fontSize: mBreakPoint1281_1366 ? '9px'
                              : '12px',
                            fontWeight: '400',
                            lineHeight: '21px',
                            width: '100%',
                            height: mBreakPoint1281_1366 ? '25px'
                              : '40px'
                          }}
                          type="text"
                          value={mERC20Addr}
                          onChange={(e) => setERC20Addr(e.target.value)}
                        />
                      </Box>
                    )}
                    {mPaymentMethod === 4 && (
                      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%' }}>
                        <OutlinedInput
                          placeholder={t('payments.address')}
                          sx={{
                            input: {
                              "&::placeholder": {    // <----- Add this.
                                opacity: 1,
                              },
                            },
                            paddingLeft: '15px',
                            backgroundColor: "#FFFFFF",
                            border: '0px solid grey',
                            borderRadius: '8px',
                            fontSize: mBreakPoint1281_1366 ? '9px'
                              : '12px',
                            fontWeight: '400',
                            lineHeight: '21px',
                            width: '100%',
                            height: mBreakPoint1281_1366 ? '25px'
                              : '40px'
                          }}
                          type="text"
                          value={mTRC20Addr}
                          onChange={(e) => setTRC20Addr(e.target.value)}
                        />
                      </Box>
                    )}
                    {mPaymentMethod === 5 && (
                      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%' }}>
                        <OutlinedInput
                          placeholder={t('payments.address')}
                          sx={{
                            input: {
                              "&::placeholder": {    // <----- Add this.
                                opacity: 1,
                              },
                            },
                            paddingLeft: '15px',
                            backgroundColor: "#FFFFFF",
                            border: '0px solid grey',
                            borderRadius: '8px',
                            fontSize: mBreakPoint1281_1366 ? '9px'
                              : '12px',
                            fontWeight: '400',
                            lineHeight: '21px',
                            width: '100%',
                            height: mBreakPoint1281_1366 ? '25px'
                              : '40px'
                          }}
                          type="text"
                          value={mBitcoinAddr}
                          onChange={(e) => setBitcoinAddr(e.target.value)}
                        />
                      </Box>
                    )}
                    {mPaymentMethod === 6 && (
                      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%' }}>
                        <OutlinedInput
                          placeholder={t('payments.address')}
                          sx={{
                            input: {
                              "&::placeholder": {    // <----- Add this.
                                opacity: 1,
                              },
                            },
                            paddingLeft: '15px',
                            backgroundColor: "#FFFFFF",
                            border: '0px solid grey',
                            borderRadius: '8px',
                            fontSize: mBreakPoint1281_1366 ? '9px'
                              : '12px',
                            fontWeight: '400',
                            lineHeight: '21px',
                            width: '100%',
                            height: mBreakPoint1281_1366 ? '25px'
                              : '40px'
                          }}
                          type="text"
                          value={mEthereumAddr}
                          onChange={(e) => setEthereumAddr(e.target.value)}
                        />
                      </Box>
                    )}
                    {(mPaymentMethod >= 1 && mPaymentMethod <= 6) && (
                      <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                        <Button_ sx={{ marginTop: '5px' }}
                          onClick={() => handleSavePaymentDetail()}
                        >{t('payments.save_payment_details')}</Button_>
                        <Button_ sx={{ marginTop: '5px' }}
                          onClick={() => handleRequestCashback()}
                        >{mStateRequestButton ? <CircularProgress sx={{ color: 'white'}} size={'36px'}/> : t('payments.request_withdrawal')}</Button_>
                      </Box>
                    )}
                  </Box>
                </Box>
                <Box sx={{ width: '50%' }}>
                  <Box sx={{ display: 'flex', justifyContent: 'start', flexDirection: 'row', alignItems: 'center' }}>
                    <Typography_ fontWeight={'500'}>{t('payments.current_balance')}: €{mCurrentBalance?.toFixed(2)}</Typography_>
                    <ToolTipForDesktop title={t('payments.payment_tip')} arrow>
                      <span style={{
                        marginLeft: '10px',
                        zIndex: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: '#ffc500',
                        width: mBreakPoint1281_1366 ? '12px' : '20px',
                        height: mBreakPoint1281_1366 ? '12px' : '20px',
                        borderRadius: '80%',
                        cursor: 'pointer',
                        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
                        pointerEvents: 'auto'
                      }}>
                        <ShiningInfoIcon style={{ fontSize: mBreakPoint1281_1366 ? '14px' : '18px', color: 'yellow' }} />
                      </span>
                    </ToolTipForDesktop>
                  </Box>
                  <Typography_ fontWeight={'500'}>{t('payments.available_for_withdrawal')}: €{mAvailableCashback?.toFixed(2)}</Typography_>
                  <Box sx={{ marginTop: '15px' }}>
                    <Typography_ fontWeight={'400'}>{t('payments.withdrawal_tip')}.</Typography_>
                    <Typography_ style={{ marginTop: '15px', fontWeight: '400' }}>
                      {mPaymentMethod === 1 ? t('payments.payment_withdrawal_tip').replaceAll('{paymentMethod}', 'Netteller').replaceAll('{amount}', 10)
                        : mPaymentMethod === 2 ? t('payments.payment_withdrawal_tip').replaceAll('{paymentMethod}', 'Skrill').replaceAll('{amount}', 10)
                          : mPaymentMethod === 3 ? t('payments.payment_withdrawal_tip').replaceAll('{paymentMethod}', 'USDT ERC20').replaceAll('{amount}', 20)
                            : mPaymentMethod === 4 ? t('payments.payment_withdrawal_tip').replaceAll('{paymentMethod}', 'USDT TRC20').replaceAll('{amount}', 20)
                              : mPaymentMethod === 5 ? t('payments.payment_withdrawal_tip').replaceAll('{paymentMethod}', 'Bitcoin').replaceAll('{amount}', 20)
                                : mPaymentMethod === 6 ? t('payments.payment_withdrawal_tip').replaceAll('{paymentMethod}', 'Ethereum').replaceAll('{amount}', 20)
                                  : ''
                      }
                    </Typography_>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box>
            <Box sx={{ marginTop: mBreakPoint1281_1366 ? '0px' : '45px', borderTop: '1px solid rgba(0, 0, 0, 0.1)', paddingBottom: '8px', display: 'flex', justifyContent: 'space-between', paddingTop: '8px' }}>
              <Typography_ style={{ fontWeight: 400, color: ' rgba(24,24,25,0.7)', width: '19%', marginLeft: '10px' }}>{t('payments.date')}</Typography_>
              <Typography_ style={{ fontWeight: 400, color: ' rgba(24,24,25,0.7)', width: '40%', marginLeft: '10px' }}>{t('getcashback.account')}</Typography_>
              <Typography_ style={{ fontWeight: 400, color: ' rgba(24,24,25,0.7)', width: '15%', marginLeft: '10px' }}>{t('payments.payment_method')}</Typography_>
              <Typography_ style={{ fontWeight: 400, color: ' rgba(24,24,25,0.7)', width: '15%', marginLeft: '10px' }}>{t('payments.amount_fee')}</Typography_>
              <Typography_ style={{ fontWeight: 400, color: ' rgba(24,24,25,0.7)', width: '20%', marginLeft: '10px' }}>{t('dashboard.status')}</Typography_>
            </Box>
            <Box>
              {disPlayItems?.map((item, idx) => (
                <SelectBox index={idx} onClick={() => handleSelectAccont(idx)} sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px', backgroundColor: 'rgba(255,255,255,1)', height: mBreakPoint1281_1366 ? '35px' : '45px', alignItems: 'center', borderRadius: '8px' }}>
                  <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} width={'19%'} variant="overline" color="black" fontWeight='300' fontSize={mBreakPoint1281_1366 ? '9px' : '11px'} lineHeight={'19.6px'} textTransform="none" paddingLeft={'8px'}>
                    {item.regDate}
                  </Box>
                  <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} width={'40%'} variant="overline" color="black" fontWeight={'300'} fontStyle={'normal'} fontSize={mBreakPoint1281_1366 ? '9px' : '11px'} line-lineHeight={'140%'} letterSpacing={'0.5px'}>
                    {item.data1}
                  </Box>
                  < Box display={'flex'} flexDirection={'column'} justifyContent={'center'} width={'15%'} variant="overline" color="black" fontWeight={'300'} fontStyle={'normal'} fontSize={mBreakPoint1281_1366 ? '9px' : '11px'} line-lineHeight={'140%'} letterSpacing={'0.5px'}>
                    {item.PaymentMethod === 1 ? 'Neteller' : item.PaymentMethod === 2 ? 'Skrill' : item.PaymentMethod === 3 ? 'USDT ERC20' : item.PaymentMethod === 4 ? 'USDT TRC20' : item.PaymentMethod === 5 ? 'Bitcoin' : item.PaymentMethod === 6 ? 'Ethereum' : ''}
                  </Box>
                  <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} width={'15%'} variant="overline" color="black" fontWeight={'300'} fontStyle={'normal'} fontSize={mBreakPoint1281_1366 ? '9px' : '11px'} line-lineHeight={'140%'} letterSpacing={'0.5px'}>
                    €{(item.Amount * 98 / 100).toFixed(2)}/€{(item.Amount * 2 / 100).toFixed(2)}
                  </Box>
                  <Box display={'flex'} alignItems={'center'} justifyContent={'center'} width={'20%'} variant="overline" color="black" fontWeight="bold">
                    <Button sx={{ width: '90%', padding: mBreakPoint1281_1366 ? '1px 12px' : '3px 12px', color: "white", fontWeight: "600", fontSize: mBreakPoint1281_1366 ? '9px' : '11px', backgroundColor: item.State === 1 ? '#27AE60' : item.State === 2 ? 'grey' : item.State === 3 ? '#4f455a' : '#352260', borderRadius: "100px", textTransform: 'none' }} >
                      {item.State === 1 ? t('payments.processed') : item.State === 2 ? t('payments.pending') : item.State === 3 ? t('payments.rejected') : t('payments.denied')}
                    </Button>
                  </Box>
                </SelectBox>

              ))}
            </Box>
          </Box>
          {disPlayItems?.length > 0 && (
            <Box sx={{ display: 'flex', padding: mBreakPoint1281_1366 ? "3px 10px" : "3px 20px", backgroundColor: '#F1F2F2', borderRadius: '44px', bottom: '25px', right: '24px', position: 'absolute' }}>
              <CustomPagination
                pageCount={Math.ceil(mPlayerPaymentTranscationHistory?.length / itemPerPage)}
                currentPage={currentPage}
                onChange={handleChangePage}
                inputCurrentPage={inputCurrentPage}
                handleInputChange={handleInputChange}
                handleGoButtonClick={handleGoButtonClick}
              />
            </Box>)}
          <BackDrop open={mLoadingDataFromServer} />
        </Box>
      )}
      {mBreakPoint0_1280 && (
        <Box sx={{ padding: '16px', backgroundColor: 'rgba(250, 250, 250, 0.7)', display: "flex", flexDirection: "column" }}>
          <Box sx={{ backgroundColor: '#FFFFFF', borderRadius: '8px' }}>
            <Box sx={{ width: '100%', backgroundImage: "url('/Images/PaymentSettingTopbar.png')", backgroundSize: 'cover', backgroundRepeat: 'no-repeat', borderRadius: '8px 8px 0 0' }}>
              <Box sx={{ marginRight: '24px', display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', justifyContent: 'end' }} style={{ width: '100%', height: '5vh' }}>
                </Box>
              </Box>
            </Box>
            <Box sx={{ paddingLeft: '24px', paddingRight: '24px' }}>
              <Box sx={{ marginTop: '19px', marginBottom: '30px' }}>
                <Box sx={{ marginTop: '24px' }}>
                  <Box sx={{ display: 'flex', justifyContent: 'start', flexDirection: 'row' }}>
                    <p style={{ fontSize: '14px', fontWeight: 500, lineHeight: '140%', color: 'black', margin: 0 }}>{t('payments.current_balance')}: €{mCurrentBalance?.toFixed(2)}</p>
                    <MobileTooltip title={t('payments.payment_tip')} flag={2} arrow />
                  </Box>
                  <p style={{ fontSize: '14px', fontWeight: 500, lineHeight: '140%', color: 'black', margin: 0 }}>{t('payments.available_for_withdrawal')}: €{mAvailableCashback?.toFixed(2)}</p>
                  <Box sx={{ marginTop: '15px' }}>
                    <p style={{ fontSize: '12px', fontWeight: 400, lineHeight: '140%', color: 'black', margin: 0 }}>{t('payments.withdrawal_tip')}.</p>
                    <p style={{ fontSize: '12px', fontWeight: 400, lineHeight: '140%', color: 'black', margin: 0, marginTop: '15px' }}>
                      {mPaymentMethod === 1 ? t('payments.payment_withdrawal_tip').replaceAll('{paymentMethod}', 'Netteller').replaceAll('{amount}', 10)
                        : mPaymentMethod === 2 ? t('payments.payment_withdrawal_tip').replaceAll('{paymentMethod}', 'Skrill').replaceAll('{amount}', 10)
                          : mPaymentMethod === 3 ? t('payments.payment_withdrawal_tip').replaceAll('{paymentMethod}', 'USDT ERC20').replaceAll('{amount}', 20)
                            : mPaymentMethod === 4 ? t('payments.payment_withdrawal_tip').replaceAll('{paymentMethod}', 'USDT TRC20').replaceAll('{amount}', 20)
                              : mPaymentMethod === 5 ? t('payments.payment_withdrawal_tip').replaceAll('{paymentMethod}', 'Bitcoin').replaceAll('{amount}', 20)
                                : mPaymentMethod === 6 ? t('payments.payment_withdrawal_tip').replaceAll('{paymentMethod}', 'Ethereum').replaceAll('{amount}', 20)
                                  : ''
                      }
                    </p>
                  </Box>
                </Box>
                <Box sx={{ width: '100%', marginTop: '24px' }}>
                  <Box sx={{ border: '1px solid #B4B4B4', borderRadius: '8px' }}>
                    <FormControl fullWidth backgroundColor={'white'}>
                      <Select
                        value={mPaymentMethod}
                        onChange={(e) => handleSelPyamentOption(e.target.value)}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        sx={{ fontSize: '12px', height: '40px', boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 }, '.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': { padding: '5px 10px', display: 'flex', alignItems: "center" } }}
                      >
                        <MenuItem sx={{ height: '30px', fontSize: '12px' }} disabled value={0}>
                          <em>{t('payments.select_withdrawal_method')}</em>
                        </MenuItem>
                        <MenuItem sx={{ height: '30px', fontSize: '12px' }} value={1} >
                          {t('payments.neteller')}
                        </MenuItem>
                        <MenuItem sx={{ height: '30px', fontSize: '12px' }} value={2}>
                          {t('payments.skrill')}
                        </MenuItem>
                        <MenuItem sx={{ height: '30px', fontSize: '12px' }} value={3}>
                          {t('payments.usdt_erc20')}
                        </MenuItem>
                        <MenuItem sx={{ height: '30px', fontSize: '12px' }} value={4}>
                          {t('payments.usdt_trc20')}
                        </MenuItem>
                        <MenuItem sx={{ height: '30px', fontSize: '12px' }} value={5}>
                          {t('payments.bitcoin')}
                        </MenuItem>
                        <MenuItem sx={{ height: '30px', fontSize: '12px' }} value={6}>
                          {t('payments.ethereum')}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <Box sx={{ marginTop: '5px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    {mPaymentMethod === 1 && (
                      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%' }}>
                        <OutlinedInput
                          placeholder={t('dashboard.email')}
                          type="email"
                          sx={{
                            input: {
                              "&::placeholder": {    // <----- Add this.
                                opacity: 1,
                              },
                            }, paddingLeft: '0px', backgroundColor: "#FFFFFF", border: '0px solid grey', borderRadius: '8px', fontSize: '12px', fontWeight: '400', lineHeight: '21px', width: '100%', height: '40px'
                          }}
                          value={mNetellerEmail}
                          onChange={(e) => setNetellerEmail(e.target.value)}
                        />
                        <OutlinedInput
                          placeholder={t('payments.id')}
                          type="id"
                          sx={{
                            input: {
                              "&::placeholder": {
                                opacity: 1,
                              },
                            }, marginTop: '10px', paddingLeft: '16px', backgroundColor: "#FFFFFF", border: '0px solid grey', borderRadius: '8px', fontSize: '12px', fontWeight: '400', lineHeight: '21px', width: '100%', height: '40px'
                          }}
                          value={mNetellerID}
                          onChange={(e) => setNetellerID(e.target.value)}
                        />
                      </Box>
                    )}
                    {mPaymentMethod === 2 && (
                      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%' }}>
                        <OutlinedInput
                          placeholder={t('dashboard.email')}
                          type="email"
                          sx={{
                            input: {
                              "&::placeholder": {    // <----- Add this.
                                opacity: 1,
                              },
                            }, paddingLeft: '0px', backgroundColor: "#FFFFFF", border: '0px solid grey', borderRadius: '8px', fontSize: '12px', fontWeight: '400', lineHeight: '21px', width: '100%', height: '40px'
                          }}
                          value={mSkrillEmail}
                          onChange={(e) => setSkrillEmail(e.target.value)}
                        />
                      </Box>
                    )}
                    {mPaymentMethod === 3 && (
                      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%' }}>
                        <OutlinedInput
                          placeholder={t('payments.address')}
                          type="text"
                          sx={{
                            input: {
                              "&::placeholder": {    // <----- Add this.
                                opacity: 1,
                              },
                            }, paddingLeft: '0', backgroundColor: "#FFFFFF", border: '0px solid grey', borderRadius: '8px', fontSize: '12px', fontWeight: '400', lineHeight: '21px', width: '100%', height: '40px'
                          }}
                          value={mERC20Addr}
                          onChange={(e) => setERC20Addr(e.target.value)}
                        />
                      </Box>
                    )}
                    {mPaymentMethod === 4 && (
                      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%' }}>
                        <OutlinedInput
                          placeholder={t('payments.address')}
                          type="text"
                          sx={{
                            input: {
                              "&::placeholder": {    // <----- Add this.
                                opacity: 1,
                              },
                            }, paddingLeft: '0px', backgroundColor: "#FFFFFF", border: '0px solid grey', borderRadius: '8px', fontWeight: '400', lineHeight: '21px', width: '100%', height: '40px', fontSize: '12px'
                          }}
                          value={mTRC20Addr}
                          onChange={(e) => setTRC20Addr(e.target.value)}
                        />
                      </Box>
                    )}
                    {mPaymentMethod === 5 && (
                      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%' }}>
                        <OutlinedInput
                          placeholder={t('payments.address')}
                          type="text"
                          sx={{
                            input: {
                              "&::placeholder": {    // <----- Add this.
                                opacity: 1,
                              },
                            }, paddingLeft: '0px', backgroundColor: "#FFFFFF", border: '0px solid grey', borderRadius: '8px', fontWeight: '400', lineHeight: '21px', width: '100%', height: '40px', fontSize: '12px'
                          }}
                          value={mBitcoinAddr}
                          onChange={(e) => setBitcoinAddr(e.target.value)}
                        />
                      </Box>
                    )}
                    {mPaymentMethod === 6 && (
                      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%' }}>
                        <OutlinedInput
                          placeholder={t('payments.address')}
                          type="text"
                          sx={{
                            input: {
                              "&::placeholder": {    // <----- Add this.
                                opacity: 1,
                              },
                            }, paddingLeft: '0px', backgroundColor: "#FFFFFF", border: '0px solid grey', borderRadius: '8px', fontWeight: '400', lineHeight: '21px', width: '100%', height: '40px', fontSize: '12px'
                          }}
                          value={mEthereumAddr}
                          onChange={(e) => setEthereumAddr(e.target.value)}
                        />
                      </Box>
                    )}
                    {(mPaymentMethod >= 1 && mPaymentMethod <= 6) && (
                      <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                        <Button sx={{ marginTop: '5px', padding: '5px 10px', color: "#FFFFFF", fontWeight: "400", fontSize: '12px', backgroundColor: "#6C3D9E", borderRadius: "8px", textTransform: 'none', height: '40px', '&:hover': { backgroundColor: 'rgba(99,57,143,0.8)' } }}
                          onClick={() => handleSavePaymentDetail()}
                        >{t('payments.save_payment_details')}</Button>
                        <Button sx={{ marginTop: '5px', padding: '15px 29px', width: '100%', color: "#FFFFFF", fontWeight: "400", fontSize: '12px', backgroundColor: "#6C3D9E", borderRadius: "8px", textTransform: 'none', height: '40px', '&:hover': { backgroundColor: 'rgba(99,57,143,0.8)' } }}
                          onClick={() => handleRequestCashback()}
                        >{mStateRequestButton ? <CircularProgress sx={{ color: 'white'}} size={'36px'}/> : t('payments.request_withdrawal')}</Button>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box>
            <Box sx={{ marginTop: '24px', borderTop: '1px solid rgba(0, 0, 0, 0.1)', display: 'flex', justifyContent: 'start', paddingLeft: '17px', paddingTop: '8px', paddingBottom: '11px' }}>
            </Box>
            <Box>
              {disPlayItems?.length > 0 && disPlayItems.map((item, idx) => (
                <CardContent sx={{ marginTop: '16px', padding: '0', paddingBottom: '1px !important' }}>
                  <SelectBox index={idx} onClick={() => handleSelectAccont(idx)} sx={{ backgroundColor: 'rgba(255,255,255,1)', borderRadius: '4px', width: '100%' }}>
                    <Grid container alignItems="top" sx={{ padding: 2, border: '1px solid #e4dddd', borderColor: '#e4dddd' }}>
                      <Grid item xs={5} style={{ marginTop: '8px' }}>
                        <p style={{ textAlign: 'start', fontSize: '12px', fontWeight: '400', letterSpacing: '-0.3px', textTransform: 'none', margin: 0 }}>{t('payments.date')}</p>
                      </Grid>
                      <Grid item xs={7} style={{ marginTop: '8px' }}>
                        <p style={{ textAlign: 'start', fontSize: '13px', fontWeight: '400', letterSpacing: '-0.3px', textTransform: 'none', margin: 0 }}>{item.regDate}</p>
                      </Grid>
                      <Grid item xs={5} style={{ marginTop: '8px' }}>
                        <p style={{ textAlign: 'start', fontSize: '12px', fontWeight: '400', letterSpacing: '-0.3px', textTransform: 'none', margin: 0 }}>{t('getcashback.account')}</p>
                      </Grid>
                      <Grid item xs={7} style={{ marginTop: '8px' }}>
                        <Box
                          onClick={() => handleForMobileTooltip(idx)}
                        >
                          < Tooltip title={item.data1} open={idx === currentSelectedItem} />
                          <p style={{ textAlign: 'start', fontSize: '13px', fontWeight: '400', letterSpacing: '-0.3px', textTransform: 'none', margin: 0 }}>{item.data1?.substring(0, 23)}{item.data1.length > 23 ? '...' : ''}</p>
                        </Box>
                      </Grid>
                      <Grid item xs={5} style={{ marginTop: '8px', textAlign: 'center', display: 'flex', justifyContent: 'start' }}>
                        <p style={{ textAlign: 'start', fontSize: '12px', fontWeight: '400', letterSpacing: '-0.3px', textTransform: 'none', margin: 0 }}>{t('payments.payment_method')}</p>
                      </Grid>
                      <Grid item xs={7} style={{ marginTop: '8px', textAlign: 'center', display: 'flex', justifyContent: 'start' }}>
                        <p style={{ textAlign: 'start', fontSize: '12px', fontWeight: '400', letterSpacing: '-0.3px', textTransform: 'none', margin: 0 }}>{item.PaymentMethod === 1 ? 'Neteller' : item.PaymentMethod === 2 ? 'Skrill' : item.PaymentMethod === 3 ? 'USDT ERC20' : item.PaymentMethod === 4 ? 'USDT TRC20' : item.PaymentMethod === 5 ? 'Bitcoin' : item.PaymentMethod === 6 ? 'Ethereum' : ''}</p>
                      </Grid>
                      <Grid item xs={5} style={{ marginTop: '8px', textAlign: 'center', display: 'flex', justifyContent: 'start' }}>
                        <p style={{ textAlign: 'start', fontSize: '12px', fontWeight: '400', letterSpacing: '-0.3px', textTransform: 'none', margin: 0 }}>{t('payments.amount_fee')}</p>
                      </Grid>
                      <Grid item xs={7} style={{ marginTop: '8px', textAlign: 'center', display: 'flex', justifyContent: 'start' }}>
                        <p style={{ textAlign: 'start', fontSize: '12px', fontWeight: '400', letterSpacing: '-0.3px', textTransform: 'none', margin: 0 }}>€{(item.Amount * 98 / 100).toFixed(2)}/€{(item.Amount * 2 / 100).toFixed(2)}</p>
                      </Grid>
                      <Grid item xs={6} textAlign="left" style={{ marginTop: '8px', width: '100%' }}>
                        <Button sx={{ width: '90%', padding: '3px 12px', color: "white", fontWeight: "600", fontSize: '12px', backgroundColor: item.State === 1 ? '#27AE60' : item.State === 2 ? 'grey' : item.State === 3 ? '#4f455a' : '#352260', borderRadius: "100px", textTransform: 'none' }} >
                          {item.State === 1 ? t('payments.processed') : item.State === 2 ? t('payments.pending') : item.State === 3 ? t('payments.rejected') : t('payments.denied')}
                        </Button>
                      </Grid>
                    </Grid>
                  </SelectBox>
                </CardContent>

              ))}
            </Box>
          </Box>
          {disPlayItems?.length > 0 && (
            <Box sx={{ marginTop: '24px', display: 'flex', justifyContent: 'center' }}>
              <CustomPagination
                pageCount={Math.ceil(mPlayerPaymentTranscationHistory?.length / itemPerPage)}
                currentPage={currentPage}
                onChange={handleChangePage}
                inputCurrentPage={inputCurrentPage}
                handleInputChange={handleInputChange}
                handleGoButtonClick={handleGoButtonClick}
              />
            </Box>
          )}
          <BackDrop open={mLoadingDataFromServer} />
        </Box>
      )
      }
    </Box >
  )
}

export default PaymentSetting;