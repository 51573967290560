/* eslint-disable react/jsx-pascal-case */
import React, { useContext, useEffect, useState } from "react";
import { OutlinedInput, Box, Button, MenuItem, Select, Typography, Checkbox, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { getLocaleInfo, getStoredAuthToken, storeLocaleInfo } from "../../utils/authToken";
import jwt_decode from 'jwt-decode';
import { countries } from "country-flags-svg";
import alertSZ from "../../utils/alert";
import { useMediaQuery } from 'react-responsive'
import { Helmet } from "react-helmet";
import { makeStyles } from '@material-ui/core/styles';
import { IoMailOutline, IoPersonOutline } from "react-icons/io5";
import { AuthContext } from "../../contexts/AuthContext";
import { getGEOinfo_ipstack } from "../../utils/getGEO";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { localizationCountry } from "../../utils/localization";
import { API_R_GETACCOUNT, API_U_NOTIFICATION, API_U_PASSWORD, API_U_PROFILE } from "../../share/api";
import { useTheme } from '@mui/material/styles';

const useStyles = makeStyles({
    input: {
        WebkitBoxShadow: '0 0 0 1000px white inset',
    },
});

const dxtmpValue = '10px';

const AccountSetting = () => {

    const { isLogin } = useContext(AuthContext);
    const { setLanguage } = useContext(AuthContext);
    const history = useNavigate();
    const authtoken = getStoredAuthToken();
    const email = authtoken ? jwt_decode(authtoken).email : null;
    if (!isLogin || !email) {
        history('/signin');
    }

    const { t, i18n } = useTranslation();
    const mBreakPoint0_1280 = useMediaQuery({ query: '(min-width: 0px) and (max-width: 1280px)' });
    const mBreakPoint1281_1366 = useMediaQuery({ query: '(min-width: 1281px) and (max-width: 1768px)' });

    setLanguage(getLocaleInfo() ? getLocaleInfo() : 'en');
    const launageIndex = getLocaleInfo() ? localizationCountry.findIndex(item => item.languageCode === getLocaleInfo()) : 0
    const [mLanguageCountryIndex, setLanguageCountryIndex] = useState(launageIndex);
    const { setDealType } = useContext(AuthContext);
    setDealType(0);
    const [mCurrentPWD, setCurrentPWD] = React.useState();
    const [mNewPWD, setNewPWD] = React.useState();
    const [mNewConfirmPWD, setNewConfirmPWD] = React.useState();
    const [mUserEmail, setUserEmail] = useState()
    const [mUserFirstName, setUserFirstName] = useState()
    const [mUserLastName, setUserLastName] = useState()
    const [mOriCountryIndex, setOriCountryIndex] = useState();
    const [mUseId, setUseId] = useState()
    const [mCountryIndex, setCountryIndex] = useState(0);
    const [mStatusNotificationEmail, setStatusNotificationEmail] = useState(true);
    const [mStatusForUpdatePassword, setStatusForUpdatePassword] = useState(false);
    const [mStatusForUpdateDetail, setStatusForUpdateDetail] = useState(false);

    const Button_ = styled(Button)({
        padding: mBreakPoint1281_1366 ? '1px 29px'
            : '15px 29px',
        color: "#FFFFFF",
        fontWeight: "400",
        fontSize: mBreakPoint1281_1366 ? '9px'
            : '14px',
        backgroundColor: "#6C3D9E",
        borderRadius: "8px",
        textTransform: 'none',
        height: mBreakPoint1281_1366 ? '25px'
            : '40px',
        width: '100%',
        '&:hover': { backgroundColor: 'rgba(99,57,143,0.8)' }
    });

    const MenuItem_ = styled(MenuItem)(({ theme }) => ({
        height: mBreakPoint1281_1366 ? '25px'
            : theme.breakpoints.between('1367', '1440') ? '25px'
                : '30px',
        fontSize: mBreakPoint1281_1366 ? '10px'
            : '14px',
    }));

    const Typography_ = styled(Typography)({
        fontSize: mBreakPoint1281_1366 ? '9px'
            : '14px',
        lineHeight: '140%',
        color: 'black',
        margin: 0
    });
    const styleClass = useStyles();
    useEffect(() => {
        const getUserAccount = async () => {
            await axios.post(API_R_GETACCOUNT, {
                email,
            }).then(res => {
                const body = res.data;
                if (res.status === 200) {
                    setUseId(body?._id)
                    setUserEmail(body?.email)
                    setUserFirstName(body?.firstName)
                    setUserLastName(body?.lastName)
                    setCountryIndex(getCountryCodewithName(body?.countryName))
                    setOriCountryIndex(getCountryCodewithName(body?.countryName))
                    setStatusNotificationEmail(body?.emailNotification);
                    const langCode = body?.langCode ? body?.langCode : 'en';
                    setLanguage(langCode);
                    const langIndex = localizationCountry.findIndex(item => item.languageCode === langCode);
                    setLanguageCountryIndex(langIndex ? langIndex : 0);
                    return;
                }
            }).catch(e => {
                const text = e.response.data;
                alertSZ(t(`backend.${text}`))
                history('/signin')
                return
            })
        }
        if (isLogin && email) {
            getUserAccount();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [email, history, isLogin])
    const getCountryCodewithName = (countryName) => {
        const countryCode = Object.keys(countries).find(
            (code) => countries[code].name === countryName
        );
        return countryCode || "Unknown";
    }
    const handleClickUpdateDetail = async () => {

        const mCountryName = countries[mCountryIndex].name;

        let state = 'NoState';
        if (mCountryIndex !== mOriCountryIndex) {
            const result = await getGEOinfo_ipstack();
            state = result.region_name ? result.region_name : 'NoState';
        }

        if (!mUserEmail || !mUserFirstName || !mUserLastName || !mCountryName) {
            alertSZ(t('setting.invalid_missing_data_please_try_again'))
            return;
        }

        setStatusForUpdateDetail(true);
        const langCode = localizationCountry[mLanguageCountryIndex] ? localizationCountry[mLanguageCountryIndex].languageCode : 'en';
        await axios.post(API_U_PROFILE, {
            mUseId, mUserEmail, mUserFirstName, mUserLastName, mCountryName, state, langCode
        }).then(res => {
            setStatusForUpdateDetail(false);
            const body = res.data;
            if (res.status === 200) {
                alertSZ(t(`backend.${body}`));
                return;
            }
        }).catch(e => {
            setStatusForUpdateDetail(false);
            const text = e.response.data;
            alertSZ(t(`backend.${text}`));
            return
        })
    }
    const handleChangePWD = async () => {
        if (!mCurrentPWD || !mNewPWD || !mNewConfirmPWD) {
            alertSZ(t('setting.please_provide_me_a_password'))
            return;
        }
        if (mNewPWD !== mNewConfirmPWD) {
            alertSZ(t('setting.password_and_confirm_password_did_not_match'))
            return
        }
        setStatusForUpdatePassword(true);
        await axios.post(API_U_PASSWORD, {
            email, mCurrentPWD, mNewPWD
        }).then(res => {
            setStatusForUpdatePassword(false);
            const body = res.data;
            if (res.status === 200) {
                alertSZ(t(`backend.${body}`))
                return;
            }
        }).catch(e => {
            setStatusForUpdatePassword(false);
            const text = e.response.data;
            alertSZ(t(`backend.${text}`))
            return
        })
    }

    const handleNotificationStatus = async () => {
        const status = !mStatusNotificationEmail;
        setStatusNotificationEmail(status);
        await axios.post(API_U_NOTIFICATION, {
            email, status
        }).then(res => {
            const body = res.data;
            if (res.status === 200) {
                alertSZ(t(`backend.${body}`))
                return;
            }
        }).catch(e => {
            const text = e.response.data;
            alertSZ(t(`backend.${text}`))
            return
        })
    }

    useEffect(() => {
        const lanuageCode = localizationCountry[mLanguageCountryIndex] ? localizationCountry[mLanguageCountryIndex].languageCode : 'en';
        setLanguage(lanuageCode);
        storeLocaleInfo(lanuageCode)
        i18n.changeLanguage(lanuageCode);
    }, [i18n, mLanguageCountryIndex, setLanguage]);

    const theme = useTheme();
    return (
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', fontFamily: theme.typography.fontFamily }}>
            <Helmet>
                <title>{t('setting.settings')} - Multifun</title>
            </Helmet>
            {!mBreakPoint0_1280 && (
                <Box sx={{ width: mBreakPoint1281_1366 ? '40%' : '35%', marginTop: mBreakPoint1281_1366 ? '35px' : '50px' }}>
                    <Box sx={{ paddingBottom: mBreakPoint1281_1366 ? '20px' : '30px', backgroundColor: 'white', borderRadius: '30px 30px 0 0 ', boxShadow: '6px 6px 50px rgba(0, 0, 0, 0.05)' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Typography_ style={{ marginTop: mBreakPoint1281_1366 ? '20px' : '32px', fontWeight: 600 }}>{t('setting.change_password')}</Typography_>
                        </Box>
                        <Box sx={{ paddingLeft: '48px', paddingRight: '48px', marginTop: mBreakPoint1281_1366 ? '10px' : dxtmpValue }}>
                            <Box sx={{ marginTop: mBreakPoint1281_1366 ? '10px' : dxtmpValue }}>
                                <OutlinedInput
                                    type="password"
                                    autoComplete="off"
                                    sx={{
                                        input: {
                                            "&::placeholder": {    // <----- Add this.
                                                opacity: 1,
                                            },
                                        },
                                        paddingLeft: '15px',
                                        backgroundColor: "#FFFFFF",
                                        border: '0px solid grey',
                                        borderRadius: '8px',
                                        fontSize: mBreakPoint1281_1366 ? '9px'
                                            : '12px',
                                        fontWeight: '400',
                                        lineHeight: '21px',
                                        width: '100%',
                                        height: mBreakPoint1281_1366 ? '25px'
                                            : '40px'
                                    }}
                                    placeholder={t('setting.current_password')}
                                    classes={{ input: styleClass.input }}
                                    value={mCurrentPWD}
                                    onChange={(e) => setCurrentPWD(e.target.value)}
                                />
                            </Box>
                            <Box sx={{ marginTop: mBreakPoint1281_1366 ? '10px' : dxtmpValue }}>
                                <OutlinedInput
                                    type="password"
                                    autoComplete="off"
                                    sx={{
                                        input: {
                                            "&::placeholder": {    // <----- Add this.
                                                opacity: 1,
                                            },
                                        },
                                        paddingLeft: '15px',
                                        backgroundColor: "#FFFFFF",
                                        border: '0px solid grey',
                                        borderRadius: '8px',
                                        fontSize: mBreakPoint1281_1366 ? '9px'
                                            : '12px',
                                        fontWeight: '400',
                                        lineHeight: '21px',
                                        width: '100%',
                                        height: mBreakPoint1281_1366 ? '25px'
                                            : '40px'
                                    }}
                                    placeholder={t('setting.new_password')}
                                    classes={{ input: styleClass.input }}
                                    value={mNewPWD}
                                    onChange={(e) => setNewPWD(e.target.value)}
                                />
                            </Box>
                            <Box sx={{ marginTop: mBreakPoint1281_1366 ? '10px' : dxtmpValue }}>
                                <OutlinedInput
                                    type="password"
                                    autoComplete="off"
                                    sx={{
                                        input: {
                                            "&::placeholder": {    // <----- Add this.
                                                opacity: 1,
                                            },
                                        },
                                        paddingLeft: '15px',
                                        backgroundColor: "#FFFFFF",
                                        border: '0px solid grey',
                                        borderRadius: '8px',
                                        fontSize: mBreakPoint1281_1366 ? '9px'
                                            : '12px',
                                        fontWeight: '400',
                                        lineHeight: '21px',
                                        width: '100%',
                                        height: mBreakPoint1281_1366 ? '25px'
                                            : '40px'
                                    }}
                                    placeholder={t('signuppage.confirm_password')}
                                    classes={{ input: styleClass.input }}
                                    value={mNewConfirmPWD}
                                    onChange={(e) => setNewConfirmPWD(e.target.value)}
                                />
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: mBreakPoint1281_1366 ? '10px' : dxtmpValue, width: '100%' }}>
                                <Button_ width={'100%'}
                                    onClick={() => handleChangePWD()}
                                >{mStatusForUpdatePassword ? <CircularProgress sx={{ color: 'white' }} size={'36px'} /> : t('setting.update_password')}</Button_>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{ paddingBottom: mBreakPoint1281_1366 ? '20px' : '30px', borderTop: 'solid 1px rgba(217, 217, 217, 1)', backgroundColor: 'white', borderRadius: '0 0  30px 30px', boxShadow: '6px 6px 50px rgba(0, 0, 0, 0.05)' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Typography_ style={{ margin: 0, marginTop: mBreakPoint1281_1366 ? '20px' : '32px', fontWeight: 600, color: '#131517' }}>{t('setting.update_personal_details')}</Typography_>
                        </Box>
                        <Box sx={{ paddingLeft: '48px', paddingRight: '48px', marginTop: mBreakPoint1281_1366 ? '10px' : dxtmpValue }}>
                            <Box sx={{ width: '100%', marginTop: '10px' }}>
                                <OutlinedInput
                                    placeholder={t('setting.email_address')}
                                    classes={{ input: styleClass.input }}
                                    sx={{
                                        input: {
                                            "&::placeholder": {    // <----- Add this.
                                                opacity: 1,
                                            },
                                        },
                                        paddingLeft: '15px',
                                        backgroundColor: "#FFFFFF",
                                        border: '0px solid grey',
                                        borderRadius: '8px',
                                        fontSize: mBreakPoint1281_1366 ? '9px'
                                            : '14px',
                                        fontWeight: '400',
                                        lineHeight: '21px',
                                        width: '100%',
                                        height: mBreakPoint1281_1366 ? '25px'
                                            : '40px'
                                    }}
                                    startAdornment={
                                        <IoMailOutline style={{ fontSize: '25px', color: 'grey', marginLeft: '15px', marginRight: '15px' }} />
                                    }
                                    value={mUserEmail}
                                    inputProps={{
                                        style: {
                                            textTransform: 'lowercase',
                                        }
                                    }}
                                />
                            </Box>
                            <Box sx={{ marginTop: mBreakPoint1281_1366 ? '10px' : dxtmpValue, display: 'flex', justifyContent: 'center' }}>
                                <Box sx={{ paddingRight: mBreakPoint1281_1366 ? '10px' : dxtmpValue, width: '48%' }}>
                                    <OutlinedInput
                                        placeholder={t('signuppage.first_name')}
                                        classes={{ input: styleClass.input }}
                                        sx={{
                                            input: {
                                                "&::placeholder": {    // <----- Add this.
                                                    opacity: 1,
                                                },
                                            },
                                            paddingLeft: '15px',
                                            backgroundColor: "#FFFFFF",
                                            border: '0px solid grey',
                                            borderRadius: '8px',
                                            fontSize: mBreakPoint1281_1366 ? '9px'
                                                : '12px',
                                            fontWeight: '400',
                                            lineHeight: '21px',
                                            width: '100%',
                                            height: mBreakPoint1281_1366 ? '25px'
                                                : '40px'
                                        }}
                                        startAdornment={
                                            <IoPersonOutline style={{ fontSize: '25px', color: 'grey', marginLeft: '15px', marginRight: '15px' }} />
                                        }
                                        value={mUserFirstName}
                                        onChange={(e) => setUserFirstName(e.target.value)}
                                    />
                                </Box>
                                <Box sx={{ width: '48%' }}>
                                    <OutlinedInput
                                        placeholder={t('signuppage.last_name')}
                                        sx={{
                                            input: {
                                                "&::placeholder": {    // <----- Add this.
                                                    opacity: 1,
                                                },
                                            },
                                            paddingLeft: '15px',
                                            backgroundColor: "#FFFFFF",
                                            border: '0px solid grey',
                                            borderRadius: '8px',
                                            fontSize: mBreakPoint1281_1366 ? '9px'
                                                : '12px',
                                            fontWeight: '400',
                                            lineHeight: '21px',
                                            width: '100%',
                                            height: mBreakPoint1281_1366 ? '25px'
                                                : '40px'
                                        }}
                                        classes={{ input: styleClass.input }}
                                        startAdornment={
                                            <IoPersonOutline style={{ fontSize: '25px', color: 'grey', marginLeft: '15px', marginRight: '15px' }} />
                                        }
                                        value={mUserLastName}
                                        onChange={(e) => setUserLastName(e.target.value)}
                                    />
                                </Box>
                            </Box>
                            <Box sx={{ marginTop: mBreakPoint1281_1366 ? '10px' : '15px', display: 'flex', justifyContent: 'space-between' }}>
                                <Select
                                    value={mCountryIndex}
                                    onChange={(e) => setCountryIndex(e.target.value)}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    sx={{
                                        boxShadow: 'none',
                                        '.MuiOutlinedInput-notchedOutline': {
                                            border: 1, color: 'rgba(0,0,0,0.2)',
                                            borderRadius: '8px'
                                        },
                                        width: '100%',
                                        '.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': { padding: '13px 14px' },
                                        height: mBreakPoint1281_1366 ? '25px' : '40px',
                                        fontSize: mBreakPoint1281_1366 ? '9px' : '12px'
                                    }}
                                >
                                    {countries && countries?.map((item, index) => (
                                        <MenuItem_ value={index} display={'flex'}>
                                            <Box sx={{ display: 'flex', justifyContent: 'start' }}>
                                                <Box sx={{ marginRight: '5px', display: 'flex', paddingLeft: '5px' }}>
                                                    <img width={mBreakPoint1281_1366 ? '20vw' : '30vw'} src={item.flag} sx={{ marginRight: '10px' }} alt="" />
                                                </Box>
                                                <Box sx={{ marginLeft: '5px', display: 'flex' }}>
                                                    {item.name}
                                                </Box>
                                            </Box>
                                        </MenuItem_>
                                    ))}
                                </Select>
                            </Box>
                            <Box sx={{ marginTop: mBreakPoint1281_1366 ? '10px' : '15px', display: 'flex', justifyContent: 'space-between' }}>
                                <Select
                                    value={mLanguageCountryIndex}
                                    onChange={(e) => setLanguageCountryIndex(e.target.value)}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    sx={{
                                        boxShadow: 'none',
                                        '.MuiOutlinedInput-notchedOutline': {
                                            border: 1, color: 'rgba(0,0,0,0.2)',
                                            borderRadius: '8px'
                                        },
                                        width: '100%',
                                        '.MuiOutlinedInput-input': { padding: '13px 20px' },
                                        height: mBreakPoint1281_1366 ? '25px' : '40px',
                                        fontSize: mBreakPoint1281_1366 ? '9px' : '12px'
                                    }}

                                >
                                    {localizationCountry?.map((item, index) => (
                                        <MenuItem value={index} display={'flex'}>
                                            <Box sx={{ display: 'flex', justifyContent: 'start' }}>
                                                <Box sx={{ marginRight: '5px', display: 'flex' }}>
                                                    <img width={mBreakPoint1281_1366 ? '20vw' : '30vw'} src={countries?.find(ITM => ITM.name === item.countryName)?.flag} sx={{ marginRight: '10px' }} alt="" />
                                                </Box>
                                                <Box sx={{ marginLeft: '5px', display: 'flex', fontSize: mBreakPoint1281_1366 ? '9px' : '11px' }}>
                                                    {item.languageName} Language
                                                </Box>
                                            </Box>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start', marginTop: mBreakPoint1281_1366 ? '10px' : dxtmpValue, width: '100%' }}>
                                <Checkbox
                                    checked={mStatusNotificationEmail}
                                    onClick={() => handleNotificationStatus()}
                                />
                                <p style={{ margin: 0, fontWeight: 400, fontSize: mBreakPoint1281_1366 ? '9px' : '12px', lineHeight: '24px', alignItems: 'center', textAlign: 'center', color: 'black' }}>
                                    {t('setting.check_email_notification')}
                                </p>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: mBreakPoint1281_1366 ? '10px' : dxtmpValue, width: '100%' }}>
                                <Button_
                                    onClick={handleClickUpdateDetail}
                                >{mStatusForUpdateDetail ? <CircularProgress sx={{ color: 'white' }} size={'36px'} /> : t('setting.update_details')}</Button_>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            )}
            {mBreakPoint0_1280 && (
                <Box sx={{ padding: '24px 16px' }}>
                    <Box sx={{ paddingBottom: '30px', backgroundColor: 'white', borderRadius: '30px 30px 0 0 ', boxShadow: '6px 6px 50px rgba(0, 0, 0, 0.05)' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <p style={{ margin: 0, marginTop: '24px', fontSize: '14px', fontWeight: 600, lineHeight: '36px', color: '#131517' }}>{t('setting.change_password')}</p>
                        </Box>
                        <Box sx={{ paddingLeft: '24px', paddingRight: '24px', marginTop: dxtmpValue }}>
                            <Box sx={{ marginTop: dxtmpValue }}>
                                <OutlinedInput
                                    placeholder={t('setting.current_password')}
                                    type="password"
                                    sx={{ paddingLeft: '16px', backgroundColor: "#FFFFFF", border: 'border: 1px solid #B4B4B4', borderRadius: '8px', fontSize: '12px', fontWeight: '400', lineHeight: '21px', width: '100%', height: '45px' }}
                                    value={mCurrentPWD}
                                    onChange={(e) => setCurrentPWD(e.target.value)}
                                />
                            </Box>
                            <Box sx={{ marginTop: dxtmpValue }}>
                                <OutlinedInput
                                    placeholder={t('setting.new_password')}
                                    sx={{ paddingLeft: '16px', backgroundColor: "#FFFFFF", border: 'border: 1px solid #B4B4B4', borderRadius: '8px', fontSize: '12px', fontWeight: '400', lineHeight: '21px', width: '100%', height: '45px' }}
                                    type="password"
                                    value={mNewPWD}
                                    onChange={(e) => setNewPWD(e.target.value)}
                                />
                            </Box>
                            <Box sx={{ marginTop: dxtmpValue }}>
                                <OutlinedInput
                                    placeholder={t('signuppage.confirm_password')}
                                    sx={{ paddingLeft: '16px', backgroundColor: "#FFFFFF", border: 'border: 1px solid #B4B4B4', borderRadius: '8px', fontSize: '12px', fontWeight: '400', lineHeight: '21px', width: '100%', height: '45px' }}
                                    type="password"
                                    value={mNewConfirmPWD}
                                    onChange={(e) => setNewConfirmPWD(e.target.value)}
                                />
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: dxtmpValue, width: '100%' }}>
                                <Button sx={{ padding: '16px 51px', width: '100%', color: "#FFFFFF", fontWeight: "400", fontSize: '12px', backgroundColor: "#6C3D9E", borderRadius: "8px", textTransform: 'none', height: '45px', '&:hover': { backgroundColor: 'rgba(99,57,143,0.8)' } }}
                                    onClick={() => handleChangePWD()}
                                >
                                    {mStatusForUpdatePassword ? <CircularProgress sx={{ color: 'white' }} size={'36px'} /> : t('setting.update_password')}
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{ paddingBottom: '24px', borderTop: 'solid 1px rgba(217, 217, 217, 1)', backgroundColor: 'white', borderRadius: '0 0  30px 30px', boxShadow: '6px 6px 50px rgba(0, 0, 0, 0.05)' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <p style={{ margin: 0, marginTop: '24px', fontSize: '14px', fontWeight: 600, lineHeight: '36px', color: '#131517' }}>{t('setting.update_personal_details')}</p>
                        </Box>
                        <Box sx={{ paddingLeft: '24px', paddingRight: '24px', marginTop: dxtmpValue }}>
                            <Box sx={{ width: '100%', marginTop: '10px' }}>
                                <OutlinedInput
                                    placeholder={t('setting.email_address')}
                                    sx={{ padding: 0, backgroundColor: "#FFFFFF", border: 'border: 1px solid #B4B4B4', borderRadius: '8px', fontSize: mBreakPoint1281_1366 ? '9px' : '14px', fontWeight: '400', lineHeight: '21px', width: '100%', height: '45px' }}
                                    classes={{ input: styleClass.input }}
                                    startAdornment={
                                        <IoMailOutline style={{ fontSize: '25px', color: 'grey', marginLeft: '15px', marginRight: '15px' }} />
                                    }
                                    value={mUserEmail}
                                    inputProps={{
                                        style: {
                                            textTransform: 'lowercase',
                                        }
                                    }}
                                />
                            </Box>
                            <Box sx={{ marginTop: dxtmpValue, display: 'flex', justifyContent: 'center' }}>
                                <Box sx={{ width: '100%' }}>
                                    <OutlinedInput
                                        placeholder={t('signuppage.first_name')}
                                        sx={{ backgroundColor: "#FFFFFF", border: 'border: 1px solid #B4B4B4', borderRadius: '8px', fontSize: '12px', fontWeight: '400', lineHeight: '21px', width: '100%', height: '45px' }}
                                        classes={{ input: styleClass.input }}
                                        startAdornment={
                                            <IoPersonOutline style={{ fontSize: '25px', color: 'grey', marginLeft: '5px', marginRight: '15px' }} />
                                        }
                                        value={mUserFirstName}
                                        onChange={(e) => setUserFirstName(e.target.value)}
                                    />
                                </Box>
                                <Box sx={{ paddingLeft: '8px', width: '100%' }}>
                                    <OutlinedInput
                                        placeholder={t('signuppage.last_name')}
                                        sx={{ backgroundColor: "#FFFFFF", border: 'border: 1px solid #B4B4B4', borderRadius: '8px', fontSize: '12px', fontWeight: '400', lineHeight: '21px', width: '100%', height: '45px' }}
                                        classes={{ input: styleClass.input }}
                                        startAdornment={
                                            <IoPersonOutline style={{ fontSize: '25px', color: 'grey', marginLeft: '5px', marginRight: '15px' }} />
                                        }
                                        value={mUserLastName}
                                        onChange={(e) => setUserLastName(e.target.value)}
                                    />
                                </Box>
                            </Box>
                            <Box sx={{ marginTop: '15px', display: 'flex', justifyContent: 'space-between' }}>
                                <Select
                                    value={mCountryIndex}
                                    onChange={(e) => setCountryIndex(e.target.value)}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 1, color: 'rgba(0,0,0,0.2)', borderRadius: '8px' }, width: '100%', '.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': { padding: '13px 14px' }, height: '40px', fontSize: '12px' }}
                                >
                                    {countries && countries?.map((item, index) => (
                                        <MenuItem_ value={index} display={'flex'}>
                                            <Box sx={{ display: 'flex', justifyContent: 'start' }}>
                                                <Box sx={{ marginRight: '5px', display: 'flex', paddingLeft: '5px' }}>
                                                    <img width="30vh" src={item.flag} sx={{ marginRight: '10px' }} alt="" />
                                                </Box>
                                                <Box sx={{ marginLeft: '5px', display: 'flex', fontSize: '12px' }}>
                                                    {item.name}
                                                </Box>
                                            </Box>
                                        </MenuItem_>
                                    ))}
                                </Select>
                            </Box>
                            <Box sx={{ marginTop: '15px', display: 'flex', justifyContent: 'space-between' }}>
                                <Select
                                    value={mLanguageCountryIndex}
                                    onChange={(e) => setLanguageCountryIndex(e.target.value)}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    sx={{
                                        fontSize: '12px',
                                        height: '40px', width: '100%',
                                        boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 1, color: 'rgba(0,0,0,0.2)', borderRadius: '8px' },
                                        '.MuiOutlinedInput-input': { padding: '1px 20px' }
                                    }}

                                >
                                    {localizationCountry?.map((item, index) => (
                                        <MenuItem value={index} display={'flex'}>
                                            <Box sx={{ display: 'flex', justifyContent: 'start' }}>
                                                <Box sx={{ marginRight: '5px', display: 'flex' }}>
                                                    <img width='30vw' src={countries?.find(ITM => ITM.name === item.countryName)?.flag} sx={{ marginRight: '10px' }} alt="" />
                                                </Box>
                                                <Box sx={{ marginLeft: '5px', display: 'flex', fontSize: '12px' }}>
                                                    {item.languageName} Language
                                                </Box>
                                            </Box>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start', marginTop: dxtmpValue, width: '100%' }}>
                                <Checkbox
                                    checked={mStatusNotificationEmail}
                                    onClick={() => handleNotificationStatus()}
                                />
                                <p style={{ margin: 0, fontWeight: 400, fontSize: '12px', lineHeight: '24px', alignItems: 'center', textAlign: 'center', color: 'black' }}>
                                    {t('setting.check_email_notification')}
                                </p>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: dxtmpValue, width: '100%' }}>
                                <Button sx={{ padding: '16px 51px', width: '100%', color: "#FFFFFF", fontWeight: "400", fontSize: '12px', backgroundColor: "#6C3D9E", borderRadius: "8px", textTransform: 'none', height: '45px', '&:hover': { backgroundColor: 'rgba(99,57,143,0.8)' } }}
                                    onClick={handleClickUpdateDetail}
                                >{mStatusForUpdateDetail ? <CircularProgress sx={{ color: 'white' }} size={'36px'} /> : t('setting.update_details')}</Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            )}
        </Box>
    )
}

export default AccountSetting;